define("client/controllers/settings/exports", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    account: Ember.computed.alias('model.taskInstance.value'),
    downloadUrl: Ember.computed('session.isAuthenticated', function () {
      var token = Ember.get(this, 'session.data.authenticated.access_token');
      return "/api/edge/library-entries/_xml?access_token=".concat(token);
    }),
    animeDownloadUrl: Ember.computed('downloadUrl', function () {
      return "".concat(this.downloadUrl, "&kind=anime");
    }),
    mangaDownloadUrl: Ember.computed('downloadUrl', function () {
      return "".concat(this.downloadUrl, "&kind=manga");
    })
  });

  _exports.default = _default;
});