define("client/serializers/library-entry", ["exports", "client/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    metrics: Ember.inject.service(),
    session: Ember.inject.service(),
    attrs: {
      review: {
        serialize: false
      },
      unit: {
        serialize: false
      },
      nextUnit: {
        serialize: false
      }
    },
    serializeAttribute: function serializeAttribute(snapshot, json, key) {
      this._super.apply(this, arguments);

      if (key === 'ratingTwenty' && key in snapshot.changedAttributes()) {
        Ember.get(this, 'session.account').incrementProperty('ratingsCount'); // If rating is changed we want to send that data to Stream

        if (!Number.isNaN(json.attributes[key])) {
          var media = Ember.get(snapshot, 'record.media');
          var mediaType = Ember.String.capitalize(Ember.get(media, 'modelType'));
          Ember.get(this, 'metrics').invoke('trackEngagement', 'Stream', {
            label: 'rating',
            content: "".concat(mediaType, ":").concat(Ember.get(media, 'id')),
            boost: json.attributes[key]
          });
        }
      }
    }
  });

  _exports.default = _default;
});