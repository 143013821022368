define("client/components/application/auth-onboarding/rate-content/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    onCreate: function onCreate() {},
    onRemove: function onRemove() {},
    onUpdate: function onUpdate() {}
  });

  _exports.default = _default;
});