define("client/templates/components/groups/dashboard/audit-log-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GYBuGScY",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"buildMessageTask\",\"isRunning\"]]],null,{\"statements\":[[0,\"  \"],[1,[23,\"loading-spinner\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[29,\"lazy-image\",null,[[\"src\"],[[29,\"image\",[[25,[\"item\",\"user\",\"avatar\"]],\"tiny\"],null]]]],false],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"audit-log-item-message\"],[9],[0,\"\\n    \"],[7,\"a\"],[12,\"href\",[29,\"href-to\",[\"users.index\",[25,[\"item\",\"user\"]]],null]],[9],[0,\"\\n      \"],[1,[25,[\"item\",\"user\",\"name\"]],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[1,[29,\"html-safe\",[[25,[\"message\"]]],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/groups/dashboard/audit-log-item.hbs"
    }
  });

  _exports.default = _default;
});