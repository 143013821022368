define("client/models/library-entry-log", ["exports", "client/models/-base", "ember-data/attr", "ember-data/relationships"], function (_exports, _base, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    actionPerformed: (0, _attr.default)('string'),
    createdAt: (0, _attr.default)('utc'),
    errorMessage: (0, _attr.default)('string'),
    progress: (0, _attr.default)('number'),
    rating: (0, _attr.default)('rating'),
    reconsumeCount: (0, _attr.default)('number'),
    status: (0, _attr.default)('string'),
    syncStatus: (0, _attr.default)('string'),
    linkedAccount: (0, _relationships.belongsTo)('linked-account'),
    media: (0, _relationships.belongsTo)('media'),
    formattedDate: Ember.computed('createdAt', function () {
      return Ember.get(this, 'createdAt').format('YYYY-MM-DD');
    })
  });

  _exports.default = _default;
});