define("client/templates/components/facebook-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q8GxCtws",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"svg-jar\",[\"l-facebook\"],null],false],[0,\"\\n\"],[7,\"span\"],[11,\"class\",\"button-label button-label--multiLine\"],[9],[0,\"\\n  \"],[1,[23,\"text\"],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/facebook-button.hbs"
    }
  });

  _exports.default = _default;
});