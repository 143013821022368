define("client/serializers/review", ["exports", "client/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      contentFormatted: {
        serialize: false
      },
      progress: {
        serialize: false
      },
      rating: {
        serialize: false
      },
      likesCount: {
        serialize: false
      }
    }
  });

  _exports.default = _default;
});