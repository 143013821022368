define("client/components/admin/report-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'table',
    classNames: ['table', 'table-hover', 'table-sm'],
    canReject: true
  });

  _exports.default = _default;
});