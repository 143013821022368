define("client/components/media/media-reaction-card", ["exports", "client/components/media/media-reaction"], function (_exports, _mediaReaction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mediaReaction.default.extend({
    classNames: ['reaction-thumb', 'card']
  });

  _exports.default = _default;
});