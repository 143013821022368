define("client/controllers/manga/show/characters", ["exports", "client/controllers/media/show/characters"], function (_exports, _characters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _characters.default.extend();

  _exports.default = _default;
});