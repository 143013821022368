define("client/routes/anime/show/characters", ["exports", "client/routes/media/show/characters"], function (_exports, _characters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = _characters.default.extend({
    ajax: Ember.inject.service(),

    /**
     * Retrieve the languages available. This is a temporary setup until our Casting setup has
     * been migrated.
     */
    beforeModel: function beforeModel(_ref) {
      var _this = this;

      var queryParams = _ref.queryParams;
      var controller = this.controllerFor(Ember.get(this, 'routeName'));

      if (Ember.isEmpty(Ember.get(controller, 'availableLanguages'))) {
        var _Ember$get$split = Ember.get(this, 'routeName').split('.'),
            _Ember$get$split2 = _slicedToArray(_Ember$get$split, 1),
            mediaType = _Ember$get$split2[0];

        var media = this.modelFor("".concat(mediaType, ".show"));
        var id = Ember.get(media, 'id');
        return Ember.get(this, 'ajax').request("/anime/".concat(id, "/_languages")).then(function (results) {
          var languages = results.map(function (result) {
            return Ember.String.capitalize(result);
          });
          Ember.set(controller, 'availableLanguages', languages);

          _this._languageCheck(queryParams);
        });
      }

      this._languageCheck(queryParams);
    },
    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      }
    },
    _getFilters: function _getFilters(_ref2) {
      var language = _ref2.language;
      var controller = this.controllerFor(Ember.get(this, 'routeName'));
      var availableLanguages = Ember.get(controller, 'availableLanguages'); // If it's empty then don't filter by language at all. Shouldn't happen tho.

      if (Ember.isEmpty(availableLanguages)) {
        return {};
      }

      return {
        language: Ember.String.capitalize(language || Ember.get(controller, 'language'))
      };
    },
    _languageCheck: function _languageCheck(queryParams) {
      var controller = this.controllerFor(Ember.get(this, 'routeName'));
      var languages = Ember.get(controller, 'availableLanguages');
      var language = Ember.String.capitalize(Ember.get(queryParams, 'language') || Ember.get(controller, 'language'));

      if (!Ember.isEmpty(languages) && !languages.includes(language)) {
        this.replaceWith({
          queryParams: {
            language: Ember.get(languages, 'firstObject')
          }
        });
      }
    }
  });

  _exports.default = _default;
});