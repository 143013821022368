define("client/transforms/utc", ["exports", "ember-data/transform", "moment"], function (_exports, _transform, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _transform.default.extend({
    serialize: function serialize(value) {
      if (value) {
        return value.toJSON();
      }

      return null;
    },
    deserialize: function deserialize(value) {
      if (value) {
        return _moment.default.utc(value);
      }

      return null;
    }
  });

  _exports.default = _default;
});