define("client/components/application/redirect-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isShown: window.explainRedirect,
    actions: {
      dismiss: function dismiss() {
        Ember.set(this, 'isShown', false);
      }
    }
  });

  _exports.default = _default;
});