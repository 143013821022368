define("client/components/application/mobile-banner", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ANDROID_PHONE_PATTERN = /(?=.*\bAndroid\b)(?=.*\bMobile\b)/i;
  var APPLE_PHONE_PATTERN = /iPhone/i;
  var CACHE_TIME_DAY = 3;

  var _default = Ember.Component.extend({
    tagName: '',
    shouldRender: true,
    cache: (0, _emberLocalStorage.storageFor)('last-used'),
    init: function init() {
      this._super.apply(this, arguments);

      var date = Ember.get(this, 'cache').get('mobile-banner');

      if (date && new Date() < new Date(date)) {
        Ember.set(this, 'shouldRender', false);
      }

      var isAndroidPhone = ANDROID_PHONE_PATTERN.test(navigator.userAgent);
      var isApplePhone = APPLE_PHONE_PATTERN.test(navigator.userAgent);
      Ember.set(this, 'isAndroid', isAndroidPhone);
      Ember.set(this, 'isApple', isApplePhone);
      Ember.set(this, 'isPhone', isAndroidPhone || isApplePhone);
    },
    actions: {
      dismiss: function dismiss() {
        var date = new Date();
        date.setTime(date.getTime() + CACHE_TIME_DAY * 24 * 60 * 60 * 1000);
        Ember.get(this, 'cache').set('mobile-banner', date);
        Ember.set(this, 'shouldRender', false);
      }
    }
  });

  _exports.default = _default;
});