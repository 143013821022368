define("client/templates/components/groups/tickets/ticket-conversation-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n/KXFvBS",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[30,[\"message-data \",[29,\"if\",[[25,[\"isLeader\"]],\"align-right\"],null]]]],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"message-data-name\"],[9],[0,\"\\n    \"],[7,\"a\"],[12,\"href\",[29,\"href-to\",[\"users.index\",[25,[\"message\",\"user\"]]],null]],[9],[1,[25,[\"message\",\"user\",\"name\"]],false],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"message-data-time\"],[9],[0,\"\\n    \"],[1,[29,\"format-relative\",[[25,[\"message\",\"createdAt\"]]],[[\"interval\"],[1000]]],false],[0,\",\\n    \"],[1,[29,\"format-date\",[[25,[\"message\",\"createdAt\"]]],[[\"hour\",\"minute\",\"timeZone\"],[\"numeric\",\"numeric\",\"UTC\"]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[12,\"class\",[30,[\"message \",[23,\"messageClass\"],\" \",[29,\"if\",[[25,[\"isLeader\"]],\"float-right\"],null]]]],[9],[0,\"\\n  \"],[1,[25,[\"message\",\"content\"]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/groups/tickets/ticket-conversation-message.hbs"
    }
  });

  _exports.default = _default;
});