define("client/initializers/redirect-io", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;
  var existingSession = window.localStorage['ember_simple_auth:session']; // Carefully parse the session

  var isLoggedIn = function () {
    if (!existingSession) return false;

    try {
      return JSON.parse(existingSession)['authenticated']['access_token'];
    } catch (e) {
      return false;
    }
  }();

  function initialize() {
    if (window.location.origin.endsWith('.io')) {
      // Redirect to the new domain
      var url = new URL(window.location);
      url.host = url.host.replace('kitsu.io', 'kitsu.app');
      url.hash = "kitsu-io-session=".concat(btoa(existingSession));
      window.location.replace(url.toString());
    } else if (window.location.hash.match(/#kitsu-io-session=.*/)) {
      if (!isLoggedIn) {
        try {
          // Import the session from the hash
          var importedSession = JSON.parse(atob(/#kitsu-io-session=(.*)/.exec(window.location.hash)[1]));
          window.localStorage['ember_simple_auth:session'] = JSON.stringify(importedSession);
        } catch (e) {
          console.error('Failed to import session');
        }
      }

      window.explainRedirect = true;

      var _url = new URL(window.location);

      _url.hash = '';
      history.replaceState(null, '', _url.toString());
    }
  }

  var _default = {
    name: 'redirectFromKitsuIO',
    initialize: initialize
  };
  _exports.default = _default;
});