define("client/routes/manga/index", ["exports", "client/routes/media/index"], function (_exports, _index) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _index.default.extend();

  _exports.default = _default;
});