define("client/routes/users/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    model: function model() {
      var user = this.modelFor('users');
      return Ember.get(user, 'pinnedPost');
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      var model = this.modelFor('users');
      Ember.set(this, 'breadcrumb', Ember.get(model, 'name'));
      Ember.set(controller, 'user', model);
    },
    titleToken: function titleToken() {
      var model = this.modelFor('users');
      var name = Ember.get(model, 'name');
      return Ember.get(this, 'intl').t('titles.users.index', {
        user: name
      });
    }
  });

  _exports.default = _default;
});