define("client/templates/settings/apps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TdF3/vfq",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"settings-container\"],[9],[0,\"\\n  \"],[7,\"h1\"],[9],[1,[29,\"t\",[\"settings.apps.header\"],null],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/settings/apps.hbs"
    }
  });

  _exports.default = _default;
});