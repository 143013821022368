define("client/metrics-adapters/stream", ["exports", "ember-metrics/metrics-adapters/base", "jquery", "client/config/environment", "client/utils/can-use-dom"], function (_exports, _base, _jquery, _environment, _canUseDom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _base.default.extend({
    toStringExtension: function toStringExtension() {
      return 'Stream';
    },
    init: function init() {
      var environment = _environment.default.kitsu.isStaging ? 'staging' : _environment.default.environment;
      var config = Ember.get(this, "config.".concat(environment));

      if (_canUseDom.default) {
        // eslint-disable-next-line
        !function (t, e) {
          t("StreamAnalytics", "https://d2j1fszo1axgmp.cloudfront.net/2.6.0/stream-analytics.min.js", e);
        }(function (t, e, n) {
          var s, i, r;
          n["_" + t] = {}, n[t] = function (e) {
            n["_" + t].clients = n["_" + t].clients || {}, n["_" + t].clients[e.apiKey] = this, this._config = e;
          };

          var c = function c(t) {
            return function () {
              return this["_" + t] = this["_" + t] || [], this["_" + t].push(arguments), this;
            };
          };

          s = ["setUser", "trackImpression", "trackEngagement"];

          for (var a = 0; a < s.length; a++) {
            var o = s[a];
            n[t].prototype[o] = c(o);
          }

          i = document.createElement("script"), i.async = !0, i.src = e, r = document.getElementsByTagName("script")[0], r.parentNode.insertBefore(i, r);
        }, window);
        var client = new window.StreamAnalytics(config);
        Ember.set(this, 'client', client);
      }
    },
    identify: function identify() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var id = options.distinctId,
          alias = options.alias;

      if (_canUseDom.default && id && alias) {
        Ember.get(this, 'client').setUser({
          id: id,
          alias: alias
        });
        Ember.set(this, 'hasUser', true);
      }
    },
    trackEvent: function trackEvent() {},
    trackPage: function trackPage() {},
    trackImpression: function trackImpression(data) {
      var router = Ember.get(this.this, 'router');

      if (_canUseDom.default && Ember.get(this, 'hasUser')) {
        var options = _objectSpread({
          location: Ember.get(router, 'currentRouteName')
        }, data);

        Ember.get(this, 'client').trackImpression(options);
      }
    },
    trackEngagement: function trackEngagement(data) {
      var router = Ember.get(this.this, 'router');

      if (_canUseDom.default && Ember.get(this, 'hasUser')) {
        var options = _objectSpread({
          location: Ember.get(router, 'currentRouteName')
        }, data);

        Ember.get(this, 'client').trackEngagement(options);
      }
    },
    willDestroy: function willDestroy() {
      if (_canUseDom.default) {
        (0, _jquery.default)('script[src*="stream-analytics"]').remove();
      }
    }
  });

  _exports.default = _default;
});