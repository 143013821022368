define("client/routes/settings", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "client/mixins/routes/data-error", "jquery"], function (_exports, _authenticatedRouteMixin, _dataError, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _dataError.default, {
    authenticationRoute: 'dashboard',
    titleToken: 'Settings',
    activate: function activate() {
      this._super.apply(this, arguments);

      (0, _jquery.default)('body').addClass('settings-page');
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);

      (0, _jquery.default)('body').removeClass('settings-page');
    }
  });

  _exports.default = _default;
});