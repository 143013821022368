define("client/templates/media/show/reactions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yX7ReJtc",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"media\",\"shouldShowAds\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"ad-unit\",null,[[\"unit\",\"targeting\",\"sizes\"],[\"kitsu.media.reviews.top\",[29,\"hash\",null,[[\"section\",\"media\"],[\"media\",[25,[\"media\",\"slug\"]]]]],[29,\"hash\",null,[[\"mobile\",\"desktop\"],[[29,\"array\",[[29,\"array\",[300,250],null],[29,\"array\",[320,100],null],[29,\"array\",[320,50],null]],null],[29,\"array\",[728,90],null]]]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[29,\"media/media-reactions\",null,[[\"media\",\"noLimit\"],[[25,[\"media\"]],true]]],false],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"media\",\"shouldShowAds\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"ad-unit\",null,[[\"unit\",\"targeting\",\"sizes\"],[\"kitsu.media.reviews.bottom\",[29,\"hash\",null,[[\"section\",\"media\"],[\"media\",[25,[\"media\",\"slug\"]]]]],[29,\"hash\",null,[[\"mobile\",\"desktop\"],[[29,\"array\",[[29,\"array\",[300,250],null],[29,\"array\",[320,100],null],[29,\"array\",[320,50],null]],null],[29,\"array\",[728,90],null]]]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/media/show/reactions.hbs"
    }
  });

  _exports.default = _default;
});