define("client/controllers/media/show", ["exports", "client/helpers/image"], function (_exports, _image) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    media: Ember.computed.alias('model'),
    coverImageStyle: Ember.computed('media.coverImage', function () {
      var coverImage = (0, _image.image)(Ember.get(this, 'media.coverImage'));
      return "background-image: url(\"".concat(coverImage, "\")").htmlSafe();
    }).readOnly()
  });

  _exports.default = _default;
});