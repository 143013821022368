define("client/routes/users", ["exports", "client/mixins/routes/data-error", "client/mixins/routes/cover-page"], function (_exports, _dataError, _coverPage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_dataError.default, _coverPage.default, {
    queryCache: Ember.inject.service(),
    intl: Ember.inject.service(),
    model: function model(_ref) {
      var slug = _ref.slug;

      if (slug.match(/\D+/)) {
        return Ember.get(this, 'queryCache').query('user', {
          filter: {
            slug: slug
          },
          include: 'profileLinks.profileLinkSite,favorites.item,pinnedPost.user,pinnedPost.targetUser,pinnedPost.spoiledUnit,pinnedPost.media,pinnedPost.targetGroup,pinnedPost.uploads,stats'
        }).then(function (records) {
          return Ember.get(records, 'firstObject');
        });
      }

      return Ember.get(this, 'store').findRecord('user', slug, {
        include: 'profileLinks.profileLinkSite,favorites.item,pinnedPost.user,pinnedPost.targetUser,pinnedPost.spoiledUnit,pinnedPost.media,pinnedPost.targetGroup,pinnedPost.uploads,stats',
        reload: true
      });
    },
    afterModel: function afterModel(model) {
      var tags = this.setHeadTags(model);
      Ember.set(this, 'headTags', tags);
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      Ember.get(controller, 'fetchFollowTask').perform();
    },
    resetController: function resetController(controller) {
      this._super.apply(this, arguments);

      Ember.set(controller, 'isEditing', false);
    },
    serialize: function serialize(model) {
      return {
        slug: Ember.get(model, 'url')
      };
    },
    setHeadTags: function setHeadTags(model) {
      var description = "".concat(Ember.get(this, 'intl').t('head.users.description', {
        user: Ember.get(model, 'name')
      }), " ").concat(Ember.get(model, 'about'));
      return [{
        type: 'meta',
        tagId: 'meta-description',
        attrs: {
          name: 'description',
          content: description
        }
      }, {
        type: 'meta',
        tagId: 'meta-og-type',
        attrs: {
          property: 'og:type',
          content: 'profile'
        }
      }, {
        type: 'meta',
        tagId: 'meta-og-description',
        attrs: {
          property: 'og:description',
          content: description
        }
      }, {
        type: 'meta',
        tagId: 'meta-og-image',
        attrs: {
          property: 'og:image',
          content: Ember.get(model, 'avatar.medium') || Ember.get(model, 'avatar')
        }
      }];
    },
    redirect: function redirect(model) {
      var routeName = Ember.get(this, 'routeName');
      var params = this.paramsFor(routeName);
      var current = Ember.get(params, 'slug');
      var correct = Ember.get(model, 'url');

      if (current !== correct) {
        this.replaceWith(routeName, correct);
      }
    }
  });

  _exports.default = _default;
});