define("client/templates/components/application/auth-onboarding/import-username", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OcjkHZ7I",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"modal-wrapper\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"auth-section active\"],[9],[0,\"\\n    \"],[7,\"h6\"],[11,\"class\",\"auth-section-title\"],[9],[0,\"Okay, let's import your \"],[1,[23,\"siteName\"],false],[0,\" account\"],[10],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"auth-section-helper\"],[9],[0,\"Our \"],[1,[23,\"siteName\"],false],[0,\" importer can migrate your existing anime and manga data. \"],[7,\"strong\"],[9],[0,\"Your lists \"],[7,\"em\"],[9],[0,\"must\"],[10],[0,\" be publicly viewable for us to import!\"],[10],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"form-group\"],[9],[0,\"\\n      \"],[1,[29,\"one-way-text\",[[25,[\"username\"]]],[[\"update\",\"class\",\"placeholder\",\"autofocus\"],[[29,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"username\"]]],null]],null],\"form-control\",\"Username\",\"autofocus\"]]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"form-cta\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"button button--primary m-b-1\"],[12,\"disabled\",[29,\"if\",[[25,[\"createImport\",\"isRunning\"]],\"disabled\"],null]],[11,\"type\",\"button\"],[9],[0,\"\\n      Back to Import Select\\n    \"],[3,\"action\",[[24,0,[]],\"changeComponent\",\"import-select\"]],[10],[0,\"\\n\\n    \"],[7,\"button\"],[11,\"class\",\"button button--primary\"],[12,\"disabled\",[29,\"if\",[[29,\"is-empty\",[[25,[\"username\"]]],null],\"disabled\"],null]],[11,\"type\",\"button\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"createImport\",\"isRunning\"]]],null,{\"statements\":[[0,\"        \"],[1,[29,\"loading-spinner\",null,[[\"size\"],[\"small\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        Start \"],[1,[23,\"siteName\"],false],[0,\" Import\\n\"]],\"parameters\":[]}],[0,\"    \"],[3,\"action\",[[24,0,[]],\"startImport\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/application/auth-onboarding/import-username.hbs"
    }
  });

  _exports.default = _default;
});