define("client/controllers/groups/group/group-page/leaders", ["exports", "ember-parachute"], function (_exports, _emberParachute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var queryParams = new _emberParachute.default({
    filter: {
      defaultValue: 'open'
    },
    query: {
      defaultValue: ''
    },
    preserveScrollPosition: {
      defaultValue: true
    }
  });

  var _default = Ember.Controller.extend(queryParams.Mixin, {
    group: Ember.computed.alias('model.group'),
    membership: Ember.computed.alias('model.membership'),
    addedTickets: Ember.computed.alias('_addedTickets'),
    actions: {
      updateQueryParam: function updateQueryParam(property, value) {
        Ember.set(this, property, value);
      },
      onTicketCreated: function onTicketCreated(ticket) {
        Ember.get(this, 'addedTickets').addObject(ticket);
      }
    }
  });

  _exports.default = _default;
});