define("client/controllers/groups/index", ["exports", "ember-parachute", "client/utils/computed-macros"], function (_exports, _emberParachute, _computedMacros) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var queryParams = new _emberParachute.default({
    category: {
      defaultValue: 'all',
      refresh: true
    },
    sort: {
      defaultValue: 'featured',
      refresh: true
    },
    query: {
      defaultValue: '',
      refresh: true
    }
  });

  var _default = Ember.Controller.extend(queryParams.Mixin, {
    router: Ember.inject.service(),
    groups: (0, _computedMacros.concat)('model.taskInstance.value', 'model.paginatedRecords'),
    queryParamsDidChange: function queryParamsDidChange(_ref) {
      var shouldRefresh = _ref.shouldRefresh,
          queryParams = _ref.queryParams,
          changed = _ref.changed;

      if ('category' in changed) {
        var category = Ember.get(queryParams, 'category');
        var sort = category === 'all' ? 'featured' : 'recent';
        Ember.set(this, 'sort', sort);
      }

      if (shouldRefresh) {
        this.send('refreshModel');
      }
    },
    actions: {
      updateQueryParam: function updateQueryParam(property, value) {
        Ember.set(this, property, value);
      },
      handleCreateClick: function handleCreateClick() {
        if (!Ember.get(this, 'session.hasUser')) {
          Ember.get(this, 'session').signUpModal();
        } else {
          Ember.get(this, 'router').transitionTo('groups.new');
        }
      }
    }
  });

  _exports.default = _default;
});