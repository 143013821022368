define("client/models/producer", ["exports", "client/models/-base", "ember-data/attr", "ember-data/relationships"], function (_exports, _base, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    name: (0, _attr.default)('string'),
    slug: (0, _attr.default)('string'),
    animeProductions: (0, _relationships.hasMany)('anime-production')
  });

  _exports.default = _default;
});