define("client/models/leader-chat-message", ["exports", "client/models/-base", "ember-data/attr", "ember-data/relationships"], function (_exports, _base, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    content: (0, _attr.default)('string'),
    contentFormatted: (0, _attr.default)('string'),
    createdAt: (0, _attr.default)('utc'),
    editedAt: (0, _attr.default)('utc'),
    group: (0, _relationships.belongsTo)('group'),
    user: (0, _relationships.belongsTo)('user')
  });

  _exports.default = _default;
});