define("client/controllers/anime/index", ["exports", "client/controllers/media/index", "ember-parachute", "client/utils/queryable", "client/utils/moment", "client/utils/media-minyear"], function (_exports, _index, _emberParachute, _queryable, _moment, _mediaMinyear) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var queryParams = new _emberParachute.default(_index.MEDIA_QUERY_PARAMS, {
    ageRating: {
      defaultValue: [],
      refresh: true,
      serialize: function serialize(value) {
        return (0, _queryable.serializeArray)(value);
      },
      deserialize: function deserialize() {
        var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
        return (0, _queryable.deserializeArray)(value);
      }
    },
    streamers: {
      defaultValue: [],
      refresh: true,
      serialize: function serialize(value) {
        return (0, _queryable.serializeArray)(value);
      },
      deserialize: function deserialize() {
        var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
        return (0, _queryable.deserializeArray)(value);
      }
    },
    season: {
      defaultValue: [],
      refresh: true,
      serialize: function serialize(value) {
        return (0, _queryable.serializeArray)(value);
      },
      deserialize: function deserialize() {
        var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
        return (0, _queryable.deserializeArray)(value);
      }
    },
    year: {
      defaultValue: [_mediaMinyear.minYearAnime, (0, _moment.moment)().year() + 2],
      refresh: true,
      serialize: function serialize(value) {
        var _value = _slicedToArray(value, 2),
            lower = _value[0],
            upper = _value[1];

        if (lower === _mediaMinyear.minYearAnime && upper === (0, _moment.moment)().year() + 2) {
          return undefined;
        }

        if (upper === (0, _moment.moment)().year() + 2) {
          return (0, _queryable.serializeArray)([lower, null]);
        }

        return (0, _queryable.serializeArray)(value);
      },
      deserialize: function deserialize() {
        var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

        var _deserializeArray = (0, _queryable.deserializeArray)(value),
            _deserializeArray2 = _slicedToArray(_deserializeArray, 2),
            lower = _deserializeArray2[0],
            upper = _deserializeArray2[1];

        if (Ember.isEmpty(upper)) {
          return [lower, (0, _moment.moment)().year() + 2];
        }

        return [lower, upper];
      }
    }
  });

  var _default = _index.default.extend(queryParams.Mixin, {
    mediaType: 'anime',
    availableRatings: ['G', 'PG', 'R', 'R18'],
    availableSeasons: ['spring', 'summer', 'fall', 'winter'],
    availableSubtypes: ['tv', 'special', 'ona', 'ova', 'movie', 'music'],
    queryParamsDidChange: function queryParamsDidChange(_ref) {
      var shouldRefresh = _ref.shouldRefresh;

      if (shouldRefresh) {
        this.send('refresh');
      }
    }
  });

  _exports.default = _default;
});