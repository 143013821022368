define("client/templates/feedback/bugs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w34S6sDQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"w-100\"],[11,\"data-canny\",\"\"],[9],[10],[0,\"\\n\"],[1,[29,\"canny-feedback-script\",null,[[\"token\",\"boardToken\",\"path\"],[[25,[\"model\",\"token\"]],\"b157fd36-e072-9960-fc53-e969974d0b6b\",\"/feedback/bugs\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/feedback/bugs.hbs"
    }
  });

  _exports.default = _default;
});