define("client/routes/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    headTags: function headTags() {
      return [{
        type: 'meta',
        tagId: 'meta-prerender-status',
        attrs: {
          name: 'prerender-status-code',
          content: '404'
        }
      }];
    }
  });

  _exports.default = _default;
});