define("client/components/application/auth-onboarding", ["exports", "ember-invoke-action"], function (_exports, _emberInvokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    component: 'social-auth',
    componentName: Ember.computed('component', function () {
      return "application/auth-onboarding/".concat(Ember.get(this, 'component'));
    }),
    actions: {
      onClose: function onClose() {
        (0, _emberInvokeAction.invokeAction)(this, 'onClose');
      },
      changeComponent: function changeComponent(component, data) {
        Ember.set(this, 'component', component);
        Ember.set(this, 'componentData', data);

        this.$('.modal').data('bs.modal')._handleUpdate();
      },
      closeModal: function closeModal() {
        this.$('.modal').modal('hide');
      }
    }
  });

  _exports.default = _default;
});