define("client/mixins/routes/data-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      error: function error(reason) {
        var status = Ember.get(reason, 'errors.firstObject.status');

        if (status === '500') {
          this.replaceWith('server-error');
        } else {
          this.replaceWith('/404');
        }
      }
    }
  });

  _exports.default = _default;
});