define("client/controllers/groups/group/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    group: Ember.computed.alias('model.group'),
    membership: Ember.computed.alias('model.membership'),
    stats: Ember.computed.alias('model.stats')
  });

  _exports.default = _default;
});