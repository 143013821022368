define("client/models/group-ban", ["exports", "client/models/-base", "ember-data/attr", "ember-data/relationships"], function (_exports, _base, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    createdAt: (0, _attr.default)('utc'),
    group: (0, _relationships.belongsTo)('group'),
    moderator: (0, _relationships.belongsTo)('user'),
    user: (0, _relationships.belongsTo)('user')
  });

  _exports.default = _default;
});