define("client/routes/feedback", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    model: function model() {
      if (!Ember.get(this, 'session.hasUser')) {
        return {};
      }

      return Ember.get(this, 'ajax').request('/sso/canny');
    },
    afterModel: function afterModel(_model, transition) {
      if (Ember.get(transition, 'targetName') === 'feedback.index') {
        return this.transitionTo('feedback.bugs');
      }
    }
  });

  _exports.default = _default;
});