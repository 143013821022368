define("client/templates/components/users/library/library-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5YK+ZW/p",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"library-search--wrapper\"],[9],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"icon\"],[9],[1,[29,\"svg-jar\",[\"search\"],null],false],[10],[0,\"\\n      \"],[1,[29,\"one-way-text\",[[25,[\"dupQuery\"]]],[[\"class\",\"placeholder\",\"update\"],[\"library-search-input\",[29,\"t\",[\"users.library.search\"],[[\"name\"],[[25,[\"user\",\"name\"]]]]],[29,\"action\",[[24,0,[]],\"updateQuery\"],null]]]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/users/library/library-search.hbs"
    }
  });

  _exports.default = _default;
});