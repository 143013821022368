define("client/components/stats/time-spent", ["exports", "moment", "client/mixins/hover-intent"], function (_exports, _moment, _hoverIntent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var UNITS = ['years', 'months', 'days', 'hours', 'minutes', 'seconds'];

  var _default = Ember.Component.extend(_hoverIntent.default, {
    hoverTimeout: 0,
    kind: 'anime',
    showTooltip: false,
    stat: {},
    breakdown: Ember.computed('stat.time', function () {
      var time = _moment.default.duration(Ember.get(this, 'stat.time'), 'seconds');

      var breakdown = UNITS.map(function (unit) {
        return {
          name: unit,
          count: time.get(unit)
        };
      });
      return breakdown.slice(0, 5).filter(function (_ref) {
        var count = _ref.count;
        return count > 0;
      });
    }),
    primaryUnit: Ember.computed('stat.time', function () {
      if (Ember.get(this, 'kind') === 'anime') {
        var time = _moment.default.duration(Ember.get(this, 'stat.time'), 'seconds');

        for (var i = 0; i < UNITS.length; i += 1) {
          var unitTime = time.as(UNITS[i]);

          if (unitTime > 1) {
            var index = i < 4 ? 4 - i : 1;
            return {
              index: index,
              name: UNITS[i],
              count: unitTime
            };
          }
        }
      } else {
        var chapters = Ember.get(this, 'stat.units');
        if (chapters > 15000) return {
          index: 4,
          count: chapters
        };
        if (chapters > 5000) return {
          index: 3,
          count: chapters
        };
        if (chapters > 1000) return {
          index: 2,
          count: chapters
        };
        return {
          index: 1,
          count: chapters
        };
      }
    }),
    percentile: Ember.computed('kind', 'stat.percentiles', function () {
      var kind = Ember.get(this, 'kind');
      var percentile = kind === 'anime' ? Ember.get(this, 'stat.percentiles.time') : Ember.get(this, 'stat.percentiles.units');
      var percentage = percentile * 100;

      if (Number(percentage).toFixed(0) >= 100) {
        return 99;
      }

      return percentage;
    })
  });

  _exports.default = _default;
});