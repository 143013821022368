define("client/templates/reviews", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mucNyCkY",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"col-md-8 col-xs-11 centered\"],[9],[0,\"\\n  \"],[1,[29,\"stream-feed/items/review\",null,[[\"review\",\"isExpanded\"],[[25,[\"model\"]],true]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/reviews.hbs"
    }
  });

  _exports.default = _default;
});