define("client/templates/components/groups/group-categories-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ErGDbZEQ",
    "block": "{\"symbols\":[\"category\"],\"statements\":[[7,\"ul\"],[11,\"class\",\"group-category-list\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"getCategoriesTask\",\"isRunning\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"text-xs-center m-t-2\"],[9],[0,\"\\n      \"],[1,[29,\"loading-spinner\",null,[[\"size\"],[\"large\"]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[29,\"sort-by\",[\"slug\",[25,[\"filteredCategories\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[12,\"class\",[30,[\"group-category-wrapper \",[29,\"if\",[[29,\"eq\",[[25,[\"currentCategory\"]],[24,1,[\"slug\"]]],null],\"active\"],null]]]],[9],[0,\"\\n        \"],[7,\"a\"],[11,\"href\",\"#\"],[9],[0,\"\\n          \"],[7,\"span\"],[11,\"class\",\"group-category-icon\"],[9],[1,[29,\"svg-jar\",[[24,1,[\"slug\"]]],null],false],[10],[0,\"\\n          \"],[7,\"span\"],[11,\"class\",\"group-category-title\"],[9],[1,[24,1,[\"name\"]],false],[10],[0,\"\\n        \"],[3,\"action\",[[24,0,[]],\"switchCategory\",[24,1,[]]]],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/groups/group-categories-list.hbs"
    }
  });

  _exports.default = _default;
});