define("client/serializers/user", ["exports", "client/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      commentsCount: {
        serialize: false
      },
      favoritesCount: {
        serialize: false
      },
      feedCompleted: {
        serialize: false
      },
      followingCount: {
        serialize: false
      },
      followersCount: {
        serialize: false
      },
      likesGivenCount: {
        serialize: false
      },
      postsCount: {
        serialize: false
      },
      profileCompleted: {
        serialize: false
      },
      ratingsCount: {
        serialize: false
      },
      waifuDirtyHack: {
        serialize: false
      }
    }
  });

  _exports.default = _default;
});