define("client/templates/components/application/auth-onboarding/import-progress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H20QotZp",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"modal-wrapper\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"auth-section active\"],[9],[0,\"\\n    \"],[7,\"h6\"],[11,\"class\",\"auth-section-title\"],[9],[0,\"Hang tight! We're importing your data!\"],[10],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"auth-section-helper\"],[9],[0,\"\\n      Your \"],[1,[23,\"siteName\"],false],[0,\" import will continue in the background.\\n\"],[4,\"unless\",[[25,[\"componentData\",\"justImport\"]]],null,{\"statements\":[[0,\"        You can safely continue setting up your account in the mean time.\\n\"]],\"parameters\":[]},null],[0,\"      We'll send you a notification when your import has completed!\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"form-cta\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"componentData\",\"justImport\"]]],null,{\"statements\":[[0,\"      \"],[7,\"button\"],[11,\"class\",\"button button--primary\"],[11,\"type\",\"button\"],[9],[0,\"\\n        Finish\\n      \"],[3,\"action\",[[24,0,[]],[25,[\"close\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"button\"],[11,\"class\",\"button button--primary\"],[11,\"type\",\"button\"],[9],[0,\"\\n        Continue\\n      \"],[3,\"action\",[[24,0,[]],\"changeComponent\",\"find-friends\"]],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/application/auth-onboarding/import-progress.hbs"
    }
  });

  _exports.default = _default;
});