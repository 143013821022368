define("client/templates/settings/linked-accounts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hFrM3ubx",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"settings-container\"],[9],[0,\"\\n  \"],[7,\"h1\"],[9],[1,[29,\"t\",[\"settings.linked-accounts.header\"],null],false],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"settings--section-head\"],[9],[0,\"\\n    \"],[1,[29,\"t\",[\"settings.linked-accounts.sub-header\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"form-group row\"],[9],[0,\"\\n      \"],[7,\"label\"],[11,\"class\",\"col-xs-4 col-form-label\"],[9],[1,[29,\"t\",[\"settings.linked-accounts.facebook\"],null],false],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"col-xs-8\"],[9],[0,\"\\n        \"],[7,\"label\"],[11,\"class\",\"form-check-label\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"session\",\"account\",\"facebookId\"]]],null,{\"statements\":[[0,\"            \"],[1,[29,\"facebook-button\",null,[[\"text\",\"disabled\",\"onclick\"],[[29,\"t\",[\"settings.linked-accounts.disconnect\"],null],[25,[\"facebookTasks\",\"isRunning\"]],[29,\"perform\",[[25,[\"disconnectFacebook\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[1,[29,\"facebook-button\",null,[[\"text\",\"disabled\",\"onclick\"],[[29,\"t\",[\"settings.linked-accounts.connect\"],null],[25,[\"facebookTasks\",\"isRunning\"]],[29,\"perform\",[[25,[\"connectFacebook\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/settings/linked-accounts.hbs"
    }
  });

  _exports.default = _default;
});