define("client/templates/media-reactions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "13wESpv3",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"col-md-10 col-xs-12 centered\"],[9],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-xs-12 col-md\"],[9],[0,\"\\n      \"],[1,[29,\"media/media-reaction-card\",null,[[\"reaction\",\"media\",\"large\"],[[25,[\"model\"]],[25,[\"model\",\"media\"]],true]]],false],[0,\"\\n\\n      \"],[7,\"div\"],[11,\"class\",\"more-reactions\"],[9],[0,\"\\n        \"],[7,\"a\"],[12,\"href\",[29,\"href-to\",[\"users.reactions\",[25,[\"model\",\"user\"]]],null]],[9],[0,\"\\n          \"],[1,[29,\"t\",[\"media-reactions.view-all\"],[[\"count\",\"user\",\"htmlSafe\"],[[25,[\"model\",\"user\",\"mediaReactionsCount\"]],[25,[\"model\",\"user\",\"name\"]],true]]],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-xs-12 col-md-5 permalink--author-card\"],[9],[0,\"\\n      \"],[1,[29,\"users/user-card\",null,[[\"user\",\"short\"],[[25,[\"model\",\"user\"]],true]]],false],[0,\"\\n\"],[4,\"unless\",[[25,[\"session\",\"hasUser\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"guest-card\"],[9],[0,\"\\n          \"],[7,\"p\"],[9],[1,[29,\"t\",[\"head.users.description\"],[[\"user\"],[[25,[\"model\",\"user\",\"name\"]]]]],false],[10],[0,\"\\n          \"],[7,\"button\"],[11,\"class\",\"button button--primary active\"],[9],[0,\"\\n            \"],[7,\"span\"],[11,\"class\",\"button-label\"],[9],[1,[29,\"t\",[\"media-reactions.create-account\"],null],false],[10],[0,\"\\n          \"],[3,\"action\",[[24,0,[]],\"signUp\"]],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/media-reactions.hbs"
    }
  });

  _exports.default = _default;
});