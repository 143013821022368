define("client/templates/components/groups/dashboard/audit-log", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HjSRShxx",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"div\"],[11,\"class\",\"card\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"card-block\"],[9],[0,\"\\n    \"],[7,\"h5\"],[11,\"class\",\"card-title\"],[9],[1,[29,\"t\",[\"groups.dashboard.overview.log\"],null],false],[10],[0,\"\\n    \"],[7,\"hr\"],[9],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"audit-log-items\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"getLogItemsTask\",\"isRunning\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"w-100 text-xs-center m-t-2\"],[9],[0,\"\\n          \"],[1,[29,\"loading-spinner\",null,[[\"size\"],[\"small\"]]],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[25,[\"getLogItemsTask\",\"last\",\"value\"]]],null,{\"statements\":[[0,\"          \"],[1,[29,\"groups/dashboard/audit-log-item\",null,[[\"item\"],[[24,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"          \"],[7,\"p\"],[9],[1,[29,\"t\",[\"errors.contentEmpty\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/groups/dashboard/audit-log.hbs"
    }
  });

  _exports.default = _default;
});