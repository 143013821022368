define("client/components/users/user-favorites/list", ["exports", "client/utils/getter"], function (_exports, _getter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showableItems: Ember.computed('items.[]', 'showCount', function () {
      return Ember.get(this, 'items').slice(0, Ember.get(this, 'showCount'));
    }).readOnly(),
    type: (0, _getter.default)(function () {
      var first = Ember.get(this, 'items.firstObject');
      return Ember.get(first, 'item.modelType');
    }),
    isCharacter: (0, _getter.default)(function () {
      return Ember.get(this, 'type') === 'character';
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments); // reset the count if transitioning between users


      if (Ember.get(this, 'userIdWas') !== Ember.get(this, 'user.id')) {
        Ember.set(this, 'showCount', Math.min(Ember.get(this, 'count'), Ember.get(this, 'items.length')));
      } else {
        Ember.set(this, 'showCount', this._getCount());
      }

      Ember.set(this, 'userIdWas', Ember.get(this, 'user.id'));
    },
    _getCount: function _getCount() {
      if (Ember.get(this, 'showCount') < Ember.get(this, 'items.length')) {
        var value = Ember.get(this, 'showCount') + Ember.get(this, 'count');

        if (value > Ember.get(this, 'items.length')) {
          return Ember.get(this, 'showCount') + (Ember.get(this, 'items.length') - Ember.get(this, 'showCount'));
        }

        return value;
      }

      return Ember.get(this, 'showCount');
    },
    actions: {
      showMore: function showMore() {
        Ember.set(this, 'showCount', this._getCount());
      }
    }
  });

  _exports.default = _default;
});