define("client/controllers/groups/group/group-page", ["exports", "client/helpers/image"], function (_exports, _image) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    group: Ember.computed.alias('model.group'),
    membership: Ember.computed.alias('model.membership'),
    coverImageStyle: Ember.computed('group.coverImage', function () {
      var coverImage = (0, _image.image)(Ember.get(this, 'group.coverImage'));
      return Ember.String.htmlSafe("background-image: url(\"".concat(coverImage, "\")"));
    }).readOnly()
  });

  _exports.default = _default;
});