define("client/controllers/users/reviews", ["exports", "client/utils/computed-macros"], function (_exports, _computedMacros) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    taskValue: (0, _computedMacros.concat)('model.taskInstance.value', 'model.paginatedRecords')
  });

  _exports.default = _default;
});