define("client/components/stream-feed/items/review", ["exports", "ember-href-to/helpers/href-to", "ember-invoke-action", "client/utils/getter", "client/utils/error-messages", "client/mixins/clipboard"], function (_exports, _hrefTo, _emberInvokeAction, _getter, _errorMessages, _clipboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_clipboard.default, {
    classNames: ['stream-item', 'row'],
    metrics: Ember.inject.service(),
    notify: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    host: (0, _getter.default)(function () {
      return "".concat(window.location.protocol, "//").concat(window.location.host);
    }),
    tweetLink: (0, _getter.default)(function () {
      var host = Ember.get(this, 'host');
      var link = (0, _hrefTo.hrefTo)(this, 'reviews', Ember.get(this, 'review.id'));
      var title = Ember.get(this, 'media.canonicalTitle');
      var text = encodeURIComponent("Check out this review of ".concat(title, " on #kitsu"));
      var url = "".concat(host).concat(link);
      return "https://twitter.com/share?text=".concat(text, "&url=").concat(url);
    }),
    facebookLink: (0, _getter.default)(function () {
      var host = Ember.get(this, 'host');
      var link = (0, _hrefTo.hrefTo)(this, 'reviews', Ember.get(this, 'review.id'));
      var url = "".concat(host).concat(link);
      return "https://www.facebook.com/sharer/sharer.php?u=".concat(url);
    }),
    activity: (0, _getter.default)(function () {
      return Ember.get(this, 'group.activities.firstObject');
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (Ember.get(this, 'group') !== undefined) {
        Ember.set(this, 'review', Ember.get(this, 'activity.subject.content') || Ember.get(this, 'activity.subject'));
      } // media might already exist in data


      if (Ember.get(this, 'media') === undefined) {
        Ember.set(this, 'media', Ember.get(this, 'review.media'));
      }

      if (!Ember.get(this, 'session').isCurrentUser(Ember.get(this, 'review.user'))) {
        Ember.set(this, 'isHidden', Ember.get(this, 'review.spoiler'));
      }
    },
    actions: {
      trackEngagement: function trackEngagement(label) {
        var data = {
          label: label,
          content: {
            foreign_id: "Review:".concat(Ember.get(this, 'review.id'))
          },
          position: Ember.get(this, 'positionInFeed') || 0
        };

        if (Ember.get(this, 'feedId') !== undefined) {
          data.feed_id = Ember.get(this, 'feedId');
        }

        Ember.get(this, 'metrics').invoke('trackEngagement', 'Stream', data);
      },
      deleteReview: function deleteReview() {
        var _this = this;

        Ember.get(this, 'review').destroyRecord().then(function () {
          // this post is being deleted from its permalink page
          if (Ember.get(_this, 'group') === undefined) {
            Ember.get(_this, 'router').transitionTo('dashboard');
          } else {
            // find the activity-group that references this review
            var record = Ember.get(_this, 'store').peekRecord('activity-group', Ember.get(_this, 'group.id'));
            (0, _emberInvokeAction.invokeAction)(_this, 'removeGroup', record);
          }

          Ember.get(_this, 'notify').success('Success! Your review has been deleted.');
        }).catch(function (err) {
          Ember.get(_this, 'review').rollbackAttributes();
          Ember.get(_this, 'notify').error((0, _errorMessages.default)(err));
        });
      }
    }
  });

  _exports.default = _default;
});