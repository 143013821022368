define("client/routes/feedback/bugs-anything", ["exports", "client/routes/feedback/bugs"], function (_exports, _bugs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _bugs.default.extend({
    templateName: 'feedback/bugs',
    intl: Ember.inject.service(),
    titleToken: function titleToken() {
      return Ember.get(this, 'intl').t('titles.feedback.bugs');
    }
  });

  _exports.default = _default;
});