define("client/templates/admin/reports/closed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o93J/riB",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"model\",\"taskInstance\",\"isRunning\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"text-xs-center w-100 m-t-1\"],[9],[0,\"\\n    \"],[1,[29,\"loading-spinner\",null,[[\"size\"],[\"large\"]]],false],[0,\"\\n    \"],[7,\"p\"],[9],[1,[29,\"t\",[\"admin.reports.loading\"],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"model\",\"taskInstance\",\"error\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"text-xs-center w-100 m-t-1\"],[9],[0,\"\\n    \"],[1,[29,\"t\",[\"errors.load\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"infinite-pagination\",null,[[\"onPagination\"],[[29,\"route-action\",[\"onPagination\"],null]]],{\"statements\":[[0,\"    \"],[1,[29,\"admin/report-list\",null,[[\"reports\"],[[29,\"reject-by\",[\"status\",\"reported\",[25,[\"taskValue\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/admin/reports/closed.hbs"
    }
  });

  _exports.default = _default;
});