define("client/components/media/filter-widgets/category-item", ["exports", "client/components/explore/category-sidebar/category-modal/category-item", "ember-invoke-action"], function (_exports, _categoryItem, _emberInvokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _categoryItem.default.extend({
    classNameBindings: ['category.childCount:has-children:no-children'],
    isActive: Ember.computed('selection.[]', function () {
      return (Ember.get(this, 'selection') || []).includes(Ember.get(this, 'category.slug'));
    }).readOnly(),
    click: function click(_ref) {
      var target = _ref.target;

      if (!Ember.get(this, 'isChild')) {
        return false;
      } // The SVG is replaced upon click, so it won't exist in the DOM anymore


      var element = Ember.get(this, 'element');

      if (!element.contains(target)) {
        return false;
      }

      var isExpandBtn = element.matches('.category-expand-btn');

      if (!isExpandBtn) {
        (0, _emberInvokeAction.invokeAction)(this, 'updateCategories', Ember.get(this, 'category.slug'));
      }

      return false; // don't bubble up
    }
  });

  _exports.default = _default;
});