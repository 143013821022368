define("client/mixins/breadcrumbs", ["exports", "ember-href-to/helpers/href-to"], function (_exports, _hrefTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Generate a BreadcrumbList schema for ember-cli-head.
   *
   * Some code here was heavily borrowed from ember-crumbly by poteto.
   */
  var _default = Ember.Mixin.create({
    head: Ember.inject.service('head-data'),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.on('routeDidChange', function () {
        Ember.run.scheduleOnce('afterRender', function () {
          var data = _this._schemaData();

          var head = Ember.get(_this, 'head');
          Ember.set(head, 'structuredData.meta-breadcrumbs', data);
        });
      });
    },
    _guessRoutePath: function _guessRoutePath(routeNames, name, index) {
      var routes = routeNames.slice(0, index + 1);

      if (routes.length === 1) {
        var path = "".concat(name, ".index");
        return this._lookupRoute(path) ? path : name;
      }

      return routes.join('.');
    },
    _lookupRoute: function _lookupRoute(routeName) {
      return Ember.getOwner(this).lookup("route:".concat(routeName));
    },
    _schemaData: function _schemaData() {
      var _this2 = this;

      var data = {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [{
          '@type': 'ListItem',
          position: 1,
          item: {
            '@id': 'https://kitsu.app',
            name: 'Kitsu'
          }
        }]
      };
      var routeNames = Ember.get(this, 'currentRouteName').split('.');
      var filteredRouteNames = routeNames.reject(function (name) {
        return name === 'index';
      });
      filteredRouteNames.forEach(function (name, index) {
        var path = _this2._guessRoutePath(routeNames, name, index);

        var route = _this2._lookupRoute(path);

        if (!route) {
          return;
        } // route couldn't be found?


        var routeCrumb = Ember.get(route, 'breadcrumb');
        var breadcrumb = routeCrumb !== undefined ? routeCrumb : Ember.String.classify(name);

        if (breadcrumb === null) {
          return;
        }

        data.itemListElement.push({
          '@type': 'ListItem',
          position: data.itemListElement.length + 1,
          item: {
            '@id': "https://kitsu.app".concat((0, _hrefTo.hrefTo)(_this2, path)),
            name: breadcrumb
          }
        });
      });
      return data;
    }
  });

  _exports.default = _default;
});