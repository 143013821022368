define("client/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YC0NycTl",
    "block": "{\"symbols\":[],\"statements\":[[1,[23,\"head-layout\"],false],[0,\"\\n\\n\"],[1,[23,\"application/site-header\"],false],[0,\"\\n\\n\"],[1,[23,\"application/redirect-warning\"],false],[0,\"\\n\\n\"],[1,[23,\"application/session-error\"],false],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"routeIsLoading\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"loading-bar\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"container global-container\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row global-row\"],[9],[0,\"\\n    \"],[1,[23,\"outlet\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[23,\"application/mobile-banner\"],false],[0,\"\\n\\n\"],[1,[29,\"from-elsewhere\",null,[[\"name\"],[\"modal\"]]],false],[0,\"\\n\"],[1,[29,\"ember-notify\",null,[[\"messageStyle\",\"closeAfter\"],[\"bootstrap\",3000]]],false],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"isStaging\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"alert alert-info staging-alert\"],[9],[0,\"\\n  \"],[1,[29,\"t\",[\"application.sandbox.alert\"],[[\"htmlSafe\"],[true]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/application.hbs"
    }
  });

  _exports.default = _default;
});