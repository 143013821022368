define("client/utils/is-file-valid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isFileValid;

  function isFileValid(file, accept) {
    var type = Ember.get(file, 'type');

    if (type && !accept.includes(type)) {
      return false;
    }

    var size = Ember.get(file, 'size');
    var sizeInMb = size / 1000000;

    if (sizeInMb >= 20) {
      return false;
    }

    return true;
  }
});