define("client/templates/components/library-entry/state/completed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CNdoC68h",
    "block": "{\"symbols\":[],\"statements\":[[4,\"library-entry/rating-button\",null,[[\"class\",\"rating\",\"swapToDropdown\",\"onClick\"],[\"button button--secondary\",[25,[\"libraryEntry\",\"rating\"]],true,[25,[\"onRatingChange\"]]]],{\"statements\":[[0,\"  \"],[1,[29,\"t\",[\"library-entry.state.rate-this\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"unless\",[[25,[\"rateOnly\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\"],[11,\"class\",\"reaction-button button button--secondary seen-this\"],[9],[0,\"\\n\"],[4,\"if\",[[29,\"and\",[[25,[\"libraryEntry\",\"mediaReaction\"]],[29,\"not\",[[25,[\"libraryEntry\",\"mediaReaction\",\"isNew\"]]],null]],null]],null,{\"statements\":[[0,\"      \"],[1,[29,\"t\",[\"library-entry.state.edit-reaction\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[29,\"t\",[\"library-entry.state.post-reaction\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[3,\"action\",[[24,0,[]],[25,[\"onReaction\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/library-entry/state/completed.hbs"
    }
  });

  _exports.default = _default;
});