define("client/templates/components/groups/dashboard/leaders/leader-chat-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Boynu9lq",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"media\"],[9],[0,\"\\n  \"],[1,[29,\"lazy-image\",null,[[\"src\",\"class\"],[[29,\"image\",[[25,[\"message\",\"user\",\"avatar\"]],\"small\"],null],\"d-flex mr-3\"]]],false],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"media-body\"],[9],[0,\"\\n    \"],[7,\"h5\"],[11,\"class\",\"mt-0\"],[9],[0,\"\\n      \"],[1,[25,[\"message\",\"user\",\"name\"]],false],[0,\"\\n      \"],[7,\"small\"],[11,\"class\",\"time\"],[9],[0,\"\\n        \"],[1,[29,\"format-date\",[[25,[\"message\",\"createdAt\"]]],[[\"hour\",\"minute\",\"timeZone\"],[\"numeric\",\"numeric\",\"UTC\"]]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[1,[29,\"html-safe\",[[25,[\"message\",\"contentFormatted\"]]],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/groups/dashboard/leaders/leader-chat-message.hbs"
    }
  });

  _exports.default = _default;
});