define("client/initializers/service-injects", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(app) {
    // sessions
    app.inject('controller', 'session', 'service:session');
    app.inject('component', 'session', 'service:session');
    app.inject('route', 'session', 'service:session');
    app.inject('ability', 'session', 'service:session');
  }

  var _default = {
    name: 'service-injects',
    initialize: initialize
  };
  _exports.default = _default;
});