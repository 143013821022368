define("client/routes/explore/category", ["exports", "client/mixins/routes/data-error"], function (_exports, _dataError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_dataError.default, {
    intl: Ember.inject.service(),
    queryCache: Ember.inject.service(),
    model: function model(_ref) {
      var slug = _ref.slug;
      return Ember.get(this, 'queryCache').query('category', {
        filter: {
          slug: slug
        },
        include: 'parent.parent'
      }).then(function (records) {
        return Ember.get(records, 'firstObject');
      });
    },
    afterModel: function afterModel(model) {
      // Redirect if parent is nil, top-level categories aren't navigatable.
      var parent = model.belongsTo('parent').value();

      if (parent === null) {
        this.transitionTo('explore.index');
      }

      var _this$paramsFor = this.paramsFor('explore'),
          mediaType = _this$paramsFor.media_type;

      Ember.set(this, 'breadcrumb', Ember.get(this, 'intl').t('titles.explore.category.index', {
        category: Ember.get(model, 'title'),
        type: Ember.String.capitalize(mediaType)
      }));
      Ember.set(this, 'headTags', this.setHeadTags(model));
    },
    setHeadTags: function setHeadTags(model) {
      return [{
        type: 'meta',
        tagId: 'meta-description',
        attrs: {
          name: 'description',
          content: Ember.get(model, 'description')
        }
      }, {
        type: 'meta',
        tagId: 'meta-og-description',
        attrs: {
          property: 'og:description',
          content: Ember.get(model, 'description')
        }
      }];
    }
  });

  _exports.default = _default;
});