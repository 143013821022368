define("client/templates/components/media/units/unit-grid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QUcVJltU",
    "block": "{\"symbols\":[\"unit\"],\"statements\":[[7,\"ul\"],[11,\"class\",\"media--unit-grid row\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"units\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\"],[11,\"class\",\"col-lg-4 col-sm-6\"],[9],[0,\"\\n      \"],[7,\"a\"],[12,\"href\",[29,\"href-to\",[[29,\"concat\",[[25,[\"media\",\"modelType\"]],\".show.\",[29,\"if\",[[29,\"eq\",[[25,[\"media\",\"modelType\"]],\"anime\"],null],\"episodes\",\"chapters\"],null],\".show\"],null],[24,1,[]]],null]],[9],[0,\"\\n        \"],[1,[29,\"media/units/unit-thumbnail\",null,[[\"unit\",\"libraryEntry\",\"media\"],[[24,1,[]],[25,[\"libraryEntry\"]],[25,[\"media\"]]]]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/media/units/unit-grid.hbs"
    }
  });

  _exports.default = _default;
});