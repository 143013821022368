define("client/components/library-entry/progress", ["exports", "ember-invoke-action"], function (_exports, _emberInvokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    media: Ember.computed.alias('libraryEntry.media'),
    totalProgressText: Ember.computed('media.unitCount', function () {
      return Ember.get(this, 'media.unitCount') || '-';
    }).readOnly(),
    actions: {
      sanitizeNumber: function sanitizeNumber(value) {
        var parsed = parseInt(value, 10);
        return Number.isNaN(parsed) ? value : parsed;
      },
      updateProgress: function updateProgress(progress) {
        var sanitized = (0, _emberInvokeAction.invoke)(this, 'sanitizeNumber', progress);
        (0, _emberInvokeAction.invokeAction)(this, 'onProgressChange', sanitized);
      }
    }
  });

  _exports.default = _default;
});