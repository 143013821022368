define("client/templates/components/explore/category-sidebar/category-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SrSpP0lM",
    "block": "{\"symbols\":[\"modal\",\"category\",\"_\"],\"statements\":[[4,\"bootstrap/bs-modal\",null,[[\"id\",\"onClose\"],[[25,[\"modalId\"]],[25,[\"onClose\"]]]],{\"statements\":[[4,\"component\",[[29,\"-assert-implicit-component-helper-argument\",[[24,1,[\"header\"]],\"expected `modal.header` to be a contextual component but found a string. Did you mean `(component modal.header)`? ('client/templates/components/explore/category-sidebar/category-modal.hbs' @ L2:C5) \"],null]],null,{\"statements\":[[0,\"    \"],[7,\"h6\"],[11,\"class\",\"text-xs-center text-uppercase m-b-0\"],[9],[1,[29,\"t\",[\"explore.modal.header\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"component\",[[29,\"-assert-implicit-component-helper-argument\",[[24,1,[\"body\"]],\"expected `modal.body` to be a contextual component but found a string. Did you mean `(component modal.body)`? ('client/templates/components/explore/category-sidebar/category-modal.hbs' @ L5:C5) \"],null]],null,{\"statements\":[[4,\"if\",[[25,[\"getCategoriesTask\",\"isRunning\"]]],null,{\"statements\":[[4,\"each\",[[29,\"repeat\",[6],null]],null,{\"statements\":[[4,\"skeleton-container\",null,[[\"class\"],[\"category-modal-skeleton\"]],{\"statements\":[[0,\"          \"],[1,[29,\"skeleton-loader\",null,[[\"class\"],[\"category-modal-skeleton-item padded\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[3]},null]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[25,[\"sortedCategories\"]]],null,{\"statements\":[[0,\"        \"],[1,[29,\"explore/category-sidebar/category-modal/category-item\",null,[[\"category\",\"handleTransition\",\"class\"],[[24,2,[]],[29,\"action\",[[24,0,[]],\"handleTransition\"],null],\"category-modal-parent\"]]],false],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/explore/category-sidebar/category-modal.hbs"
    }
  });

  _exports.default = _default;
});