define("client/components/bootstrap/bs-dropdown-item", ["exports", "ember-invoke-action"], function (_exports, _emberInvokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'a',
    classNames: ['dropdown-item'],
    click: function click() {
      (0, _emberInvokeAction.invokeAction)(this, 'onClick');
    }
  });

  _exports.default = _default;
});