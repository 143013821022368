define("client/utils/humanize-duration", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = humanizeDuration;

  /**
   * Returns a humanized string from a moment duration object.
   *
   * @export
   * @param {any} duration
   */
  function humanizeDuration(duration) {
    var highestUnit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

    if (!_moment.default.isDuration(duration)) {
      return;
    }

    var str = null;
    var types = ['years', 'months', 'weeks', 'days', 'hours', 'minutes'];
    types.forEach(function (type) {
      if (highestUnit && str) {
        return;
      }

      var amount = duration.get(type);

      if (amount === 0) {
        return;
      }

      var humanized = "".concat(amount, " ").concat(type);

      if (amount === 1) {
        humanized = humanized.slice(0, humanized.length - 1); // drop the 's'
      }

      var pre = str ? "".concat(str, ", ") : '';
      str = "".concat(pre).concat(humanized);
    });
    return str;
  }
});