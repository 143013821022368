define("client/models/review", ["exports", "client/models/-base", "ember-data/attr", "ember-data/relationships"], function (_exports, _base, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    content: (0, _attr.default)('string'),
    contentFormatted: (0, _attr.default)('string'),
    likesCount: (0, _attr.default)('number'),
    progress: (0, _attr.default)('number'),
    rating: (0, _attr.default)('rating'),
    spoiler: (0, _attr.default)('boolean'),
    libraryEntry: (0, _relationships.belongsTo)('library-entry', {
      inverse: 'review'
    }),
    media: (0, _relationships.belongsTo)('media'),
    user: (0, _relationships.belongsTo)('user'),
    body: Ember.computed.alias('content')
  });

  _exports.default = _default;
});