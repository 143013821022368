define("client/models/post", ["exports", "client/models/-upload-owner", "ember-data/attr", "ember-data/relationships"], function (_exports, _uploadOwner, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _uploadOwner.default.extend({
    blocked: (0, _attr.default)('boolean'),
    commentsCount: (0, _attr.default)('number'),
    content: (0, _attr.default)('string'),
    contentFormatted: (0, _attr.default)('string'),
    createdAt: (0, _attr.default)('utc', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    editedAt: (0, _attr.default)('utc'),
    embed: (0, _attr.default)('object'),
    embedUrl: (0, _attr.default)('string'),
    nsfw: (0, _attr.default)('boolean'),
    postLikesCount: (0, _attr.default)('number'),
    spoiler: (0, _attr.default)('boolean'),
    targetInterest: (0, _attr.default)('string'),
    topLevelCommentsCount: (0, _attr.default)('number'),
    updatedAt: (0, _attr.default)('utc', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    media: (0, _relationships.belongsTo)('media', {
      async: false
    }),
    spoiledUnit: (0, _relationships.belongsTo)('-base', {
      async: false
    }),
    targetGroup: (0, _relationships.belongsTo)('group', {
      async: false
    }),
    targetUser: (0, _relationships.belongsTo)('user', {
      async: false
    }),
    user: (0, _relationships.belongsTo)('user', {
      async: false
    }),
    comments: (0, _relationships.hasMany)('comment', {
      inverse: 'post'
    }),
    postLikes: (0, _relationships.hasMany)('post-like', {
      inverse: 'post'
    })
  });

  _exports.default = _default;
});