define("client/utils/getter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getter;

  function getter(func) {
    return Ember.computed(func);
  }
});