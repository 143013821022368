define("client/components/media/media-actions", ["exports", "client/utils/error-messages"], function (_exports, _errorMessages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['media-actions'],
    isIgnoring: Ember.computed.notEmpty('ignore'),
    notify: Ember.inject.service(),
    queryCache: Ember.inject.service(),
    store: Ember.inject.service(),
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.get(this, 'queryCache').query('media-ignore', this._getRequestOptions()).then(function (ignore) {
        return Ember.set(_this, 'ignore', Ember.get(ignore, 'firstObject'));
      });
    },
    _getRequestOptions: function _getRequestOptions() {
      return {
        filter: {
          userId: Ember.get(this, 'session.account.id'),
          mediaId: Ember.get(this, 'media.id')
        }
      };
    },
    actions: {
      toggleIgnore: function toggleIgnore() {
        var _this2 = this;

        if (Ember.get(this, 'session.isAuthenticated') === false) {
          return Ember.get(this, 'session.signUpModal')();
        }

        if (Ember.get(this, 'isIgnoring')) {
          Ember.get(this, 'ignore').destroyRecord().then(function () {
            Ember.get(_this2, 'queryCache').invalidateQuery('ignore', _this2._getRequestOptions());
            Ember.set(_this2, 'ignore', undefined);
          }).catch(function (err) {
            return Ember.get(_this2, 'notify').error((0, _errorMessages.default)(err));
          });
        } else {
          Ember.get(this, 'store').createRecord('media-ignore', {
            user: Ember.get(this, 'session.account'),
            media: Ember.get(this, 'media')
          }).save().then(function (record) {
            Ember.set(_this2, 'ignore', record);
          }).catch(function (err) {
            return Ember.get(_this2, 'notify').error((0, _errorMessages.default)(err));
          });
        }
      }
    }
  });

  _exports.default = _default;
});