define("client/components/bootstrap/bs-modal", ["exports", "ember-invoke-action", "jquery"], function (_exports, _emberInvokeAction, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['modal', 'fade'],
    attributeBindings: ['tabindex', 'aria-hidden', 'backdrop:data-backdrop', 'keyboard:data-keyboard'],
    ariaRole: 'dialog',
    tabindex: '-1',
    'aria-hidden': 'true',
    backdrop: 'true',
    keyboard: 'true',
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.$().modal('show');
      this.$().off('hidden.bs.modal').on('hidden.bs.modal', function (event) {
        (0, _emberInvokeAction.invokeAction)(_this, 'onClose', event);
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.$().off('hidden.bs.modal');
      (0, _jquery.default)('.modal-backdrop').remove();
      (0, _jquery.default)('body').removeClass('modal-open');
    }
  });

  _exports.default = _default;
});