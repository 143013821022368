define("client/components/users/edit-profile/header", ["exports", "ember-invoke-action", "client/helpers/image", "client/utils/error-messages"], function (_exports, _emberInvokeAction, _image, _errorMessages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tab: 'about-me',
    coverImageStyle: Ember.computed('user.coverImage', function () {
      var coverImage = (0, _image.image)(Ember.get(this, 'user.coverImage'));
      return Ember.String.htmlSafe("background-image: url(\"".concat(coverImage, "\")"));
    }).readOnly(),
    actions: {
      triggerClick: function triggerClick(elementId) {
        this.$("#".concat(elementId)).click();
      },
      updateImage: function updateImage(property, event) {
        var _this = this;

        if (event.files && event.files[0]) {
          var reader = new FileReader();

          reader.onload = function (evt) {
            return Ember.run(function () {
              Ember.set(_this, property, evt.target.result);
            });
          };

          reader.onerror = function (err) {
            (0, _emberInvokeAction.invokeAction)(_this, 'onError', (0, _errorMessages.default)(err));
          };

          reader.readAsDataURL(event.files[0]);
        }
      },
      changeTab: function changeTab(tab) {
        Ember.set(this, 'tab', tab);
        (0, _emberInvokeAction.invokeAction)(this, 'onChange', tab);
      }
    }
  });

  _exports.default = _default;
});