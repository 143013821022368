define("client/templates/components/users/ban-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uRqtUQRH",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"modals/confirm-action\",null,[[\"title\",\"text\",\"onClose\",\"onConfirm\"],[[29,\"t\",[\"components.users.ban.title\"],null],[29,\"t\",[\"components.users.ban.text\"],null],[25,[\"onClose\"]],[29,\"action\",[[24,0,[]],\"banUser\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/users/ban-user.hbs"
    }
  });

  _exports.default = _default;
});