define("client/routes/users/reactions", ["exports", "kitsu-shared/mixins/pagination"], function (_exports, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_pagination.default, {
    intl: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var _this$paramsFor = this.paramsFor(Ember.get(this, 'routeName')),
          filter = _this$paramsFor.filter;

      if (!['all', 'anime', 'manga'].includes(filter)) {
        this.replaceWith({
          queryParams: {
            filter: 'all'
          }
        });
      }
    },
    model: function model(_ref) {
      var filter = _ref.filter,
          sort = _ref.sort;
      var mediaInclude = filter === 'all' ? 'anime,manga' : filter;
      return {
        reactionsTaskInstance: this.queryPaginated('media-reaction', {
          include: "user,".concat(mediaInclude, ",libraryEntry"),
          filter: this._getFilter(filter),
          sort: this._getSortingKey(sort)
        }),
        paginatedRecords: []
      };
    },
    titleToken: function titleToken() {
      var model = this.modelFor('users');
      var name = Ember.get(model, 'name');
      return Ember.get(this, 'intl').t('titles.users.reactions', {
        user: name
      });
    },
    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      }
    },
    _getFilter: function _getFilter(filter) {
      var user = this.modelFor('users');
      var reactionFilter = {
        user_id: Ember.get(user, 'id')
      };

      if (filter !== 'all') {
        reactionFilter.media_type = Ember.String.capitalize(filter);
      }

      return reactionFilter;
    },
    _getSortingKey: function _getSortingKey(sort) {
      switch (sort) {
        case 'best':
          return '-upVotesCount';

        default:
          return '-createdAt';
      }
    }
  });

  _exports.default = _default;
});