define("client/models/post-follow", ["exports", "client/models/-base", "ember-data/relationships"], function (_exports, _base, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    user: (0, _relationships.belongsTo)('user'),
    post: (0, _relationships.belongsTo)('post')
  });

  _exports.default = _default;
});