define("client/transforms/object", ["exports", "jquery", "ember-data/transform"], function (_exports, _jquery, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _transform.default.extend({
    deserialize: function deserialize(value, options) {
      if (value === null && options !== undefined && options.defaultValue !== undefined) {
        return options.defaultValue;
      }

      return _jquery.default.isPlainObject(value) ? value : {};
    },
    serialize: function serialize(value) {
      return Ember.isEmpty(value) ? {} : value;
    }
  });

  _exports.default = _default;
});