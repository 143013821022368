define("client/components/application/site-header", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    authOpened: false,
    authComponent: 'social-auth',
    router: Ember.inject.service(),
    dismissedAnnouncements: (0, _emberLocalStorage.storageFor)('dismissedAnnouncements'),
    isBrowseRoute: Ember.computed('router.currentRouteName', function () {
      var route = Ember.get(this, 'router.currentRouteName');
      var valids = ['anime', 'manga', 'explore'];
      return valids.includes((route || '').split('.')[0]);
    }).readOnly(),
    isFeedbackRoute: Ember.computed('router.currentRouteName', function () {
      var route = Ember.get(this, 'router.currentRouteName');
      return (route || '').split('.')[0] === 'feedback';
    }).readOnly(),
    actions: {
      invalidateSession: function invalidateSession() {
        Ember.get(this, 'session').invalidate();
      },
      transitionToDashboard: function transitionToDashboard() {
        var currentRouteName = Ember.get(this, 'router.currentRouteName');

        if (currentRouteName === 'dashboard') {
          window.location.reload();
        } else {
          Ember.get(this, 'router').transitionTo('dashboard');
        }
      }
    }
  });

  _exports.default = _default;
});