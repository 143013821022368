define("client/models/group-permission", ["exports", "client/models/-base", "ember-data/attr", "ember-data/relationships"], function (_exports, _base, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    permission: (0, _attr.default)('string'),
    groupMember: (0, _relationships.belongsTo)('group-member', {
      inverse: 'permissions'
    })
  });

  _exports.default = _default;
});