define("client/templates/components/stream-feed/items/library-entry/modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dvFVvGlc",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[4,\"bootstrap/bs-modal\",null,[[\"onClose\"],[[25,[\"onClose\"]]]],{\"statements\":[[4,\"component\",[[29,\"-assert-implicit-component-helper-argument\",[[24,1,[\"body\"]],\"expected `modal.body` to be a contextual component but found a string. Did you mean `(component modal.body)`? ('client/templates/components/stream-feed/items/library-entry/modal.hbs' @ L2:C5) \"],null]],null,{\"statements\":[[0,\"    \"],[1,[29,\"stream-feed/items/library-entry\",null,[[\"group\",\"feedId\",\"positionInFeed\",\"showAll\",\"activityLimit\",\"deleteActivity\",\"removeGroup\"],[[25,[\"group\"]],[25,[\"feedId\"]],[25,[\"positionInFeed\"]],true,[28],[25,[\"deleteActivity\"]],[25,[\"removeGroup\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/stream-feed/items/library-entry/modal.hbs"
    }
  });

  _exports.default = _default;
});