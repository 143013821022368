define("client/templates/components/explore/favorite-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s7N6Lz1I",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"isFavorited\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\"],[11,\"class\",\"button btn-danger button--small pull-sm-right\"],[12,\"onclick\",[29,\"perform\",[[25,[\"destroyFavoriteTask\"]]],null]],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"destroyFavoriteTask\",\"isRunning\"]]],null,{\"statements\":[[0,\"      \"],[1,[29,\"t\",[\"explore.favorite-button.removing\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[29,\"t\",[\"explore.favorite-button.remove\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"button\"],[11,\"class\",\"button button--primary button--small pull-sm-right\"],[12,\"onclick\",[29,\"perform\",[[25,[\"createFavoriteTask\"]]],null]],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"createFavoriteTask\",\"isRunning\"]]],null,{\"statements\":[[0,\"      \"],[1,[29,\"t\",[\"explore.favorite-button.creating\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[29,\"t\",[\"explore.favorite-button.create\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/explore/favorite-button.hbs"
    }
  });

  _exports.default = _default;
});