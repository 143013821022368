define("client/controllers/users/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    shouldShowOnboarding: Ember.computed('user.updatedAt', function () {
      var user = Ember.get(this, 'user');
      return Ember.get(user, 'createdAt').getTime() === Ember.get(user, 'updatedAt').getTime();
    })
  });

  _exports.default = _default;
});