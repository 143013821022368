define("client/services/features", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    fetchFlags: function fetchFlags() {
      var _this = this;

      this.features = {};
      return Ember.get(this, 'ajax').request('_flags').then(function (response) {
        _this.features = response;
      });
    },
    hasFeature: function hasFeature(feature) {
      return !!this.features[feature];
    }
  });

  _exports.default = _default;
});