define("client/models/episode", ["exports", "client/models/-base", "ember-data/attr", "ember-data/relationships"], function (_exports, _base, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    airdate: (0, _attr.default)('utc'),
    canonicalTitle: (0, _attr.default)('string'),
    length: (0, _attr.default)('number'),
    number: (0, _attr.default)('number'),
    seasonNumber: (0, _attr.default)('number'),
    description: (0, _attr.default)('string'),
    thumbnail: (0, _attr.default)('object'),
    titles: (0, _attr.default)('object'),
    media: (0, _relationships.belongsTo)('media', {
      inverse: 'episodes'
    })
  });

  _exports.default = _default;
});