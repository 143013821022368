define("client/templates/settings/account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iktEBfUO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"settings-container\"],[9],[0,\"\\n  \"],[7,\"h1\"],[9],[1,[29,\"t\",[\"settings.account.header\"],null],false],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"settings--section-head text-danger\"],[9],[0,\"\\n    \"],[1,[29,\"t\",[\"settings.account.sub-header\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"form-group row\"],[9],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"button btn-danger btn-lg btn-block\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],[29,\"toggle\",[\"showConfirmModal\",[24,0,[]]],null]],null]],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"deleteAccount\",\"isRunning\"]]],null,{\"statements\":[[0,\"          \"],[1,[23,\"loading-spinner\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[1,[29,\"t\",[\"settings.account.delete\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"showConfirmModal\"]]],null,{\"statements\":[[0,\"        \"],[1,[29,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"modal\",[29,\"component\",[\"modals/confirm-action\"],[[\"title\",\"text\",\"onConfirm\",\"onClose\"],[[29,\"t\",[\"settings.account.confirm-title\"],null],[29,\"t\",[\"settings.account.confirm-text\"],null],[29,\"perform\",[[25,[\"deleteAccount\"]]],null],[29,\"toggle-action\",[\"showConfirmModal\",[24,0,[]]],null]]]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/settings/account.hbs"
    }
  });

  _exports.default = _default;
});