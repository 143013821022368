define("client/routes/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    breadcrumb: null,
    title: 'Kitsu - More of what you love.',
    redirect: function redirect() {
      if (!Ember.get(this, 'session.hasUser')) {
        this.transitionTo('explore.index', 'anime');
      }
    }
  });

  _exports.default = _default;
});