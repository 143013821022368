define("client/components/users/edit-profile/footer", ["exports", "ember-invoke-action", "client/utils/error-messages"], function (_exports, _emberInvokeAction, _errorMessages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      updateProfile: function updateProfile() {
        var _this = this;

        Ember.get(this, 'updateTask').perform().then(function () {
          (0, _emberInvokeAction.invokeAction)(_this, 'onUpdate');
        }).catch(function (errors) {
          var firstError = Ember.get(errors, 'firstObject.reason');
          Ember.set(_this, 'errorMessage', (0, _errorMessages.default)(firstError));
        });
      }
    }
  });

  _exports.default = _default;
});