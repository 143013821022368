define("client/utils/format-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = formatContent;

  /* global emojione */
  function formatContent(content) {
    if (Ember.isEmpty(content)) {
      return '';
    } // Convert unicode and :emoji: content into Emojione images


    return typeof emojione !== 'undefined' ? emojione.toImage(content) : content;
  }
});