define("client/utils/inject-script", ["exports", "client/utils/can-use-dom"], function (_exports, _canUseDom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.injectScript = injectScript;
  _exports.default = void 0;
  var _scriptsLoaded = {};
  var _scriptsLoading = {};

  function injectBrowser(src) {
    return new Ember.RSVP.Promise(function (resolve, reject) {
      // script has been loaded in the past or is currently loading
      if (_scriptsLoaded[src]) {
        return resolve();
      }

      if (_scriptsLoading[src]) {
        return _scriptsLoading[src].then(function () {
          resolve();
        });
      } // add the new script to our loading cache


      var done;
      _scriptsLoading[src] = new Ember.RSVP.Promise(function (_resolve) {
        done = _resolve;
      });

      _scriptsLoading[src].then(function () {
        delete _scriptsLoading[src];
      }); // Inject script into DOM (non-FastBoot)


      var element = document.createElement('script');
      element.async = true;
      element.src = src;

      element.onload = function () {
        _scriptsLoaded[src] = true;
        done();
        resolve();
      };

      element.onerror = function () {
        done();
        reject();
      };

      document.head.appendChild(element);
    });
  }
  /**
   * Injects a script into the DOM.
   *
   * @export
   * @param {String} src
   * @returns {RSVP.Promise}
   */


  function injectScript(src) {
    if (_canUseDom.default) {
      return injectBrowser(src);
    }
  }

  var _default = injectScript;
  _exports.default = _default;
});