define("client/templates/components/follow-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mSMFkKHQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\"],[11,\"class\",\"button-label\"],[9],[0,\"\\n\"],[4,\"if\",[[29,\"or\",[[29,\"or\",[[25,[\"getFollowStatus\",\"isRunning\"]],[25,[\"loading\"]]],null],[25,[\"toggleFollow\",\"isRunning\"]]],null]],null,{\"statements\":[[0,\"    \"],[1,[23,\"loading-spinner\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"isFollowing\"]]],null,{\"statements\":[[0,\"      \"],[1,[29,\"t\",[\"follow-button.unfollow\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[29,\"t\",[\"follow-button.follow\"],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/follow-button.hbs"
    }
  });

  _exports.default = _default;
});