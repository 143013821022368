define("client/models/notification", ["exports", "client/models/-base", "ember-data/attr", "ember-data/relationships"], function (_exports, _base, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    group: (0, _attr.default)('string'),
    isRead: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    isSeen: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    streamId: (0, _attr.default)('string'),
    activities: (0, _relationships.hasMany)('activity', {
      inverse: null
    })
  });

  _exports.default = _default;
});