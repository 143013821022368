define("client/templates/components/bootstrap/bs-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "43VW/9BD",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[12,\"class\",[30,[\"modal-dialog \",[29,\"if\",[[25,[\"largeModal\"]],\"modal-lg\"],null]]]],[11,\"role\",\"document\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"modal-content\"],[9],[0,\"\\n    \"],[15,1,[[29,\"hash\",null,[[\"header\",\"body\",\"footer\"],[[29,\"component\",[\"bootstrap/bs-modal-header\"],[[\"label\"],[[25,[\"label\"]]]]],[29,\"component\",[\"bootstrap/bs-modal-body\"],null],[29,\"component\",[\"bootstrap/bs-modal-footer\"],null]]]]]],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/bootstrap/bs-modal.hbs"
    }
  });

  _exports.default = _default;
});