define("client/adapters/application", ["exports", "ember-data/adapters/json-api", "ember-simple-auth/mixins/data-adapter-mixin", "client/config/environment"], function (_exports, _jsonApi, _dataAdapterMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend(_dataAdapterMixin.default, {
    host: _environment.default.kitsu.APIHost,
    namespace: 'api/edge',
    coalesceFindRequests: false,
    authorize: function authorize(xhr) {
      // Session is injected via `DataAdapaterMixin`
      var _Ember$get = Ember.get(this, 'session.data.authenticated'),
          accessToken = _Ember$get.access_token;

      xhr.setRequestHeader('Authorization', "Bearer ".concat(accessToken));
    }
  });

  _exports.default = _default;
});