define("client/models/manga", ["exports", "ember-data/attr", "ember-data/relationships", "client/models/media"], function (_exports, _attr, _relationships, _media) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _media.default.extend({
    chapterCount: (0, _attr.default)('number'),
    serialization: (0, _attr.default)('string'),
    volumeCount: (0, _attr.default)('number'),
    chapters: (0, _relationships.hasMany)('chapter', {
      inverse: 'manga'
    })
  });

  _exports.default = _default;
});