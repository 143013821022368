define("client/templates/components/stream-feed/items/post/modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f1FkawsU",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[4,\"bootstrap/bs-modal\",null,[[\"onClose\"],[[25,[\"onClose\"]]]],{\"statements\":[[4,\"component\",[[29,\"-assert-implicit-component-helper-argument\",[[24,1,[\"body\"]],\"expected `modal.body` to be a contextual component but found a string. Did you mean `(component modal.body)`? ('client/templates/components/stream-feed/items/post/modal.hbs' @ L2:C5) \"],null]],null,{\"statements\":[[0,\"    \"],[1,[29,\"stream-feed/items/post\",null,[[\"post\",\"isExpanded\",\"readOnly\",\"isModalView\"],[[25,[\"post\"]],true,[25,[\"readOnly\"]],true]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/stream-feed/items/post/modal.hbs"
    }
  });

  _exports.default = _default;
});