define("client/components/users/user-stats", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    classNames: ['user-stats'],
    normStats: Ember.computed('user.stats', function () {
      var stats = Ember.get(this, 'user.stats');
      var normed = stats.reduce(function (acc, stat) {
        var camelKind = Ember.String.camelize(Ember.get(stat, 'kind'));
        return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, camelKind, stat));
      }, {});

      if (normed.animeAmountConsumed && normed.animeAmountConsumed.statsData.time < 1) {
        delete normed.animeAmountConsumed;
      }

      if (normed.mangaAmountConsumed && normed.mangaAmountConsumed.statsData.units < 1) {
        delete normed.mangaAmountConsumed;
      }

      if (normed.animeCategoryBreakdown && normed.animeCategoryBreakdown.statsData.total < 1) {
        delete normed.animeCategoryBreakdown;
      }

      if (normed.mangaCategoryBreakdown && normed.mangaCategoryBreakdown.statsData.total < 1) {
        delete normed.mangaCategoryBreakdown;
      }

      return normed;
    }).readOnly()
  });

  _exports.default = _default;
});