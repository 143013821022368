define("client/utils/moment", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.moment = moment;
  _exports.momentUser = momentUser;

  function moment() {
    return _moment.default.apply(void 0, arguments);
  }

  function momentUser(context) {
    var _Ember$getOwner$looku;

    for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      args[_key - 1] = arguments[_key];
    }

    return (_Ember$getOwner$looku = Ember.getOwner(context).lookup('service:moment')).moment.apply(_Ember$getOwner$looku, args);
  }
});