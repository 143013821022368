define("client/components/modals/confirm-action", ["exports", "ember-invoke-action"], function (_exports, _emberInvokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      onConfirm: function onConfirm() {
        this.$('.modal').modal('hide');
        (0, _emberInvokeAction.invokeAction)(this, 'onConfirm');
      }
    }
  });

  _exports.default = _default;
});