define("client/models/report", ["exports", "client/models/-base", "ember-data/attr", "ember-data/relationships"], function (_exports, _base, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    createdAt: (0, _attr.default)('utc'),
    naughtyId: (0, _attr.default)('number'),
    naughtyType: (0, _attr.default)('string'),
    reason: (0, _attr.default)('string'),
    status: (0, _attr.default)('string'),
    explanation: (0, _attr.default)('string'),
    naughty: (0, _relationships.belongsTo)('-base'),
    moderator: (0, _relationships.belongsTo)('user'),
    user: (0, _relationships.belongsTo)('user'),
    naughtyLink: Ember.computed('naughtyType', function () {
      return Ember.get(this, 'naughtyType').pluralize().toLowerCase();
    }).readOnly()
  });

  _exports.default = _default;
});