define("client/components/episodes/video-player", ["exports", "ember-invoke-action"], function (_exports, _emberInvokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['video-player'],
    store: Ember.inject.service(),
    // Parameters
    video: null,
    // State
    loading: true,
    didMark: false,
    onProgress: function onProgress(_ref) {
      var position = _ref.position,
          duration = _ref.duration;
      if (Ember.get(this, 'didMark')) return;
      var progress = position / duration;
      var remaining = duration - position; // more than 90% done or less than 5 minutes remaining

      if (progress > 0.9 || remaining < 300) {
        Ember.set(this, 'didMark', true);
        (0, _emberInvokeAction.invokeAction)(this, 'onMark');
      }
    }
  });

  _exports.default = _default;
});