define("client/models/casting", ["exports", "client/models/-base", "ember-data/attr", "ember-data/relationships"], function (_exports, _base, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    featured: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    language: (0, _attr.default)('string'),
    role: (0, _attr.default)('string'),
    voiceActor: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    character: (0, _relationships.belongsTo)('character'),
    media: (0, _relationships.belongsTo)('media'),
    person: (0, _relationships.belongsTo)('person')
  });

  _exports.default = _default;
});