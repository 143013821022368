define("client/templates/groups/group/dashboard/tickets", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "06tdMKLa",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[1,[29,\"groups/tickets/ticket-list\",null,[[\"group\",\"filter\",\"query\",\"updateQueryParam\",\"class\"],[[25,[\"group\"]],[25,[\"filter\"]],[25,[\"query\"]],[29,\"action\",[[24,0,[]],\"updateQueryParam\"],null],\"group-create-wrapper ticket-wrapper\"]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/groups/group/dashboard/tickets.hbs"
    }
  });

  _exports.default = _default;
});