define("client/templates/explore/category/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9wl7L0Wj",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"explore-page col-sm no-padding-left\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"media\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"media-body\"],[9],[0,\"\\n      \"],[1,[29,\"explore/favorite-button\",null,[[\"category\"],[[25,[\"category\"]]]]],false],[0,\"\\n      \"],[7,\"h3\"],[9],[1,[29,\"t\",[\"explore.category.index\"],[[\"category\",\"type\"],[[29,\"t\",[[29,\"category-to-i18n\",[[25,[\"category\",\"slug\"]]],null]],null],[25,[\"mediaType\"]]]]],false],[10],[0,\"\\n\"],[4,\"if\",[[29,\"not\",[[29,\"is-empty\",[[25,[\"category\",\"description\"]]],null]],null]],null,{\"statements\":[[0,\"        \"],[7,\"p\"],[9],[1,[29,\"read-more\",[[25,[\"category\",\"description\"]],280],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[1,[29,\"explore/explore-search\",null,[[\"mediaType\"],[[25,[\"mediaType\"]]]]],false],[0,\"\\n\\n\"],[0,\"  \"],[1,[29,\"explore/explore-section\",null,[[\"type\",\"mediaType\",\"category\",\"sort\",\"filters\",\"more\",\"limit\"],[\"newly-released\",[25,[\"mediaType\"]],[25,[\"category\"]],\"-start_date\",\"status:current\",\"explore.category.more\",15]]],false],[0,\"\\n\\n\"],[0,\"  \"],[1,[29,\"explore/explore-section\",null,[[\"type\",\"mediaType\",\"category\",\"more\",\"limit\"],[\"trending\",[25,[\"mediaType\"]],[25,[\"category\"]],\"explore.category.more\",15]]],false],[0,\"\\n\\n\"],[0,\"  \"],[1,[29,\"explore/explore-section\",null,[[\"type\",\"mediaType\",\"category\",\"sort\",\"more\",\"limit\"],[\"most-popular\",[25,[\"mediaType\"]],[25,[\"category\"]],\"-user_count\",\"explore.category.more\",15]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"explore-sidebar col-sm\"],[9],[0,\"\\n  \"],[1,[29,\"explore/category-sidebar/related-categories\",null,[[\"category\"],[[25,[\"category\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/explore/category/index.hbs"
    }
  });

  _exports.default = _default;
});