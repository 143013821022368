define("client/templates/notifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZUoq2Pw3",
    "block": "{\"symbols\":[\"group\"],\"statements\":[[7,\"div\"],[11,\"class\",\"col-md-8 col-xs-11 centered\"],[9],[0,\"\\n  \"],[7,\"ul\"],[11,\"class\",\"notification-list full-size\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"model\",\"taskInstance\",\"isRunning\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"text-xs-center w-100\"],[9],[0,\"\\n        \"],[1,[29,\"loading-spinner\",null,[[\"size\"],[\"large\"]]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"infinite-pagination\",null,[[\"onPagination\"],[[29,\"route-action\",[\"onPagination\"],null]]],{\"statements\":[[4,\"each\",[[25,[\"notifications\"]]],null,{\"statements\":[[0,\"          \"],[1,[29,\"application/user-notifications/item\",null,[[\"tagName\",\"class\",\"group\"],[\"li\",\"notification-item\",[24,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"          \"],[7,\"div\"],[11,\"class\",\"stream-error-wrapper\"],[9],[0,\"\\n            \"],[1,[29,\"t\",[\"notifications.none\"],null],false],[0,\"\\n          \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/notifications.hbs"
    }
  });

  _exports.default = _default;
});