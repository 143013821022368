define("client/models/feed", ["exports", "client/models/-base", "ember-data/relationships"], function (_exports, _base, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    activityGroups: (0, _relationships.hasMany)('activity-group')
  });

  _exports.default = _default;
});