define("client/models/-base", ["exports", "ember-data/model", "client/utils/getter"], function (_exports, _model, _getter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Ember Data doesn't currently allow polymorphic relationships to have
   * a base model that doesn't exist.
   *
   * Open Issue: https://github.com/emberjs/data/issues/4377
   *
   * Provides a `modelType` property to get the modelName easily without the need to
   * determine if we're dealing with a proxy or model instance.
   */
  var _default = _model.default.extend({
    modelType: (0, _getter.default)(function () {
      return this.constructor.modelName;
    })
  });

  _exports.default = _default;
});