define("client/controllers/groups/group/dashboard/reports", ["exports", "ember-parachute", "client/utils/computed-macros"], function (_exports, _emberParachute, _computedMacros) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var queryParams = new _emberParachute.default({
    filter: {
      defaultValue: 'open',
      refresh: true
    }
  });

  var _default = Ember.Controller.extend(queryParams.Mixin, {
    filterOptions: ['open', 'resolved', 'all'],
    reports: (0, _computedMacros.concat)('model.taskInstance.value', 'model.paginatedRecords'),
    queryParamsDidChange: function queryParamsDidChange(_ref) {
      var shouldRefresh = _ref.shouldRefresh;

      if (shouldRefresh) {
        this.send('refreshModel');
      }
    }
  });

  _exports.default = _default;
});