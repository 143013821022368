define("client/templates/components/application/redirect-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vC5uvYcD",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"isShown\"]]],null,{\"statements\":[[0,\"  \"],[7,\"aside\"],[11,\"id\",\"explain-redirect\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"inner\"],[9],[0,\"\\n          \"],[7,\"h5\"],[9],[0,\"We've Moved!\"],[10],[0,\"\\n          Don't be alarmed by the URL, we've moved from Kitsu.io to Kitsu.app\\n          \"],[7,\"span\"],[11,\"class\",\"dismiss\"],[9],[0,\"\\n            \"],[1,[29,\"svg-jar\",[\"close\"],null],false],[0,\"\\n          \"],[3,\"action\",[[24,0,[]],\"dismiss\"]],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/application/redirect-warning.hbs"
    }
  });

  _exports.default = _default;
});