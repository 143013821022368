define("client/models/group-report", ["exports", "client/models/report", "ember-data/relationships"], function (_exports, _report, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _report.default.extend({
    group: (0, _relationships.belongsTo)('group')
  });

  _exports.default = _default;
});