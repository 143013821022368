define("client/templates/components/media/media-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l2k9jau3",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"media-summary\"],[9],[0,\"\\n\"],[0,\"  \"],[1,[29,\"media/media-information\",null,[[\"media\"],[[25,[\"media\"]]]]],false],[0,\"\\n\\n\"],[0,\"\\n\"],[0,\"  \"],[1,[29,\"media/featured-characters\",null,[[\"media\"],[[25,[\"media\"]]]]],false],[0,\"\\n\\n\"],[0,\"  \"],[1,[29,\"media/media-related\",null,[[\"media\"],[[25,[\"media\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"media\",\"shouldShowAds\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"ad-unit\",null,[[\"unit\",\"targeting\",\"sizes\"],[\"kitsu.media.summary.sidebar\",[29,\"hash\",null,[[\"section\",\"media\"],[\"media\",[25,[\"media\",\"slug\"]]]]],[29,\"hash\",null,[[\"mobile\",\"tablet\"],[[29,\"array\",[[29,\"array\",[300,250],null],[29,\"array\",[320,100],null],[29,\"array\",[320,50],null]],null],[29,\"array\",[[29,\"array\",[336,280],null],[29,\"array\",[300,250],null]],null]]]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/media/media-summary.hbs"
    }
  });

  _exports.default = _default;
});