define("client/templates/components/media/media-poster", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+BycSADc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"grid-poster\"],[9],[0,\"\\n\"],[4,\"media-popover\",null,[[\"media\"],[[25,[\"media\"]]]],{\"statements\":[[0,\"    \"],[1,[29,\"lazy-image\",null,[[\"src\"],[[29,\"image\",[[25,[\"media\",\"posterImage\"]],\"medium\"],null]]]],false],[0,\"\\n\\n\"],[0,\"    \"],[7,\"div\"],[11,\"class\",\"poster-overlay\"],[9],[0,\"\\n      \"],[7,\"a\"],[12,\"href\",[29,\"href-to\",[[29,\"concat\",[[25,[\"media\",\"modelType\"]],\".show\"],null],[25,[\"media\"]]],null]],[9],[10],[0,\"\\n      \"],[1,[29,\"library-entry/status-dropdown\",null,[[\"media\"],[[25,[\"media\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"ellipsis\"],[9],[0,\"\\n  \"],[7,\"a\"],[12,\"href\",[29,\"href-to\",[[29,\"concat\",[[25,[\"media\",\"modelType\"]],\".show\"],null],[25,[\"media\"]]],null]],[11,\"class\",\"media-title\"],[9],[0,\"\\n    \"],[1,[25,[\"media\",\"computedTitle\"]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/media/media-poster.hbs"
    }
  });

  _exports.default = _default;
});