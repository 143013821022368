define("client/templates/components/application/session-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o+vqHOcu",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[4,\"if\",[[25,[\"session\",\"error\"]]],null,{\"statements\":[[4,\"bootstrap/bs-modal\",null,[[\"keyboard\",\"backdrop\",\"onClose\"],[\"false\",\"static\",[29,\"action\",[[24,0,[]],\"reload\"],null]]],{\"statements\":[[4,\"component\",[[29,\"-assert-implicit-component-helper-argument\",[[24,1,[\"header\"]],\"expected `modal.header` to be a contextual component but found a string. Did you mean `(component modal.header)`? ('client/templates/components/application/session-error.hbs' @ L3:C7) \"],null]],null,{\"statements\":[[0,\"      \"],[7,\"h4\"],[11,\"class\",\"modal-title\"],[9],[0,\"\\n        \"],[1,[29,\"t\",[\"errors.serverError.title\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"component\",[[29,\"-assert-implicit-component-helper-argument\",[[24,1,[\"body\"]],\"expected `modal.body` to be a contextual component but found a string. Did you mean `(component modal.body)`? ('client/templates/components/application/session-error.hbs' @ L8:C7) \"],null]],null,{\"statements\":[[0,\"      \"],[1,[29,\"t\",[\"errors.serverError.body\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"component\",[[29,\"-assert-implicit-component-helper-argument\",[[24,1,[\"footer\"]],\"expected `modal.footer` to be a contextual component but found a string. Did you mean `(component modal.footer)`? ('client/templates/components/application/session-error.hbs' @ L11:C7) \"],null]],null,{\"statements\":[[4,\"bootstrap/bs-button\",null,[[\"onClick\",\"type\"],[[29,\"action\",[[24,0,[]],\"reload\"],null],\"btn-primary\"]],{\"statements\":[[0,\"        \"],[1,[29,\"t\",[\"errors.serverError.reload\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/application/session-error.hbs"
    }
  });

  _exports.default = _default;
});