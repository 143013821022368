define("client/templates/components/application/mobile-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qZnlhG92",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[29,\"and\",[[25,[\"shouldRender\"]],[25,[\"isPhone\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"alert alert-kitsu\"],[11,\"role\",\"alert\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"close\"],[11,\"aria-label\",\"Close\"],[11,\"type\",\"button\"],[9],[0,\"\\n      \"],[7,\"span\"],[11,\"aria-hidden\",\"true\"],[9],[0,\"×\"],[10],[0,\"\\n    \"],[3,\"action\",[[24,0,[]],\"dismiss\"]],[10],[0,\"\\n    \"],[7,\"p\"],[9],[1,[29,\"t\",[\"mobile-banner.pitch\"],null],false],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"isAndroid\"]]],null,{\"statements\":[[0,\"      \"],[7,\"a\"],[11,\"href\",\"https://play.google.com/store/apps/details?id=com.everfox.animetrackerandroid\"],[9],[0,\"\\n        \"],[1,[29,\"svg-jar\",[\"google-play\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"isApple\"]]],null,{\"statements\":[[0,\"      \"],[7,\"a\"],[11,\"href\",\"https://itunes.apple.com/us/app/kitsu-anime/id590452826\"],[9],[0,\"\\n        \"],[1,[29,\"svg-jar\",[\"app-store\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n    \"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/application/mobile-banner.hbs"
    }
  });

  _exports.default = _default;
});