define("client/templates/components/groups/dashboard/members/ban-list-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6wAvmO1u",
    "block": "{\"symbols\":[],\"statements\":[[7,\"th\"],[11,\"scope\",\"row\"],[9],[1,[29,\"lazy-image\",null,[[\"src\"],[[29,\"image\",[[25,[\"ban\",\"user\",\"avatar\"]],\"small\"],null]]]],false],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"leader-name\"],[9],[0,\"\\n  \"],[7,\"a\"],[12,\"href\",[29,\"href-to\",[\"users.index\",[25,[\"ban\",\"user\"]]],null]],[9],[0,\"\\n    \"],[1,[25,[\"ban\",\"user\",\"name\"]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"edit-leader\"],[9],[0,\"\\n  \"],[7,\"a\"],[11,\"href\",\"#\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"unbanUserTask\",\"isRunning\"]]],null,{\"statements\":[[0,\"      \"],[1,[29,\"loading-spinner\",null,[[\"size\"],[\"small\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[29,\"t\",[\"groups.dashboard.members.bans.unban\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[3,\"action\",[[24,0,[]],[29,\"perform\",[[25,[\"unbanUserTask\"]]],null]]],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/groups/dashboard/members/ban-list-user.hbs"
    }
  });

  _exports.default = _default;
});