define("client/components/media/filter-widgets/category-list", ["exports", "client/components/explore/category-sidebar/category-modal", "ember-invoke-action"], function (_exports, _categoryModal, _emberInvokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _categoryModal.default.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var mutatableCategories = Ember.copy(Ember.get(this, 'selection'));
      Ember.set(this, 'mutatableCategories', mutatableCategories);
    },
    actions: {
      updateCategories: function updateCategories(category) {
        var categories = Ember.get(this, 'mutatableCategories');

        if (categories.includes(category)) {
          categories.removeObject(category);
        } else {
          categories.addObject(category);
        }

        (0, _emberInvokeAction.invokeAction)(this, 'update', categories);
      },
      resetCategories: function resetCategories() {
        (0, _emberInvokeAction.invokeAction)(this, 'update', []);
      }
    }
  });

  _exports.default = _default;
});