define("client/models/media-reaction", ["exports", "client/models/-base", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _base, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Validations = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    reaction: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      max: 140
    })]
  });
  _exports.Validations = Validations;

  var _default = _base.default.extend(Validations, {
    reaction: (0, _attr.default)('string'),
    upVotesCount: (0, _attr.default)('number'),
    libraryEntry: (0, _relationships.belongsTo)('library-entry', {
      inverse: 'mediaReaction'
    }),
    anime: (0, _relationships.belongsTo)('anime'),
    manga: (0, _relationships.belongsTo)('manga'),
    user: (0, _relationships.belongsTo)('user'),
    votes: (0, _relationships.hasMany)('media-reaction-vote'),
    media: Ember.computed('anime', 'manga', function () {
      return this.belongsTo('anime').value() || this.belongsTo('manga').value();
    }).readOnly()
  });

  _exports.default = _default;
});