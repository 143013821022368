define("client/components/expanding-textarea", ["exports", "ember-one-way-controls/components/one-way-textarea", "ember-invoke-action"], function (_exports, _oneWayTextarea, _emberInvokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global autosize */

  /* TODO: ember-one-way-controls is deprecated, use native textarea for expanding-textarea instead.
  Also remove height fix from shame.css (.report-content-input) by using textarea rows attribute
  Migration: https://github.com/DavyJonesLocker/ember-one-way-controls#migrating
  {{! old }}
  {{one-way-textarea myValue update=(action (mut myValue))}}
  {{! new }}
  <textarea value={{myValue}} oninput={{action (mut myValue) value="target.value"}}></textarea>
  */
  var _default = _oneWayTextarea.default.extend({
    autocomplete: 'ignore',
    keyUp: function keyUp() {},
    keyDown: function keyDown(event) {
      var method = Ember.get(this, "keyEvents.".concat(event.keyCode));

      if (method) {
        (0, _emberInvokeAction.invokeAction)(this, method, this, event, event.target.value);
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      autosize(Ember.get(this, 'element'));
      Ember.run.later(function () {
        _this.resize();

        if (Ember.get(_this, 'autofocus') && !Ember.get(_this, 'isDestroyed')) {
          _this.$().focus();
        }
      }, 200);
    },
    willDestroyElement: function willDestroyElement() {
      autosize.destroy(Ember.get(this, 'element'));

      this._super.apply(this, arguments);
    },
    clear: function clear() {
      this.$().val('');
      this.resize();
    },
    resize: function resize() {
      if (Ember.get(this, 'isDestroyed')) {
        return;
      }

      autosize.update(Ember.get(this, 'element'));
    }
  });

  _exports.default = _default;
});