define("client/components/lazy-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['lazy-image'],
    didUpdateAttrs: function didUpdateAttrs() {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', function () {
        _this.element.querySelector('img').classList.remove('lazyload');

        _this.element.querySelector('img').classList.add('lazyload');
      });
    }
  });

  _exports.default = _default;
});