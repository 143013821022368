define("client/components/library-entry/state/new-entry", ["exports", "ember-invoke-action"], function (_exports, _emberInvokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      createLibraryEntry: function createLibraryEntry(status, rating) {
        if (!Ember.get(this, 'session.hasUser')) {
          return Ember.get(this, 'session').signUpModal();
        }

        (0, _emberInvokeAction.invokeAction)(this, 'onCreate', status, rating);
      }
    }
  });

  _exports.default = _default;
});