define("client/models/group-neighbor", ["exports", "client/models/-base", "ember-data/relationships"], function (_exports, _base, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    destination: (0, _relationships.belongsTo)('group'),
    source: (0, _relationships.belongsTo)('group', {
      inverse: 'neighbors'
    })
  });

  _exports.default = _default;
});