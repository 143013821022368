define("client/components/episodes/episode-video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isMarking: false,
    isPlaying: false,
    video: null,
    episode: null,
    media: null,
    didUpdateAttrs: function didUpdateAttrs() {
      Ember.set(this, 'video', null);

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});