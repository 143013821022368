define("client/components/library-entry/regular-rating", ["exports", "ember-invoke-action"], function (_exports, _emberInvokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      Ember.set(this, 'regularRating', Ember.get(this, 'rating') / 2 || 0.5);
    },
    actions: {
      onClick: function onClick(rating) {
        (0, _emberInvokeAction.invokeAction)(this, 'onClick', rating * 2);
      }
    }
  });

  _exports.default = _default;
});