define("client/routes/notifications", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "kitsu-shared/mixins/pagination"], function (_exports, _authenticatedRouteMixin, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _pagination.default, {
    authenticationRoute: 'dashboard',
    queryCache: Ember.inject.service(),
    model: function model() {
      return {
        taskInstance: this.queryPaginated('feed', {
          type: 'notifications',
          id: Ember.get(this, 'session.account.id'),
          include: 'actor,target.post',
          page: {
            limit: 30
          }
        }, {
          cache: false
        }),
        paginatedRecords: []
      };
    },
    actions: {
      onPagination: function onPagination() {
        return this._super('feed', {
          type: 'notifications',
          id: Ember.get(this, 'session.account.id')
        });
      }
    }
  });

  _exports.default = _default;
});