define("client/components/users/user-favorites", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var favoriteComputed = function favoriteComputed() {
    var _Ember;

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    var type = args.pop();
    return (_Ember = Ember).computed.apply(_Ember, args.concat([function () {
      var records = Ember.get(this, 'user.favorites') || [];
      return records.filter(function (record) {
        return Ember.get(record, 'item.modelType') === type;
      }).rejectBy('isDeleted').sortBy('favRank').toArray();
    }])).readOnly();
  };

  var _default = Ember.Component.extend({
    classNames: ['user-favorites'],

    /**
     * Number of entries to show before the fold
     */
    animeCount: 8,
    mangaCount: 8,
    characterCount: 8,
    animeFavorites: favoriteComputed('user.favorites.@each.isDeleted', 'anime'),
    mangaFavorites: favoriteComputed('user.favorites.@each.isDeleted', 'manga'),
    characterFavorites: favoriteComputed('user.favorites.@each.isDeleted', 'character')
  });

  _exports.default = _default;
});