define("client/utils/elements-match", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Replacement for jQuery(element).is(selector)
  var _default = function _default(element, selector) {
    return element.matches.call(element, selector);
  };

  _exports.default = _default;
});