define("client/components/stream-feed/items/media-reaction", ["exports", "client/components/media/media-reaction", "ember-invoke-action", "client/utils/error-messages", "client/mixins/clipboard"], function (_exports, _mediaReaction, _emberInvokeAction, _errorMessages, _clipboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mediaReaction.default.extend(_clipboard.default, {
    classNames: ['stream-item', 'row'],
    metrics: Ember.inject.service(),
    router: Ember.inject.service(),
    activity: Ember.computed.reads('group.activities.firstObject'),
    media: Ember.computed.alias('reaction.media'),
    canDelete: Ember.computed('session.account', 'reaction', function () {
      var currentUser = Ember.get(this, 'session.hasUser') && Ember.get(this, 'session.account');

      if (!currentUser) {
        return false;
      }

      if (currentUser.hasRole('admin', Ember.get(this, 'reaction'))) {
        return true;
      }

      if (Ember.get(currentUser, 'id') === Ember.get(this, 'reaction.user.id')) {
        return true;
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'host', "".concat(window.location.protocol, "//").concat(window.location.host));
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (Ember.get(this, 'group') !== undefined) {
        Ember.set(this, 'reaction', Ember.get(this, 'activity.subject.content') || Ember.get(this, 'activity.subject'));
      }

      this._super.apply(this, arguments);
    },
    actions: {
      trackEngagement: function trackEngagement(label) {
        var data = {
          label: label,
          content: {
            foreign_id: "MediaReaction:".concat(Ember.get(this, 'reaction.id'))
          },
          position: Ember.get(this, 'positionInFeed') || 0
        };

        if (Ember.get(this, 'feedId') !== undefined) {
          data.feed_id = Ember.get(this, 'feedId');
        }

        Ember.get(this, 'metrics').invoke('trackEngagement', 'Stream', data);
      },
      deleteReaction: function deleteReaction() {
        var _this = this;

        if (Ember.get(this, 'reaction.isDeleted')) {
          return;
        }

        Ember.get(this, 'reaction').destroyRecord().then(function () {
          // this reaction is being deleted from its permalink page
          if (Ember.get(_this, 'group') === undefined) {
            Ember.get(_this, 'router').transitionTo('dashboard');
          } else {
            // try to find the activity-group that references this reaction
            var record = Ember.get(_this, 'store').peekRecord('activity-group', Ember.get(_this, 'group.id'));
            (0, _emberInvokeAction.invokeAction)(_this, 'removeGroup', record);
          }

          Ember.get(_this, 'notify').success('Success! The reaction has been deleted.');
        }).catch(function (err) {
          Ember.get(_this, 'reaction').rollbackAttributes();
          Ember.get(_this, 'notify').error((0, _errorMessages.default)(err));
        });
      }
    }
  });

  _exports.default = _default;
});