define("client/initializers/onerror", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    Ember.onerror = function (error) {
      /**
       * `SecurityError: DOM Exception 18` is thrown by Safari (iOS/OSX) when
       * pushState is used >= 100 times within 30 seconds. This will reload browsers
       * that hit this limit.
      */
      if (error.name === 'SecurityError' && error.code === 18) {
        return Ember.run.later(function () {
          return window.location.reload();
        }, 1);
      }

      console.error(error);
      /**
       * An Ember.onerror handler _must_ rethrow exceptions when `Ember.testing` is
       * `true` or the test suite is unreliable.
       */

      if (Ember.testing) {
        throw error;
      }
    };
  }

  var _default = {
    name: 'onerror',
    initialize: initialize
  };
  _exports.default = _default;
});