define("client/templates/components/users/library/library-grid/loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lcgR3uRA",
    "block": "{\"symbols\":[\"_\",\"_\"],\"statements\":[[4,\"each\",[[29,\"repeat\",[2],null]],null,{\"statements\":[[4,\"skeleton-container\",null,[[\"class\"],[\"library-grid-skeleton\"]],{\"statements\":[[4,\"each\",[[29,\"repeat\",[4],null]],null,{\"statements\":[[0,\"      \"],[1,[29,\"skeleton-loader\",null,[[\"class\"],[\"library-grid-first\"]]],false],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/users/library/library-grid/loader.hbs"
    }
  });

  _exports.default = _default;
});