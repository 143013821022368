define("client/components/users/about-me", ["exports", "client/utils/humanize-duration", "moment"], function (_exports, _humanizeDuration, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['about-me-panel'],
    intl: Ember.inject.service(),
    gender: Ember.computed('user.gender', function () {
      var gender = Ember.get(this, 'user.gender') || 'secret';

      if (['secret', 'male', 'female'].includes(gender)) {
        return Ember.get(this, 'intl').t("users.activity.about.gender.".concat(gender));
      }

      return gender;
    }),
    createdHumanize: Ember.computed('user.createdAt', function () {
      return (0, _humanizeDuration.default)(_moment.default.duration((0, _moment.default)().diff(this.get('user.createdAt'))), true);
    })
  });

  _exports.default = _default;
});