define("client/components/episodes/video-player/hulu-player", ["exports", "client/utils/inject-script"], function (_exports, _injectScript) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /* global HuluPlayer */
  var HULU_API = 'https://player.hulu.com/iframe/iframe_api';

  var _default = Ember.Component.extend({
    classNames: ['hulu-embed'],
    session: Ember.inject.service(),
    video: null,
    videoId: Ember.computed.alias('video.embedData.eid'),
    width: 800,
    height: 600,
    onLoad: function onLoad() {},
    onProgress: function onProgress() {},
    isPlaying: false,
    init: function init() {
      // Start loading the script ASAP
      (0, _injectScript.injectScript)(HULU_API);

      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments); // Hulu's script injects another (versioned) script which is what we want.  Thankfully they
      // set an ID on it that we can use to monitor loading


      (0, _injectScript.injectScript)(HULU_API).then(function () {
        if (HuluPlayer && HuluPlayer.DP) return _this.initPlayer();
        var huluPlayerApi = document.getElementById('hulu-playerapi-script');

        huluPlayerApi.onload = function () {
          return _this.initPlayer();
        };
      });
    },
    videoDidChange: Ember.observer('video', function () {
      var videoId = Ember.get(this, 'videoId');
      this.changeVideo(videoId);
    }),
    playingDidUpdate: Ember.observer('isPlaying', function () {
      if (Ember.get(this, 'isPlaying')) {
        this.resume();
      } else {
        this.pause();
      }
    }),
    initPlayer: function initPlayer() {
      var _this2 = this;

      var containerId = Ember.get(this, 'elementId'); // Initialize the HuluPlayer instance

      var options = _objectSpread(_objectSpread({}, Ember.getProperties(this, 'width', 'height')), {}, {
        id: Ember.get(this, 'videoId'),
        playerType: 'dash'
      }); // tag this embed if needed


      var data = this.video && this.video.embedData;

      if (data && data.network === undefined) {
        options.partner = 'kitsu';
      }

      var player = new HuluPlayer.DP(containerId, options);
      Ember.set(this, 'player', player); // Hook onto the events

      player.addEventListener('player_ready', function () {
        return Ember.get(_this2, 'onLoad')();
      });
      player.addEventListener('videoMetadata', function (_ref) {
        var length = _ref.data.length;
        if (!Ember.get(_this2, 'isPlaying')) _this2.pause();
        Ember.set(_this2, 'duration', length);
        Ember.get(_this2, 'onProgress')({
          position: 0,
          duration: length
        });
      });
      player.addEventListener('videoStateChange', function (_ref2) {
        var state = _ref2.data;

        if (state === 'playing') {
          Ember.set(_this2, 'isPlaying', true);
        } else if (state === 'pause') {
          Ember.set(_this2, 'isPlaying', false);
        }
      });
      player.addEventListener('videoPlayheadUpdate', function (_ref3) {
        var _ref3$data = _ref3.data,
            position = _ref3$data.position,
            duration = _ref3$data.duration,
            state = _ref3$data.state;
        if (state === 'ad') return;
        Ember.set(_this2, 'position', position);
        Ember.get(_this2, 'onProgress')({
          position: position,
          duration: duration
        });
      });
      player.loadPlayer();
    },
    changeVideo: function changeVideo(videoId) {
      var player = Ember.get(this, 'player');
      if (!player) return;
      player.playVideo(videoId);
    },
    resume: function resume() {
      var player = Ember.get(this, 'player');
      if (player) player.resumeVideo();
    },
    pause: function pause() {
      var player = Ember.get(this, 'player');
      if (player) player.pauseVideo();
    }
  });

  _exports.default = _default;
});