define("client/components/bootstrap/bs-dropdown-button", ["exports", "client/components/bootstrap/bs-button"], function (_exports, _bsButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _bsButton.default.extend({
    attributeBindings: ['aria-haspopup', 'aria-expanded', 'data-toggle'],
    classNames: ['dropdown-toggle'],
    'aria-haspopup': 'true',
    'aria-expanded': 'false',
    'data-toggle': 'dropdown'
  });

  _exports.default = _default;
});