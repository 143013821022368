define("client/templates/users/followers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U90GLCi8",
    "block": "{\"symbols\":[\"follow\"],\"statements\":[[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"model\",\"taskInstance\",\"isRunning\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"text-xs-center w-100 m-t-1 m-b-1\"],[9],[0,\"\\n        \"],[1,[29,\"loading-spinner\",null,[[\"size\"],[\"large\"]]],false],[0,\"\\n        \"],[7,\"p\"],[9],[1,[29,\"t\",[\"users.followers.loading\"],null],false],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"model\",\"taskInstance\",\"error\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"text-xs-center w-100 m-t-1 m-b-1\"],[9],[0,\"\\n        \"],[1,[29,\"t\",[\"errors.load\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"taskValue\"]]],null,{\"statements\":[[4,\"infinite-pagination\",null,[[\"onPagination\"],[[29,\"route-action\",[\"onPagination\"],null]]],{\"statements\":[[4,\"each\",[[25,[\"taskValue\"]]],null,{\"statements\":[[4,\"if\",[[24,1,[\"follower\"]]],null,{\"statements\":[[0,\"            \"],[1,[29,\"users/user-card\",null,[[\"user\"],[[24,1,[\"follower\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"text-xs-center w-100 m-t-1 m-b-1\"],[9],[0,\"\\n        \"],[1,[29,\"t\",[\"users.followers.not-found\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n    \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/users/followers.hbs"
    }
  });

  _exports.default = _default;
});