define("client/models/media", ["exports", "client/models/-base", "ember-data/attr", "ember-data/relationships", "client/utils/get-title-field"], function (_exports, _base, _attr, _relationships, _getTitleField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    abbreviatedTitles: (0, _attr.default)('array'),
    averageRating: (0, _attr.default)('number'),
    canonicalTitle: (0, _attr.default)('string'),
    coverImage: (0, _attr.default)('object', {
      defaultValue: '/images/default_cover.png'
    }),
    coverImageTopOffset: (0, _attr.default)('number'),
    endDate: (0, _attr.default)('utc'),
    favoritesCount: (0, _attr.default)('number'),
    nsfw: (0, _attr.default)('boolean'),
    popularityRank: (0, _attr.default)('number'),
    posterImage: (0, _attr.default)('object', {
      defaultValue: '/images/default_poster.jpg'
    }),
    ratingFrequencies: (0, _attr.default)('object'),
    ratingRank: (0, _attr.default)('number'),
    slug: (0, _attr.default)('string'),
    startDate: (0, _attr.default)('utc'),
    status: (0, _attr.default)('string'),
    subtype: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    tba: (0, _attr.default)('string'),
    titles: (0, _attr.default)('object'),
    castings: (0, _relationships.hasMany)('casting'),
    categories: (0, _relationships.hasMany)('category'),
    mediaRelationships: (0, _relationships.hasMany)('media-relationship', {
      inverse: 'source'
    }),
    reviews: (0, _relationships.hasMany)('review'),
    episodes: (0, _relationships.hasMany)('episode', {
      inverse: 'media'
    }),
    shouldShowAds: Ember.computed.alias('nsfw'),
    unitCount: Ember.computed.or('episodeCount', 'chapterCount'),
    computedTitle: Ember.computed('session.account.titleLanguagePreference', 'titles', function () {
      return (0, _getTitleField.getComputedTitle)(Ember.get(this, 'session'), this);
    }).readOnly(),
    year: Ember.computed('startDate', function () {
      var startDate = Ember.get(this, 'startDate');
      return startDate ? Ember.get(this, 'startDate').year() : '';
    }).readOnly(),
    yearlessTitle: Ember.computed('computedTitle', function () {
      var title = Ember.get(this, 'computedTitle');
      return title.replace(/\(\d{4}\)$/, '');
    }).readOnly(),
    totalRatings: Ember.computed('ratingFrequencies', function () {
      // eslint-disable-next-line
      var keys = Array.apply(null, {
        length: 19
      }).map(Number.call, Number).map(function (num) {
        return num + 2;
      });
      var freqs = Ember.get(this, 'ratingFrequencies');
      return keys.reduce(function (prev, curr) {
        return prev + (parseInt(freqs[curr], 10) || 0);
      }, 0);
    }).readOnly(),
    titlesSorted: Ember.computed('titles', function () {
      var _this = this;

      var data = Ember.get(this, 'titles');
      var titlesArray = Object.keys(data).map(function (code) {
        return {
          code: code,
          value: data[code]
        };
      });
      var collator = new Intl.Collator(this.intl.primaryLocale || 'en', {
        numeric: true,
        sensitivity: 'base'
      });

      var translateCode = function translateCode(code) {
        return _this.intl.t("media.show.summary.titles.".concat(code), code);
      };

      titlesArray.sort(function (a, b) {
        return collator.compare(translateCode(a.code), translateCode(b.code));
      });
      return titlesArray;
    }).readOnly()
  });

  _exports.default = _default;
});