define("client/components/dashboard/onboarding/checklist-progress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    hasRatings: Ember.computed.gte('user.ratingsCount', 5),
    hasFollows: Ember.computed.gte('user.followingCount', 5),
    hasComments: Ember.computed.gte('user.commentsCount', 1),
    hasLikes: Ember.computed.gte('user.likesGivenCount', 3),
    stepsCompleted: Ember.computed('hasRatings', 'hasFollows', 'hasComments', 'hasLikes', function () {
      var steps = [Ember.get(this, 'hasRatings'), Ember.get(this, 'hasFollows'), Ember.get(this, 'hasComments'), Ember.get(this, 'hasLikes')];
      return 4 - steps.sort().lastIndexOf(false);
    }).readOnly(),
    percentageComplete: Ember.computed('stepsCompleted', function () {
      return 100 - 20 * Ember.get(this, 'stepsCompleted');
    }).readOnly(),
    ratingsLeft: Ember.computed('user.ratingsCount', function () {
      return 5 - Ember.get(this, 'user.ratingsCount');
    }).readOnly(),
    followsLeft: Ember.computed('user.followingCount', function () {
      return 5 - Ember.get(this, 'user.followingCount');
    }).readOnly(),
    likesLeft: Ember.computed('user.likesGivenCount', function () {
      return 3 - Ember.get(this, 'user.likesGivenCount');
    }).readOnly()
  });

  _exports.default = _default;
});