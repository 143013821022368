define("client/routes/groups/group/dashboard/settings", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_emberCan.CanMixin, {
    intl: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var model = this.modelFor('groups.group.dashboard');
      var membership = Ember.get(model, 'membership');

      if (!this.can('manage settings for group', {
        membership: membership
      })) {
        return this.transitionTo('groups.group.dashboard.index');
      }
    },
    model: function model() {
      var model = this.modelFor('groups.group.dashboard');
      return Ember.RSVP.hash({
        group: Ember.get(model, 'group'),
        category: Ember.get(model, 'group').belongsTo('category').load(),
        categories: Ember.get(this, 'store').findAll('group-category')
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      Ember.set(controller, 'records', []);
      Ember.set(controller, 'savedNeighbors', []);
      Ember.set(controller, '_neighbors', []);
      Ember.get(controller, 'records').addObject(Ember.get(model, 'group'));
      var options = Ember.get(model, 'group.isClosed') ? ['closed'] : ['open', 'closed', 'restricted'];
      Ember.set(controller, 'privacyOptions', options);
      var category = Ember.get(model, 'category');
      Ember.set(controller, 'selectedCategory', {
        id: Ember.get(category, 'id'),
        name: Ember.get(category, 'name'),
        slug: Ember.get(category, 'slug')
      });
    },
    titleToken: function titleToken(model) {
      var group = Ember.get(model, 'group.name');
      return Ember.get(this, 'intl').t('titles.groups.group.dashboard.settings', {
        group: group
      });
    }
  });

  _exports.default = _default;
});