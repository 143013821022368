define("client/models/character", ["exports", "client/models/-base", "ember-data/attr", "ember-data/relationships"], function (_exports, _base, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    description: (0, _attr.default)('string'),
    image: (0, _attr.default)('object', {
      defaultValue: '/image/default_avatar.png'
    }),
    name: (0, _attr.default)('string'),
    slug: (0, _attr.default)('string'),
    primaryMedia: (0, _relationships.belongsTo)('media')
  });

  _exports.default = _default;
});