define("client/templates/components/groups/dashboard/group-member", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5dhyj9g9",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/groups/dashboard/group-member.hbs"
    }
  });

  _exports.default = _default;
});