define("client/utils/array-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unshiftObject = unshiftObject;
  _exports.unshiftObjects = unshiftObjects;

  function unshiftObject(target, object) {
    var included = target.includes(object);

    if (!included) {
      target.insertAt(0, object);
    }

    return target;
  }

  function unshiftObjects(target, objects) {
    target.beginPropertyChanges();
    objects.reverse().forEach(function (object) {
      unshiftObject(target, object);
    });
    target.endPropertyChanges();
    return target;
  }
});