define("client/utils/queryable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.serializeArray = serializeArray;
  _exports.deserializeArray = deserializeArray;
  _exports.default = void 0;

  function serializeArray(value) {
    var isRange = Ember.typeOf(value[0]) !== 'string';

    if (isRange && value.length === 2) {
      return value.join('..');
    }

    if (!isRange && value.length > 1) {
      return value.reject(function (x) {
        return Ember.isEmpty(x);
      }).join();
    }

    return value.join();
  }

  function deserializeArray(value) {
    var isRange = value.includes('..');

    if (isRange) {
      return value.split('..').map(function (x) {
        if (Ember.isEmpty(x)) {
          return '';
        }

        if (Number.isInteger(JSON.parse(x))) {
          return parseInt(x, 10);
        }

        return parseFloat(x);
      });
    }

    return value.split(',');
  }

  var _default = {
    serializeArray: serializeArray,
    deserializeArray: deserializeArray
  };
  _exports.default = _default;
});