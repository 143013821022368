define("client/routes/groups/group/group-page/rules", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    model: function model() {
      return this.modelFor('groups.group.group-page');
    },
    afterModel: function afterModel(model) {
      var tags = this.setHeadTags(model);
      Ember.set(this, 'headTags', tags);
    },
    titleToken: function titleToken(model) {
      var group = Ember.get(model, 'group.name');
      return Ember.get(this, 'intl').t('titles.groups.group.group-page.rules', {
        group: group
      });
    },
    setHeadTags: function setHeadTags(model) {
      var description = "Group rules for ".concat(Ember.get(model, 'group.name'), ".\n      ").concat(Ember.get(model, 'group.tagline'));
      return [{
        type: 'meta',
        tagId: 'meta-description',
        attrs: {
          property: 'description',
          content: description
        }
      }, {
        type: 'meta',
        tagId: 'meta-og-description',
        attrs: {
          property: 'og:description',
          content: description
        }
      }];
    }
  });

  _exports.default = _default;
});