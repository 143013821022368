define("client/services/aozora-conflicts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    list: function list() {
      return Ember.get(this, 'ajax').request('/users/_conflicts', {
        method: 'GET'
      });
    },
    resolve: function resolve(chosen) {
      return Ember.get(this, 'ajax').request('/users/_conflicts', {
        method: 'POST',
        dataType: 'json',
        data: {
          chosen: chosen
        }
      });
    }
  });

  _exports.default = _default;
});