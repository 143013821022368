define("client/templates/components/users/onboarding/feed-notice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qW8M06vU",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h6\"],[9],[1,[29,\"t\",[\"users.activity.onboarding.welcome\"],[[\"user\"],[[25,[\"session\",\"account\",\"name\"]]]]],false],[10],[0,\"\\n\"],[7,\"p\"],[9],[1,[29,\"t\",[\"users.activity.onboarding.about\"],[[\"htmlSafe\"],[true]]],false],[10],[0,\"\\n\"],[7,\"strong\"],[9],[1,[29,\"t\",[\"users.activity.onboarding.action\"],[[\"htmlSafe\"],[true]]],false],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/users/onboarding/feed-notice.hbs"
    }
  });

  _exports.default = _default;
});