define("client/mixins/clipboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global ClipboardJS */
  var _default = Ember.Mixin.create({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var id = Ember.get(this, 'elementId');
      Ember.set(this, 'clipboard', new ClipboardJS("#".concat(id, " [data-clipboard-text]")));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (Ember.get(this, 'clipboard') !== undefined) {
        Ember.get(this, 'clipboard').destroy();
      }
    }
  });

  _exports.default = _default;
});