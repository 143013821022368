define("client/templates/components/stream-feed/edit-post", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LZK81hup",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[4,\"bootstrap/bs-modal\",null,[[\"id\",\"onClose\"],[[25,[\"modalId\"]],[25,[\"onClose\"]]]],{\"statements\":[[4,\"component\",[[29,\"-assert-implicit-component-helper-argument\",[[24,1,[\"header\"]],\"expected `modal.header` to be a contextual component but found a string. Did you mean `(component modal.header)`? ('client/templates/components/stream-feed/edit-post.hbs' @ L2:C5) \"],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"text-xs-center\"],[9],[0,\"\\n      \"],[7,\"h4\"],[9],[1,[29,\"t\",[\"components.stream-feed.edit-post.header\"],null],false],[10],[0,\"\\n      \"],[7,\"small\"],[9],[1,[29,\"t\",[\"components.stream-feed.edit-post.error.tagged\"],null],false],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[29,\"-assert-implicit-component-helper-argument\",[[24,1,[\"body\"]],\"expected `modal.body` to be a contextual component but found a string. Did you mean `(component modal.body)`? ('client/templates/components/stream-feed/edit-post.hbs' @ L9:C5) \"],null]],null,{\"statements\":[[0,\"    \"],[1,[29,\"stream-feed/create-post\",null,[[\"post\",\"isExpanded\",\"isEditing\",\"unitNumber\",\"shouldUnit\",\"onCreate\"],[[25,[\"post\"]],true,true,[25,[\"unitNumber\"]],[29,\"not\",[[29,\"is-empty\",[[25,[\"unitNumber\"]]],null]],null],[29,\"perform\",[[25,[\"updatePost\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/stream-feed/edit-post.hbs"
    }
  });

  _exports.default = _default;
});