define("client/models/favorite", ["exports", "client/models/-base", "ember-data/attr", "ember-data/relationships"], function (_exports, _base, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    favRank: (0, _attr.default)('number', {
      defaultValue: 9999
    }),
    user: (0, _relationships.belongsTo)('user', {
      inverse: 'favorites'
    }),
    item: (0, _relationships.belongsTo)('-base')
  });

  _exports.default = _default;
});