define("client/templates/users/groups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6oFYUHOM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n\"],[0,\"    \"],[1,[29,\"groups/group-categories-list\",null,[[\"currentCategory\",\"onChange\"],[[25,[\"category\"]],[29,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"category\"]]],null]],null]]]],false],[0,\"\\n\\n\"],[0,\"    \"],[1,[29,\"groups/group-list\",null,[[\"sort\",\"query\",\"groups\",\"isLoading\",\"categoryRoute\",\"sortOptions\",\"onPagination\",\"updateQueryParam\",\"class\"],[[25,[\"sort\"]],[25,[\"query\"]],[29,\"map-by\",[\"group\",[25,[\"groups\"]]],null],[25,[\"model\",\"taskInstance\",\"isRunning\"]],\"users.groups\",[25,[\"sortOptions\"]],[29,\"route-action\",[\"onPagination\"],null],[29,\"action\",[[24,0,[]],\"updateQueryParam\"],null],\"group-result-list\"]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/users/groups.hbs"
    }
  });

  _exports.default = _default;
});