define("client/models/user", ["exports", "client/models/-base", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _base, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Validations = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      type: 'email',
      regex: /^[^@]+@([^@\.]+\.)+[^@\.]+$/
    })],
    name: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 3,
      max: 20
    })],
    slug: {
      disabled: Ember.computed.empty('model.slug'),
      validators: [(0, _emberCpValidations.validator)('length', {
        min: 3,
        max: 20
      }), (0, _emberCpValidations.validator)('format', {
        regex: /^[_a-zA-Z0-9]+$/,
        messageKey: 'errors.user.name.invalid'
      }), (0, _emberCpValidations.validator)('format', {
        regex: /(?!^\d+$)^.+$/,
        messageKey: 'errors.user.name.numbers'
      }), (0, _emberCpValidations.validator)('format', {
        regex: /^[a-zA-Z0-9]/,
        messageKey: 'errors.user.name.starts'
      })]
    },
    password: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        // Disable if this user already has a password
        disabled: Ember.computed.alias('model.hasPassword'),
        presence: true
      }), (0, _emberCpValidations.validator)('length', {
        // Disable if the password is empty
        disabled: Ember.computed.empty('model.password'),
        min: 8
      })]
    }
  });
  _exports.Validations = Validations;

  var _default = _base.default.extend(Validations, {
    about: (0, _attr.default)('string'),
    avatar: (0, _attr.default)('object', {
      defaultValue: '/images/default_avatar.png'
    }),
    birthday: (0, _attr.default)('utc'),
    commentsCount: (0, _attr.default)('number'),
    confirmed: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    coverImage: (0, _attr.default)('object', {
      defaultValue: '/images/default_cover.png'
    }),
    country: (0, _attr.default)('string'),
    createdAt: (0, _attr.default)('utc'),
    email: (0, _attr.default)('string'),
    facebookId: (0, _attr.default)('string'),
    favoritesCount: (0, _attr.default)('number'),
    feedCompleted: (0, _attr.default)('boolean'),
    followersCount: (0, _attr.default)('number'),
    followingCount: (0, _attr.default)('number'),
    gender: (0, _attr.default)('string'),
    hasPassword: (0, _attr.default)('boolean'),
    language: (0, _attr.default)('string'),
    likesGivenCount: (0, _attr.default)('number'),
    location: (0, _attr.default)('string'),
    mediaReactionsCount: (0, _attr.default)('number'),
    name: (0, _attr.default)('string'),
    password: (0, _attr.default)('string'),
    pastNames: (0, _attr.default)('array'),
    postsCount: (0, _attr.default)('number'),
    proTier: (0, _attr.default)('string'),
    proExpiresAt: (0, _attr.default)('utc'),
    profileCompleted: (0, _attr.default)('boolean'),
    ratingsCount: (0, _attr.default)('number'),
    ratingSystem: (0, _attr.default)('string', {
      defaultValue: 'simple'
    }),
    reviewsCount: (0, _attr.default)('number'),
    roles: (0, _attr.default)('array'),
    sfwFilter: (0, _attr.default)('boolean'),
    sfwFilterPreference: (0, _attr.default)('string'),
    // enum: 'sfw', 'nsfw_sometimes', 'nsfw_everywhere'
    slug: (0, _attr.default)('string'),
    shareToGlobal: (0, _attr.default)('boolean'),
    status: (0, _attr.default)('string', {
      defaultValue: 'registered'
    }),
    subscribedToNewsletter: (0, _attr.default)('boolean'),
    timeZone: (0, _attr.default)('string'),
    title: (0, _attr.default)('string'),
    titleLanguagePreference: (0, _attr.default)('string', {
      defaultValue: 'canonical'
    }),
    theme: (0, _attr.default)('string', {
      defaultValue: 'light'
    }),
    waifuOrHusbando: (0, _attr.default)('string'),
    updatedAt: (0, _attr.default)('utc'),
    // NOTE: These properties are not used for mapping posts to user
    // without them, ember-data will override its null inverse!
    posts: (0, _relationships.hasMany)('post', {
      inverse: 'user'
    }),
    receivedPosts: (0, _relationships.hasMany)('post', {
      inverse: 'targetUser'
    }),
    uploads: (0, _relationships.hasMany)('upload', {
      inverse: 'user'
    }),
    waifu: (0, _relationships.belongsTo)('character'),
    pinnedPost: (0, _relationships.belongsTo)('post', {
      inverse: null
    }),
    blocks: (0, _relationships.hasMany)('block', {
      inverse: 'user'
    }),
    favorites: (0, _relationships.hasMany)('favorite', {
      inverse: 'user'
    }),
    followers: (0, _relationships.hasMany)('follow', {
      inverse: 'followed'
    }),
    following: (0, _relationships.hasMany)('follow', {
      inverse: 'follower'
    }),
    notificationSettings: (0, _relationships.hasMany)('notification-setting', {
      inverse: 'user'
    }),
    oneSignalPlayers: (0, _relationships.hasMany)('one-signal-player', {
      inverse: 'user'
    }),
    profileLinks: (0, _relationships.hasMany)('profile-link', {
      inverse: 'user'
    }),
    stats: (0, _relationships.hasMany)('stat', {
      inverse: 'user'
    }),
    userRoles: (0, _relationships.hasMany)('user-role'),
    // HACK: We use this to flag the model as dirty when waifu changes, as ember-data
    // doesn't currently track the dirtiness of a relationship.
    waifuDirtyHack: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    url: Ember.computed.or('slug', 'id'),
    isPro: Ember.computed('proExpiresAt', function () {
      var date = Ember.get(this, 'proExpiresAt');

      if (Ember.isEmpty(date)) {
        return false;
      }

      return !date.isBefore();
    }).readOnly(),
    isAozoraImported: Ember.computed('status', function () {
      return Ember.get(this, 'status') === 'aozora';
    }).readOnly(),
    isSimpleRating: Ember.computed('ratingSystem', function () {
      return Ember.get(this, 'ratingSystem') === 'simple';
    }).readOnly(),
    hasRole: function hasRole()
    /* roleName, resource */
    {
      // Blanket access for staff & mods for the time being.
      var title = (Ember.get(this, 'title') || '').toLowerCase();
      return title === 'staff' || title === 'mod'; // Resource-dependent roles

      /*
      const roles = get(this, 'userRoles').map(ur => get(ur, 'role'));
      const validRoles = roles.filter((r) => {
        let hasRole = get(r, 'name') === roleName && !get(r, 'hasDirtyAttributes');
        if (hasRole && get(r, 'resourceType') && resource) {
          hasRole = hasRole && get(r, 'resourceType') === classify(get(resource, 'modelType'));
        }
        return hasRole;
      });
       let valid = false;
      validRoles.forEach((role) => {
        // Class based role or record based role
        if (isEmpty(get(role, 'resourceId')) || get(role, 'resourceId') === get(resource, 'id')) {
          valid = true;
        }
      });
       return valid;
      */
    }
  });

  _exports.default = _default;
});