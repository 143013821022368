define("client/templates/components/groups/dashboard/latest-reports", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MHmW7tyH",
    "block": "{\"symbols\":[\"report\"],\"statements\":[[7,\"div\"],[11,\"class\",\"card\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"card-block\"],[9],[0,\"\\n    \"],[7,\"h5\"],[11,\"class\",\"card-title\"],[9],[1,[29,\"t\",[\"groups.dashboard.overview.reports\"],null],false],[10],[0,\"\\n    \"],[7,\"hr\"],[9],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"getLatestReportsTask\",\"isRunning\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"w-100 text-xs-center\"],[9],[0,\"\\n        \"],[1,[29,\"loading-spinner\",null,[[\"size\"],[\"small\"]]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[25,[\"getLatestReportsTask\",\"last\",\"value\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"report-item\"],[9],[0,\"\\n          \"],[1,[29,\"lazy-image\",null,[[\"src\"],[[29,\"image\",[[24,1,[\"user\",\"avatar\"]],\"small\"],null]]]],false],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"report-meta\"],[9],[0,\"\\n            \"],[7,\"a\"],[12,\"href\",[29,\"href-to\",[\"groups.group.dashboard.reports\"],null]],[9],[0,\"\\n\"],[4,\"if\",[[24,1,[\"explanation\"]]],null,{\"statements\":[[0,\"                \"],[1,[29,\"truncate\",[[24,1,[\"explanation\"]],50],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"                \"],[1,[29,\"t\",[\"groups.reports.no-explanation\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"            \"],[10],[0,\"\\n            \"],[7,\"span\"],[11,\"class\",\"report-reason\"],[9],[1,[24,1,[\"reason\"]],false],[10],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"        \"],[7,\"p\"],[9],[1,[29,\"t\",[\"errors.contentEmpty\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/groups/dashboard/latest-reports.hbs"
    }
  });

  _exports.default = _default;
});