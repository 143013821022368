define("client/routes/feedback/mobile-features-anything", ["exports", "client/routes/feedback/mobile-features"], function (_exports, _mobileFeatures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mobileFeatures.default.extend({
    templateName: 'feedback/mobile-features',
    intl: Ember.inject.service(),
    titleToken: function titleToken() {
      return Ember.get(this, 'intl').t('titles.feedback.mobile-features');
    }
  });

  _exports.default = _default;
});