define("client/routes/manga/show", ["exports", "client/routes/media/show"], function (_exports, _show) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _show.default.extend({
    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);

      var tags = this.setHeadTags(model);
      tags.push({
        type: 'meta',
        tagId: 'meta-og-type',
        attrs: {
          property: 'og:type',
          content: 'books.book'
        }
      });
      Ember.set(this, 'headTags', tags);
    },
    titleToken: function titleToken(model) {
      var title = Ember.get(model, 'computedTitle');
      var subtype = Ember.String.capitalize(Ember.get(model, 'subtype'));
      return "".concat(title, " | ").concat(subtype);
    }
  });

  _exports.default = _default;
});