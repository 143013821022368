define("client/components/application/auth-onboarding/import-progress", ["exports", "ember-invoke-action"], function (_exports, _emberInvokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'siteName', Ember.get(this, 'componentData.siteName'));
    },
    actions: {
      changeComponent: function changeComponent(component) {
        for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          args[_key - 1] = arguments[_key];
        }

        _emberInvokeAction.invokeAction.apply(void 0, [this, 'changeComponent', component].concat(args));
      }
    }
  });

  _exports.default = _default;
});