define("client/transforms/rating", ["exports", "ember-data/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _transform.default.extend({
    deserialize: function deserialize(value) {
      return value / 2;
    },
    serialize: function serialize(value) {
      return value * 2 || null;
    }
  });

  _exports.default = _default;
});