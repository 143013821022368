define("client/templates/components/explore/explore-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P3/gh3gz",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"explore-search--wrapper\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"icon\"],[9],[1,[29,\"svg-jar\",[\"search\"],null],false],[10],[0,\"\\n  \"],[1,[29,\"one-way-search\",[[25,[\"query\"]]],[[\"update\",\"class\",\"placeholder\"],[[29,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"query\"]]],null]],null],\"explore-search-input\",[29,\"t\",[\"explore.search-placeholder\"],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[29,\"search-results\",null,[[\"tooltipClass\",\"target\",\"attachment\",\"targetAttachment\",\"offset\",\"query\",\"isOpened\"],[\"explore-search-dropdown\",\".explore-search-input\",\"top left\",\"bottom left\",\"0px 0px\",[25,[\"query\"]],[29,\"gt\",[[25,[\"query\",\"length\"]],0],null]]]],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"explore-search-info\"],[9],[0,\"\\n  \"],[1,[29,\"t\",[\"explore.advanced-search\"],[[\"mediaType\",\"htmlSafe\"],[[25,[\"mediaType\"]],true]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/explore/explore-search.hbs"
    }
  });

  _exports.default = _default;
});