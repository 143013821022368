define("client/templates/components/media/featured-characters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "i46kBaZG",
    "block": "{\"symbols\":[\"casting\"],\"statements\":[[4,\"if\",[[25,[\"shouldRender\"]]],null,{\"statements\":[[0,\"  \"],[7,\"section\"],[11,\"class\",\"media--main-characters\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"favorite-characters-panel\"],[9],[0,\"\\n      \"],[7,\"h5\"],[9],[1,[29,\"t\",[\"media.show.summary.characters.header\"],null],false],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"favorite-media-grid row\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"taskInstance\",\"isRunning\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\"],[11,\"class\",\"text-xs-center w-100 m-t-1 m-b-1\"],[9],[0,\"\\n            \"],[1,[29,\"loading-spinner\",null,[[\"size\"],[\"large\"]]],false],[0,\"\\n          \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"taskInstance\",\"value\"]]],null,{\"statements\":[[4,\"each\",[[25,[\"taskInstance\",\"value\"]]],null,{\"statements\":[[0,\"            \"],[7,\"div\"],[12,\"aria-label\",[24,1,[\"character\",\"name\"]]],[11,\"class\",\"favorite-item col-sm-3 hint--top hint--bounce hint--rounded\"],[9],[0,\"\\n              \"],[7,\"a\"],[11,\"href\",\"javascript:void(0);\"],[9],[0,\"\\n                \"],[1,[29,\"lazy-image\",null,[[\"src\"],[[29,\"image\",[[24,1,[\"character\",\"image\"]]],null]]]],false],[0,\"\\n              \"],[10],[0,\"\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"      \"],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"taskInstance\",\"value\"]]],null,{\"statements\":[[0,\"        \"],[7,\"a\"],[11,\"class\",\"more-link\"],[12,\"href\",[29,\"href-to\",[[29,\"concat\",[[25,[\"media\",\"modelType\"]],\".show.characters\"],null]],null]],[9],[0,\"\\n          \"],[1,[29,\"t\",[\"media.show.summary.characters.more\"],null],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/media/featured-characters.hbs"
    }
  });

  _exports.default = _default;
});