define("client/serializers/application", ["exports", "ember-data/serializers/json-api"], function (_exports, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend({
    keyForAttribute: function keyForAttribute(key) {
      return Ember.String.camelize(key);
    },
    keyForRelationship: function keyForRelationship(key) {
      return Ember.String.camelize(key);
    },
    serializeAttribute: function serializeAttribute(snapshot, json, key) {
      if (key in snapshot.changedAttributes()) {
        return this._super.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});