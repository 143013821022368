define("client/components/stream-feed/items/library-entry/activities", ["exports", "ember-invoke-action"], function (_exports, _emberInvokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Component.extend({
    shouldGroup: Ember.computed('feedId', function () {
      var _Ember$get$split = Ember.get(this, 'feedId').split(':'),
          _Ember$get$split2 = _slicedToArray(_Ember$get$split, 1),
          feed = _Ember$get$split2[0];

      return feed !== 'user_aggr';
    }).readOnly(),
    groupedActivities: Ember.computed('activities.@each.isDeleted', function () {
      var _this = this;

      if (Ember.get(this, 'shouldGroup') === false) {
        return Ember.get(this, 'activities').filterBy('isDeleted', false).map(function (activity) {
          return {
            activity: activity
          };
        }).slice(0, Ember.get(this, 'activityLimit'));
      }

      var groups = {};
      var activities = Ember.get(this, 'activities').filterBy('isDeleted', false);
      activities.forEach(function (activity) {
        var key = _this._getGroupingKey(activity);

        groups[key] = groups[key] || [];
        groups[key].addObject(activity);
      });
      var result = [];
      Object.keys(groups).forEach(function (key) {
        var group = groups[key];
        var others = group.toArray().slice(1).reject(function (a) {
          return Ember.get(a, 'actor.id') === Ember.get(group, 'firstObject.actor.id');
        });
        result.addObject({
          activity: Ember.get(group, 'firstObject'),
          others: others
        });
      });
      return result.slice(0, Ember.get(this, 'activityLimit'));
    }).readOnly(),
    _getGroupingKey: function _getGroupingKey(activity) {
      var verb = Ember.get(activity, 'verb');

      switch (verb) {
        case 'updated':
          return "".concat(verb, "_").concat(Ember.get(activity, 'status'));

        case 'progressed':
          return "".concat(verb, "_").concat(Ember.get(activity, 'progress'));

        case 'rated':
          return "".concat(verb, "_").concat(Ember.get(activity, 'rating'));

        default:
          throw new Error('Unsupported activity.');
      }
    },
    actions: {
      onDelete: function onDelete(activity) {
        Ember.get(this, 'activities').removeObject(activity);

        if (Ember.get(this, 'activities.length') === 0) {
          (0, _emberInvokeAction.strictInvokeAction)(this, 'removeGroup');
        }
      }
    }
  });

  _exports.default = _default;
});