define("client/adapters/feed", ["exports", "client/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForQuery: function urlForQuery(query) {
      var type = query.type,
          id = query.id;
      delete query.type; // eslint-disable-line no-param-reassign

      delete query.id; // eslint-disable-line no-param-reassign

      var host = Ember.get(this, 'host') || '';
      var url = "".concat(host, "/").concat(Ember.get(this, 'namespace'), "/feeds/").concat(type);
      return "".concat(url, "/").concat(id);
    }
  });

  _exports.default = _default;
});