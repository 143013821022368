define("client/models/site-announcement", ["exports", "client/models/-base", "ember-data/attr"], function (_exports, _base, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    description: (0, _attr.default)('string'),
    imageUrl: (0, _attr.default)('string'),
    link: (0, _attr.default)('string'),
    title: (0, _attr.default)('string')
  });

  _exports.default = _default;
});