define("client/routes/explore/category/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      var _this$paramsFor = this.paramsFor('explore'),
          mediaType = _this$paramsFor.media_type;

      var category = this.modelFor('explore.category');
      Ember.setProperties(controller, {
        mediaType: mediaType,
        category: category
      });
    },
    titleToken: function titleToken() {
      var category = this.modelFor('explore.category');

      var _this$paramsFor2 = this.paramsFor('explore'),
          mediaType = _this$paramsFor2.media_type;

      return Ember.get(this, 'intl').t('titles.explore.category.index', {
        category: Ember.get(category, 'title'),
        type: Ember.String.capitalize(mediaType)
      });
    }
  });

  _exports.default = _default;
});