define("client/templates/components/users/library/library-grid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tYD7hqoX",
    "block": "{\"symbols\":[\"libraryEntry\"],\"statements\":[[7,\"section\"],[11,\"class\",\"media-browse\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"media-grid container\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"isLoading\"]]],null,{\"statements\":[[0,\"        \"],[1,[29,\"users/library/library-grid/loader\",null,[[\"tagName\"],[\"\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[25,[\"libraryEntries\"]]],null,{\"statements\":[[4,\"occludable-area\",null,[[\"rootMargin\",\"class\",\"bufferClass\"],[[29,\"hash\",null,[[\"top\"],[600]]],\"poster-wrapper\",\"library-occlusion-buffer-grid\"]],{\"statements\":[[0,\"            \"],[1,[29,\"users/library/library-grid/library-entry\",null,[[\"libraryEntry\",\"saveEntry\",\"removeEntry\"],[[24,1,[]],[25,[\"saveEntry\"]],[25,[\"removeEntry\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},{\"statements\":[[0,\"          \"],[1,[29,\"users/library/library-empty\",null,[[\"media\",\"user\",\"isSearching\",\"class\"],[[25,[\"media\"]],[25,[\"user\"]],[25,[\"isSearching\"]],\"w-100\"]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/users/library/library-grid.hbs"
    }
  });

  _exports.default = _default;
});