define("client/mixins/models/episodic", ["exports", "ember-data/attr", "ember-data/relationships"], function (_exports, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    episodeCount: (0, _attr.default)('number'),
    episodeLength: (0, _attr.default)('number'),
    episodes: (0, _relationships.hasMany)('episode', {
      inverse: 'media'
    })
  });

  _exports.default = _default;
});