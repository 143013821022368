define("client/components/users/library/library-list/progress-cell", ["exports", "ember-invoke-action"], function (_exports, _emberInvokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this._removeClickHandler();
    },
    actions: {
      sanitizeNumber: function sanitizeNumber(value) {
        var parsed = parseInt(value, 10);
        return Number.isNaN(parsed) ? value : parsed;
      },
      showInput: function showInput() {
        var _this = this;

        Ember.set(this, 'showProgressEditor', true);
        Ember.run.scheduleOnce('afterRender', function () {
          _this._setupClickHandler();
        });
      }
    },

    /**
     * Setup a click event handler on the body to remove the progress input and save the
     * record.
     *
     * @private
     */
    _setupClickHandler: function _setupClickHandler() {
      var _this2 = this;

      this.progressInputHandler = function (_ref) {
        var target = _ref.target;
        var isProgressInput = target.matches('.library-progress-input');

        if (!isProgressInput) {
          Ember.set(_this2, 'showProgressEditor', false);

          _this2._removeClickHandler();

          (0, _emberInvokeAction.invokeAction)(_this2, 'save');
        }
      };

      document.body.addEventListener('click', this.progressInputHandler);
    },
    _removeClickHandler: function _removeClickHandler() {
      if (this.progressInputHandler) {
        document.body.removeEventListener('click', this.progressInputHandler);
        this.progressInputHandler = null;
      }
    }
  });

  _exports.default = _default;
});