define("client/components/modals/media-trailer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    metrics: Ember.inject.service(),
    actions: {
      playVideo: function playVideo() {
        Ember.get(this, 'metrics').trackEvent({
          category: 'trailer',
          action: 'play',
          label: Ember.get(this, 'media.modelType'),
          value: Ember.get(this, 'media.id')
        });
      }
    }
  });

  _exports.default = _default;
});