define("client/models/group-member", ["exports", "client/models/-base", "ember-data/attr", "ember-data/relationships"], function (_exports, _base, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    createdAt: (0, _attr.default)('utc'),
    rank: (0, _attr.default)('string'),
    unreadCount: (0, _attr.default)('number'),
    hidden: (0, _attr.default)('boolean'),
    group: (0, _relationships.belongsTo)('group', {
      inverse: 'members'
    }),
    user: (0, _relationships.belongsTo)('user'),
    permissions: (0, _relationships.hasMany)('group-permission', {
      inverse: 'groupMember'
    }),

    /**
     * Checks to see if the group member has a specific permission.
     *
     * @param {String} permission
     * @returns {Boolean}
     */
    hasPermission: function hasPermission(permission) {
      var permissions = this.hasMany('permissions').value();

      if (permissions) {
        var record = permissions.find(function (perm) {
          return Ember.get(perm, 'permission') === 'owner' || Ember.get(perm, 'permission') === permission;
        });
        return record ? !Ember.get(record, 'hasDirtyAttributes') : false;
      }

      return false;
    }
  });

  _exports.default = _default;
});