define("client/services/stream-realtime", ["exports", "client/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    /**
     * Setup GetStream connection to the correct Feed Group.
     */
    init: function init() {
      this._super.apply(this, arguments);

      if (!window.stream) {
        return;
      }

      var _Ember$get = Ember.get(_environment.default, 'stream.realtime'),
          enabled = _Ember$get.enabled,
          config = _Ember$get.config;

      var _Ember$get2 = Ember.get(config, _environment.default.kitsu.env),
          app = _Ember$get2.app,
          key = _Ember$get2.key;

      if (!enabled) {
        return;
      }

      this.client = window.stream.connect(key, null, app);
    },

    /**
     * Subscribe to a specific feed in the connected feed group. This opens a websocket connection
     * with GetStream that will send updates on new and deleted activities for the feed. Connection
     * can be closed by calling `.cancel()` on the returned value.
     *
     * @param {String} feed The name of the feed in GetStream
     * @param {String} id The id of the specific feed
     * @param {String} token Read only token for the feed returned from the API
     * @param {Function} callback
     */
    subscribe: function subscribe(feed, id, token, callback) {
      if (!this.client) {
        return;
      }

      return this.client.feed(feed, id, token).subscribe(function (data) {
        callback(data);
      });
    }
  });

  _exports.default = _default;
});