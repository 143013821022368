define("client/components/users/library/library-list", ["exports", "ember-invoke-action"], function (_exports, _emberInvokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    hasSelectedMedia: Ember.computed.gt('selectedLibraryEntries.length', 0),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'selectedLibraryEntries', []);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (Ember.get(this, 'media') !== Ember.get(this, 'mediaWas')) {
        Ember.set(this, 'selectedLibraryEntries', []);
      }

      Ember.set(this, 'mediaWas', Ember.get(this, 'media'));
    },
    actions: {
      selectEntry: function selectEntry(libraryEntry, value) {
        var list = Ember.get(this, 'selectedLibraryEntries');

        if (value) {
          list.addObject(libraryEntry);
        } else {
          list.removeObject(libraryEntry);
        }
      },
      selectAll: function selectAll() {
        var entries = Ember.get(this, 'libraryEntries');
        Ember.get(this, 'selectedLibraryEntries').addObjects(entries);
      },
      resetSelection: function resetSelection() {
        Ember.set(this, 'selectedLibraryEntries', []);
      },
      removeEntriesBulk: function removeEntriesBulk() {
        var _this = this;

        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        return _emberInvokeAction.invokeAction.apply(void 0, [this, 'removeEntriesBulk'].concat(args)).then(function () {
          if (!Ember.get(_this, 'isDestroyed')) {
            (0, _emberInvokeAction.invoke)(_this, 'resetSelection');
          }
        });
      },
      updateStatusBulk: function updateStatusBulk() {
        var _this2 = this;

        for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
          args[_key2] = arguments[_key2];
        }

        return _emberInvokeAction.invokeAction.apply(void 0, [this, 'updateStatusBulk'].concat(args)).then(function () {
          if (!Ember.get(_this2, 'isDestroyed')) {
            (0, _emberInvokeAction.invoke)(_this2, 'resetSelection');
          }
        });
      }
    }
  });

  _exports.default = _default;
});