define("client/components/users/library/library-sorting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.sortingOptions = ['watched', // Oldest
      'started', // Oldest
      'finished', // Oldest
      'added', // Oldest
      'progress', // Least
      'rating', // Lowest
      'title', // A - Z
      'length' // Shortest
      ];
    },
    actions: {
      toggleSortDirection: function toggleSortDirection() {
        var direction = Ember.get(this, 'direction');
        Ember.set(this, 'direction', direction === 'desc' ? 'asc' : 'desc');
      }
    }
  });

  _exports.default = _default;
});