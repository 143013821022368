define("client/routes/unsubscribe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      Ember.set(controller, 'email', Ember.get(controller, 'inputEmail'));
      Ember.run.scheduleOnce('afterRender', function () {
        return Ember.set(controller, 'inputEmail', null);
      });
    },
    resetController: function resetController(controller) {
      Ember.set(controller, 'email', null);
      Ember.set(controller, 'inputEmail', null);
    }
  });

  _exports.default = _default;
});