define("client/routes/comments", ["exports", "client/mixins/routes/data-error"], function (_exports, _dataError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_dataError.default, {
    intl: Ember.inject.service(),
    model: function model(_ref) {
      var id = _ref.id;
      return Ember.get(this, 'store').findRecord('comment', id, {
        include: 'user,parent,uploads,post,post.user,post.targetUser,post.targetGroup,post.media,post.uploads',
        reload: true
      });
    },
    afterModel: function afterModel(model) {
      Ember.set(this, 'breadcrumb', "Comment by ".concat(Ember.get(model, 'user.name')));
      var tags = this.setHeadTags(model);
      Ember.set(this, 'headTags', tags);
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      var postId = Ember.get(model, 'post.id');
      var parentId = Ember.get(model, 'parent.id');
      Ember.set(controller, 'post', Ember.get(this, 'store').peekRecord('post', postId));

      if (parentId) {
        Ember.set(controller, 'parent', Ember.get(this, 'store').peekRecord('comment', parentId));
      }
    },
    titleToken: function titleToken(model) {
      var commenter = Ember.get(model, 'user.name');
      return Ember.get(this, 'intl').t('titles.comments', {
        user: commenter
      });
    },
    setHeadTags: function setHeadTags(model) {
      var tags = [];
      var content = Ember.get(model, 'content');

      if (content) {
        var description = content.substring(0, 140);
        tags.push({
          type: 'meta',
          tagId: 'meta-description',
          attrs: {
            name: 'description',
            content: description
          }
        }, {
          type: 'meta',
          tagId: 'meta-og-type',
          attrs: {
            name: 'og:type',
            content: 'article'
          }
        }, {
          type: 'meta',
          tagId: 'meta-og-description',
          attrs: {
            name: 'og:description',
            content: description
          }
        }, {
          type: 'meta',
          tagId: 'meta-og-image',
          attrs: {
            name: 'og:image',
            content: Ember.get(model, 'user.avatar.medium') || Ember.get(model, 'user.avatar')
          }
        });
      } // If the comment has likes, add extra data (Slack uses this for example)


      if (Ember.get(model, 'likesCount')) {
        tags.push({
          type: 'meta',
          tagId: 'meta-twitter-label1',
          attrs: {
            property: 'twitter:label1',
            content: 'Likes'
          }
        }, {
          type: 'meta',
          tagId: 'meta-twitter-data1',
          attrs: {
            property: 'twitter:data1',
            content: Ember.get(model, 'likesCount')
          }
        });
      } // Push replies count if any exist


      if (Ember.get(model, 'repliesCount')) {
        tags.push({
          type: 'meta',
          tagId: 'meta-twitter-label2',
          attrs: {
            property: 'twitter:label2',
            content: 'Replies'
          }
        }, {
          type: 'meta',
          tagId: 'meta-twitter-data2',
          attrs: {
            property: 'twitter:data2',
            content: Ember.get(model, 'repliesCount')
          }
        });
      }

      return tags;
    }
  });

  _exports.default = _default;
});