define("client/components/groups/dashboard/overview-stats", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    membersToday: Ember.computed.alias('stats.today.members'),
    membersTotal: Ember.computed.alias('stats.total.members'),
    postsToday: Ember.computed.alias('stats.today.posts'),
    commentsToday: Ember.computed.alias('stats.today.comments'),
    reportsTotal: Ember.computed.alias('stats.total.openReports'),
    ticketsTotal: Ember.computed.alias('stats.total.openTickets')
  });

  _exports.default = _default;
});