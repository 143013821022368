define("client/mixins/hover-intent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global hoverintent */
  var _default = Ember.Mixin.create({
    hoverTimeout: 250,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var element = Ember.get(this, 'element');
      var hoverIntentInstance = hoverintent(element, function () {
        _this._onMouseEnter();
      }, function () {
        _this._onMouseLeave();
      }).options({
        timeout: Ember.get(this, 'hoverTimeout')
      });
      Ember.set(this, 'hoverIntentInstance', hoverIntentInstance);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var hoverIntentInstance = Ember.get(this, 'hoverIntentInstance');

      if (hoverIntentInstance) {
        hoverIntentInstance.remove();
      }
    },
    _onMouseEnter: function _onMouseEnter() {
      if (Ember.get(this, 'isDestroyed')) {
        return;
      }

      Ember.set(this, 'showTooltip', true);
      Ember.set(this, 'isTooltipHovered', false);
    },
    _onMouseLeave: function _onMouseLeave() {
      if (Ember.get(this, 'isDestroyed')) {
        return;
      } // We don't want to close the tooltip if the tooltip itself is hovered.
      // The tooltip communicates that to us via the onHover/onLeave actions


      if (Ember.get(this, 'isTooltipHovered')) {
        // tooltip is currently hovered, so observe the variable and exit after
        Ember.addObserver(this, 'isTooltipHovered', this._onMouseLeave);
      } else {
        Ember.removeObserver(this, 'isTooltipHovered', this._onMouseLeave);
        Ember.set(this, 'showTooltip', false);
      }
    }
  });

  _exports.default = _default;
});