define("client/components/infinite-pagination", ["exports", "kitsu-shared/components/infinite-pagination"], function (_exports, _infinitePagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _infinitePagination.default;
    }
  });
});