define("client/templates/components/dashboard/onboarding/feed-notice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+1fmem5u",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h6\"],[9],[1,[29,\"t\",[\"dashboard.onboarding.welcome\"],[[\"user\"],[[25,[\"session\",\"account\",\"name\"]]]]],false],[10],[0,\"\\n\"],[7,\"p\"],[9],[1,[29,\"t\",[\"dashboard.onboarding.about\"],null],false],[10],[0,\"\\n\"],[7,\"strong\"],[9],[1,[29,\"t\",[\"dashboard.onboarding.action\"],null],false],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/dashboard/onboarding/feed-notice.hbs"
    }
  });

  _exports.default = _default;
});