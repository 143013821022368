define("client/models/chapter", ["exports", "ember-data/attr", "ember-data/relationships", "client/models/-base"], function (_exports, _attr, _relationships, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    published: (0, _attr.default)('utc'),
    canonicalTitle: (0, _attr.default)('string'),
    length: (0, _attr.default)('number'),
    number: (0, _attr.default)('number'),
    volumeNumber: (0, _attr.default)('number'),
    description: (0, _attr.default)('string'),
    thumbnail: (0, _attr.default)('object'),
    titles: (0, _attr.default)('object'),
    manga: (0, _relationships.belongsTo)('manga', {
      inverse: 'chapters'
    })
  });

  _exports.default = _default;
});