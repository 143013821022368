define("client/services/ajax", ["exports", "ember-ajax/services/ajax", "client/config/environment"], function (_exports, _ajax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    contentType: 'application/vnd.api+json',
    namespace: '/api/edge',
    session: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'host', _environment.default.kitsu.APIHost);
    },
    headers: Ember.computed('session.token', function () {
      var headers = {
        accept: 'application/vnd.api+json'
      };
      var token = Ember.get(this, 'session.token');

      if (token) {
        headers.Authorization = "Bearer ".concat(token);
      }

      return headers;
    }).readOnly()
  });

  _exports.default = _default;
});