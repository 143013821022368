define("client/services/metrics", ["exports", "ember-metrics/services/metrics", "client/config/environment"], function (_exports, _metrics, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _metrics.default.extend({
    router: Ember.inject.service(),

    /**
     * Support providing `staging` in the `environments` array for a metric adapter.
     */
    _filterEnvironments: function _filterEnvironments(adapterOption, appEnvironment) {
      var environment = appEnvironment;

      if (environment === 'production' && _environment.default.kitsu.isStaging) {
        environment = 'staging';
      }

      return this._super(adapterOption, environment);
    }
  });

  _exports.default = _default;
});