define("client/templates/components/groups/member-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "93tOm49e",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\"],[11,\"class\",\"button-label\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"groupTasks\",\"isRunning\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"loading-spinner\",null,[[\"size\"],[\"small\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"isMemberOfGroup\"]]],null,{\"statements\":[[0,\"      \"],[1,[29,\"t\",[\"groups.leave-group\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[29,\"t\",[\"groups.join-group\"],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/groups/member-button.hbs"
    }
  });

  _exports.default = _default;
});