define("client/templates/components/dashboard/site-announcements", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hCiq0TX8",
    "block": "{\"symbols\":[\"announcement\"],\"statements\":[[4,\"with\",[[25,[\"announcements\",\"firstObject\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"user-blotter card\"],[9],[0,\"\\n    \"],[7,\"h6\"],[9],[1,[24,1,[\"title\"]],false],[10],[0,\"\\n    \"],[7,\"p\"],[9],[1,[24,1,[\"description\"]],false],[10],[0,\"\\n\"],[4,\"if\",[[24,1,[\"imageUrl\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"featured-image\"],[9],[0,\"\\n        \"],[7,\"img\"],[12,\"src\",[24,1,[\"imageUrl\"]]],[12,\"alt\",[24,1,[\"title\"]]],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,1,[\"link\"]]],null,{\"statements\":[[0,\"      \"],[7,\"a\"],[12,\"href\",[24,1,[\"link\"]]],[11,\"class\",\"button button--primary\"],[9],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"button-label\"],[9],[1,[29,\"t\",[\"dashboard.announcements.button\"],null],false],[10],[0,\"\\n      \"],[3,\"action\",[[24,0,[]],\"dismissAnnouncement\",[24,1,[]]],[[\"preventDefault\"],[false]]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"span\"],[11,\"class\",\"dismiss-blotter\"],[9],[0,\"\\n      \"],[1,[29,\"svg-jar\",[\"close\"],null],false],[0,\"\\n    \"],[3,\"action\",[[24,0,[]],\"dismissAnnouncement\",[24,1,[]]]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/dashboard/site-announcements.hbs"
    }
  });

  _exports.default = _default;
});