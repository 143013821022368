define("client/templates/components/library-entry/state/current", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sDMol1JT",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[11,\"class\",\"completed-button button button--secondary\"],[9],[0,\"\\n  \"],[1,[29,\"t\",[\"library-entry.state.finished-this\"],null],false],[0,\"\\n\"],[3,\"action\",[[24,0,[]],[25,[\"onStatusChange\"]],\"completed\"]],[10],[0,\"\\n\\n\"],[4,\"library-entry/rating-button\",null,[[\"class\",\"rating\",\"swapToDropdown\",\"onClick\"],[\"rating-button button button--secondary button--muted\",[25,[\"libraryEntry\",\"rating\"]],true,[25,[\"onRatingChange\"]]]],{\"statements\":[[0,\"  \"],[1,[29,\"t\",[\"library-entry.state.rate-this\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/library-entry/state/current.hbs"
    }
  });

  _exports.default = _default;
});