define("client/routes/posts", ["exports", "client/mixins/routes/data-error"], function (_exports, _dataError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_dataError.default, {
    intl: Ember.inject.service(),
    metrics: Ember.inject.service(),
    model: function model(_ref) {
      var id = _ref.id;
      return Ember.get(this, 'store').findRecord('post', id, {
        include: 'user,targetUser,targetGroup,media,uploads',
        reload: true
      });
    },
    afterModel: function afterModel(model) {
      Ember.set(this, 'breadcrumb', "Post by ".concat(Ember.get(model, 'user.name')));
      var tags = this.setHeadTags(model);
      Ember.set(this, 'headTags', tags);
      Ember.get(this, 'metrics').invoke('trackImpression', 'Stream', {
        content_list: ["Post:".concat(Ember.get(model, 'id'))],
        location: Ember.get(this, 'routeName')
      });
    },
    titleToken: function titleToken() {
      var model = this.modelFor('posts');
      var name = Ember.get(model, 'user.name');
      return Ember.get(this, 'intl').t('titles.posts', {
        user: name
      });
    },
    setHeadTags: function setHeadTags(model) {
      var tags = [];
      var content = Ember.get(model, 'content');

      if (content) {
        var description = content.substring(0, 140);
        tags.push({
          type: 'meta',
          tagId: 'meta-description',
          attrs: {
            name: 'description',
            content: description
          }
        }, {
          type: 'meta',
          tagId: 'meta-og-type',
          attrs: {
            name: 'og:type',
            content: 'article'
          }
        }, {
          type: 'meta',
          tagId: 'meta-og-description',
          attrs: {
            name: 'og:description',
            content: description
          }
        }, {
          type: 'meta',
          tagId: 'meta-og-image',
          attrs: {
            name: 'og:image',
            content: Ember.get(model, 'user.avatar.medium') || Ember.get(model, 'user.avatar')
          }
        });
      } // If the post has likes, add extra data (Slack uses this for example)


      if (Ember.get(model, 'postLikesCount')) {
        tags.push({
          type: 'meta',
          tagId: 'meta-twitter-label1',
          attrs: {
            property: 'twitter:label1',
            content: 'Likes'
          }
        }, {
          type: 'meta',
          tagId: 'meta-twitter-data1',
          attrs: {
            property: 'twitter:data1',
            content: Ember.get(model, 'postLikesCount')
          }
        });
      } // Push comments count if any exist


      if (Ember.get(model, 'commentsCount')) {
        tags.push({
          type: 'meta',
          tagId: 'meta-twitter-label2',
          attrs: {
            property: 'twitter:label2',
            content: 'Comments'
          }
        }, {
          type: 'meta',
          tagId: 'meta-twitter-data2',
          attrs: {
            property: 'twitter:data2',
            content: Ember.get(model, 'commentsCount')
          }
        });
      }

      return tags;
    }
  });

  _exports.default = _default;
});