define("client/abilities/post", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend({
    canWrite: Ember.computed.readOnly('session.hasUser'),
    canEdit: Ember.computed('session.hasUser', 'model.user', function () {
      if (!Ember.get(this, 'session.hasUser')) {
        return false;
      }

      var isOwner = Ember.get(this, 'session').isCurrentUser(Ember.get(this, 'model.user'));
      var isAdmin = Ember.get(this, 'session.account').hasRole('admin', Ember.get(this, 'model'));
      return isOwner || isAdmin;
    }).readOnly()
  });

  _exports.default = _default;
});