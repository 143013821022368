define("client/templates/components/admin/report-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9ClLSQmd",
    "block": "{\"symbols\":[\"report\"],\"statements\":[[7,\"thead\"],[11,\"class\",\"thead-inverse\"],[9],[0,\"\\n  \"],[7,\"tr\"],[9],[0,\"\\n    \"],[7,\"th\"],[9],[1,[29,\"t\",[\"components.admin.reports.table.user\"],null],false],[10],[0,\"\\n    \"],[7,\"th\"],[9],[1,[29,\"t\",[\"components.admin.reports.table.type\"],null],false],[10],[0,\"\\n    \"],[7,\"th\"],[9],[1,[29,\"t\",[\"components.admin.reports.table.reason\"],null],false],[10],[0,\"\\n    \"],[7,\"th\"],[9],[1,[29,\"t\",[\"components.admin.reports.table.explanation\"],null],false],[10],[0,\"\\n    \"],[7,\"th\"],[9],[1,[29,\"t\",[\"components.admin.reports.table.moderator\"],null],false],[10],[0,\"\\n    \"],[7,\"th\"],[9],[1,[29,\"t\",[\"components.admin.reports.table.status\"],null],false],[10],[0,\"\\n    \"],[7,\"th\"],[11,\"class\",\"text-xs-center\"],[9],[1,[29,\"t\",[\"components.admin.reports.table.actions\"],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"tbody\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"reports\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"admin/report-item\",null,[[\"report\"],[[24,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/admin/report-list.hbs"
    }
  });

  _exports.default = _default;
});