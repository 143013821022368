define("client/components/groups/group-result", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['group-result'],
    categoryRoute: 'groups.index',
    queryCache: Ember.inject.service(),
    router: Ember.inject.service(),
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      if (!Ember.get(this, 'session.hasUser')) {
        return;
      }

      Ember.get(this, 'queryCache').query('group-member', {
        filter: {
          group: Ember.get(this, 'group.id'),
          user: Ember.get(this, 'session.account.id')
        }
      }).then(function (records) {
        return Ember.set(_this, 'membership', Ember.get(records, 'firstObject'));
      });
    },
    actions: {
      handleGroupClick: function handleGroupClick() {
        var slug = Ember.get(this, 'group.slug');
        Ember.get(this, 'router').transitionTo('groups.group.group-page.index', slug);
      }
    }
  });

  _exports.default = _default;
});