define("client/mixins/routes/canonical-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * This mixin checks if the dynamic segment of a route does not match the
   * value of that key from the route's model. This forces us to match our route
   * segments to model attributes, for example:
   *
   *    `/anime/:slug` => { slug: 'cowboy-bebop' }
   *
   * If you requested `/anime/1`, this mixin would redirect you to
   * `/anime/cowboy-bebop`
   */
  var _default = Ember.Mixin.create({
    redirect: function redirect(model) {
      var routeName = Ember.get(this, 'routeName');
      var params = this.paramsFor(routeName);

      var _Object$keys = Object.keys(params),
          _Object$keys2 = _slicedToArray(_Object$keys, 1),
          key = _Object$keys2[0];

      var current = Ember.get(params, key);
      var correct = Ember.get(model, key);

      if (current !== correct) {
        this.replaceWith(routeName, correct);
      }
    }
  });

  _exports.default = _default;
});