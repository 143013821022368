define("client/templates/comments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q3cc00cv",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"full-page-post centered\"],[9],[0,\"\\n  \"],[1,[29,\"stream-feed/items/post\",null,[[\"post\",\"isExpanded\",\"comment\",\"isReply\",\"readOnly\"],[[25,[\"post\"]],true,[25,[\"model\"]],[25,[\"parent\"]],[29,\"not\",[[25,[\"session\",\"hasUser\"]]],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/comments.hbs"
    }
  });

  _exports.default = _default;
});