define("client/components/episodes/streamer-plug", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // TODO: move this to the server's Streamer model
  var STREAMING_LINKS = {
    hulu: {
      name: 'Hulu',
      link: 'https://www.hulu.com/start',
      description: 'More anime awaits, on Hulu.',
      cta: 'Start your free trial',
      logo: 'hulu-lg'
    }
  };

  var _default = Ember.Component.extend({
    classNames: ['streamer-plug'],
    classNameBindings: ['streamerClassName'],
    streamer: null,
    streamerClassName: Ember.computed('streamer', function () {
      return "".concat(Ember.get(this, 'streamer'), "-plug");
    }),
    streamerInfo: Ember.computed('streamer', function () {
      return STREAMING_LINKS[Ember.get(this, 'streamer')];
    })
  });

  _exports.default = _default;
});