define("client/templates/components/episodes/streamer-plug", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "K2rKrSQI",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\"],[11,\"class\",\"plug-logo\"],[9],[0,\"\\n  \"],[1,[29,\"svg-jar\",[[25,[\"streamerInfo\",\"logo\"]]],null],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"p\"],[11,\"class\",\"plug-description\"],[9],[0,\"\\n  \"],[1,[25,[\"streamerInfo\",\"description\"]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"a\"],[12,\"href\",[25,[\"streamerInfo\",\"link\"]]],[11,\"class\",\"plug-cta\"],[9],[0,\"\\n  \"],[1,[25,[\"streamerInfo\",\"cta\"]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/episodes/streamer-plug.hbs"
    }
  });

  _exports.default = _default;
});