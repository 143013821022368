define("client/metrics-adapters/twitter-pixel", ["exports", "ember-metrics/metrics-adapters/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    toStringExtension: function toStringExtension() {
      return 'TwitterPixel';
    },
    init: function init() {
      var _Ember$get = Ember.get(this, 'config'),
          id = _Ember$get.id;
      /* eslint-disable */


      !function (e, t, n, s, u, a) {
        e.twq || (s = e.twq = function () {
          s.exe ? s.exe.apply(s, arguments) : s.queue.push(arguments);
        }, s.version = '1.1', s.queue = [], u = t.createElement(n), u.async = !0, u.src = 'https://static.ads-twitter.com/uwt.js', a = t.getElementsByTagName(n)[0], a.parentNode.insertBefore(u, a));
      }(window, document, 'script');
      /* eslint-enable */

      window.twq('init', id);
    },
    trackPage: function trackPage() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      window.twq('track', 'PageView', options);
    },
    trackEvent: function trackEvent() {},
    willDestroy: function willDestroy() {
      document.querySelectorAll('script[src*="https://static.ads-twitter.com/uwt.js"]').forEach(function (element) {
        element.remove();
      });
      document.querySelectorAll('script[src*="analytics.twitter.com"]').forEach(function (element) {
        element.remove();
      });
    }
  });

  _exports.default = _default;
});