define("client/templates/components/users/user-favorites/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lg4FVtrU",
    "block": "{\"symbols\":[\"favorite\"],\"statements\":[[4,\"each\",[[25,[\"showableItems\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"favorite-item col-xs-3 hint--top hint--bounce hint--rounded\"],[12,\"aria-label\",[29,\"if\",[[25,[\"isCharacter\"]],[24,1,[\"item\",\"name\"]],[24,1,[\"item\",\"computedTitle\"]]],null]],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"isCharacter\"]]],null,{\"statements\":[[0,\"      \"],[7,\"a\"],[11,\"href\",\"#\"],[9],[0,\"\\n        \"],[1,[29,\"lazy-image\",null,[[\"src\"],[[29,\"image\",[[24,1,[\"item\",\"image\"]]],null]]]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"a\"],[12,\"href\",[29,\"href-to\",[[29,\"concat\",[[25,[\"type\"]],\".show\"],null],[24,1,[\"item\"]]],null]],[9],[0,\"\\n        \"],[1,[29,\"lazy-image\",null,[[\"src\"],[[29,\"image\",[[24,1,[\"item\",\"posterImage\"]],\"tiny\"],null]]]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[4,\"unless\",[[29,\"gte\",[[25,[\"showCount\"]],[25,[\"items\",\"length\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"text-xs-center w-100\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"button button--primary\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"showMore\"],null]],[9],[0,\"\\n      \"],[1,[29,\"t\",[\"components.users.favorites.more\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/users/user-favorites/list.hbs"
    }
  });

  _exports.default = _default;
});