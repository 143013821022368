define("client/components/users/edit-profile/body/favorites", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['tab-pane'],
    tab: 'anime',
    init: function init() {
      this._super.apply(this, arguments);

      var data = Ember.get(this, 'externalData');

      if (data) {
        Ember.set(this, 'tab', Ember.get(data, 'tab') || Ember.get(this, 'tab'));
      }
    }
  });

  _exports.default = _default;
});