define("client/routes/media/index", ["exports", "client/mixins/routes/slide-header", "kitsu-shared/mixins/pagination"], function (_exports, _slideHeader, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Route.extend(_slideHeader.default, _pagination.default, {
    templateName: 'media/index',
    queryCache: Ember.inject.service(),
    model: function model(params) {
      var options = this._getRequestOptions(params);

      var _Ember$get$split = Ember.get(this, 'routeName').split('.'),
          _Ember$get$split2 = _slicedToArray(_Ember$get$split, 1),
          mediaType = _Ember$get$split2[0];

      return {
        taskInstance: this.queryPaginated(mediaType, options),
        paginatedRecords: []
      };
    },
    activate: function activate() {
      this._super.apply(this, arguments);

      document.body.classList.add('browse-page');
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);

      document.body.classList.remove('browse-page');
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller._setDirtyValues();
    },
    headTags: function headTags() {
      var _Ember$get$split3 = Ember.get(this, 'routeName').split('.'),
          _Ember$get$split4 = _slicedToArray(_Ember$get$split3, 1),
          mediaType = _Ember$get$split4[0];

      var description = "Looking for that ".concat(mediaType, "? Find all the best anime and manga\n      on Kitsu!");
      return [{
        type: 'meta',
        tagId: 'meta-description',
        attrs: {
          property: 'description',
          content: description
        }
      }, {
        type: 'meta',
        tagId: 'meta-og-description',
        attrs: {
          property: 'og:description',
          content: description
        }
      }];
    },
    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    },

    /**
     * Build the request object that is sent to our API.
     *
     * @private
     * @param {Object} params
     */
    _getRequestOptions: function _getRequestOptions(params) {
      var _this = this;

      var options = {
        filter: {},
        page: {
          offset: 0,
          limit: 20
        },
        fields: this._getFieldsets()
      };
      Object.keys(params).forEach(function (key) {
        var value = params[key];

        if (Ember.isEmpty(value)) {
          return;
        }

        if (Ember.isArray(value)) {
          var filtered = value.reject(function (x) {
            return Ember.isEmpty(x);
          });

          if (Ember.isEmpty(filtered)) {
            return;
          }
        }

        if (key !== 'sort') {
          var type = Ember.typeOf(value);
          options.filter[key] = _this.serializeQueryParam(value, key, type);
        }
      });

      if (!Ember.isEmpty(options.filter.unitCount)) {
        var _Ember$get$split5 = Ember.get(this, 'routeName').split('.'),
            _Ember$get$split6 = _slicedToArray(_Ember$get$split5, 1),
            mediaType = _Ember$get$split6[0];

        var unitKey = mediaType === 'anime' ? 'episodeCount' : 'chapterCount';
        options.filter[unitKey] = options.filter.unitCount;
        delete options.filter.unitCount;
      }

      if (Ember.isEmpty(options.filter.text)) {
        options.sort = this._getSortingKey(params.sort);
      }

      return options;
    },

    /**
     * Build the fieldsets object that is sent to our API.
     *
     * @private
     */
    _getFieldsets: function _getFieldsets() {
      var _Ember$get$split7 = Ember.get(this, 'routeName').split('.'),
          _Ember$get$split8 = _slicedToArray(_Ember$get$split7, 1),
          mediaType = _Ember$get$split8[0];

      return _defineProperty({}, mediaType, ['slug', 'canonicalTitle', 'titles', 'posterImage', 'description', 'averageRating', 'startDate', 'popularityRank', 'ratingRank'].join(','));
    },

    /**
     * Converts the client-side sorting key to one the server API expects.
     *
     * @private
     * @param {String} sort
     */
    _getSortingKey: function _getSortingKey(sort) {
      switch (sort) {
        case 'rating':
          return '-average_rating';

        case 'date':
          return '-start_date';

        case 'recent':
          return '-created_at';

        default:
          return '-user_count';
      }
    }
  });

  _exports.default = _default;
});