define("client/components/modals/progressing-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    modalBase: null,
    component: null,
    componentName: Ember.computed('modalBase', 'component', function () {
      var modalBase = Ember.get(this, 'modalBase');
      var component = Ember.get(this, 'component');
      return "".concat(modalBase, "/").concat(component);
    }).readOnly(),
    actions: {
      changeComponent: function changeComponent(component, data) {
        Ember.set(this, 'component', component);
        Ember.set(this, 'componentData', data);

        this.$('.modal').data('bs.modal')._handleUpdate();
      },
      closeModal: function closeModal() {
        this.$('.modal').modal('hide');
      }
    }
  });

  _exports.default = _default;
});