define("client/utils/media-minyear", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.minYearManga = _exports.minYearAnime = void 0;
  var minYearAnime = 1907;
  _exports.minYearAnime = minYearAnime;
  var minYearManga = 1862;
  _exports.minYearManga = minYearManga;
});