define("client/components/ember-tether", ["exports", "ember-tether/components/ember-tether", "ember-invoke-action"], function (_exports, _emberTether, _emberInvokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberTether.default.extend({
    mouseEnter: function mouseEnter() {
      (0, _emberInvokeAction.invokeAction)(this, 'onHover');
    },
    mouseLeave: function mouseLeave() {
      (0, _emberInvokeAction.invokeAction)(this, 'onLeave');
    }
  });

  _exports.default = _default;
});