define("client/controllers/media-reactions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      signUp: function signUp() {
        Ember.get(this, 'session.signUpModal')();
      }
    }
  });

  _exports.default = _default;
});