define("client/abilities/group", ["exports", "ember-can", "ember-can/computed"], function (_exports, _emberCan, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var hasPermission = function hasPermission(permission) {
    return Ember.computed('membership.permissions.@each.hasDirtyAttributes', function () {
      return this._isGroupMember() && Ember.get(this, 'membership').hasPermission(permission);
    }).readOnly();
  };

  var _default = _emberCan.Ability.extend({
    membership: null,
    postAbility: (0, _computed.ability)('post', 'post'),
    commentAbility: (0, _computed.ability)('comment', 'comment'),
    canViewDashboard: Ember.computed('membership', function () {
      var isMember = this._isGroupMember();

      return isMember && Ember.get(Ember.get(this, 'membership'), 'permissions.length') > 0;
    }).readOnly(),
    canManageEverything: hasPermission('owner'),
    canManageMembers: hasPermission('members'),
    canManageLeaders: hasPermission('leaders'),
    canManageSettings: hasPermission('community'),
    canManageReports: hasPermission('content'),
    canManageTickets: hasPermission('tickets'),
    canWritePost: Ember.computed('model', 'membership', function () {
      // If this is a restricted group, then posting is limited to leaders
      var group = Ember.get(this, 'model');

      if (group && Ember.get(group, 'isRestricted')) {
        var membership = this._isGroupMember();

        return membership && membership.hasPermission('content');
      }

      return true;
    }).readOnly(),
    canEditPost: Ember.computed('model', 'membership', 'post', function () {
      // If you're the owner of the content, or a site-admin then the post is editable
      var canEditPost = Ember.get(this, 'postAbility.canEdit');

      if (canEditPost) {
        return true;
      } // Do you have the correct group permissions to edit community content?


      var membership = this._isGroupMember();

      return membership && membership.hasPermission('content');
    }).readOnly(),
    canEditComment: Ember.computed('model', 'membership', 'comment', function () {
      // If you're the owner of the comment, or a site admin then you can edit
      var canEditComment = Ember.get(this, 'commentAbility.canEdit');

      if (canEditComment) {
        return true;
      } // Requires the correct permissions


      var membership = this._isGroupMember();

      return membership && membership.hasPermission('content');
    }).readOnly(),

    /**
     * Checks if the user is authenticated and that they are a group member.
     *
     * @returns {Boolean}
     */
    _isGroupMember: function _isGroupMember() {
      var membership = Ember.get(this, 'membership');
      var user = Ember.get(this, 'session.hasUser') && Ember.get(this, 'session.account');
      return user && membership ? membership : false;
    }
  });

  _exports.default = _default;
});