define("client/templates/components/search-typeahead", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "puKAc+0g",
    "block": "{\"symbols\":[\"item\",\"&default\"],\"statements\":[[1,[29,\"search-input\",null,[[\"text\",\"showIcon\",\"inputClass\",\"update\",\"disabled\",\"placeholder\"],[[25,[\"value\"]],[25,[\"showIcon\"]],\"form-control\",[29,\"action\",[[24,0,[]],\"update\"],null],[25,[\"disabled\"]],[25,[\"placeholder\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"request\",\"isRunning\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"text-xs-center\"],[9],[0,\"\\n    \"],[1,[29,\"loading-spinner\",null,[[\"size\"],[\"small\"]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[29,\"and\",[[25,[\"items\"]],[25,[\"isOpened\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"ul\"],[11,\"class\",\"typeahead-items-container nav\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"items\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\"],[11,\"class\",\"typeahead-item-container\"],[9],[0,\"\\n          \"],[15,2,[[24,1,[]]]],[0,\"\\n        \"],[3,\"action\",[[24,0,[]],\"close\"]],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/search-typeahead.hbs"
    }
  });

  _exports.default = _default;
});