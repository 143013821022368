define("client/templates/explore/more", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kSNQMzKx",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"explore/explore-more\",null,[[\"getUserFavoritesTask\",\"mediaType\",\"type\",\"more\"],[[25,[\"model\",\"userFavoritesTask\"]],[25,[\"mediaType\"]],[25,[\"type\"]],\"explore.more\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/explore/more.hbs"
    }
  });

  _exports.default = _default;
});