define("client/models/profile-link", ["exports", "client/models/-base", "ember-data/attr", "ember-data/relationships", "ember-validators/format"], function (_exports, _base, _attr, _relationships, _format) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    url: (0, _attr.default)('string'),
    profileLinkSite: (0, _relationships.belongsTo)('profile-link-site'),
    user: (0, _relationships.belongsTo)('user', {
      inverse: 'profileLinks'
    }),
    site: Ember.computed.alias('profileLinkSite'),
    isURL: Ember.computed('url', function () {
      var url = Ember.get(this, 'url');
      return _format.regularExpressions.url.test(url);
    }).readOnly()
  });

  _exports.default = _default;
});