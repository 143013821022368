define("client/routes/reviews", ["exports", "client/mixins/routes/data-error"], function (_exports, _dataError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_dataError.default, {
    intl: Ember.inject.service(),
    metrics: Ember.inject.service(),
    model: function model(_ref) {
      var id = _ref.id;
      return Ember.get(this, 'store').findRecord('review', id, {
        include: 'user,media',
        reload: true
      });
    },
    afterModel: function afterModel(model) {
      var tags = this.setHeadTags(model);
      Ember.set(this, 'headTags', tags);
      Ember.get(this, 'metrics').invoke('trackImpression', 'Stream', {
        content_list: ["Review:".concat(Ember.get(model, 'id'))],
        location: Ember.get(this, 'routeName')
      });
    },
    titleToken: function titleToken() {
      var model = this.modelFor('reviews');
      var name = Ember.get(model, 'user.name');
      return Ember.get(this, 'intl').t('titles.reviews', {
        user: name
      });
    },
    setHeadTags: function setHeadTags(model) {
      var description = Ember.get(model, 'content').substring(0, 140);
      return [{
        type: 'meta',
        tagId: 'meta-description',
        attrs: {
          name: 'description',
          content: description
        }
      }, {
        type: 'meta',
        tagId: 'meta-og-title',
        attrs: {
          property: 'og:title',
          content: "Review of ".concat(Ember.get(model, 'media.canonicalTitle'))
        }
      }, {
        type: 'meta',
        tagId: 'meta-og-description',
        attrs: {
          property: 'og:description',
          content: description
        }
      }, {
        type: 'meta',
        tagId: 'meta-og-image',
        attrs: {
          property: 'og:image',
          content: Ember.get(model, 'media.posterImage.large')
        }
      }, {
        type: 'meta',
        tagId: 'meta-twitter-label1',
        attrs: {
          property: 'twitter:label1',
          content: 'Reviewed by'
        }
      }, {
        type: 'meta',
        tagId: 'meta-twitter-data1',
        attrs: {
          property: 'twitter:data1',
          content: Ember.get(model, 'user.name')
        }
      }, {
        type: 'meta',
        tagId: 'meta-twitter-label2',
        attrs: {
          property: 'twitter:label2',
          content: 'Rating'
        }
      }, {
        type: 'meta',
        tagId: 'meta-twitter-data2',
        attrs: {
          property: 'twitter:data2',
          content: "".concat(Ember.get(model, 'rating'), " out of 10")
        }
      }];
    }
  });

  _exports.default = _default;
});