define("client/metrics-adapters/go-squared", ["exports", "ember-metrics/metrics-adapters/base", "ember-metrics/utils/object-transforms"], function (_exports, _base, _objectTransforms) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    toStringExtension: function toStringExtension() {
      return 'GoSquared';
    },
    init: function init() {
      var _Ember$get = Ember.get(this, 'config'),
          id = _Ember$get.id;
      /* eslint-disable */


      !function (g, s, q, r, d) {
        r = g[r] = g[r] || function () {
          (r.q = r.q || []).push(arguments);
        };

        d = s.createElement(q);
        q = s.getElementsByTagName(q)[0];
        d.src = 'https://d1l6p2sc9645hc.cloudfront.net/tracker.js';
        q.parentNode.insertBefore(d, q);
      }(window, document, 'script', '_gs');
      /* eslint-enable */

      if (window._gs && id) {
        window._gs(id, false);
      }
    },
    identify: function identify() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var id = options.distinctId,
          name = options.name,
          email = options.email;

      if (window._gs) {
        window._gs('identify', {
          id: id,
          name: name,
          email: email
        });
      }
    },
    unidentify: function unidentify() {
      if (window._gs) {
        window._gs('unidentify');
      }
    },
    trackPage: function trackPage() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var page = options.page,
          title = options.title;

      if (window._gs) {
        window._gs('track', page, title);
      }
    },
    trackEvent: function trackEvent() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var compactedOptions = (0, _objectTransforms.compact)(options);
      var eventName = compactedOptions.eventName;

      if (!eventName) {
        return;
      }

      if (window._gs) {
        window._gs('event', eventName, (0, _objectTransforms.without)(compactedOptions, 'eventName') || {});
      }
    },
    willDestroy: function willDestroy() {
      document.querySelectorAll('script[src*="https://d1l6p2sc9645hc.cloudfront.net/tracker.js"]').forEach(function (element) {
        element.remove();
      });
    }
  });

  _exports.default = _default;
});