define("client/templates/components/application/auth-onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NPMnuSTv",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[4,\"bootstrap/bs-modal\",null,[[\"id\",\"onClose\"],[[25,[\"modalId\"]],[29,\"action\",[[24,0,[]],\"onClose\"],null]]],{\"statements\":[[4,\"component\",[[29,\"-assert-implicit-component-helper-argument\",[[24,1,[\"body\"]],\"expected `modal.body` to be a contextual component but found a string. Did you mean `(component modal.body)`? ('client/templates/components/application/auth-onboarding.hbs' @ L2:C5) \"],null]],null,{\"statements\":[[0,\"    \"],[1,[29,\"component\",[[25,[\"componentName\"]]],[[\"componentData\",\"modal\",\"changeComponent\",\"close\"],[[25,[\"componentData\"]],[24,0,[]],[29,\"action\",[[24,0,[]],\"changeComponent\"],null],[29,\"action\",[[24,0,[]],\"closeModal\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/application/auth-onboarding.hbs"
    }
  });

  _exports.default = _default;
});