define("client/components/media/filter-widgets/multi-select", ["exports", "ember-invoke-action"], function (_exports, _emberInvokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'ul',
    includeAll: false,
    actions: {
      toggle: function toggle(option) {
        var value = Ember.copy(Ember.get(this, 'selected'));

        if (value.includes(option)) {
          value.removeObject(option);
        } else {
          value.addObject(option);
        }

        (0, _emberInvokeAction.invokeAction)(this, 'onChange', value);
      },
      clear: function clear() {
        (0, _emberInvokeAction.invokeAction)(this, 'onChange', []);
      }
    }
  });

  _exports.default = _default;
});