define("client/models/streaming-link", ["exports", "client/models/-base", "ember-data/attr", "ember-data/relationships"], function (_exports, _base, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    url: (0, _attr.default)('string'),
    subs: (0, _attr.default)('array'),
    dubs: (0, _attr.default)('array'),
    media: (0, _relationships.belongsTo)('media'),
    streamer: (0, _relationships.belongsTo)('streamer')
  });

  _exports.default = _default;
});