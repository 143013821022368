define("client/services/raven", ["exports", "ember-cli-sentry/services/raven", "client/config/environment"], function (_exports, _raven, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Reference Travis: https://github.com/travis-ci/travis-web/blob/master/app/services/raven.js
  var _default = _raven.default.extend({
    benignErrors: ['TaskCancelation', 'TaskInstance', 'TransitionAborted', 'UnrecognizedURLError', 'not found', 'returned a 403', 'returned a 404', 'operation failed', 'operation was aborted'],
    unhandledPromiseErrorMessage: '',
    release: _environment.default.release,
    captureException: function captureException(error) {
      if (!this.ignoreError(error)) {
        this._super.apply(this, arguments);
      }
    },
    ignoreError: function ignoreError(error) {
      if (!this.shouldReportError()) {
        return true;
      }

      var name = error.name,
          message = error.message;

      if (!message) {
        return false;
      }

      return this.get('benignErrors').any(function (benign) {
        return name && name.includes(benign) || message.includes(benign);
      });
    },
    shouldReportError: function shouldReportError() {
      if (Ember.testing) {
        return false;
      }

      var sampleRate = 10;
      return Math.random() * 100 <= sampleRate;
    }
  });

  _exports.default = _default;
});