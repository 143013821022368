define("client/templates/components/bootstrap/bs-modal-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5JE7s1MJ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,[\"closeButton\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\"],[11,\"class\",\"close\"],[11,\"data-dismiss\",\"modal\"],[11,\"aria-label\",\"Close\"],[11,\"type\",\"button\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"aria-hidden\",\"true\"],[9],[0,\"×\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[15,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/bootstrap/bs-modal-header.hbs"
    }
  });

  _exports.default = _default;
});