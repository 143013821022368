define("client/components/library-entry/status-dropdown", ["exports", "client/components/library-entry/state", "ember-invoke-action", "client/models/library-entry"], function (_exports, _state, _emberInvokeAction, _libraryEntry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _state.default.extend({
    classNameBindings: ['libraryEntry:has-entry'],
    showHeader: false,
    isLoading: Ember.computed('getLibraryEntryTask.isRunning', 'createLibraryEntryTask.isRunning', 'removeLibraryEntryTask.isRunning', 'updateLibraryEntryTask.isRunning', function () {
      return Ember.get(this, 'getLibraryEntryTask.isRunning') || Ember.get(this, 'createLibraryEntryTask.isRunning') || Ember.get(this, 'removeLibraryEntryTask.isRunning') || Ember.get(this, 'updateLibraryEntryTask.isRunning');
    }).readOnly(),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'statuses', _libraryEntry.LIBRARY_STATUSES);
    },
    actions: {
      updateLibraryEntry: function updateLibraryEntry(status) {
        if (!Ember.get(this, 'session.hasUser')) {
          return Ember.get(this, 'session').signUpModal();
        }

        if (Ember.get(this, 'libraryEntry')) {
          (0, _emberInvokeAction.invoke)(this, 'updateAttribute', 'status', status);
        } else {
          Ember.get(this, 'createLibraryEntryTask').perform(status);
        }
      }
    },
    _getRequestOptions: function _getRequestOptions() {
      var options = this._super();

      delete options.include;
      return options;
    }
  });

  _exports.default = _default;
});