define("client/authenticators/oauth2", ["exports", "ember-simple-auth/authenticators/oauth2-password-grant", "client/config/environment"], function (_exports, _oauth2PasswordGrant, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _oauth2PasswordGrant.default.extend({
    refreshAccessTokens: true,
    session: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      var host = _environment.default.kitsu.APIHost || '';
      Ember.set(this, 'serverTokenEndpoint', "".concat(host, "/api/oauth/token"));
      Ember.set(this, 'serverTokenRevocationEndpoint', "".concat(host, "/api/oauth/revoke"));
    }
  });

  _exports.default = _default;
});