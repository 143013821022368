define("client/models/group-invite", ["exports", "client/models/-base", "ember-data/attr", "ember-data/relationships"], function (_exports, _base, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    acceptedAt: (0, _attr.default)('utc'),
    createdAt: (0, _attr.default)('utc'),
    declinedAt: (0, _attr.default)('utc'),
    revokedAt: (0, _attr.default)('utc'),
    group: (0, _relationships.belongsTo)('group'),
    sender: (0, _relationships.belongsTo)('user'),
    user: (0, _relationships.belongsTo)('user'),
    hasResponded: Ember.computed('acceptedAt', 'revokedAt', 'declinedAt', function () {
      return !!(Ember.get(this, 'acceptedAt') || Ember.get(this, 'declinedAt') || Ember.get(this, 'revokedAt'));
    }).readOnly()
  });

  _exports.default = _default;
});