define("client/routes/groups/group/dashboard/tickets", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_emberCan.CanMixin, {
    intl: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var model = this.modelFor('groups.group.dashboard');
      var membership = Ember.get(model, 'membership');

      if (!this.can('manage tickets for group', {
        membership: membership
      })) {
        return this.transitionTo('groups.group.dashboard.index');
      }
    },
    model: function model() {
      return this.modelFor('groups.group.dashboard');
    },
    titleToken: function titleToken(model) {
      var group = Ember.get(model, 'group.name');
      return Ember.get(this, 'intl').t('titles.groups.group.dashboard.tickets', {
        group: group
      });
    }
  });

  _exports.default = _default;
});