define("client/services/query-cache", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CACHE_TIME_HOUR = 1;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    raven: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.cache = {};
    },

    /**
     * Attempts to use cached results so we don't execute to the API for every request.
     *
     * @param {String} type Ember Data Model Type
     * @param {Object} query Request Options
     * @param {Object} options Custom Options for cache
     */
    query: function query(type, _query) {
      var _this = this;

      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
        cache: true
      };

      var task = function task() {
        return Ember.get(_this, 'store').query(type, _query);
      };

      return this._getCachedQuery(type, _query, options, task);
    },

    /**
     * Push a record collection into the cache store for the query type.
     *
     * @param {String} type
     * @param {Object} query
     * @param {*} records
     */
    push: function push(type, query, records) {
      var cache = this.cache[type] || (this.cache[type] = {});

      var queryAsString = this._stringifyQuery(query);

      if (records && (Ember.isPresent(records) || Object.keys(records).length > 0)) {
        cache[queryAsString] = {
          promise: Ember.RSVP.resolve(records),
          expiry: this._getExpiryDate()
        };
      }
    },

    /**
     * Get cached records of a specific type and query.
     *
     * @param {String} type
     * @param {Object} query
     * @param {Boolean} checkExpiry
     */
    get: function get(type, query) {
      var checkExpiry = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
      var cache = this.cache[type];

      if (!cache) {
        return;
      }

      var queryAsString = this._stringifyQuery(query); // Attempt to get the cached result


      var cachedResult = cache[queryAsString];

      if (cachedResult) {
        if (checkExpiry && this._isExpired(cachedResult)) {
          delete cache[queryAsString];
        }

        return cachedResult.promise;
      }
    },

    /**
     * Invalidate an entire model types cache entries
     *
     * @param {String} type
     */
    invalidateType: function invalidateType(type) {
      delete this.cache[type];
    },

    /**
     * Invalidate the cache for a specific query
     *
     * @param {String} type
     * @param {Object} query
     */
    invalidateQuery: function invalidateQuery(type, query) {
      var cache = this.cache[type];

      if (!cache) {
        return;
      }

      var queryAsString = this._stringifyQuery(query);

      delete cache[queryAsString];
    },

    /**
     * Attempt to get a cached result if it exists and is within the expiry time, otherwise
     * execute the request and cache the results.
     *
     * @param {String} type Ember Data Model Type
     * @param {Object} query Request Options
     * @param {Object} options Cache Options
     * @param {Function} task
     * @private
     */
    _getCachedQuery: function _getCachedQuery(type, query, options, task) {
      var _this2 = this;

      var cache = this.cache[type] || (this.cache[type] = {});

      var queryAsString = this._stringifyQuery(query); // Attempt to get the cached result


      var cachedResult = cache[queryAsString];

      if (cachedResult) {
        if (this._isExpired(cachedResult)) {
          delete cache[queryAsString];
        } else {
          return cachedResult.promise;
        }
      } // Execute the task (API Request), cache and return the results.


      var promise = task();
      return promise.then(function (records) {
        // Don't cache empty results
        if (options.cache && Ember.get(records, 'length') > 0) {
          cache[queryAsString] = {
            promise: promise,
            expiry: _this2._getExpiryDate()
          };
        }

        return records;
      }).catch(function (error) {
        console.error('cache-query error:', error);
        Ember.get(_this2, 'raven').captureException(error);
        return {};
      });
    },

    /**
     * Returns a stringified sorted version of an object. This is so regardless of how you
     * build your request query, it is cached correctly with sorting.
     *
     * @param {Object} query
     * @private
     */
    _stringifyQuery: function _stringifyQuery(query) {
      var sortedObject = this._sortObject(query);

      return JSON.stringify(sortedObject);
    },

    /**
     * Sort an object alphabetically by its keys.
     *
     * @param {Object} object
     * @private
     */
    _sortObject: function _sortObject(object) {
      var _this3 = this;

      return Object.keys(object).sort().reduce(function (previous, current) {
        if (Ember.typeOf(object[current]) === 'object') {
          // eslint-disable-next-line
          previous[current] = _this3._sortObject(object[current]);
          return previous;
        }

        previous[current] = object[current]; // eslint-disable-line

        return previous;
      }, {});
    },

    /**
     * Create a date 2 hours in the future.
     *
     * @private
     */
    _getExpiryDate: function _getExpiryDate() {
      var date = new Date();
      date.setTime(date.getTime() + CACHE_TIME_HOUR * 60 * 60 * 1000);
      return date;
    },

    /**
     * Determines if the expiry date is older than the current time.
     *
     * @param {Object} cachedResult
     * @private
     */
    _isExpired: function _isExpired(cachedResult) {
      return new Date() > cachedResult.expiry;
    }
  });

  _exports.default = _default;
});