define("client/templates/components/groups/tickets/ticket-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jmXcEVSi",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\"],[11,\"href\",\"#\"],[11,\"class\",\"inbox-item\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"inbox-item-img\"],[9],[0,\"\\n    \"],[1,[29,\"lazy-image\",null,[[\"src\"],[[29,\"image\",[[25,[\"ticket\",\"user\",\"avatar\"]],\"small\"],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"inbox-item-author\"],[9],[1,[25,[\"ticket\",\"user\",\"name\"]],false],[10],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"inbox-item-text\"],[9],[1,[25,[\"ticket\",\"firstMessage\",\"content\",\"content\"]],false],[10],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"inbox-item-date\"],[9],[0,\"\\n    \"],[1,[29,\"format-relative\",[[25,[\"ticket\",\"createdAt\"]]],[[\"interval\"],[1000]]],false],[0,\",\\n    \"],[1,[29,\"format-date\",[[25,[\"ticket\",\"createdAt\"]]],[[\"hour\",\"minute\",\"timeZone\"],[\"numeric\",\"numeric\",\"UTC\"]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[3,\"action\",[[24,0,[]],[29,\"toggle\",[\"isCoversationOpened\",[24,0,[]]],null]]],[10],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"isCoversationOpened\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"modal\",[29,\"component\",[\"groups/tickets/ticket-conversation\"],[[\"ticket\",\"isPersonalTicket\",\"onClose\"],[[25,[\"ticket\"]],[25,[\"isPersonalTicket\"]],[29,\"toggle-action\",[\"isCoversationOpened\",[24,0,[]]],null]]]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/groups/tickets/ticket-list-item.hbs"
    }
  });

  _exports.default = _default;
});