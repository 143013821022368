define("client/routes/admin", ["exports", "client/mixins/routes/data-error"], function (_exports, _dataError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_dataError.default, {
    beforeModel: function beforeModel() {
      var user = Ember.get(this, 'session.account');

      if (user === undefined || !user.hasRole('admin')) {
        return this.transitionTo('dashboard');
      }
    }
  });

  _exports.default = _default;
});