define("client/routes/settings/password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    resetController: function resetController(controller) {
      Ember.set(controller, 'password', null);
      Ember.set(controller, 'passwordConfirm', null);
    }
  });

  _exports.default = _default;
});