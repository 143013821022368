define("client/templates/groups/group/group-page/leaders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1Df8gPY3",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row leader-team\"],[9],[0,\"\\n    \"],[1,[29,\"groups/tickets/ticket-list\",null,[[\"isPersonalTicket\",\"group\",\"filter\",\"query\",\"updateQueryParam\",\"addedTickets\",\"class\"],[true,[25,[\"group\"]],[25,[\"filter\"]],[25,[\"query\"]],[29,\"action\",[[24,0,[]],\"updateQueryParam\"],null],[25,[\"addedTickets\"]],\"leader-chat leader-tickets\"]]],false],[0,\"\\n\\n    \"],[1,[29,\"groups/leaders/leader-list\",null,[[\"group\",\"membership\",\"isManaging\",\"onTicketCreated\",\"class\"],[[25,[\"group\"]],[25,[\"membership\"]],false,[29,\"action\",[[24,0,[]],\"onTicketCreated\"],null],\"leader-table-card card\"]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/groups/group/group-page/leaders.hbs"
    }
  });

  _exports.default = _default;
});