define("client/templates/components/application/auth-onboarding/rate-content/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lEhT2nK8",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"lazy-image\",null,[[\"src\"],[[29,\"image\",[[25,[\"item\",\"posterImage\"]],\"small\"],null]]]],false],[0,\"\\n\"],[7,\"small\"],[9],[1,[25,[\"item\",\"canonicalTitle\"]],false],[10],[0,\"\\n\"],[7,\"span\"],[11,\"class\",\"rating-container\"],[9],[0,\"\\n  \"],[1,[29,\"library-entry/state\",null,[[\"media\",\"showHeader\",\"createOnly\",\"rateOnly\",\"hideProgress\",\"onCreate\",\"onRemove\",\"onUpdate\"],[[25,[\"item\"]],false,true,true,true,[29,\"action\",[[24,0,[]],[25,[\"onCreate\"]]],null],[29,\"action\",[[24,0,[]],[25,[\"onRemove\"]]],null],[29,\"action\",[[24,0,[]],[25,[\"onUpdate\"]]],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/application/auth-onboarding/rate-content/item.hbs"
    }
  });

  _exports.default = _default;
});