define("client/components/users/onboarding/checklist-progress", ["exports", "client/helpers/is-object"], function (_exports, _isObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var isObjectComputed = function isObjectComputed(property) {
    return Ember.computed(property, function () {
      return (0, _isObject.isObject)([Ember.get(this, property)]);
    }).readOnly();
  };

  var _default = Ember.Component.extend({
    hasRatings: Ember.computed.gte('user.ratingsCount', 5),
    hasAvatar: isObjectComputed('user.avatar'),
    hasCover: isObjectComputed('user.coverImage'),
    hasFavorites: Ember.computed.gte('user.favoritesCount', 1),
    hasAbout: Ember.computed('user.about.length', function () {
      return Ember.isPresent(Ember.get(this, 'user.about'));
    }).readOnly(),
    stepsCompleted: Ember.computed('hasRatings', 'hasAvatar', 'hasCover', 'hasAbout', 'hasFavorites', function () {
      var steps = [Ember.get(this, 'hasRatings'), Ember.get(this, 'hasAvatar'), Ember.get(this, 'hasCover'), Ember.get(this, 'hasAbout'), Ember.get(this, 'hasFavorites')];
      return 5 - steps.sort().lastIndexOf(false);
    }).readOnly(),
    percentageComplete: Ember.computed('stepsCompleted', function () {
      return 100 - 16.66 * Ember.get(this, 'stepsCompleted');
    }).readOnly(),
    ratingsLeft: Ember.computed('user.ratingsCount', function () {
      return 5 - Ember.get(this, 'user.ratingsCount');
    }).readOnly()
  });

  _exports.default = _default;
});