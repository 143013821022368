define("client/templates/components/library-entry/regular-rating", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kkIqerpG",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"star-rating\",null,[[\"rating\",\"numStars\",\"width\",\"height\",\"onClick\"],[[25,[\"regularRating\"]],5,\"30\",\"30\",[29,\"action\",[[24,0,[]],\"onClick\"],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/library-entry/regular-rating.hbs"
    }
  });

  _exports.default = _default;
});