define("client/components/structured-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'script',
    attributeBindings: ['type'],
    type: 'application/ld+json',
    structuredData: Ember.computed('data', function () {
      var data = Ember.get(this, 'data');
      return JSON.stringify(data);
    }).readOnly()
  });

  _exports.default = _default;
});