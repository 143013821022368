define("client/components/stream-feed/items/post/embed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Component.extend({
    classNames: ['stream-content-embed'],
    attributeBindings: ['style'],
    shouldRenderVideo: false,
    isVideo: Ember.computed('embed.{kind,video}', function () {
      return this.embed.kind && this.embed.kind.includes('video') && _typeof(this.embed.video) === 'object';
    }),
    requiresIFrame: Ember.computed('isVideo', 'embed.video.type', function () {
      if (!this.isVideo) {
        return false;
      }

      return this.embed.video.type !== 'video/mp4';
    }),
    videoSrc: Ember.computed('isVideo', 'embed.{site.name,video.url}', function () {
      if (!this.isVideo) {
        return null;
      }

      var url = this.embed.video.url;

      if (this.embed.site.name === 'YouTube') {
        return url.includes('?') ? "".concat(url, "&autoplay=1") : "".concat(url, "?autoplay=1");
      }

      return url;
    }),
    style: Ember.computed('isVideo', 'embed.video.{width,height}', function () {
      if (this.isVideo) {
        var _this$embed$video = this.embed.video,
            width = _this$embed$video.width,
            height = _this$embed$video.height;
        return Ember.String.htmlSafe("padding-bottom: calc(100% * (".concat(height, " / ").concat(width, "))"));
      }

      return null;
    }).readOnly(),
    orientation: Ember.computed('embed.image.{height,width}', function () {
      var kind = this.get('embed.kind');

      if (kind === 'image') {
        return 'landscape';
      }

      var image = this.get('embed.image');

      if (image) {
        var width = image.width,
            height = image.height;
        var ratio = width / height;

        if (ratio > 1.25) {
          return 'landscape';
        }
      }

      return 'portrait';
    }).readOnly(),
    actions: {
      revealVideo: function revealVideo() {
        this.set('shouldRenderVideo', true);
      }
    }
  });

  _exports.default = _default;
});