define("client/breakpoints", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    mobileSmall: '(max-width: 432px)',
    mobile: '(max-width: 543px)',
    mobileLarge: '(max-width: 600px)',
    tabletSmall: '(max-width: 768px)',
    tablet: '(max-width: 991px)',
    desktop: '(min-width: 992px)',
    sidebarLeftRightCollapse: '(max-width: 1199px)',
    sidebarRightCollapse: '(min-width: 992px) and (max-width: 1199px)'
  };
  _exports.default = _default;
});