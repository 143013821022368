define("client/templates/components/search-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uG3bsnjo",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"showIcon\"]]],null,{\"statements\":[[0,\"  \"],[7,\"label\"],[11,\"class\",\"filter-icon\"],[9],[0,\"\\n    \"],[7,\"span\"],[9],[1,[29,\"svg-jar\",[\"search\"],[[\"fill\"],[\"#bbb\"]]],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[29,\"one-way-search\",[[25,[\"dirtyText\"]]],[[\"update\",\"disabled\",\"placeholder\",\"class\"],[[29,\"perform\",[[25,[\"updateTask\"]]],null],[25,[\"disabled\"]],[25,[\"placeholder\"]],[25,[\"inputClass\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/search-input.hbs"
    }
  });

  _exports.default = _default;
});