define("client/templates/components/media/filter-widgets/multi-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LiMmrZ0O",
    "block": "{\"symbols\":[\"option\",\"&default\"],\"statements\":[[4,\"if\",[[25,[\"includeAll\"]]],null,{\"statements\":[[0,\"  \"],[7,\"li\"],[12,\"class\",[29,\"if\",[[29,\"is-empty\",[[25,[\"selected\"]]],null],\"active\"],null]],[11,\"aria-label\",\"All\"],[9],[0,\"\\n    \"],[15,2,[\"All\"]],[0,\"\\n  \"],[3,\"action\",[[24,0,[]],\"clear\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"each\",[[25,[\"selection\"]]],null,{\"statements\":[[0,\"  \"],[7,\"li\"],[12,\"class\",[30,[[29,\"if\",[[29,\"contains\",[[24,1,[]],[25,[\"selected\"]]],null],\"active\"],null],\" \",[29,\"if\",[[25,[\"tooltip\"]],[25,[\"tooltipClasses\"]]],null]]]],[12,\"aria-label\",[24,1,[]]],[9],[0,\"\\n    \"],[15,2,[[24,1,[]]]],[0,\"\\n  \"],[3,\"action\",[[24,0,[]],\"toggle\",[24,1,[]]]],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/media/filter-widgets/multi-select.hbs"
    }
  });

  _exports.default = _default;
});