define("client/routes/groups/group/dashboard/reports", ["exports", "ember-can", "kitsu-shared/mixins/pagination"], function (_exports, _emberCan, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_emberCan.CanMixin, _pagination.default, {
    intl: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var model = this.modelFor('groups.group.dashboard');
      var membership = Ember.get(model, 'membership');

      if (!this.can('manage reports for group', {
        membership: membership
      })) {
        return this.transitionTo('groups.group.dashboard.index');
      }
    },
    model: function model() {
      var model = this.modelFor('groups.group.dashboard');
      return {
        taskInstance: this.queryPaginated('feed', {
          type: 'reports_aggr',
          id: Ember.get(model, 'group.id'),
          include: 'subject.user,subject.naughty,subject.moderator'
        }, {
          cache: false
        }),
        paginatedRecords: []
      };
    },
    titleToken: function titleToken() {
      var model = this.modelFor('groups.group.dashboard');
      var group = Ember.get(model, 'group.name');
      return Ember.get(this, 'intl').t('titles.groups.group.dashboard.reports', {
        group: group
      });
    },
    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      }
    },
    _getRealStatus: function _getRealStatus(filter) {
      switch (filter) {
        case 'open':
          return 'reported';

        case 'resolved':
          return 'resolved';

        default:
          return undefined;
      }
    }
  });

  _exports.default = _default;
});