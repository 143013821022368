define("client/templates/components/users/library/library-sorting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FAV2kosL",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[7,\"span\"],[9],[1,[29,\"t\",[\"users.library.sorting.label\"],null],false],[10],[0,\"\\n\"],[4,\"power-select\",null,[[\"searchEnabled\",\"options\",\"selected\",\"onchange\",\"triggerClass\",\"matchTriggerWidth\"],[false,[25,[\"sortingOptions\"]],[25,[\"sort\"]],[29,\"action\",[[24,0,[]],[25,[\"update\"]]],null],\"d-inline-block\",false]],{\"statements\":[[0,\"  \"],[1,[29,\"t\",[[29,\"concat\",[\"users.library.sorting.options.\",[24,1,[]]],null]],null],false],[0,\"\\n\"]],\"parameters\":[1]},null],[7,\"span\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"toggleSortDirection\"],null]],[12,\"class\",[30,[\"sorting sorting-\",[23,\"direction\"],\" hint--top hint--bounce hint--rounded\"]]],[12,\"aria-label\",[29,\"t\",[[29,\"concat\",[\"users.library.sorting.directions.\",[25,[\"sort\"]],\".\",[25,[\"direction\"]]],null]],null]],[9],[0,\"\\n  \"],[1,[29,\"svg-jar\",[\"arrow-down\"],null],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/users/library/library-sorting.hbs"
    }
  });

  _exports.default = _default;
});