define("client/models/group-action-log", ["exports", "client/models/-base", "ember-data/attr", "ember-data/relationships"], function (_exports, _base, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    verb: (0, _attr.default)('string'),
    group: (0, _relationships.belongsTo)('group', {
      inverse: 'actionLogs'
    }),
    user: (0, _relationships.belongsTo)('user'),
    target: (0, _relationships.belongsTo)('-base')
  });

  _exports.default = _default;
});