define("client/templates/components/episodes/video-picker/language-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y68evAl6",
    "block": "{\"symbols\":[\"video\"],\"statements\":[[7,\"div\"],[11,\"class\",\"dropdown\"],[9],[0,\"\\n  \"],[7,\"a\"],[11,\"href\",\"#\"],[11,\"class\",\"dropdown-toggle\"],[11,\"data-toggle\",\"dropdown\"],[11,\"aria-haspopup\",\"true\"],[11,\"aria-expanded\",\"false\"],[9],[0,\"\\n    \"],[1,[25,[\"selectedVideo\",\"languageTitle\"]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"dropdown-menu\"],[11,\"aria-labelledby\",\"dropdownMenuButton\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"videos\"]]],null,{\"statements\":[[0,\"      \"],[7,\"a\"],[12,\"class\",[30,[\"dropdown-item \",[29,\"if\",[[29,\"eq\",[[24,1,[]],[25,[\"selectedVideo\"]]],null],\"active\"],null]]]],[11,\"href\",\"#\"],[9],[0,\"\\n        \"],[1,[24,1,[\"languageTitle\"]],false],[0,\"\\n      \"],[3,\"action\",[[24,0,[]],[25,[\"onChange\"]],[24,1,[]]]],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"span\"],[11,\"class\",\"divider\"],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/episodes/video-picker/language-selector.hbs"
    }
  });

  _exports.default = _default;
});