define("client/components/stream-feed/items/follow", ["exports", "ember-invoke-action", "client/utils/getter"], function (_exports, _emberInvokeAction, _getter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['stream-item', 'row'],
    metrics: Ember.inject.service(),
    activity: (0, _getter.default)(function () {
      return Ember.get(this, 'group.activities.firstObject');
    }),
    follow: (0, _getter.default)(function () {
      return Ember.get(this, 'activity.subject');
    }),
    actions: {
      trackEngagement: function trackEngagement(label) {
        var data = {
          label: label,
          content: {
            foreign_id: "Follow:".concat(Ember.get(this, 'follow.id'))
          },
          position: Ember.get(this, 'positionInFeed') || 0
        };

        if (Ember.get(this, 'feedId') !== undefined) {
          data.feed_id = Ember.get(this, 'feedId');
        }

        Ember.get(this, 'metrics').invoke('trackEngagement', 'Stream', data);
      },
      deleteActivity: function deleteActivity() {
        var _this = this;

        (0, _emberInvokeAction.strictInvokeAction)(this, 'deleteActivity', 'user_aggr', Ember.get(this, 'activity'), function () {
          (0, _emberInvokeAction.strictInvokeAction)(_this, 'removeGroup', Ember.get(_this, 'group'));
        });
      }
    }
  });

  _exports.default = _default;
});