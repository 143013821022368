define("client/controllers/groups/group/dashboard/tickets", ["exports", "ember-parachute"], function (_exports, _emberParachute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var queryParams = new _emberParachute.default({
    filter: {
      defaultValue: 'open'
    },
    query: {
      defaultValue: ''
    }
  });

  var _default = Ember.Controller.extend(queryParams.Mixin, {
    group: Ember.computed.alias('model.group'),
    actions: {
      updateQueryParam: function updateQueryParam(property, value) {
        Ember.set(this, property, value);
      }
    }
  });

  _exports.default = _default;
});