define("client/models/group-ticket-message", ["exports", "client/models/-base", "ember-data/attr", "ember-data/relationships"], function (_exports, _base, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    content: (0, _attr.default)('string'),
    createdAt: (0, _attr.default)('utc'),
    kind: (0, _attr.default)('string'),
    ticket: (0, _relationships.belongsTo)('group-ticket', {
      inverse: 'messages'
    }),
    user: (0, _relationships.belongsTo)('user')
  });

  _exports.default = _default;
});