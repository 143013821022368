define("client/components/stream-feed/items/library-entry", ["exports", "client/utils/getter", "ember-invoke-action", "client/utils/moment"], function (_exports, _getter, _emberInvokeAction, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['stream-item', 'row'],
    isDescriptionExpanded: false,
    hasMoreActivities: false,
    showAll: false,
    activityLimit: 2,
    metrics: Ember.inject.service(),
    media: (0, _getter.default)(function () {
      return Ember.get(this, 'group.activities.firstObject.media');
    }),
    groupByTime: (0, _getter.default)(function () {
      return Ember.get(this, 'showAll') === true ? Ember.get(this, 'groupByTimeAll') : Ember.get(this, 'groupByTimeLimited');
    }),
    groupByTimeLimited: Ember.computed('groupByTimeAll', function () {
      var groups = Ember.get(this, 'groupByTimeAll');
      var activityCount = Ember.get(this, 'group.activities.length');
      var result = groups.slice(0, 1);

      if (activityCount > Ember.get(this, 'activityLimit')) {
        Ember.set(this, 'hasMoreActivities', true);
      }

      return result;
    }),
    groupByTimeAll: Ember.computed('group.activities.@each.isDeleted', function () {
      var _this = this;

      var temp = {};
      var activities = Ember.get(this, 'group.activities').toArray().sort(function (a, b) {
        if (Ember.get(a, 'time').isBefore(Ember.get(b, 'time'))) {
          return 1;
        }

        if (Ember.get(a, 'time').isAfter(Ember.get(b, 'time'))) {
          return -1;
        }

        return 0;
      });
      activities.forEach(function (activity) {
        var time = (0, _moment.momentUser)(_this, Ember.get(activity, 'time'));
        var calendar = time.calendar(null, {
          sameDay: '[Today]',
          lastDay: '[Yesterday]',
          lastWeek: 'dddd',
          sameElse: 'dddd'
        });
        var key = "".concat(calendar, "-").concat(time.format('DD'));

        if (Ember.get(temp, key) === undefined) {
          temp[key] = {
            calendar: calendar,
            date: time.format('MMM Do'),
            activities: []
          };
        }

        temp[key].activities.push(activity);
      });
      return Object.values(temp);
    }),
    actions: {
      removeGroup: function removeGroup() {
        (0, _emberInvokeAction.invokeAction)(this, 'removeGroup', Ember.get(this, 'group'));
      },
      trackEngagement: function trackEngagement(label) {
        var data = {
          label: label,
          content: {
            foreign_id: Ember.get(this, 'group.activities.firstObject.foreignId')
          },
          position: Ember.get(this, 'positionInFeed') || 0
        };

        if (Ember.get(this, 'feedId') !== undefined) {
          data.feed_id = Ember.get(this, 'feedId');
        }

        Ember.get(this, 'metrics').invoke('trackEngagement', 'Stream', data);
      }
    }
  });

  _exports.default = _default;
});