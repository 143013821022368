define("client/models/group", ["exports", "client/models/-base", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _base, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Validations = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 3,
      max: 50
    })],
    privacy: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('inclusion', {
      in: ['open', 'closed', 'restricted']
    })],
    tagline: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      max: 60
    })],
    category: [(0, _emberCpValidations.validator)('presence', true)]
  });
  _exports.Validations = Validations;

  var _default = _base.default.extend(Validations, {
    about: (0, _attr.default)('string'),
    avatar: (0, _attr.default)('object', {
      defaultValue: '/images/default_avatar.png'
    }),
    coverImage: (0, _attr.default)('object', {
      defaultValue: '/images/default_cover.png'
    }),
    leadersCount: (0, _attr.default)('number'),
    locale: (0, _attr.default)('string'),
    membersCount: (0, _attr.default)('number'),
    name: (0, _attr.default)('string'),
    neighborsCount: (0, _attr.default)('number'),
    nsfw: (0, _attr.default)('boolean'),
    privacy: (0, _attr.default)('string', {
      defaultValue: 'open'
    }),
    rules: (0, _attr.default)('string'),
    rulesFormatted: (0, _attr.default)('string'),
    slug: (0, _attr.default)('string'),
    tagline: (0, _attr.default)('string'),
    category: (0, _relationships.belongsTo)('group-category', {
      async: false
    }),
    categoryHack: (0, _attr.default)('boolean'),
    actionLogs: (0, _relationships.hasMany)('group-action-log', {
      inverse: 'group'
    }),
    invites: (0, _relationships.hasMany)('group-invite', {
      inverse: 'group'
    }),
    members: (0, _relationships.hasMany)('group-member', {
      inverse: 'group'
    }),
    neighbors: (0, _relationships.hasMany)('group-neighbor', {
      inverse: 'source'
    }),
    reports: (0, _relationships.hasMany)('group-report', {
      inverse: 'group'
    }),
    tickets: (0, _relationships.hasMany)('group-ticket', {
      inverse: 'group'
    }),
    isOpen: Ember.computed.equal('privacy', 'isOpen'),
    isClosed: Ember.computed.equal('privacy', 'closed'),
    isRestricted: Ember.computed.equal('privacy', 'restricted')
  });

  _exports.default = _default;
});