define("client/instance-initializers/sw", ["exports", "ember-cli-workbox/instance-initializers/sw"], function (_exports, _sw) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _sw.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function get() {
      return _sw.initialize;
    }
  });
});