define("client/templates/components/search-results", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ss3dhfN/",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"isOpened\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[9],[0,\" \"],[0,\"\\n\"],[4,\"ember-tether\",null,[[\"class\",\"target\",\"attachment\",\"targetAttachment\",\"offset\"],[[25,[\"tooltipClass\"]],[25,[\"target\"]],[29,\"or\",[[25,[\"attachment\"]],\"top right\"],null],[29,\"or\",[[25,[\"targetAttachment\"]],\"bottom right\"],null],[29,\"or\",[[25,[\"offset\"]],\"-30px 0px\"],null]]],{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"search--drop\"],[9],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"search--header\"],[9],[1,[29,\"t\",[\"components.search.header\"],null],false],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"style\",\"overflow: auto\"],[9],[0,\"\\n\"],[0,\"        \"],[1,[29,\"search-results/group\",null,[[\"group\",\"items\",\"isLoading\",\"onPagination\",\"close\"],[\"media\",[25,[\"groups\",\"media\"]],[25,[\"mediaTask\",\"isRunning\"]],[29,\"perform\",[[25,[\"nextPageTask\"]],\"media\"],null],[29,\"action\",[[24,0,[]],\"close\"],null]]]],false],[0,\"\\n\"],[0,\"        \"],[1,[29,\"search-results/group\",null,[[\"group\",\"items\",\"isLoading\",\"moreLink\",\"close\"],[\"groups\",[25,[\"groups\",\"groups\"]],[25,[\"groupsTask\",\"isRunning\"]],[29,\"href-to\",[\"groups.index\",[29,\"query-params\",null,[[\"query\"],[[25,[\"query\"]]]]]],null],[29,\"action\",[[24,0,[]],\"close\"],null]]]],false],[0,\"\\n\"],[0,\"        \"],[1,[29,\"search-results/group\",null,[[\"group\",\"items\",\"isLoading\",\"onPagination\",\"close\"],[\"users\",[25,[\"groups\",\"users\"]],[25,[\"usersTask\",\"isRunning\"]],[29,\"perform\",[[25,[\"nextPageTask\"]],\"users\"],null],[29,\"action\",[[24,0,[]],\"close\"],null]]]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/search-results.hbs"
    }
  });

  _exports.default = _default;
});