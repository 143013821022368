define("client/templates/feedback/mobile-features", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U/8Tq4l2",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"w-100\"],[11,\"data-canny\",\"\"],[9],[10],[0,\"\\n\"],[1,[29,\"canny-feedback-script\",null,[[\"token\",\"boardToken\",\"path\"],[[25,[\"model\",\"token\"]],\"fed94acf-1df6-dda7-fdd0-7c86cb41eb63\",\"/feedback/mobile-features\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/feedback/mobile-features.hbs"
    }
  });

  _exports.default = _default;
});