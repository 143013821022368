define("client/routes/confirm-email", ["exports", "client/utils/error-messages"], function (_exports, _errorMessages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    notify: Ember.inject.service(),
    beforeModel: function beforeModel(_ref) {
      var _this = this;

      var queryParams = _ref.queryParams;
      var token = queryParams.token;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.get(_this, 'ajax').request('/users?filter[self]=true', {
          headers: {
            Authorization: "Bearer ".concat(token)
          }
        }).then(function (response) {
          return resolve(response);
        }).catch(function (err) {
          return reject(err);
        });
      }).then(function (response) {
        if (Ember.isEmpty(Ember.get(response, 'data')) === true) {
          Ember.get(_this, 'notify').error('The token is either invalid or expired.');

          _this.transitionTo('dashboard');
        } else {
          var user = Ember.get(response, 'data.firstObject');
          return Ember.get(_this, 'ajax').request("/users/".concat(Ember.get(user, 'id')), {
            method: 'PATCH',
            headers: {
              Authorization: "Bearer ".concat(token)
            },
            data: JSON.stringify({
              data: {
                type: 'users',
                id: Ember.get(user, 'id'),
                attributes: {
                  confirmed: true
                }
              }
            }),
            contentType: 'application/vnd.api+json'
          }).then(function () {
            Ember.get(_this, 'notify').success('You\'ve successfully confirmed your email address!', {
              closeAfter: 5000
            });

            _this.transitionTo('dashboard');
          }).catch(function (err) {
            return Ember.get(_this, 'notify').error((0, _errorMessages.default)(err));
          });
        }
      }).catch(function (err) {
        return Ember.get(_this, 'notify').error((0, _errorMessages.default)(err));
      });
    }
  });

  _exports.default = _default;
});