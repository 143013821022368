define("client/templates/components/search-results/row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ejV10JDE",
    "block": "{\"symbols\":[\"tag\"],\"statements\":[[7,\"a\"],[11,\"class\",\"media col-sm-12\"],[12,\"href\",[29,\"href-to\",[[25,[\"route\"]],[25,[\"slug\"]]],null]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"media-left col-sm-2 no-padding-left\"],[9],[0,\"\\n    \"],[1,[29,\"lazy-image\",null,[[\"src\",\"imgClass\"],[[29,\"image\",[[25,[\"imageUrl\"]],\"small\"],null],[25,[\"imageClass\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"media-body col-sm no-padding-left\"],[9],[0,\"\\n    \"],[7,\"p\"],[9],[1,[23,\"title\"],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"each\",[[25,[\"tags\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"tag tag-default\"],[9],[1,[29,\"t\",[[24,1,[]]],null],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/search-results/row.hbs"
    }
  });

  _exports.default = _default;
});