define("client/routes/anime/show/reactions", ["exports", "client/routes/media/show/reactions"], function (_exports, _reactions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _reactions.default.extend();

  _exports.default = _default;
});