define("client/templates/components/modals/confirm-action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "58xvXKB7",
    "block": "{\"symbols\":[\"modal\",\"&default\"],\"statements\":[[4,\"bootstrap/bs-modal\",null,[[\"id\",\"onClose\"],[[25,[\"modalId\"]],[25,[\"onClose\"]]]],{\"statements\":[[4,\"component\",[[29,\"-assert-implicit-component-helper-argument\",[[24,1,[\"body\"]],\"expected `modal.body` to be a contextual component but found a string. Did you mean `(component modal.body)`? ('client/templates/components/modals/confirm-action.hbs' @ L2:C5) \"],null]],null,{\"statements\":[[4,\"if\",[[26,2]],null,{\"statements\":[[0,\"      \"],[15,2],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"h4\"],[11,\"class\",\"text-xs-center\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"title\"]]],null,{\"statements\":[[0,\"          \"],[1,[23,\"title\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[1,[29,\"t\",[\"reaction-modal.modals.confirm.check\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"text\"]]],null,{\"statements\":[[0,\"        \"],[7,\"p\"],[11,\"class\",\"text-xs\"],[9],[0,\"\\n          \"],[1,[23,\"text\"],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[29,\"-assert-implicit-component-helper-argument\",[[24,1,[\"footer\"]],\"expected `modal.footer` to be a contextual component but found a string. Did you mean `(component modal.footer)`? ('client/templates/components/modals/confirm-action.hbs' @ L21:C5) \"],null]],null,{\"statements\":[[0,\"    \"],[7,\"button\"],[11,\"class\",\"button button--primary btn-lg btn-block\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"onConfirm\"],null]],[9],[0,\"\\n      \"],[1,[29,\"t\",[\"reaction-modal.modals.confirm.button\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/modals/confirm-action.hbs"
    }
  });

  _exports.default = _default;
});