define("client/models/activity", ["exports", "client/models/-base", "ember-data/attr", "ember-data/relationships"], function (_exports, _base, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    foreignId: (0, _attr.default)('string'),
    mentionedUsers: (0, _attr.default)('array'),
    nineteenScale: (0, _attr.default)('boolean'),
    postId: (0, _attr.default)('number'),
    progress: (0, _attr.default)('number'),
    rating: (0, _attr.default)('rating'),
    reason: (0, _attr.default)('string'),
    replyToType: (0, _attr.default)('string'),
    replyToUser: (0, _attr.default)('string'),
    status: (0, _attr.default)('string'),
    streamId: (0, _attr.default)('string'),
    time: (0, _attr.default)('utc'),
    verb: (0, _attr.default)('string'),
    actor: (0, _relationships.belongsTo)('user'),
    media: (0, _relationships.belongsTo)('media'),
    subject: (0, _relationships.belongsTo)('-base'),
    target: (0, _relationships.belongsTo)('-base'),
    unit: (0, _relationships.belongsTo)('-base')
  });

  _exports.default = _default;
});