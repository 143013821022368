define("client/components/search-results/group", ["exports", "ember-invoke-action"], function (_exports, _emberInvokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    page: 0,
    router: Ember.inject.service(),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var items = Ember.get(this, 'items');
      var pages = Ember.get(items, 'nbPages');
      var hasNextPage = pages > 1 && Ember.get(this, 'page') !== pages - 1;
      Ember.set(this, 'hasNextPage', hasNextPage);
    },
    actions: {
      onPagination: function onPagination() {
        var _this = this;

        Ember.set(this, 'isLoadingMore', true);
        var page = Ember.get(this, 'page') + 1;
        (0, _emberInvokeAction.invokeAction)(this, 'onPagination', page).then(function () {
          Ember.set(_this, 'page', page);
          var hasNextPage = page !== Ember.get(_this, 'items.nbPages') - 1;
          Ember.set(_this, 'hasNextPage', hasNextPage);
        }).finally(function () {
          Ember.set(_this, 'isLoadingMore', false);
        });
      },
      transitionTo: function transitionTo(item) {
        (0, _emberInvokeAction.invokeAction)(this, 'close');

        if (Ember.typeOf(item) === 'string') {
          Ember.get(this, 'router').transitionTo(item);
        } else {
          var type = Ember.get(item, 'kind');

          if (type === 'user') {
            Ember.get(this, 'router').transitionTo('users.index', Ember.get(item, 'slug'));
          } else if (type === 'group') {
            Ember.get(this, 'router').transitionTo('groups.group.group-page.index', Ember.get(item, 'slug'));
          } else {
            Ember.get(this, 'router').transitionTo("".concat(type, ".show"), Ember.get(item, 'slug'));
          }
        }
      }
    }
  });

  _exports.default = _default;
});