define("client/templates/admin/reports", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BXT8g24L",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"settings--header\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n      \"],[7,\"h2\"],[9],[1,[29,\"t\",[\"admin.reports.header\"],null],false],[10],[0,\"\\n      \"],[7,\"ul\"],[11,\"class\",\"settings--navigation\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"admin.reports.index\"],[[\"tagName\",\"class\"],[\"li\",\"list-item\"]],{\"statements\":[[0,\"          \"],[7,\"a\"],[12,\"href\",[29,\"href-to\",[\"admin.reports.index\"],null]],[9],[1,[29,\"t\",[\"admin.reports.open\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"link-to\",[\"admin.reports.closed\"],[[\"tagName\",\"class\"],[\"li\",\"list-item\"]],{\"statements\":[[0,\"          \"],[7,\"a\"],[12,\"href\",[29,\"href-to\",[\"admin.reports.closed\"],null]],[9],[1,[29,\"t\",[\"admin.reports.closed\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"container user-reports\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[1,[23,\"outlet\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/admin/reports.hbs"
    }
  });

  _exports.default = _default;
});