define("client/templates/components/application/nav-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "md9bhftE",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\"],[11,\"for\",\"search\"],[11,\"class\",\"site-search__icon\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"icon\"],[9],[0,\"\\n    \"],[1,[29,\"svg-jar\",[\"search\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[3,\"action\",[[24,0,[]],[29,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"isOpened\"]]],null],true],null]]],[10],[0,\"\\n\\n\"],[1,[29,\"one-way-input\",[[25,[\"query\"]]],[[\"update\",\"id\",\"class\",\"placeholder\",\"autocomplete\"],[[29,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"query\"]]],null]],null],\"search\",[25,[\"inputClass\"]],[29,\"t\",[\"components.application.nav-search\"],null],\"off\"]]],false],[0,\"\\n\\n\"],[1,[29,\"search-results\",null,[[\"tooltipClass\",\"target\",\"query\",\"isOpened\",\"onClose\"],[\"navbar-search-results\",[29,\"concat\",[\"#\",[25,[\"elementId\"]]],null],[25,[\"query\"]],[29,\"and\",[[25,[\"isOpened\"]],[29,\"gt\",[[25,[\"query\",\"length\"]],0],null]],null],[29,\"action\",[[24,0,[]],\"close\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/application/nav-search.hbs"
    }
  });

  _exports.default = _default;
});