define("client/templates/components/stream-feed/items/post/uploads-grid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uyPcZJyx",
    "block": "{\"symbols\":[\"photoswipe\",\"img\",\"index\"],\"statements\":[[4,\"photo-swipe\",null,[[\"bgOpacity\",\"showAnimationDuration\",\"hideAnimationDuration\",\"history\",\"items\",\"class\"],[0.92,0,0,false,[25,[\"galleryItems\"]],[29,\"concat\",[\"stream-content-post-uploads \",[25,[\"gridStyle\"]]],null]]],{\"statements\":[[4,\"each\",[[29,\"take\",[5,[25,[\"galleryItems\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"a\"],[12,\"href\",[30,[[24,2,[\"src\"]]]]],[9],[0,\"\\n      \"],[1,[29,\"lazy-image\",null,[[\"src\"],[[24,2,[\"thumbSrc\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[29,\"and\",[[29,\"gt\",[[25,[\"galleryItems\",\"length\"]],5],null],[29,\"eq\",[[24,3,[]],4],null]],null]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"uploads-overlay uploads-more\"],[9],[0,\"+\"],[1,[29,\"dec\",[4,[25,[\"galleryItems\",\"length\"]]],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[29,\"eq\",[[24,2,[\"type\"]],\"image/gif\"],null]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"uploads-overlay uploads-gif\"],[9],[0,\"GIF\"],[10],[0,\"\\n      \"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"    \"],[3,\"action\",[[24,0,[]],[24,1,[\"actions\",\"open\"]],[29,\"hash\",null,[[\"index\",\"shareEl\",\"shareButtons\"],[[24,3,[]],true,[29,\"array\",[[29,\"hash\",null,[[\"id\",\"label\",\"url\",\"download\"],[\"download\",\"Download Image\",\"{{raw_image_url}}\",true]]]],null]]]]]],[10],[0,\"\\n\"]],\"parameters\":[2,3]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/stream-feed/items/post/uploads-grid.hbs"
    }
  });

  _exports.default = _default;
});