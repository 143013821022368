define("client/components/groups/group-list", ["exports", "ember-invoke-action"], function (_exports, _emberInvokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    categoryRoute: 'groups.index',
    sortOptions: [{
      key: 'featured',
      value: 'group-list.sort.featured'
    }, {
      key: 'recent',
      value: 'group-list.sort.recent'
    }, {
      key: 'newest',
      value: 'group-list.sort.newest'
    }, {
      key: 'oldest',
      value: 'group-list.sort.oldest'
    }],
    activeSort: Ember.computed('sort', function () {
      var _this = this;

      return Ember.get(this, 'sortOptions').find(function (option) {
        return Ember.get(option, 'key') === Ember.get(_this, 'sort');
      });
    }).readOnly(),
    actions: {
      updateQueryParam: function updateQueryParam(property, value) {
        (0, _emberInvokeAction.invokeAction)(this, 'updateQueryParam', property, value);
      },
      onPagination: function onPagination() {
        return (0, _emberInvokeAction.invokeAction)(this, 'onPagination');
      }
    }
  });

  _exports.default = _default;
});