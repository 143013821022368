define("client/routes/anime/show", ["exports", "client/routes/media/show"], function (_exports, _show) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _show.default.extend({
    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);

      var type = 'video.other';

      if (Ember.get(model, 'subtype') === 'TV') {
        type = 'video.tv_show';
      } else if (Ember.get(model, 'subtype') === 'movie') {
        type = 'video.movie';
      }

      var tags = this.setHeadTags(model);
      tags.push({
        type: 'meta',
        tagId: 'meta-og-type',
        attrs: {
          property: 'og:type',
          content: type
        }
      });
      Ember.set(this, 'headTags', tags);
    },
    _schemaData: function _schemaData(model) {
      var data = this._super.apply(this, arguments);

      data['@type'] = 'TVSeries';

      if (Ember.get(model, 'episodeCount')) {
        data.numberOfEpisodes = Ember.get(model, 'episodeCount');
      }

      return data;
    }
  });

  _exports.default = _default;
});