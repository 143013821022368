define("client/abilities/admin", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend({
    canView: Ember.computed('session.hasUser', 'model', function () {
      if (!Ember.get(this, 'session.hasUser')) {
        return false;
      }

      return Ember.get(this, 'session.account').hasRole('admin', Ember.get(this, 'model'));
    }).readOnly()
  });

  _exports.default = _default;
});