define("client/templates/components/image-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r/+LIFyp",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"card image-card\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"card-block\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"image\"]]],null,{\"statements\":[[0,\"      \"],[7,\"img\"],[12,\"src\",[29,\"image\",[[25,[\"image\"]]],null]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[29,\"svg-jar\",[\"image\"],null],false],[0,\"\\n      \"],[7,\"p\"],[9],[1,[29,\"t\",[\"image-form.help-text\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"],[3,\"action\",[[24,0,[]],\"triggerFileSelect\"]],[10],[0,\"\\n\\n\"],[1,[29,\"file-select\",null,[[\"update\"],[[29,\"action\",[[24,0,[]],\"selectedImage\"],null]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"showCropperModal\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"modal\",[29,\"component\",[\"modals/image-cropper\"],[[\"image\",\"aspectRatio\",\"update\",\"onClose\"],[[25,[\"dataURI\"]],[25,[\"aspectRatio\"]],[29,\"action\",[[24,0,[]],\"updateImage\"],null],[29,\"action\",[[24,0,[]],\"closeModal\"],null]]]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/image-form.hbs"
    }
  });

  _exports.default = _default;
});