define("client/templates/components/users/view-past-names", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5S7Fv3pB",
    "block": "{\"symbols\":[\"modal\",\"name\"],\"statements\":[[4,\"bootstrap/bs-modal\",null,[[\"id\",\"onClose\"],[[25,[\"modalId\"]],[25,[\"onClose\"]]]],{\"statements\":[[4,\"component\",[[29,\"-assert-implicit-component-helper-argument\",[[24,1,[\"header\"]],\"expected `modal.header` to be a contextual component but found a string. Did you mean `(component modal.header)`? ('client/templates/components/users/view-past-names.hbs' @ L2:C5) \"],null]],null,{\"statements\":[[0,\"    \"],[7,\"h3\"],[11,\"class\",\"text-xs-center\"],[9],[0,\"\\n      \"],[1,[29,\"t\",[\"components.users.past-name.title\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[29,\"-assert-implicit-component-helper-argument\",[[24,1,[\"body\"]],\"expected `modal.body` to be a contextual component but found a string. Did you mean `(component modal.body)`? ('client/templates/components/users/view-past-names.hbs' @ L8:C5) \"],null]],null,{\"statements\":[[0,\"    \"],[7,\"ul\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"user\",\"pastNames\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\"],[9],[1,[24,2,[]],false],[10],[0,\"\\n\"]],\"parameters\":[2]},{\"statements\":[[0,\"        \"],[7,\"li\"],[9],[7,\"i\"],[9],[1,[29,\"t\",[\"components.users.past-name.none\"],null],false],[10],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/users/view-past-names.hbs"
    }
  });

  _exports.default = _default;
});