define("client/templates/components/groups/group-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5pigqBZN",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"stream-item-options\"],[9],[0,\"\\n\"],[0,\"  \"],[7,\"span\"],[11,\"class\",\"more-wrapper\"],[9],[0,\"\\n    \"],[7,\"a\"],[11,\"href\",\"#\"],[11,\"class\",\"more-drop\"],[11,\"data-toggle\",\"dropdown\"],[11,\"aria-haspopup\",\"true\"],[11,\"aria-expanded\",\"false\"],[9],[0,\"\\n      \"],[1,[29,\"svg-jar\",[\"more\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"dropdown-menu dropdown-menu-right\"],[9],[0,\"\\n\"],[0,\"      \"],[7,\"a\"],[11,\"class\",\"dropdown-item\"],[11,\"href\",\"#\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"membership\",\"hidden\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\"],[9],[1,[29,\"t\",[\"groups.show-posts.text\"],[[\"group\"],[[25,[\"group\",\"name\"]]]]],false],[10],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"option-info\"],[9],[1,[29,\"t\",[\"groups.show-posts.info\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[7,\"div\"],[9],[1,[29,\"t\",[\"groups.hide-posts.text\"],[[\"group\"],[[25,[\"group\",\"name\"]]]]],false],[10],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"option-info\"],[9],[1,[29,\"t\",[\"groups.hide-posts.info\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[3,\"action\",[[24,0,[]],\"editHidden\",[29,\"not\",[[25,[\"membership\",\"hidden\"]]],null]]],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/groups/group-actions.hbs"
    }
  });

  _exports.default = _default;
});