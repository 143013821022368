define("client/templates/groups/group/dashboard/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "k7gSnzQY",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"groups/dashboard/overview-stats\",null,[[\"stats\",\"class\"],[[25,[\"stats\"]],\"stats-container card-columns\"]]],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"latest-cards card-columns\"],[9],[0,\"\\n  \"],[1,[29,\"groups/dashboard/latest-tickets\",null,[[\"group\"],[[25,[\"group\"]]]]],false],[0,\"\\n  \"],[1,[29,\"groups/dashboard/latest-reports\",null,[[\"group\"],[[25,[\"group\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[29,\"groups/dashboard/audit-log\",null,[[\"group\"],[[25,[\"group\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/groups/group/dashboard/index.hbs"
    }
  });

  _exports.default = _default;
});