define("client/mixins/routes/slide-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    activate: function activate() {
      this._super.apply(this, arguments);

      document.body.classList.add('slide-header');
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);

      document.body.classList.remove('slide-header');
    }
  });

  _exports.default = _default;
});