define("client/components/library-entry/rating-button", ["exports", "ember-invoke-action"], function (_exports, _emberInvokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    swapToDropdown: false,
    showDropdown: false,
    rating: 1,
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'guid', Ember.guidFor(this));
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments); // Show dropdown by default if we have a rating


      if (Ember.get(this, 'swapToDropdown') && 'rating' in this.attrs) {
        Ember.set(this, 'showDropdown', !!Ember.get(this, 'rating'));
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this._onBodyClick) {
        document.body.removeEventListener('click', this._onBodyClick);
      }
    },
    actions: {
      toggleTether: function toggleTether() {
        var _this = this;

        if (!this._onBodyClick) {
          Ember.run.scheduleOnce('afterRender', function () {
            _this._onBodyClick = function (_ref) {
              var target = _ref.target;
              var elementId = Ember.get(_this, 'guid');
              var isChildElement = target.matches(".rating-button-".concat(elementId, " *, .rating-button-").concat(elementId));
              var isTetherElement = target.matches('.rating-tether *, .rating-tether');
              var isDropdownElement = target.matches('.rating-button-dropdown-menu *, .rating-button-dropdown-menu');

              if (!isChildElement && !isTetherElement && !isDropdownElement) {
                Ember.set(_this, 'showTooltip', false);
              }
            };

            document.body.addEventListener('click', _this._onBodyClick);
          });
        }

        this.toggleProperty('showTooltip');
      },
      ratingSelected: function ratingSelected(rating) {
        if (Ember.get(this, 'swapToDropdown')) {
          Ember.set(this, 'showDropdown', !!rating);
        }

        (0, _emberInvokeAction.invokeAction)(this, 'onClick', rating);
        Ember.set(this, 'showTooltip', false);
      }
    }
  });

  _exports.default = _default;
});