define("client/routes/media/show", ["exports", "client/mixins/routes/canonical-redirect", "client/mixins/routes/cover-page"], function (_exports, _canonicalRedirect, _coverPage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Route.extend(_canonicalRedirect.default, _coverPage.default, {
    templateName: 'media/show',
    head: Ember.inject.service('head-data'),
    queryCache: Ember.inject.service(),
    model: function model(_ref) {
      var slug = _ref.slug;

      var _Ember$get$split = Ember.get(this, 'routeName').split('.'),
          _Ember$get$split2 = _slicedToArray(_Ember$get$split, 1),
          type = _Ember$get$split2[0];

      var include = ['categories'];

      if (type === 'anime') {
        include.push('animeProductions.producer');
      }

      include = include.join(',');

      if (slug.match(/\D+/)) {
        return Ember.get(this, 'queryCache').query(type, {
          filter: {
            slug: slug
          },
          fields: {
            categories: 'slug,title'
          },
          include: include
        }).then(function (records) {
          return Ember.get(records, 'firstObject');
        });
      }

      return Ember.get(this, 'store').findRecord(type, slug, {
        include: include,
        reload: true
      });
    },
    afterModel: function afterModel(model) {
      if (model) {
        Ember.set(this, 'breadcrumb', Ember.get(model, 'canonicalTitle'));
      }
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments); // add structured data for this route


      var head = Ember.get(this, 'head');
      var data = this.setStructuredData(model);
      Ember.set(head, 'structuredData.media-show', data);
    },
    serialize: function serialize(model) {
      return {
        slug: Ember.get(model, 'slug')
      };
    },
    titleToken: function titleToken(model) {
      return Ember.get(model, 'computedTitle');
    },

    /**
     * Meta tags with ember-cli-meta-tags
     *
     * @param {Object} model
     * @returns {Array}
     */
    setHeadTags: function setHeadTags(model) {
      var data = [{
        type: 'meta',
        tagId: 'meta-description',
        attrs: {
          name: 'description',
          content: Ember.get(model, 'description')
        }
      }, {
        type: 'meta',
        tagId: 'meta-og-title',
        attrs: {
          property: 'og:title',
          content: Ember.get(model, 'canonicalTitle')
        }
      }, {
        type: 'meta',
        tagId: 'meta-og-description',
        attrs: {
          property: 'og:description',
          content: Ember.get(model, 'description')
        }
      }, {
        type: 'meta',
        tagId: 'meta-og-image',
        attrs: {
          property: 'og:image',
          content: Ember.get(model, 'posterImage.tiny')
        }
      }];

      if (Ember.get(model, 'averageRating')) {
        data.push({
          type: 'meta',
          tagId: 'meta-twitter-label1',
          attrs: {
            property: 'twitter:label1',
            content: 'Average rating'
          }
        }, {
          type: 'meta',
          tagId: 'meta-twitter-data1',
          attrs: {
            property: 'twitter:data1',
            content: "".concat(Ember.get(model, 'averageRating'), "%")
          }
        }, {
          type: 'meta',
          tagId: 'meta-twitter-label2',
          attrs: {
            property: 'twitter:label2',
            content: 'Total ratings'
          }
        }, {
          type: 'meta',
          tagId: 'meta-twitter-data2',
          attrs: {
            property: 'twitter:data2',
            content: Ember.get(model, 'totalRatings').toLocaleString()
          }
        });
      }

      return data;
    },

    /**
     * Structured data with ember-cli-head
     *
     * @param {Object} model
     * @returns {Object}
     */
    setStructuredData: function setStructuredData(model) {
      var data = {
        '@context': 'http://schema.org',
        '@type': 'CreativeWorkSeries',
        name: Object.values(Ember.get(model, 'titles')).uniq(),
        description: Ember.get(model, 'description'),
        image: Ember.get(model, 'posterImage.large'),
        genre: Ember.get(model, 'categories').mapBy('title')
      };

      if (Ember.get(model, 'averageRating')) {
        data.aggregateRating = {
          '@type': 'AggregateRating',
          bestRating: 100,
          worstRating: 5,
          ratingValue: Ember.get(model, 'averageRating'),
          ratingCount: Ember.get(model, 'totalRatings')
        };
      }

      if (Ember.get(model, 'startDate')) {
        data.startDate = Ember.get(model, 'startDate').format('YYYY-MM-DD');
      }

      if (Ember.get(model, 'endDate')) {
        data.endDate = Ember.get(model, 'endDate').format('YYYY-MM-DD');
      }

      return data;
    }
  });

  _exports.default = _default;
});