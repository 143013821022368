define("client/routes/groups/group/group-page/members", ["exports", "kitsu-shared/mixins/pagination"], function (_exports, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_pagination.default, {
    intl: Ember.inject.service(),
    model: function model() {
      var model = this.modelFor('groups.group.group-page');
      return {
        taskInstance: this.queryPaginated('group-member', this._getRequestOptions(model)),
        paginatedRecords: []
      };
    },
    afterModel: function afterModel(model) {
      var tags = this.setHeadTags(model);
      Ember.set(this, 'headTags', tags);
    },
    titleToken: function titleToken() {
      var model = this.modelFor('groups.group.group-page');
      var group = Ember.get(model, 'group.name');
      return Ember.get(this, 'intl').t('titles.groups.group.group-page.members', {
        group: group
      });
    },
    setHeadTags: function setHeadTags(model) {
      var description = "Group members of ".concat(Ember.get(model, 'group.name'), ".\n      ").concat(Ember.get(model, 'group.tagline'));
      return [{
        type: 'meta',
        tagId: 'meta-description',
        attrs: {
          property: 'description',
          content: description
        }
      }, {
        type: 'meta',
        tagId: 'meta-og-description',
        attrs: {
          property: 'og:description',
          content: description
        }
      }];
    },
    _getRequestOptions: function _getRequestOptions(model) {
      return {
        include: 'user',
        filter: {
          query_group: Ember.get(model, 'group.id')
        },
        fields: {
          users: ['avatar', 'coverImage', 'name', 'slug'].join(',')
        },
        page: {
          limit: 20
        }
      };
    }
  });

  _exports.default = _default;
});