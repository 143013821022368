define("client/components/users/library/old-library/library-entries", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    // Returns the intl version of our status
    statusName: Ember.computed('status', function () {
      var status = Ember.get(this, 'status');
      var type = Ember.get(this, 'mediaType');
      return Ember.get(this, 'intl').t("library-shared.".concat(status), {
        type: type
      });
    }),
    // Displays the number of entries within this section
    stats: Ember.computed('metaCount', function () {
      var count = Ember.get(this, 'metaCount') || 0;
      var text = count === 1 ? 'title' : 'titles';
      return count ? "".concat(count, " ").concat(text) : '';
    })
  });

  _exports.default = _default;
});