define("client/routes/groups/new", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    authenticationRoute: 'dashboard',
    intl: Ember.inject.service(),
    notify: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        group: Ember.get(this, 'store').createRecord('group'),
        categories: Ember.get(this, 'store').query('group-category', {})
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      Ember.set(controller, 'selectedCategory', null);
    },
    actions: {
      createGroup: function createGroup() {
        var _this = this;

        var controller = this.controllerFor(Ember.get(this, 'routeName'));
        var group = Ember.get(controller, 'group');
        Ember.set(controller, 'isSaving', true);
        return group.save().then(function () {
          _this.transitionTo('groups.group.group-page', Ember.get(group, 'slug'));
        }).catch(function () {
          Ember.get(_this, 'notify').error(Ember.get(_this, 'intl').t('errors.request'));
        }).finally(function () {
          Ember.set(controller, 'isSaving', false);
        });
      }
    }
  });

  _exports.default = _default;
});