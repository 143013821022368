define("client/templates/components/bootstrap/bs-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YquiCg6U",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1,[[29,\"hash\",null,[[\"button\",\"menu\",\"menu-item\"],[[29,\"component\",[\"bootstrap/bs-dropdown-button\"],[[\"id\"],[[29,\"concat\",[[25,[\"elementId\"]],\"-dropdown\"],null]]]],[29,\"component\",[\"bootstrap/bs-dropdown-menu\"],[[\"aria-labelledby\"],[[29,\"concat\",[[25,[\"elementId\"]],\"-dropdown\"],null]]]],[29,\"component\",[\"bootstrap/bs-dropdown-item\"],null]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/bootstrap/bs-dropdown.hbs"
    }
  });

  _exports.default = _default;
});