define("client/components/groups/group-form-field", ["exports", "ember-invoke-action"], function (_exports, _emberInvokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'label',
    classNames: ['field-wrapper'],
    mouseEnter: function mouseEnter() {
      (0, _emberInvokeAction.invokeAction)(this, 'onHover');
    }
  });

  _exports.default = _default;
});