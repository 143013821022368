define("client/templates/components/media/media-rating", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "47VMAOzf",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[11,\"class\",\"media-rating\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"media\",\"averageRating\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[12,\"class\",[30,[\"media-community-rating \",[23,\"percentageClass\"]]]],[9],[0,\"\\n      \"],[1,[29,\"t\",[\"media.show.summary.ratings.community\"],[[\"rating\"],[[29,\"truncate\",[[25,[\"media\",\"averageRating\"]],2],null]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/media/media-rating.hbs"
    }
  });

  _exports.default = _default;
});