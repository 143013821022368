define("client/serializers/feed", ["exports", "client/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload) {
      payload.data.forEach(function (data) {
        if (data.attributes.isSeen === undefined || data.attributes.isSeen === null) {
          delete data.attributes.isSeen; // eslint-disable-line no-param-reassign

          delete data.attributes.isRead; // eslint-disable-line no-param-reassign
        }
      });
      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});