define("client/components/stream-feed/items/library-entry/activity", ["exports", "ember-invoke-action"], function (_exports, _emberInvokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    rating: Ember.computed(function () {
      var rating = Ember.get(this, 'activity.rating');

      if (Ember.get(this, 'activity.nineteenScale')) {
        return rating;
      } // Rating is in the old 0.5 <-> 5.0 format and has been ` / 2` by the rating transform.
      // Convert the rating to the new nineteen scale format


      return rating * 4;
    }).readOnly(),
    actions: {
      deleteActivity: function deleteActivity(activity) {
        var _this = this;

        Ember.set(this, 'isDeleteing', true);
        (0, _emberInvokeAction.strictInvokeAction)(this, 'deleteActivity', 'user', activity, function () {
          activity.deleteRecord();
          (0, _emberInvokeAction.strictInvokeAction)(_this, 'onDelete', activity);
          Ember.set(_this, 'isDeleteing', false);
        });
      }
    }
  });

  _exports.default = _default;
});