define("client/components/media/media-information", ["exports", "client/utils/humanize-duration", "moment"], function (_exports, _humanizeDuration, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var computedProduction = function computedProduction(key) {
    return Ember.computed('media.animeProductions', function () {
      var productions = Ember.get(this, 'media.animeProductions');
      return productions.filterBy('role', key).mapBy('producer.name').join(', ');
    }).readOnly();
  };

  var _default = Ember.Component.extend({
    classNames: ['media--information'],
    isExpanded: false,
    producers: computedProduction('producer'),
    licensors: computedProduction('licensor'),
    studios: computedProduction('studio'),
    isAnime: Ember.computed.equal('media.modelType', 'anime'),
    isManga: Ember.computed.not('isAnime'),
    season: Ember.computed('media.startDate', function () {
      var start = Ember.get(this, 'media.startDate');

      if (!start) {
        return null;
      }

      var month = start.month() + 1;

      if ([12, 1, 2].includes(month)) {
        return 'winter';
      }

      if ([3, 4, 5].includes(month)) {
        return 'spring';
      }

      if ([6, 7, 8].includes(month)) {
        return 'summer';
      }

      if ([9, 10, 11].includes(month)) {
        return 'fall';
      }
    }).readOnly(),
    seasonYear: Ember.computed('season', function () {
      var season = Ember.get(this, 'season');

      if (!season) {
        return null;
      }

      var start = Ember.get(this, 'media.startDate');
      var year = start.year();
      var month = start.month() + 1;

      if (season === 'winter' && month === 12) {
        return year + 1;
      }

      return year;
    }).readOnly(),
    airedLongerThanOneDay: Ember.computed('media.{startDate,endDate}', function () {
      var start = Ember.get(this, 'media.startDate');

      if (!start) {
        return false;
      }

      return !start.isSame(Ember.get(this, 'media.endDate'));
    }).readOnly(),
    totalTime: Ember.computed('media.{episodeCount,episodeLength}', function () {
      var count = Ember.get(this, 'media.episodeCount');
      var length = Ember.get(this, 'media.episodeLength');

      var time = _moment.default.duration(count * length, 'minutes');

      return (0, _humanizeDuration.default)(time);
    }).readOnly()
  });

  _exports.default = _default;
});