define("client/controllers/groups/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    isSaving: false,
    privacyOptions: ['open', 'closed', 'restricted'],
    hoveredField: 'name',
    store: Ember.inject.service(),
    group: Ember.computed.alias('model.group'),
    categories: Ember.computed('model.categories', function () {
      return Ember.get(this, 'model.categories').map(function (category) {
        return {
          id: Ember.get(category, 'id'),
          name: Ember.get(category, 'name'),
          slug: Ember.get(category, 'slug')
        };
      });
    }).readOnly(),

    /** Determines if the submit button is disabled/enabled. */
    isValid: Ember.computed('group.validations.isValid', 'isSaving', function () {
      return Ember.get(this, 'group.validations.isValid') && !Ember.get(this, 'isSaving');
    }).readOnly(),
    actions: {
      selectCategory: function selectCategory(category) {
        Ember.set(this, 'selectedCategory', category);
        var record = Ember.get(this, 'store').peekRecord('group-category', Ember.get(category, 'id'));
        this.group.set('category', record);
      }
    }
  });

  _exports.default = _default;
});