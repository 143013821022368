define("client/templates/groups/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PdU0gnCh",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"groups/group-categories-list\",null,[[\"currentCategory\",\"onChange\"],[[25,[\"category\"]],[29,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"category\"]]],null]],null]]]],false],[0,\"\\n\\n\"],[1,[29,\"groups/group-list\",null,[[\"sort\",\"query\",\"groups\",\"isLoading\",\"onPagination\",\"updateQueryParam\",\"class\"],[[25,[\"sort\"]],[25,[\"query\"]],[25,[\"groups\"]],[25,[\"model\",\"taskInstance\",\"isRunning\"]],[29,\"route-action\",[\"onPagination\"],null],[29,\"action\",[[24,0,[]],\"updateQueryParam\"],null],\"group-result-list\"]]],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"group-result-secondary\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"card\"],[9],[0,\"\\n    \"],[1,[29,\"t\",[\"groups.index.call-to-action\"],null],false],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"button button--primary\"],[9],[0,\"\\n      \"],[1,[29,\"t\",[\"groups.index.create-btn\"],null],false],[0,\"\\n    \"],[3,\"action\",[[24,0,[]],\"handleCreateClick\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"session\",\"hasUser\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"dashboard/unread-groups\",null,[[\"class\"],[\"sidebar-item\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/groups/index.hbs"
    }
  });

  _exports.default = _default;
});