define("client/templates/components/explore/category-sidebar/category-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "idHYslfW",
    "block": "{\"symbols\":[\"category\"],\"statements\":[[7,\"ul\"],[11,\"class\",\"list-unstyled categories-list\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"categories\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\"],[9],[0,\"\\n      \"],[7,\"a\"],[12,\"href\",[29,\"href-to\",[\"explore.category\",[24,1,[\"slug\"]]],null]],[9],[1,[29,\"t\",[[29,\"category-to-i18n\",[[24,1,[\"slug\"]]],null]],null],false],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"categories-more\"],[9],[0,\"\\n  \"],[7,\"a\"],[11,\"href\",\"#\"],[9],[1,[29,\"t\",[\"explore.widget.more-categories\"],null],false],[3,\"action\",[[24,0,[]],[29,\"toggle\",[\"isModalOpen\",[24,0,[]]],null]]],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"isModalOpen\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"modal\",[29,\"component\",[\"explore/category-sidebar/category-modal\"],[[\"modalId\",\"onClose\"],[\"category-modal\",[29,\"toggle-action\",[\"isModalOpen\",[24,0,[]]],null]]]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/explore/category-sidebar/category-list.hbs"
    }
  });

  _exports.default = _default;
});