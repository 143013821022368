define("client/templates/components/media-popover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bxZdG6ul",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"showTooltip\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[9],[0,\"\\n\"],[4,\"ember-tether\",null,[[\"class\",\"target\",\"attachment\",\"targetAttachment\",\"offset\",\"constraints\"],[[29,\"concat\",[[25,[\"class\"]],\" media-tooltip\"],null],[29,\"concat\",[\"#\",[25,[\"elementId\"]]],null],[29,\"or\",[[25,[\"attachment\"]],\"middle left\"],null],[29,\"or\",[[25,[\"targetAttachment\"]],\"middle right\"],null],\"0px 0px\",[29,\"array\",[[29,\"hash\",null,[[\"to\",\"attachment\"],[\"scrollParent\",\"together\"]]]],null]]],{\"statements\":[[0,\"      \"],[7,\"div\"],[9],[0,\"\\n        \"],[7,\"div\"],[9],[0,\"\\n          \"],[7,\"span\"],[11,\"class\",\"media-title\"],[9],[1,[25,[\"media\",\"yearlessTitle\"]],false],[10],[0,\"\\n          \"],[7,\"span\"],[11,\"class\",\"media-year\"],[9],[1,[25,[\"media\",\"year\"]],false],[10],[0,\"\\n        \"],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"media\",\"averageRating\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\"],[12,\"class\",[30,[\"media-ratings \",[23,\"percentageClass\"]]]],[9],[1,[25,[\"media\",\"averageRating\"]],false],[0,\"%\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[1,[29,\"media/media-rankings\",null,[[\"media\",\"length\"],[[25,[\"media\"]],\"short\"]]],false],[0,\"\\n\"],[4,\"if\",[[25,[\"media\",\"description\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\"],[11,\"class\",\"media-description\"],[9],[1,[25,[\"media\",\"description\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/media-popover.hbs"
    }
  });

  _exports.default = _default;
});