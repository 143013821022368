define("client/templates/unsubscribe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "khWRMQV4",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"one-way-email\",null,[[\"update\",\"value\",\"placeholder\"],[[29,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"email\"]]],null]],null],[25,[\"email\"]],[29,\"t\",[\"unsubscribe.email\"],null]]]],false],[0,\"\\n\"],[7,\"button\"],[11,\"class\",\"button button--primary\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],[29,\"perform\",[[25,[\"unsubscribeEmail\"]]],null]],null]],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"sendEmail\",\"isRunning\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"loading-spinner\",null,[[\"size\"],[\"small\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[29,\"t\",[\"unsubscribe.unsubscribe\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/unsubscribe.hbs"
    }
  });

  _exports.default = _default;
});