define("client/controllers/anime/show/episodes/show", ["exports", "client/controllers/media/show/units/show"], function (_exports, _show) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _show.default.extend();

  _exports.default = _default;
});