define("client/templates/components/library-entry/advanced-rating", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A+NlqIZK",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"range-slider\",null,[[\"class\",\"min\",\"max\",\"start\",\"step\",\"connect\",\"tooltips\",\"formatTo\",\"on-slide\",\"on-set\"],[\"advanced-rating-slider\",1,10,[25,[\"rating\"]],0.5,[29,\"array\",[false,true],null],true,[29,\"action\",[[24,0,[]],\"formatValue\"],null],[29,\"action\",[[24,0,[]],\"onSlide\"],null],[29,\"action\",[[24,0,[]],\"onClick\"],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/library-entry/advanced-rating.hbs"
    }
  });

  _exports.default = _default;
});