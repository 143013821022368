define("client/components/library-entry/simple-rating", ["exports", "ember-invoke-action"], function (_exports, _emberInvokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    ratings: [{
      tag: 'awful',
      value: 1
    }, {
      tag: 'meh',
      value: 4
    }, {
      tag: 'good',
      value: 7
    }, {
      tag: 'great',
      value: 10
    }],
    actions: {
      onClick: function onClick(rating) {
        (0, _emberInvokeAction.invokeAction)(this, 'onClick', Ember.get(rating, 'value'));
      }
    }
  });

  _exports.default = _default;
});