define("client/templates/groups/group/dashboard/members", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eRQWv9cw",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row leader-wrapper\"],[9],[0,\"\\n\"],[0,\"    \"],[1,[29,\"groups/dashboard/members/invite-list\",null,[[\"group\",\"class\"],[[25,[\"group\"]],\"leader-chat pending-invites\"]]],false],[0,\"\\n\\n\"],[0,\"    \"],[1,[29,\"groups/dashboard/members/ban-list\",null,[[\"group\",\"class\"],[[25,[\"group\"]],\"leader-table-card card banned-members\"]]],false],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"row audit-row member-list\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"group-audit-log\"],[9],[0,\"\\n      \"],[1,[29,\"groups/dashboard/members/member-list\",null,[[\"group\"],[[25,[\"group\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/groups/group/dashboard/members.hbs"
    }
  });

  _exports.default = _default;
});