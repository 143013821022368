define("client/routes/users/groups", ["exports", "kitsu-shared/mixins/pagination"], function (_exports, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_pagination.default, {
    intl: Ember.inject.service(),
    model: function model(_ref) {
      var category = _ref.category,
          sort = _ref.sort,
          query = _ref.query;
      var user = this.modelFor('users');
      return {
        taskInstance: this.queryPaginated('group-member', {
          filter: {
            query_user: Ember.get(user, 'id'),
            group_name: Ember.isPresent(query) ? query : undefined,
            group_category: category !== 'all' ? category : undefined
          },
          fields: {
            groups: ['slug', 'name', 'avatar', 'tagline', 'membersCount', 'category'].join(',')
          },
          sort: Ember.isPresent(query) ? undefined : this._getSortingKey(sort),
          include: 'group.category',
          page: {
            limit: 20
          }
        }),
        paginatedRecords: []
      };
    },
    titleToken: function titleToken() {
      var model = this.modelFor('users');
      var name = Ember.get(model, 'name');
      return Ember.get(this, 'intl').t('titles.users.groups', {
        user: name
      });
    },
    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      }
    },
    _getSortingKey: function _getSortingKey(sort) {
      switch (sort) {
        case 'oldest':
          return 'created_at';

        default:
          return '-created_at';
      }
    }
  });

  _exports.default = _default;
});