define("client/templates/components/users/unban-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "q5wgR3Mj",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"modals/confirm-action\",null,[[\"title\",\"text\",\"onClose\",\"onConfirm\"],[[29,\"t\",[\"components.users.unban.title\"],null],[29,\"t\",[\"components.users.unban.text\"],null],[25,[\"onClose\"]],[29,\"action\",[[24,0,[]],\"unbanUser\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/users/unban-user.hbs"
    }
  });

  _exports.default = _default;
});