define("client/templates/components/groups/leaders/leader-list-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WSQV50Ua",
    "block": "{\"symbols\":[],\"statements\":[[7,\"th\"],[11,\"scope\",\"row\"],[9],[0,\"\\n  \"],[1,[29,\"lazy-image\",null,[[\"src\"],[[29,\"image\",[[25,[\"leader\",\"user\",\"avatar\"]],\"small\"],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"leader-name\"],[9],[0,\"\\n  \"],[7,\"a\"],[12,\"href\",[29,\"href-to\",[\"users.index\",[25,[\"leader\",\"user\"]]],null]],[9],[0,\"\\n    \"],[1,[25,[\"leader\",\"user\",\"name\"]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"isManaging\"]]],null,{\"statements\":[[0,\"  \"],[7,\"td\"],[11,\"class\",\"edit-leader\"],[9],[0,\"\\n    \"],[7,\"a\"],[11,\"href\",\"#\"],[9],[0,\"\\n      \"],[1,[29,\"t\",[\"groups.leaders.list.edit-btn\"],null],false],[0,\"\\n    \"],[3,\"action\",[[24,0,[]],[29,\"toggle\",[\"showModal\",[24,0,[]]],null]]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[25,[\"showModal\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"modal\",[29,\"component\",[\"groups/dashboard/leaders/leader-permissions-modal\"],[[\"leader\",\"onClose\"],[[25,[\"leader\"]],[29,\"toggle-action\",[\"showModal\",[24,0,[]]],null]]]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/groups/leaders/leader-list-user.hbs"
    }
  });

  _exports.default = _default;
});