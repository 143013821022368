define("client/serializers/group", ["exports", "client/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      categoryHack: {
        serialize: false
      },
      rulesFormatted: {
        serialize: false
      },
      leadersCount: {
        serialize: false
      },
      membersCount: {
        serialize: false
      },
      neighborsCount: {
        serialize: false
      },
      slug: {
        serialize: false
      }
    }
  });

  _exports.default = _default;
});