define("client/models/upload", ["exports", "client/models/-base", "ember-data/attr", "ember-data/relationships"], function (_exports, _base, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    content: (0, _attr.default)('object'),
    uploadOrder: (0, _attr.default)('number'),
    user: (0, _relationships.belongsTo)('user', {
      inverse: 'uploads'
    }),
    owner: (0, _relationships.belongsTo)('-upload-owner', {
      inverse: 'uploads'
    })
  });

  _exports.default = _default;
});