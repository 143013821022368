define("client/components/modals/image-cropper", ["exports", "ember-invoke-action"], function (_exports, _emberInvokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global Cropper */
  var _default = Ember.Component.extend({
    width: Ember.computed('_width', function () {
      return Ember.get(this, '_width');
    }).readOnly(),
    height: Ember.computed('_height', function () {
      return Ember.get(this, '_height');
    }).readOnly(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var image = document.getElementById("".concat(Ember.get(this, 'elementId'), "-image"));
      this._cropEventFn = Ember.run.bind(this, '_updateDimensions');
      image.addEventListener('crop', this._cropEventFn);
      var cropper = new Cropper(image, {
        viewMode: 1,
        autoCropArea: 0.5,
        scalable: false,
        minContainerWidth: 500,
        minContainerHeight: 300,
        aspectRatio: Ember.get(this, 'aspectRatio') || NaN
      });
      Ember.set(this, 'cropper', cropper);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this._cropEventFn) {
        var image = document.getElementById("".concat(Ember.get(this, 'elementId'), "-image"));
        image.removeEventListener('crop', this._cropEventFn);
      }
    },
    actions: {
      closeModal: function closeModal() {
        this.$('.modal').modal('hide');
      },
      updateImage: function updateImage() {
        var cropper = Ember.get(this, 'cropper');
        var dataURI = cropper.getCroppedCanvas().toDataURL();
        (0, _emberInvokeAction.invokeAction)(this, 'update', dataURI);
        (0, _emberInvokeAction.invoke)(this, 'closeModal');
      }
    },
    _updateDimensions: function _updateDimensions() {
      var cropper = Ember.get(this, 'cropper');
      var data = cropper.getData();
      Ember.set(this, '_width', Math.round(data.width));
      Ember.set(this, '_height', Math.round(data.height));
    }
  });

  _exports.default = _default;
});