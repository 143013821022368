define("client/templates/components/users/edit-profile/body/profile-links/link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SvA1YhEP",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h6\"],[9],[1,[25,[\"link\",\"site\",\"name\"]],false],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"form-group row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-xs-12\"],[9],[0,\"\\n    \"],[7,\"label\"],[11,\"class\",\"col-form-label\"],[9],[0,\"\\n      \"],[1,[29,\"t\",[[29,\"concat\",[\"users.edit-modal.profile-links.sites.\",[29,\"profile-link-to-svg\",[[25,[\"link\",\"site\",\"name\"]]],null],\".about\"],null]],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-xs-12\"],[9],[0,\"\\n    \"],[1,[29,\"one-way-text\",[[25,[\"link\",\"url\"]]],[[\"update\",\"class\",\"placeholder\"],[[29,\"action\",[[24,0,[]],\"update\"],null],\"form-control\",[29,\"t\",[[29,\"concat\",[\"users.edit-modal.profile-links.sites.\",[29,\"profile-link-to-svg\",[[25,[\"link\",\"site\",\"name\"]]],null],\".placeholder\"],null]],null]]]],false],[0,\"\\n\"],[4,\"if\",[[25,[\"isSaved\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"profile-link-remove\"],[9],[0,\"\\n        \"],[7,\"a\"],[11,\"href\",\"#\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"remove\"],null]],[9],[1,[29,\"t\",[\"users.edit-modal.profile-links.remove\"],null],false],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/users/edit-profile/body/profile-links/link.hbs"
    }
  });

  _exports.default = _default;
});