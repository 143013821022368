define("client/models/library-entry", ["exports", "client/models/-base", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _base, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LIBRARY_STATUSES = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    progress: [(0, _emberCpValidations.validator)('number', {
      integer: true,
      gte: 0
    })],
    reconsumeCount: [(0, _emberCpValidations.validator)('number', {
      integer: true,
      gte: 0
    })]
  });
  var LIBRARY_STATUSES = ['current', 'planned', 'completed', 'on_hold', 'dropped'];
  _exports.LIBRARY_STATUSES = LIBRARY_STATUSES;

  var _default = _base.default.extend(Validations, {
    finishedAt: (0, _attr.default)('utc'),
    notes: (0, _attr.default)('string'),
    progress: (0, _attr.default)('number'),
    private: (0, _attr.default)('boolean'),
    ratingTwenty: (0, _attr.default)('rating'),
    reconsumeCount: (0, _attr.default)('number'),
    startedAt: (0, _attr.default)('utc'),
    status: (0, _attr.default)('string'),
    updatedAt: (0, _attr.default)('utc'),
    volumesOwned: (0, _attr.default)('number'),
    anime: (0, _relationships.belongsTo)('anime'),
    manga: (0, _relationships.belongsTo)('manga'),
    review: (0, _relationships.belongsTo)('review'),
    mediaReaction: (0, _relationships.belongsTo)('media-reaction'),
    unit: (0, _relationships.belongsTo)('-base'),
    nextUnit: (0, _relationships.belongsTo)('-base'),
    user: (0, _relationships.belongsTo)('user'),
    // Can't use `or` as it may try to load the anime relationship, so check values
    media: Ember.computed('anime', 'manga', function () {
      return this.belongsTo('anime').value() || this.belongsTo('manga').value();
    }).readOnly(),
    rating: Ember.computed.alias('ratingTwenty'),
    ratingGroup: Ember.computed('rating', function () {
      var rating = Ember.get(this, 'rating');
      return this._getRatingGroup(rating);
    }).readOnly(),
    _getRatingGroup: function _getRatingGroup(rating) {
      if (rating > 0 && rating < 4) {
        return 'awful';
      }

      if (rating >= 4 && rating < 7) {
        return 'meh';
      }

      if (rating >= 7 && rating < 10) {
        return 'good';
      }

      return 'great';
    }
  });

  _exports.default = _default;
});