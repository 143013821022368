define("client/services/apollo", ["exports", "ember-apollo-client/services/apollo", "apollo-link-context"], function (_exports, _apollo, _apolloLinkContext) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _apollo.default.extend({
    session: Ember.inject.service(),
    link: Ember.computed('session.token', function () {
      var httpLink = this._super.apply(this, arguments);

      var token = Ember.get(this, 'session.token');
      var authLink = (0, _apolloLinkContext.setContext)(function () {
        if (!token) return {};
        return {
          headers: {
            Authorization: "Bearer ".concat(token)
          }
        };
      });
      return authLink.concat(httpLink);
    })
  });

  _exports.default = _default;
});