define("client/templates/admin/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JH+MEGUS",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h1\"],[9],[1,[29,\"t\",[\"admin.header\"],null],false],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"w-100\"],[9],[0,\"\\n  \"],[7,\"p\"],[9],[1,[29,\"t\",[\"admin.deployed-version\"],[[\"version\"],[[29,\"app-version\",null,null]]]],false],[10],[0,\"\\n  \"],[7,\"ul\"],[9],[0,\"\\n    \"],[7,\"li\"],[9],[7,\"a\"],[12,\"href\",[29,\"href-to\",[\"admin.reports.index\"],null]],[9],[1,[29,\"t\",[\"admin.links.index\"],null],false],[10],[10],[0,\"\\n    \"],[7,\"li\"],[9],[7,\"a\"],[11,\"href\",\"/api/admin\"],[11,\"target\",\"_blank\"],[9],[1,[29,\"t\",[\"admin.links.admin\"],null],false],[10],[0,\"\\n      \"],[7,\"ul\"],[9],[0,\"\\n        \"],[7,\"li\"],[9],[7,\"a\"],[11,\"href\",\"/api/admin/anime/new\"],[11,\"target\",\"_blank\"],[9],[0,\"New Anime\"],[10],[10],[0,\"\\n        \"],[7,\"li\"],[9],[7,\"a\"],[11,\"href\",\"/api/admin/manga/new\"],[11,\"target\",\"_blank\"],[9],[0,\"New Manga\"],[10],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"li\"],[9],[7,\"a\"],[11,\"href\",\"/api/sidekiq\"],[11,\"target\",\"_blank\"],[9],[1,[29,\"t\",[\"admin.links.sidekiq\"],null],false],[10],[10],[0,\"\\n    \"],[7,\"li\"],[9],[7,\"a\"],[11,\"href\",\"/api/pghero\"],[11,\"target\",\"_blank\"],[9],[1,[29,\"t\",[\"admin.links.pghero\"],null],false],[10],[10],[0,\"\\n    \"],[7,\"li\"],[9],[7,\"a\"],[11,\"href\",\"/api/flipper\"],[11,\"target\",\"_blank\"],[9],[1,[29,\"t\",[\"admin.links.flipper\"],null],false],[10],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/admin/index.hbs"
    }
  });

  _exports.default = _default;
});