define("client/templates/components/modals/media-trailer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z31OmnU7",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[4,\"bootstrap/bs-modal\",null,[[\"id\",\"class\",\"largeModal\",\"onClose\"],[[25,[\"modalId\"]],\"trailer-modal\",true,[25,[\"onClose\"]]]],{\"statements\":[[4,\"component\",[[29,\"-assert-implicit-component-helper-argument\",[[24,1,[\"body\"]],\"expected `modal.body` to be a contextual component but found a string. Did you mean `(component modal.body)`? ('client/templates/components/modals/media-trailer.hbs' @ L2:C5) \"],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"embed-responsive embed-responsive-16by9\"],[9],[0,\"\\n      \"],[7,\"iframe\"],[11,\"class\",\"embed-responsive-item\"],[12,\"src\",[30,[\"https://www.youtube.com/embed/\",[25,[\"media\",\"youtubeVideoId\"]],\"?rel=0&showinfo=0\"]]],[11,\"frameborder\",\"0\"],[11,\"allowfullscreen\",\"\"],[9],[10],[0,\"\\n    \"],[3,\"action\",[[24,0,[]],\"playVideo\"],[[\"preventDefault\"],[false]]],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/modals/media-trailer.hbs"
    }
  });

  _exports.default = _default;
});