define("client/services/facebook", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // TODO: Refactor into generic oauth2 service
  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    torii: Ember.inject.service(),
    connect: function connect(user) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.get(_this, 'torii').open('facebook', {}).then(function (response) {
          return resolve(response);
        }).catch(function (err) {
          return reject(err);
        });
      }).then(function (response) {
        Ember.set(user, 'facebookId', Ember.get(response, 'userId'));
        return user.save().catch(function (error) {
          Ember.set(user, 'facebookId', null);
          throw error;
        });
      });
    },
    disconnect: function disconnect(user) {
      var id = Ember.get(user, 'facebookId');
      Ember.set(user, 'facebookId', null);
      return user.save().catch(function (error) {
        Ember.set(user, 'facebookId', id);
        throw error;
      });
    },
    getUserData: function getUserData() {
      if (this._isInitialized() === false) {
        throw new Error('Facebook not initialized.');
      }

      return new Ember.RSVP.Promise(function (resolve) {
        window.FB.api('/me', {
          fields: 'id,name,email,gender'
        }, function (response) {
          resolve(response);
        });
      });
    },
    importFriends: function importFriends() {
      var _this2 = this;

      if (this._isInitialized() === false) {
        throw new Error('Facebook not initialized.');
      }

      return new Ember.RSVP.Promise(function (resolve, reject) {
        window.FB.getLoginStatus(function (_ref) {
          var status = _ref.status,
              authResponse = _ref.authResponse;

          if (status === 'connected') {
            Ember.get(_this2, 'ajax').request('/follows/import_from_facebook', {
              method: 'POST',
              dataType: 'json',
              data: {
                assertion: authResponse.accessToken
              }
            }).then(function (response) {
              return resolve(response);
            }).catch(function (err) {
              return reject(err);
            });
          } else {
            Ember.get(_this2, 'torii').open('facebook', {}).then(function () {
              return _this2.importFriends();
            });
          }
        });
      });
    },
    _isInitialized: function _isInitialized() {
      return window.FB !== undefined;
    }
  });

  _exports.default = _default;
});