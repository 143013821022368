define("client/components/skeleton-loader", ["exports", "kitsu-shared/components/skeleton-loader"], function (_exports, _skeletonLoader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _skeletonLoader.default;
    }
  });
});