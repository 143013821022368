define("client/controllers/media/index", ["exports", "client/utils/moment", "client/utils/computed-macros", "client/utils/media-minyear", "client/utils/queryable"], function (_exports, _moment, _computedMacros, _mediaMinyear, _queryable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.MEDIA_QUERY_PARAMS = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var MEDIA_QUERY_PARAMS = {
    averageRating: {
      defaultValue: [5, 100],
      refresh: true,
      serialize: function serialize(value) {
        var _value = _slicedToArray(value, 2),
            lower = _value[0],
            upper = _value[1];

        if (lower === 5 && upper === 100) {
          return undefined;
        }

        if (lower === 5) {
          return (0, _queryable.serializeArray)([5, upper]);
        }

        return (0, _queryable.serializeArray)(value);
      },
      deserialize: function deserialize() {
        var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

        var _deserializeArray = (0, _queryable.deserializeArray)(value),
            _deserializeArray2 = _slicedToArray(_deserializeArray, 2),
            lower = _deserializeArray2[0],
            upper = _deserializeArray2[1];

        if (Ember.isEmpty(lower)) {
          return [5, upper];
        }

        return [lower, upper];
      }
    },
    categories: {
      defaultValue: [],
      refresh: true,
      serialize: function serialize(value) {
        return (0, _queryable.serializeArray)(value);
      },
      deserialize: function deserialize() {
        var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
        return (0, _queryable.deserializeArray)(value);
      }
    },
    text: {
      defaultValue: '',
      refresh: true
    },
    sort: {
      defaultValue: 'popularity',
      refresh: true
    },
    subtype: {
      defaultValue: [],
      refresh: true,
      serialize: function serialize(value) {
        return (0, _queryable.serializeArray)(value);
      },
      deserialize: function deserialize() {
        var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
        return (0, _queryable.deserializeArray)(value);
      }
    },
    unitCount: {
      defaultValue: [1, 100],
      refresh: true,
      serialize: function serialize(value) {
        var _value2 = _slicedToArray(value, 2),
            lower = _value2[0],
            upper = _value2[1];

        if (lower === 1 && upper === 100) {
          return undefined;
        }

        if (upper === 100) {
          return (0, _queryable.serializeArray)([lower, null]);
        }

        if (lower === 1) {
          return (0, _queryable.serializeArray)([null, upper]);
        }

        return (0, _queryable.serializeArray)(value);
      },
      deserialize: function deserialize() {
        var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

        var _deserializeArray3 = (0, _queryable.deserializeArray)(value),
            _deserializeArray4 = _slicedToArray(_deserializeArray3, 2),
            lower = _deserializeArray4[0],
            upper = _deserializeArray4[1];

        if (Ember.isEmpty(upper)) {
          return [lower, 100];
        }

        if (Ember.isEmpty(lower) && !Ember.isEmpty(upper)) {
          return [1, upper];
        }

        return [lower, upper];
      }
    }
  };
  _exports.MEDIA_QUERY_PARAMS = MEDIA_QUERY_PARAMS;

  var _default = Ember.Controller.extend({
    sortOptions: ['popularity', 'rating', 'date', 'recent'],
    taskValue: (0, _computedMacros.concat)('model.taskInstance.value', 'model.paginatedRecords'),
    init: function init() {
      this._super.apply(this, arguments);

      var mediaType = Ember.get(this, 'mediaType');
      Ember.set(this, 'isAnime', mediaType === 'anime');
      Ember.set(this, 'isManga', mediaType === 'manga');
      Ember.set(this, 'minYear', Ember.get(this, 'isAnime') ? _mediaMinyear.minYearAnime : _mediaMinyear.minYearManga);
      Ember.set(this, 'maxYear', (0, _moment.moment)().year() + 2);
    },
    actions: {
      formatValue: function formatValue(value) {
        return parseFloat(parseFloat(value).toFixed(0));
      }
    },
    _setDirtyValues: function _setDirtyValues() {
      Ember.set(this, 'dirtyYear', Ember.get(this, 'year'));
      Ember.set(this, 'dirtyRating', Ember.get(this, 'averageRating'));
      Ember.set(this, 'dirtyUnits', Ember.get(this, 'unitCount'));
    }
  });

  _exports.default = _default;
});