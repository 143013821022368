define("client/models/person", ["exports", "ember-data/attr", "client/models/-base", "ember-data/relationships"], function (_exports, _attr, _base, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    // TODO: Replace with default image for characters
    image: (0, _attr.default)('object', {
      defaultValue: '/image/default_avatar.png'
    }),
    name: (0, _attr.default)('string'),
    castings: (0, _relationships.hasMany)('casting')
  });

  _exports.default = _default;
});