define("client/authenticators/assertion", ["exports", "client/authenticators/oauth2"], function (_exports, _oauth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _oauth.default.extend({
    torii: Ember.inject.service(),
    authenticate: function authenticate(provider) {
      var _this = this;

      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.get(_this, 'torii').open(provider, options).then(function (providerResponse) {
          var data = {
            grant_type: 'assertion',
            assertion: Ember.get(providerResponse, 'accessToken'),
            provider: provider
          };

          _this.makeRequest(Ember.get(_this, 'serverTokenEndpoint'), data).then(function (response) {
            Ember.run(function () {
              var expiresIn = response.expires_in;

              var expiresAt = _this._absolutizeExpirationTime(expiresIn);

              _this._scheduleAccessTokenRefresh(expiresIn, expiresAt, response.refresh_token);

              if (!Ember.isEmpty(expiresAt)) {
                return resolve(Object.assign(response, {
                  expires_at: expiresAt
                }));
              }

              resolve(response);
            });
          }, function (xhr) {
            Ember.run(null, reject, xhr.responseJSON || xhr.responseText);
          });
        });
      });
    }
  });

  _exports.default = _default;
});