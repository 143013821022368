define("client/components/users/edit-profile/body/profile-links/link", ["exports", "ember-invoke-action"], function (_exports, _emberInvokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isSaved: Ember.computed('link', function () {
      return !Ember.get(this, 'link.isNew') && !Ember.get(this, 'link.isDeleted');
    }),
    actions: {
      update: function update(content) {
        (0, _emberInvokeAction.invokeAction)(this, 'update', Ember.get(this, 'link'), content);
      },
      remove: function remove() {
        (0, _emberInvokeAction.invokeAction)(this, 'remove', Ember.get(this, 'link'));
      }
    }
  });

  _exports.default = _default;
});