define("client/utils/get-title-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getTitleField = getTitleField;
  _exports.getComputedTitle = getComputedTitle;
  _exports.default = void 0;

  function getTitleField(preference) {
    var titleLocales = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var userLocale = arguments.length > 2 ? arguments[2] : undefined;

    switch (preference) {
      case 'english':
      case 'localized':
        if (userLocale in titleLocales) return userLocale;
        return 'en';

      case 'romanized':
        // TODO: Bodge while originalLocale is not exposed by the JSON:API endpoint
        if ('en_jp' in titleLocales) return 'en_jp';
        if ('en_cn' in titleLocales) return 'en_cn';
        if ('en_kr' in titleLocales) return 'en_kr';
        return 'en_jp';

      default:
        return 'canonical';
    }
  }

  function getComputedTitle(session, context) {
    if (!Ember.get(session, 'hasUser')) {
      return Ember.get(context, 'canonicalTitle');
    }

    var preference = Ember.get(session, 'account.titleLanguagePreference').toLowerCase();
    var titles = Ember.get(context, 'titles');
    var userLocale = (Ember.get(session, 'account.language') || 'en').toLowerCase().replace('-', '_');
    var key = getTitleField(preference, titles, userLocale);
    return key !== undefined ? Ember.get(context, "titles.".concat(key)) || Ember.get(context, 'canonicalTitle') : Ember.get(context, 'canonicalTitle');
  }

  var _default = {
    getTitleField: getTitleField,
    getComputedTitle: getComputedTitle
  };
  _exports.default = _default;
});