define("client/templates/components/library-entry/state/new-entry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vaJ+15up",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[11,\"class\",\"button button--secondary seen-this\"],[9],[0,\"\\n  \"],[1,[29,\"t\",[\"library-entry.state.seen-this\"],[[\"type\"],[[25,[\"media\",\"modelType\"]]]]],false],[0,\"\\n\"],[3,\"action\",[[24,0,[]],\"createLibraryEntry\",\"completed\"]],[10],[0,\"\\n\\n\"],[4,\"unless\",[[25,[\"createOnly\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\"],[11,\"class\",\"button button--secondary want-to-watch\"],[9],[0,\"\\n    \"],[1,[29,\"t\",[\"library-entry.state.want-to-watch\"],[[\"type\"],[[25,[\"media\",\"modelType\"]]]]],false],[0,\"\\n  \"],[3,\"action\",[[24,0,[]],\"createLibraryEntry\",\"planned\"]],[10],[0,\"\\n\\n\"],[0,\"  \"],[7,\"button\"],[11,\"class\",\"button button--secondary started-watching\"],[9],[0,\"\\n    \"],[1,[29,\"t\",[\"library-entry.state.started-watching\"],[[\"type\"],[[25,[\"media\",\"modelType\"]]]]],false],[0,\"\\n  \"],[3,\"action\",[[24,0,[]],\"createLibraryEntry\",\"current\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/library-entry/state/new-entry.hbs"
    }
  });

  _exports.default = _default;
});