define("client/routes/password-reset", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    routeIfAlreadyAuthenticated: 'dashboard',
    // Remove token from URL so it can't leak if we have an external link on this page.
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      Ember.set(controller, 'usableToken', Ember.get(controller, 'token'));
      Ember.run.scheduleOnce('afterRender', function () {
        return Ember.set(controller, 'token', null);
      });
    },
    resetController: function resetController(controller) {
      Ember.set(controller, 'usableToken', null);
      Ember.set(controller, 'email', null);
      Ember.set(controller, 'password', null);
      Ember.set(controller, 'passwordConfirm', null);
    }
  });

  _exports.default = _default;
});