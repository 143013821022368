define("client/models/comment", ["exports", "client/models/-upload-owner", "ember-data/attr", "ember-data/relationships"], function (_exports, _uploadOwner, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _uploadOwner.default.extend({
    blocked: (0, _attr.default)('boolean'),
    content: (0, _attr.default)('string'),
    contentFormatted: (0, _attr.default)('string'),
    createdAt: (0, _attr.default)('utc', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    editedAt: (0, _attr.default)('utc'),
    embed: (0, _attr.default)('object'),
    embedUrl: (0, _attr.default)('string'),
    likesCount: (0, _attr.default)('number'),
    repliesCount: (0, _attr.default)('number'),
    updatedAt: (0, _attr.default)('utc'),
    parent: (0, _relationships.belongsTo)('comment', {
      inverse: 'replies'
    }),
    post: (0, _relationships.belongsTo)('post', {
      inverse: 'comments'
    }),
    user: (0, _relationships.belongsTo)('user', {
      async: false
    }),
    likes: (0, _relationships.hasMany)('comment-like'),
    replies: (0, _relationships.hasMany)('comment', {
      inverse: 'parent'
    })
  });

  _exports.default = _default;
});