define("client/models/group-category", ["exports", "client/models/-base", "ember-data/attr"], function (_exports, _base, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    description: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    slug: (0, _attr.default)('string')
  });

  _exports.default = _default;
});