define("client/components/stats/category-breakdown-graph", ["exports", "d3-selection", "d3-shape"], function (_exports, _d3Selection, _d3Shape) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var COLORS = ['#FEB700', '#FF9300', '#FF3281', '#BC6EDA', '#00BBED', '#545C97', '#EA6200'];

  var _default = Ember.Component.extend({
    stat: null,
    size: 140,
    classNames: ['category-breakdown-graph'],
    didReceiveAttrs: function didReceiveAttrs() {
      Ember.run.scheduleOnce('render', this, this.draw);
    },
    displayGenres: Ember.computed('stat.categories', function () {
      // Get the data, conver it to an object of {name, number}
      var data = Ember.get(this, 'stat.categories');
      var total = Ember.get(this, 'stat.total');
      var arrayData = Object.keys(data).map(function (key) {
        return {
          name: key,
          percent: data[key] / total * 100
        };
      }); // Sort the data from biggest to smallest and take the top 7

      var sorted = arrayData.sort(function (_ref, _ref2) {
        var a = _ref.percent;
        var b = _ref2.percent;
        return b - a;
      }).slice(0, 7); // Remove smaller than 1% categories

      var filtered = sorted.filter(function (_ref3) {
        var percent = _ref3.percent;
        return Math.round(percent) > 0;
      }); // Add the colors

      return filtered.map(function (datum, i) {
        return _objectSpread(_objectSpread({}, datum), {}, {
          relativeSize: datum.percent / filtered[0].percent * 100,
          color: COLORS[i % COLORS.length]
        });
      });
    }),
    draw: function draw() {
      var data = Ember.get(this, 'displayGenres');
      var size = Ember.get(this, 'size');
      var radius = size / 2; // The donut chart

      var outerArc = (0, _d3Shape.arc)().outerRadius(radius).innerRadius(radius - 20);
      var chart = (0, _d3Shape.pie)().sort(null).value(function (_ref4) {
        var percent = _ref4.percent;
        return percent;
      });
      var svg = (0, _d3Selection.select)(this.element).select('.graph-canvas').append('svg').attr('width', size).attr('height', size).append('g').attr('transform', "translate(".concat(radius, ", ").concat(radius, ")"));
      var g = svg.selectAll('.arc').data(chart(data)).enter().append('g').attr('class', 'arc');
      g.append('path').attr('d', outerArc).style('fill', function (_ref5) {
        var color = _ref5.data.color;
        return color;
      });
    }
  });

  _exports.default = _default;
});