define("client/components/library-entry/state/completed", ["exports", "ember-invoke-action", "client/utils/elements-match", "jquery"], function (_exports, _emberInvokeAction, _elementsMatch, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isChangingRating: false,
    actions: {
      showRating: function showRating() {
        var _this = this;

        Ember.set(this, 'isChangingRating', true); // watch for clicks outside the tether element for closure

        Ember.run.scheduleOnce('afterRender', function () {
          (0, _jquery.default)(document.body).on('click.library-state-rating', function (_ref) {
            var target = _ref.target;
            var id = '#library-state-completed-rating';
            var isChild = (0, _elementsMatch.default)(target, "".concat(id, " *, ").concat(id));

            if (!isChild) {
              Ember.set(_this, 'isChangingRating', false);
              (0, _jquery.default)(document.body).off('click.library-state-rating');
            }
          });
        });
      },
      ratingSelected: function ratingSelected(rating) {
        Ember.set(this, 'isChangingRating', false);
        (0, _jquery.default)(document.body).off('click.library-state-rating');
        (0, _emberInvokeAction.invokeAction)(this, 'onRatingChange', rating);
      }
    }
  });

  _exports.default = _default;
});