define("client/models/role", ["exports", "client/models/-base", "ember-data/attr", "ember-data/relationships"], function (_exports, _base, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    name: (0, _attr.default)('string'),
    resourceId: (0, _attr.default)('number'),
    resourceType: (0, _attr.default)('string'),
    resource: (0, _relationships.belongsTo)('-base')
  });

  _exports.default = _default;
});