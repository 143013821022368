define("client/components/application/nav-search", ["exports", "client/utils/elements-match"], function (_exports, _elementsMatch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isOpened: false,
    query: undefined,
    searchEventListener: undefined,
    closeSearchEventListener: undefined,
    inputClass: Ember.computed('isOpened', 'query', function () {
      var isActive = Ember.get(this, 'isOpened') || Ember.isPresent(Ember.get(this, 'query'));
      return "site-search__input ".concat(isActive ? 'active' : '');
    }).readOnly(),
    _searchEventListener: function _searchEventListener(event) {
      if (this.isDestroyed) return;
      var target = Ember.get(event, 'target');
      var id = "#".concat(Ember.get(this, 'elementId'));
      var isChild = (0, _elementsMatch.default)(target, "".concat(id, " *, ").concat(id));
      var isPopover = (0, _elementsMatch.default)(target, '.navbar-search-results *, .navbar-search-results');

      if (isChild || isPopover) {
        if (isChild) {
          if (!Ember.get(this, 'isOpened')) {
            Ember.set(this, 'isOpened', true);
          }
        }

        return;
      }

      Ember.set(this, 'isOpened', false);
    },
    // Close search results when the user clicks outside of the input or results window
    _closeSearchEventListener: function _closeSearchEventListener(event) {
      if (this.isDestroyed) return;
      var searchResults = document.getElementsByClassName('navbar-search-results')[0];
      var isClickInInput = this.element && this.element.contains(event.target);
      var isClickInResults = searchResults && searchResults.contains(event.target);
      if (!isClickInInput && !isClickInResults) Ember.set(this, 'isOpened', false);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      Ember.set(this, 'searchEventListener', this._searchEventListener.bind(this));
      Ember.set(this, 'closeSearchEventListener', this._closeSearchEventListener.bind(this));
      this.element.addEventListener('focusin', Ember.get(this, 'searchEventListener'));
      document.addEventListener('click', Ember.get(this, 'closeSearchEventListener'));
    },
    willDestroyElement: function willDestroyElement() {
      this.element.removeEventListener('focusin', Ember.get(this, 'searchEventListener'));
      document.removeEventListener('click', Ember.get(this, 'closeSearchEventListener'));
    },
    actions: {
      close: function close() {
        Ember.set(this, 'isOpened', false);
        console.log(Ember.get(this, 'isOpened'));
      }
    }
  });

  _exports.default = _default;
});