define("client/routes/media", ["exports", "client/mixins/routes/data-error"], function (_exports, _dataError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_dataError.default, {
    templateName: 'media'
  });

  _exports.default = _default;
});