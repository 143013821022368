define("client/routes/media/show/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    templateName: 'media/show/index',
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      var parentRoute = Ember.get(this, 'routeName').split('.').slice(0, 2).join('.');
      var parentController = this.controllerFor(parentRoute);
      Ember.set(controller, 'parent', parentController);
    }
  });

  _exports.default = _default;
});