define("client/routes/media-reactions", ["exports", "client/mixins/routes/data-error"], function (_exports, _dataError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_dataError.default, {
    model: function model(_ref) {
      var id = _ref.id;
      return Ember.get(this, 'store').findRecord('media-reaction', id, {
        include: 'user,anime,manga',
        reload: true
      });
    },
    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);

      var user = Ember.get(model, 'user.name');
      var crumb = "Reaction by ".concat(user);
      Ember.set(this, 'breadcrumb', crumb);
      var tags = this.setHeadTags(model);
      Ember.set(this, 'headTags', tags);
    },
    setHeadTags: function setHeadTags(model) {
      var description = Ember.get(model, 'reaction');
      return [{
        type: 'meta',
        tagId: 'meta-description',
        attrs: {
          property: 'description',
          content: description
        }
      }, {
        type: 'meta',
        tagId: 'meta-og-title',
        attrs: {
          property: 'og:title',
          content: "".concat(Ember.get(model, 'media.canonicalTitle'), " reaction by ").concat(Ember.get(model, 'user.name'))
        }
      }, {
        type: 'meta',
        tagId: 'meta-og-description',
        attrs: {
          property: 'og:description',
          content: description
        }
      }, {
        type: 'meta',
        tagId: 'meta-og-image',
        attrs: {
          property: 'og:image',
          content: Ember.get(model, 'user.avatar.medium') || Ember.get(model, 'media.posterImage.medium')
        }
      }, {
        type: 'meta',
        tagId: 'meta-twitter-label1',
        attrs: {
          property: 'twitter:label1',
          content: 'Votes'
        }
      }, {
        type: 'meta',
        tagId: 'meta-twitter-data1',
        attrs: {
          property: 'twitter:data1',
          content: Ember.get(model, 'upVotesCount')
        }
      }];
    }
  });

  _exports.default = _default;
});