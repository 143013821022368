define("client/initializers/browser-scripts", ["exports", "client/config/environment", "client/utils/can-use-dom", "client/utils/inject-script"], function (_exports, _environment, _canUseDom, _injectScript) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  /**
   * Inject Google AdWords conversion script into the `head` on initialization.
   */
  function adwords() {
    var google = _environment.default.google;

    if (google.adwords) {
      (0, _injectScript.default)('https://www.googleadservices.com/pagead/conversion_async.js').catch(function () {});
    }
  }
  /**
   * Inject Canny's SDK script into the `head` on initialization.
   */


  function canny() {
    var c = function c() {
      c.q.push(arguments);
    };

    c.q = [];
    window.Canny = c;
    (0, _injectScript.default)('https://canny.io/sdk.js').catch(function () {});
  }
  /**
   * Inject OneSignal's script into the `head` on initialzation.
   */


  function onesignal() {
    window.OneSignal = window.OneSignal || [];
    window.OneSignal.push(['init', {
      appId: _environment.default.onesignal[_environment.default.kitsu.env].appId,
      allowLocalhostAsSecureOrigin: true,
      autoRegister: false,
      notifyButton: {
        enable: false
      },
      persistNotification: false,
      welcomeNotification: {
        title: 'Kitsu'
      },
      promptOptions: {
        // actionMessage limited to 90 characters
        actionMessage: 'Enable notifications to stay updated on all the new activity.',
        // acceptButtonText limited to 15 characters
        acceptButtonText: 'SURE!',
        // cancelButtonText limited to 15 characters
        cancelButtonText: 'NO THANKS'
      }
    }]);
    (0, _injectScript.default)('https://cdn.onesignal.com/sdks/OneSignalSDK.js').catch(function () {});
  }

  function initialize() {
    // Don't bother if we don't have DOM access, FastBoot?
    if (!_canUseDom.default) {
      return;
    }

    if (_environment.default.kitsu.isStaging) {
      var meta = document.createElement('meta');
      meta.name = 'robots';
      meta.content = 'noindex, nofollow';
      document.head.appendChild(meta);
    } // Inject scripts


    adwords();
    canny();
    onesignal();
  }

  var _default = {
    name: 'browser-scripts',
    initialize: initialize
  };
  _exports.default = _default;
});