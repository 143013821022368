define("client/templates/components/users/edit-profile/footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/s/t5k9P",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"errorMessage\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"alert alert-danger\"],[9],[0,\"\\n    \"],[1,[23,\"errorMessage\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"p\"],[11,\"class\",\"link-note\"],[9],[0,\"\\n  Visit your \"],[7,\"a\"],[12,\"href\",[29,\"href-to\",[\"settings\"],null]],[12,\"onclick\",[23,\"transition\"]],[9],[0,\"settings page\"],[10],[0,\" to change your username, email, notification settings, and more.\\n\"],[10],[0,\"\\n\"],[7,\"button\"],[11,\"class\",\"button button--save-edit button--primary\"],[12,\"disabled\",[29,\"if\",[[29,\"or\",[[25,[\"updateTask\",\"isRunning\"]],[29,\"is-empty\",[[25,[\"isDirty\"]]],null]],null],\"disabled\"],null]],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"updateProfile\"],null]],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"updateTask\",\"isRunning\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"t\",[\"users.edit-modal.footer.updating\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[29,\"t\",[\"users.edit-modal.footer.update\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/users/edit-profile/footer.hbs"
    }
  });

  _exports.default = _default;
});