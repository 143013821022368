define("client/components/ad-unit", ["exports", "client/config/environment", "client/utils/inject-script"], function (_exports, _environment, _injectScript) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Borrowed from Discourse's method of loading scripts by Components.
   * This allows us to skip loading and injecting this script for PRO users.
   */
  var _scriptIsLoaded = false;
  var _promise = null;

  var loadGPTScript = function loadGPTScript() {
    if (_scriptIsLoaded) {
      return Ember.RSVP.resolve();
    }

    if (_promise) {
      return _promise;
    }

    var src = 'https://www.googletagservices.com/tag/js/gpt.js';
    _promise = (0, _injectScript.default)(src).then(function () {
      _scriptIsLoaded = true;
      window.googletag = window.googletag || {};
      window.googletag.cmd = window.googletag.cmd || [];
      window.googletag.cmd.push(function () {
        window.googletag.pubads().enableSingleRequest();
        window.googletag.pubads().disableInitialLoad();
        window.googletag.pubads().collapseEmptyDivs();
        window.googletag.enableServices();
      });
    });
    return _promise;
  };
  /**
   * Renders a GPT responsive ad unit.
   *
   *  {{ad-unit unit="<ad_unit_code>" sizes=(hash
   *    mobile=(array ...)
   *    tablet=(array ...)
   *    desktop=(array ...)
   *  )}}
   *
   * @TODO: Can observe the viewport size and refresh ads when it drops below a breakpoint.
   * @TODO: Can be extracted into a more generalized/supported addon.
   */


  var _default = Ember.Component.extend({
    classNames: ['kitsu-ad'],
    classNameBindings: ['isEnabled'],

    /** Default viewport breakpoints (width, height) */
    viewports: {
      mobile: [340, 400],
      tablet: [750, 200],
      desktop: [1024, 200]
    },
    session: Ember.inject.service(),
    adUnitPath: Ember.computed('unit', function () {
      var _Ember$get = Ember.get(this, 'googleConfig'),
          networkId = _Ember$get.networkId;

      return "/".concat(networkId, "/").concat(Ember.get(this, 'unit'));
    }),
    init: function init() {
      this._super.apply(this, arguments);

      var ads = _environment.default.google.ads;
      var enabled = ads.enabled;
      Ember.set(this, 'googleConfig', ads);
      Ember.set(this, 'isEnabled', enabled);
      Ember.set(this, 'adUnitId', "gpt-ad-unit-".concat(Ember.get(this, 'elementId')));
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments); // don't continue if this is a PRO user


      if (Ember.get(this, 'session.hasUser') && Ember.get(this, 'session.account.isPro')) {
        return;
      }

      if (Ember.get(this, 'isEnabled')) {
        this._initAd();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (Ember.get(this, 'isEnabled')) {
        this._destroyAd();
      }
    },

    /** Initializes the loading of the GPT script and catches failed loads */
    _initAd: function _initAd() {
      var _this = this;

      loadGPTScript().then(function () {
        if (Ember.get(_this, 'isDestroyed')) {
          return;
        } // API might not be ready yet.


        window.googletag.cmd = window.googletag.cmd || [];
        window.googletag.cmd.push(function () {
          if (Ember.get(_this, 'isDestroyed')) {
            return;
          }

          _this._deliverAd();
        });
      }).catch(function () {
        // an error occurred, maybe blocked by an adblock or failed network request
        if (Ember.get(_this, 'isDestroyed')) {
          return;
        }

        Ember.set(_this, 'isEnabled', false);
      });
    },
    _destroyAd: function _destroyAd() {
      var slotRef = Ember.get(this, 'adSlotRef');

      if (window.googletag && window.googletag.defineSlots && slotRef) {
        window.googletag.destroySlots([slotRef]);
      }
    },

    /** Setup all the GPT code required to deliver this ad */
    _deliverAd: function _deliverAd() {
      var viewports = Ember.get(this, 'viewports');
      var sizes = Ember.get(this, 'sizes'); // build responsive size mapping

      var mapping = window.googletag.sizeMapping();
      mapping.addSize([0, 0], [1, 1]);
      Object.keys(viewports).forEach(function (viewport) {
        var viewportSize = viewports[viewport];
        var adSizes = sizes[viewport];

        if (adSizes) {
          mapping.addSize(viewportSize, adSizes);
        }
      });
      mapping = mapping.build(); // define the ad slot

      var adUnitPath = Ember.get(this, 'adUnitPath');
      var divId = Ember.get(this, 'adUnitId');
      var targeting = Ember.get(this, 'targeting') || {};
      var initialSize = Object.values(sizes)[0];
      var slot = window.googletag.defineSlot(adUnitPath, initialSize || [], divId).defineSizeMapping(mapping).addService(window.googletag.pubads());
      Object.keys(targeting).forEach(function (targetingKey) {
        slot.setTargeting(targetingKey, targeting[targetingKey]);
      });
      Ember.set(this, 'adSlotRef', slot); // request and refresh the ad

      window.googletag.display(divId);
      window.googletag.pubads().refresh([slot]);
    }
  });

  _exports.default = _default;
});