define("client/abilities/review", ["exports", "client/abilities/post"], function (_exports, _post) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _post.default.extend();

  _exports.default = _default;
});