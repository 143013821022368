define("client/components/users/user-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['card', 'user-card'],
    classNameBindings: ['short:short:col-sm-3'],
    short: false,
    queryCache: Ember.inject.service(),
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      var currentUser = Ember.get(this, 'session.hasUser') && Ember.get(this, 'session.account.id');

      if (currentUser) {
        Ember.get(this, 'queryCache').query('follow', {
          filter: {
            follower: currentUser,
            followed: Ember.get(this, 'user.id')
          }
        }).then(function (records) {
          return Ember.set(_this, 'follow', Ember.get(records, 'firstObject'));
        });
      }
    }
  });

  _exports.default = _default;
});