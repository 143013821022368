define("client/controllers/anime/show/characters", ["exports", "client/controllers/media/show/characters", "ember-parachute"], function (_exports, _characters, _emberParachute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var queryParams = new _emberParachute.default({
    language: {
      defaultValue: 'japanese',
      refresh: true
    }
  });

  var _default = _characters.default.extend(queryParams.Mixin, {
    queryParamsDidChange: function queryParamsDidChange(_ref) {
      var shouldRefresh = _ref.shouldRefresh;

      if (shouldRefresh) {
        this.send('refreshModel');
      }
    }
  });

  _exports.default = _default;
});