define("client/templates/components/media/media-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wFBtflqL",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"stream-item-options\"],[9],[0,\"\\n\"],[0,\"  \"],[7,\"span\"],[11,\"class\",\"more-wrapper\"],[9],[0,\"\\n    \"],[7,\"a\"],[11,\"href\",\"#\"],[11,\"class\",\"more-drop\"],[11,\"data-toggle\",\"dropdown\"],[11,\"aria-haspopup\",\"true\"],[11,\"aria-expanded\",\"false\"],[9],[0,\"\\n      \"],[1,[29,\"svg-jar\",[\"more\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"dropdown-menu dropdown-menu-right\"],[9],[0,\"\\n\"],[0,\"      \"],[7,\"a\"],[11,\"class\",\"dropdown-item\"],[11,\"href\",\"#\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"isIgnoring\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\"],[9],[1,[29,\"t\",[\"media.show-posts.text\"],[[\"media\"],[[25,[\"media\",\"computedTitle\"]]]]],false],[10],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"option-info\"],[9],[1,[29,\"t\",[\"media.show-posts.info\"],[[\"type\"],[[29,\"capitalize\",[[25,[\"media\",\"modelType\"]]],null]]]],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[7,\"div\"],[9],[1,[29,\"t\",[\"media.hide-posts.text\"],[[\"media\"],[[25,[\"media\",\"computedTitle\"]]]]],false],[10],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"option-info\"],[9],[1,[29,\"t\",[\"media.hide-posts.info\"],[[\"type\"],[[29,\"capitalize\",[[25,[\"media\",\"modelType\"]]],null]]]],false],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[3,\"action\",[[24,0,[]],\"toggleIgnore\"]],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/media/media-actions.hbs"
    }
  });

  _exports.default = _default;
});