define("client/controllers/media/show/units/show", ["exports", "client/utils/humanize-duration", "moment"], function (_exports, _humanizeDuration, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    humanizedLength: Ember.computed('model.length', function () {
      var unitType = Ember.get(this, 'model.modelType');
      var length = Ember.get(this, 'model.length');

      if (unitType === 'episode') {
        var time = _moment.default.duration(length, 'minutes');

        return (0, _humanizeDuration.default)(time);
      }

      return Ember.get(this, 'intl').t('media.show.units.pages', {
        length: length
      });
    }).readOnly()
  });

  _exports.default = _default;
});