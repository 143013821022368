define("client/routes/group-invite", ["exports", "client/mixins/routes/data-error", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _dataError, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_dataError.default, _authenticatedRouteMixin.default, {
    authenticationRoute: 'dashboard',
    model: function model(_ref) {
      var id = _ref.id;
      return Ember.get(this, 'store').findRecord('group-invite', id, {
        include: 'group,sender,user',
        reload: true
      });
    },
    afterModel: function afterModel(model) {
      var userId = Ember.get(this, 'session.account.id');
      var inviteeId = Ember.get(model, 'user.id');

      if (userId !== inviteeId) {
        return this.transitionTo('dashboard');
      }
    }
  });

  _exports.default = _default;
});