define("client/routes/groups/group/group-page", ["exports", "client/mixins/routes/cover-page"], function (_exports, _coverPage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_coverPage.default, {
    breadcrumb: null,
    ajax: Ember.inject.service(),
    model: function model() {
      var model = this.modelFor('groups.group');
      return Ember.RSVP.hash({
        group: model,
        membership: Ember.get(model, 'userMembership')
      });
    },
    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);

      if (Ember.get(model, 'membership')) {
        var id = Ember.get(model, 'group.id');
        Ember.get(this, 'ajax').post("/groups/".concat(id, "/_read")).catch(function () {});
      }
    }
  });

  _exports.default = _default;
});