define("client/templates/components/library-entry/simple-rating", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SlDLkmix",
    "block": "{\"symbols\":[\"rating\"],\"statements\":[[4,\"each\",[[25,[\"ratings\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"simple-rating-wrapper hint--rounded hint--bounce hint--top\"],[12,\"aria-label\",[29,\"t\",[[29,\"concat\",[\"library-entry.rating.\",[24,1,[\"tag\"]]],null]],null]],[9],[0,\"\\n    \"],[1,[29,\"svg-jar\",[[24,1,[\"tag\"]]],[[\"width\",\"height\"],[\"50px\",\"50px\"]]],false],[0,\"\\n  \"],[3,\"action\",[[24,0,[]],\"onClick\",[24,1,[]]]],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/library-entry/simple-rating.hbs"
    }
  });

  _exports.default = _default;
});