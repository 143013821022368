define("client/components/image-form", ["exports", "ember-invoke-action"], function (_exports, _emberInvokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      triggerFileSelect: function triggerFileSelect() {
        this.$('input').click();
      },
      selectedImage: function selectedImage(event) {
        var _this = this;

        if (event.files && event.files[0]) {
          var reader = new FileReader();

          reader.onload = function (evt) {
            return Ember.run(function () {
              Ember.set(_this, 'dataURI', evt.target.result);
              Ember.set(_this, 'showCropperModal', true);
            });
          };

          reader.readAsDataURL(event.files[0]);
        }
      },
      updateImage: function updateImage(data) {
        (0, _emberInvokeAction.invokeAction)(this, 'update', data);
      },
      closeModal: function closeModal() {
        this.$('input').val('');
        Ember.set(this, 'showCropperModal', false);
      }
    }
  });

  _exports.default = _default;
});