define("client/models/group-ticket", ["exports", "client/models/-base", "ember-data/attr", "ember-data/relationships"], function (_exports, _base, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    createdAt: (0, _attr.default)('utc'),
    status: (0, _attr.default)('string'),
    assignee: (0, _relationships.belongsTo)('user'),
    firstMessage: (0, _relationships.belongsTo)('group-ticket-message'),
    group: (0, _relationships.belongsTo)('group'),
    user: (0, _relationships.belongsTo)('user'),
    messages: (0, _relationships.hasMany)('group-ticket-message', {
      inverse: 'ticket'
    }),
    isOpen: Ember.computed.equal('status', 'created'),
    isResolved: Ember.computed.equal('status', 'resolved')
  });

  _exports.default = _default;
});