define("client/storages/onboarding-ratings", ["exports", "ember-local-storage/session/array"], function (_exports, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Storage = _array.default.extend();

  Storage.reopenClass({
    initialState: function initialState() {
      return [];
    }
  });
  var _default = Storage;
  _exports.default = _default;
});