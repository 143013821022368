define("client/templates/groups/group/group-page/rules", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "q4CUsvMG",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"container group-community-rules\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"h2\"],[9],[1,[29,\"t\",[\"groups.rules.header\"],[[\"group\"],[[25,[\"group\",\"name\"]]]]],false],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"group\",\"rules\"]]],null,{\"statements\":[[0,\"      \"],[1,[29,\"html-safe\",[[25,[\"group\",\"rulesFormatted\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"p\"],[9],[0,\"\\n\"],[4,\"if\",[[29,\"can\",[\"manage settings for group\"],[[\"membership\"],[[25,[\"membership\"]]]]]],null,{\"statements\":[[0,\"        \"],[7,\"p\"],[11,\"class\",\"m-b-2\"],[9],[0,\"\\n          \"],[1,[29,\"t\",[\"groups.rules.empty\"],[[\"link\",\"htmlSafe\"],[[29,\"href-to\",[\"groups.group.dashboard.settings\"],null],true]]],false],[0,\"\\n        \"],[10],[0,\"\\n        \"],[1,[29,\"render-markdown\",[\"community-guidelines\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[29,\"render-markdown\",[\"community-guidelines\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/groups/group/group-page/rules.hbs"
    }
  });

  _exports.default = _default;
});