define("client/utils/observer-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = observerManager;
  _exports.ListenerMap = _exports.ObserverMap = void 0;
  var ObserverMap = new Map();
  _exports.ObserverMap = ObserverMap;
  var ListenerMap = new WeakMap();
  _exports.ListenerMap = ListenerMap;

  var sortObject = function sortObject(object) {
    return Object.keys(object).sort().reduce(function (previous, current) {
      if (Ember.typeOf(object[current]) === 'object') {
        // eslint-disable-next-line
        previous[current] = sortObject(object[current]);
        return previous;
      }

      previous[current] = object[current]; // eslint-disable-line

      return previous;
    }, {});
  };

  var mapGetWithDefault = function mapGetWithDefault(map, key, value) {
    if (map.has(key)) {
      return map.get(key);
    }

    map.set(key, value);
    return map.get(key);
  }; // Code based on https://github.com/m59peacemaker/browser-insular-observer


  function observerManager() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    // Get an observer that we have already registered with these options,
    // or create a new one.
    var key = JSON.stringify(sortObject(options));
    var observer = ObserverMap.get(key);

    if (observer === undefined) {
      var callback = function callback(entries) {
        entries.forEach(function (entry) {
          var targets = mapGetWithDefault(ListenerMap, entry.target, []);
          targets.forEach(function (listener) {
            return listener(entry);
          });
        });
      };

      observer = new IntersectionObserver(callback, options);
      ObserverMap.set(key, observer);
    } // Observe the target using the correct IntersectionObserver


    return function observe(target, listener) {
      var targets = mapGetWithDefault(ListenerMap, target, []);
      targets.push(listener);
      observer.observe(target); // Return a function to cleanup

      return function disconnect() {
        var idx = targets.indexOf(listener);
        targets.splice(idx, 1);

        if (targets.length === 0) {
          ListenerMap.delete(target);
          return observer.unobserve && observer.unobserve(target);
        }
      };
    };
  }
});