define("client/templates/components/episodes/episode-video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p7X2EkIM",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"video\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"episodes/video-player\",null,[[\"video\",\"onMark\",\"isPlaying\"],[[25,[\"video\"]],[29,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"isMarking\"]]],null],true],null],[25,[\"isPlaying\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[25,[\"isMarking\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"episodes/video-updater\",null,[[\"episode\"],[[25,[\"episode\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[1,[29,\"episodes/video-picker\",null,[[\"onChange\",\"onClickCurrent\",\"isPlaying\",\"episode\",\"selectedVideo\",\"media\"],[[29,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"video\"]]],null]],null],[29,\"action\",[[24,0,[]],[29,\"toggle\",[\"isPlaying\",[24,0,[]]],null]],null],[25,[\"isPlaying\"]],[25,[\"episode\"]],[25,[\"video\"]],[25,[\"media\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/episodes/episode-video.hbs"
    }
  });

  _exports.default = _default;
});