define("client/models/anime", ["exports", "ember-data/attr", "ember-data/relationships", "client/models/media", "client/mixins/models/episodic"], function (_exports, _attr, _relationships, _media, _episodic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _media.default.extend(_episodic.default, {
    ageRating: (0, _attr.default)('string'),
    ageRatingGuide: (0, _attr.default)('string'),
    youtubeVideoId: (0, _attr.default)('string'),
    animeProductions: (0, _relationships.hasMany)('anime-production'),
    streamingLinks: (0, _relationships.hasMany)('streaming-link'),
    shouldShowAds: Ember.computed('nsfw', 'ageRating', function () {
      return !(this.nsfw || this.ageRating === 'R');
    })
  });

  _exports.default = _default;
});