define("client/serializers/group-report", ["exports", "client/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      createdAt: {
        serialize: false
      },
      naughtyType: {
        serialize: false
      },
      naughtyId: {
        serialize: false
      }
    },
    serializeBelongsTo: function serializeBelongsTo(snapshot, json, relationship) {
      var belongsTo = snapshot.belongsTo(relationship.key);

      if (Ember.isPresent(belongsTo)) {
        this._super.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});