define("client/routes/feedback/mobile-bugs-anything", ["exports", "client/routes/feedback/mobile-bugs"], function (_exports, _mobileBugs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mobileBugs.default.extend({
    templateName: 'feedback/mobile-bugs',
    intl: Ember.inject.service(),
    titleToken: function titleToken() {
      return Ember.get(this, 'intl').t('titles.feedback.mobile-bugs');
    }
  });

  _exports.default = _default;
});