define("client/models/list-import", ["exports", "client/models/-base", "ember-data/attr", "ember-data/relationships"], function (_exports, _base, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    createdAt: (0, _attr.default)('date'),
    errorMessage: (0, _attr.default)('string'),
    errorTrace: (0, _attr.default)('string'),
    inputFile: (0, _attr.default)('object'),
    inputText: (0, _attr.default)('string'),
    kind: (0, _attr.default)('string'),
    progress: (0, _attr.default)('number'),
    status: (0, _attr.default)('string'),
    strategy: (0, _attr.default)('string'),
    total: (0, _attr.default)('number'),
    user: (0, _relationships.belongsTo)('user')
  });

  _exports.default = _default;
});