define("client/components/users/library/library-list/bulk-edit", ["exports", "ember-invoke-action", "client/models/library-entry"], function (_exports, _emberInvokeAction, _libraryEntry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'libraryEntryStatuses', _libraryEntry.LIBRARY_STATUSES);
    },
    actions: {
      removeEntriesBulk: function removeEntriesBulk() {
        var _this = this;

        Ember.set(this, 'isRemoveLoading', true);

        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        _emberInvokeAction.invokeAction.apply(void 0, [this, 'removeEntriesBulk'].concat(args)).finally(function () {
          Ember.set(_this, 'isRemoveLoading', false);
        });
      },
      updateStatusBulk: function updateStatusBulk() {
        var _this2 = this;

        Ember.set(this, 'isUpdateLoading', true);

        for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
          args[_key2] = arguments[_key2];
        }

        _emberInvokeAction.invokeAction.apply(void 0, [this, 'updateStatusBulk'].concat(args)).finally(function () {
          Ember.set(_this2, 'isUpdateLoading', false);
        });
      }
    }
  });

  _exports.default = _default;
});