define("client/services/local-cache", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    localCache: (0, _emberLocalStorage.storageFor)('local-cache'),

    /**
     * Builds a cache object of:
     *  type: { key: value }
     *
     * @param {String} type
     * @param {String} key
     * @param {any} value
     */
    addToCache: function addToCache(type, key, value) {
      var exists = Ember.get(this, 'localCache').get(type);

      if (!exists) {
        Ember.get(this, 'localCache').set(type, {});
      }

      Ember.get(this, 'localCache').set("".concat(type, ".").concat(key), value);
    },

    /**
     * Retrieves a value from the local cache.
     *
     * @param {String} type
     * @param {String} key
     * @returns {any|null}
     */
    getFromCache: function getFromCache(type, key) {
      var typeCache = Ember.get(this, 'localCache').get(type);

      if (!typeCache) {
        return null;
      }

      return Ember.get(typeCache, key);
    }
  });

  _exports.default = _default;
});