define("client/routes/explore/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    model: function model() {
      return this.modelFor('explore');
    },
    afterModel: function afterModel() {
      this._super.apply(this, arguments);

      var _this$paramsFor = this.paramsFor('explore'),
          mediaType = _this$paramsFor.media_type;

      Ember.set(this, 'breadcrumb', "Explore ".concat(Ember.String.capitalize(mediaType)));
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      var _this$paramsFor2 = this.paramsFor('explore'),
          mediaType = _this$paramsFor2.media_type;

      Ember.set(controller, 'mediaType', mediaType);
    },
    titleToken: function titleToken() {
      var _this$paramsFor3 = this.paramsFor('explore'),
          mediaType = _this$paramsFor3.media_type;

      return Ember.get(this, 'intl').t('titles.explore.index', {
        mediaType: mediaType
      });
    }
  });

  _exports.default = _default;
});