define("client/components/power-select", ["exports", "ember-power-select/components/power-select"], function (_exports, _powerSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Override `ember-power-select` to support intl messages
  var _default = _powerSelect.default.extend({
    intl: Ember.inject.service(),
    loadingMessage: Ember.computed('intl.locale', function () {
      return Ember.get(this, 'intl').t('selects.loading');
    }),
    noMatchesMessage: Ember.computed('intl.locale', function () {
      return Ember.get(this, 'intl').t('selects.none');
    }),
    searchMessage: Ember.computed('intl.locale', function () {
      return Ember.get(this, 'intl').t('selects.search');
    })
  });

  _exports.default = _default;
});