define("client/controllers/dashboard", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MAGIC_NUMBER = 7;

  var _default = Ember.Controller.extend({
    lastUsed: (0, _emberLocalStorage.storageFor)('last-used'),
    streamId: Ember.computed('streamType', 'streamInterest', function () {
      var streamType = Ember.get(this, 'streamType');
      var interest = Ember.get(this, 'streamInterest');

      if (interest && streamType === 'interest_timeline') {
        return "".concat(Ember.get(this, 'session.account.id'), "-").concat(Ember.String.capitalize(interest));
      }

      return streamType === 'global' ? 'global' : Ember.get(this, 'session.account.id');
    }).readOnly(),
    init: function init() {
      this._super.apply(this, arguments);

      var defaultType = this._getDefaultType();

      var cachedType = Ember.get(this, 'lastUsed.feedType');

      if (this._isCachedTypeOutdated(cachedType)) {
        Ember.set(this, 'lastUsed.feedType', defaultType);
      }

      if (cachedType && cachedType.includes('/')) {
        Ember.set(this, 'streamInterest', cachedType.split('/')[1]);
        Ember.set(this, 'streamType', cachedType.split('/')[0]);
      } else {
        Ember.set(this, 'streamType', cachedType || defaultType);
      }
    },
    actions: {
      switchFeed: function switchFeed(type) {
        window.scrollTo(0, 0);

        if (type.includes('/')) {
          Ember.set(this, 'streamInterest', type.split('/')[1]);
          Ember.set(this, 'streamType', type.split('/')[0]);
        } else {
          Ember.set(this, 'streamInterest', null);
          Ember.set(this, 'streamType', type);
        }

        Ember.set(this, 'lastUsed.feedType', type);
      }
    },
    _getDefaultType: function _getDefaultType() {
      if (Ember.get(this, 'session.account.followingCount') >= MAGIC_NUMBER) {
        return 'timeline';
      }

      return 'global';
    },
    _isCachedTypeOutdated: function _isCachedTypeOutdated(type) {
      return type === 'group_timeline';
    }
  });

  _exports.default = _default;
});