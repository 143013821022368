define("client/utils/languages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.LANGUAGE_CODES = _exports.LANGUAGES = void 0;
  // Listed alphabetically by ID. Client displays alphabetically by text
  // https://crowdin.com/project/kitsu-web
  // Also update config/environments.js when adding a new language group so
  // they can be used as Moment locales
  // Unfortunately a seperate file as Ember cannot import ESM in config files
  var LANGUAGES = [// { id: 'af-za', text: 'Afrikaans' },
  // { id: 'am-et', text: '' }, // Amharic
  {
    id: 'ar-001',
    text: 'العربية (BETA)'
  }, // rtl
  // { id: 'as-in', text: '' }, // Assamese
  // { id: 'az-az', text: '' }, // Azerbaijani
  // { id: 'be-by', text: '' }, // Belarusian
  // { id: 'bg-bg', text: 'Български' }, // Bulgarian
  // { id: 'bn-bd', text: 'বাংলা (বাংলাদেশ)' }, // Bengali
  // { id: 'bs-ba', text: '' }, // Bosnian
  {
    id: 'ca-es',
    text: 'Català'
  }, // { id: 'ceb-ph', text: '' }, // Cebuano
  // { id: 'co-fr', text: '' }, // Corsican
  {
    id: 'cs-cz',
    text: 'Česky'
  }, // { id: 'cy-gb', text: 'Cymraeg' } // Welsh
  // { id: 'da-dk', text: 'Dansk' }, // Danish
  {
    id: 'de-de',
    text: 'Deutsch'
  }, // { id: 'el-gr', text: 'Ελληνικά' }, // Greek
  // { id: 'en-au', text: 'English (Australian)' },
  // { id: 'en-ca', text: 'English (Canadian)' },
  {
    id: 'en-gb',
    text: 'English (British)'
  }, // { id: 'en-pt', text: 'English (Pirate)' },
  {
    id: 'en-us',
    text: 'English (American)'
  }, {
    id: 'eo-uy',
    text: 'Esperanto'
  }, // Should be eo and not eo-uy
  {
    id: 'es-ar',
    text: 'Español (Argentino)'
  }, {
    id: 'es-es',
    text: 'Español'
  }, {
    id: 'es-mx',
    text: 'Español (Mexicano)'
  }, // { id: 'et-ee', text: 'Eesti Keel' }, // Estonian
  // { id: 'eu-es', text: 'Euskara' }, // Basque
  // { id: 'fa-af', text: 'دری (BETA)' }, // Dari, Persian (Afghanistan) rtl
  // { id: 'fa-ir', text: 'فارسی (BETA)' }, // Persian (Iran) rtl
  // { id: 'fi-fi', text: 'Suomi' }, // Finnish
  {
    id: 'mis-ca',
    text: 'Français (Canadien, Acadien)'
  }, {
    id: 'fr-ca',
    text: 'Français (Canadien, Québécois)'
  }, {
    id: 'fr-fr',
    text: 'Français'
  }, // { id: 'fy-nl', text: 'Frysk' }, // Westerlauwersk Frysk, Western Frisian
  // { id: 'ga-ie', text: 'Gaeilge' }, // Irish
  // { id: 'gl-es', text: 'galego' }, // Galician
  // { id: 'gu-in', text: 'ગુજરાતી' }, // Gujarati
  // { id: 'ha-hg', text: 'هَرْشَن هَوْسَ' }, // Hausa, Should be ha-ng and not ha-hg
  // { id: 'haw-us', text: 'ʻŌlelo Hawaiʻi' }, Hawaiian
  // { id: 'he-il', text: 'עברית (BETA)' }, // Hebrew rtl
  // { id: 'hi-in', text: 'हिन्दी' }, // Hindi
  // { id: 'hil-ph', text: '' }, // Hiligaynon
  // { id: 'hmn-cn', text: '' }, // Hmong, Miao
  // { id: 'hr-hr', text: 'Hrvatski' }, // Croatian
  // { id: 'ht-ht', text: 'kreyòl ayisyen' }, // Haitian
  {
    id: 'hu-hu',
    text: 'Magyar'
  }, // { id: 'hy-am', text: 'հայերեն' }, // Armenian
  {
    id: 'id-id',
    text: 'Bahasa Indonesia'
  }, // { id: 'ig-ng', text: 'Ásụ̀sụ̀ Ìgbò' }, // Igbo
  // { id: 'is-is', text: 'Íslenska' }, // Icelandic
  {
    id: 'it-it',
    text: 'Italiano'
  }, // { id: 'ja-jp', text: '日本語' }, // Japanese
  // { id: 'jv-id', text: 'ꦧꦱꦗꦮ' }, // Javanese
  // { id: 'ka-ge', text: 'ქართული' }, // Georgian
  // { id: 'kk-kz', text: 'Қазақ' }, // Kazakh
  // { id: 'km-kh', text: '' }, // Khmer
  // { id: 'kn-in', text: 'ಕನ್ನಡ' }, // Kannada
  // { id: 'ko-kr', text: '한국어' }, // Korean
  // { id: 'ku-tr', text: '' }, // Kurdish
  // { id: 'ky-kg', text: '' }, // Kyrgyz
  // { id: 'lb-lu', text: 'Lëtzebuergesch' }, // Luxembourgish
  // { id: 'lo-la', text: '' }, // Lao
  // { id: 'lt-lt', text: 'Lietuvių Kalba' }, // Lithuanian
  // { id: 'lv-lv', text: 'Latviešu' }, // Latvian
  // { id: 'mai-in', text: '' }, // Maithili
  // { id: 'mg-mg', text: '' }, // Malagasy
  // { id: 'mi-nz', text: '' }, // Maori
  // { id: 'mk-mk', text: '' }, // Macedonian
  // { id: 'ml-in', text: '' }, // Malayalam
  // { id: 'mn-mn', text: '' }, // Mongolian
  // { id: 'mr-in', text: '' }, // Marathi
  // { id: 'ms-my', text: 'Bahasa Melayu' }, // Malay
  // { id: 'mt-mt', text: 'Malti' }, // Maltese
  // { id: 'my-mm', text: '' }, // Burmese
  {
    id: 'nb-no',
    text: 'Norsk (Bokmål)'
  }, // { id: 'ne-np', text: '' }, // Nepali
  {
    id: 'nl-nl',
    text: 'Nederlands'
  }, // { id: 'nn-no', text: 'Norsk (Nynorsk)' }, // Norwegian
  // { id: 'ny-mw', text: '' }, // Chewa
  // { id: 'or-in', text: '' }, // Oriya
  // { id: 'pa-in', text: '' }, // Panjabi
  {
    id: 'pl-pl',
    text: 'Polski'
  }, // { id: 'ps-af', text: '' }, // Pushto
  {
    id: 'pt-br',
    text: 'Português (Brasileiro)'
  }, {
    id: 'pt-pt',
    text: 'Português'
  }, // { id: 'ro-ro', text: 'Română' }, // Romanian
  {
    id: 'ru-ru',
    text: 'Русский'
  }, // { id: 'rw-rw', text: '' }, // Kinyarwanda
  // { id: 'sc-it', text: 'Sardu' }, // Sardinian
  // { id: 'sco-gb', text: 'Scots' },
  // { id: 'sd-pk', text: 'سنڌي' }, // Sindhi
  // { id: 'si-lk', text: '' }, // Sinhala
  // { id: 'sk-sk', text: 'Slovenčina' }, // Slovak
  // { id: 'sl-sl', text: 'Slovenščina' }, // Slovenian
  // { id: 'sn-zw', text: '' }, // Shona
  // { id: 'so-so', text: '' }, // Somali
  // { id: 'sq-al', text: '' }, // Albanian
  // { id: 'sr-cs', text: 'Srpski' }, // Serbian Latin
  // { id: 'sr-sp', text: 'Српски' }, // Serbian Cyrillic
  // { id: 'su-id', text: '' }, // Sundanese
  {
    id: 'sv-se',
    text: 'Svenska'
  }, // { id: 'sw-ke', text: '' }, // Swahili
  // { id: 'ta-in', text: '' }, // Tamil
  // { id: 'te-in', text: '' }, // Telugu
  // { id: 'tg-tj', text: '' }, // Tajik
  // { id: 'th-th', text: 'ไทย' }, // Thai
  // { id: 'tk-tm', text: '' }, // Turkmen
  {
    id: 'tl-ph',
    text: 'Wikang Tagalog'
  }, // { id: 'tlh-aa', text: 'tlhIngan Hol' }, // Klingon
  {
    id: 'tr-tr',
    text: 'Türkçe'
  }, // { id: 'tt-ru', text: '' }, // Tatar
  // { id: 'ug-cn', text: '' }, // Uighur
  // { id: 'uk-ua', text: 'Українська' }, // Ukrainian
  // { id: 'ur-in', text: 'اردو (پاکستانی)' }, // Urdu Indian
  // { id: 'ur-pk', text: 'اردو (ہندوستانی)' }, // Urdu Pakistani
  // { id: 'uz-uz', text: '' }, // Uzbek
  // { id: 'val-es', text: 'valencià' }, // Valencian
  // { id: 'vi-vn', text: 'Việtnam' },
  // { id: 'vls-be', text: '' }, // West Flemish
  // { id: 'xh-za', text: '' }, // Xhosa
  // { id: 'yo-ng', text: '' }, // Yoruba
  {
    id: 'zh-cn',
    text: '中文 (简体)'
  } // Mainland China, Simplified Mandarin
  // { id: 'zh_hk', text: '' }, // Hong Kong, Traditional Cantonese
  // { id: 'zh-tw', text: '中文 (繁體)' }, // Taiwan, Traditional Mandarin
  // { id: 'zu-za', text: '' } // Zulu
  ];
  _exports.LANGUAGES = LANGUAGES;
  var LANGUAGE_CODES = LANGUAGES.map(function (locale) {
    return locale.id;
  });
  _exports.LANGUAGE_CODES = LANGUAGE_CODES;
});