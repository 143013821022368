define("client/templates/components/media/units/unit-thumbnail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NC6Ye0GU",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[30,[\"media-unit--block \",[29,\"if\",[[29,\"gte\",[[25,[\"libraryEntry\",\"progress\"]],[25,[\"unit\",\"number\"]]],null],\"complete\"],null]]]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"thumbnail-wrapper\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"completion-status\"],[9],[1,[29,\"t\",[\"media.show.units.completed\"],[[\"type\"],[[25,[\"unit\",\"modelType\"]]]]],false],[10],[0,\"\\n\"],[4,\"if\",[[29,\"is-object-empty\",[[25,[\"unit\",\"thumbnail\"]]],null]],null,{\"statements\":[[0,\"      \"],[1,[29,\"lazy-image\",null,[[\"src\"],[[29,\"image\",[[25,[\"media\",\"posterImage\"]],\"medium\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[29,\"lazy-image\",null,[[\"src\"],[[29,\"image\",[[25,[\"unit\",\"thumbnail\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"thumbnail-description\"],[9],[0,\"\\n    \"],[7,\"h6\"],[9],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"unit-number\"],[9],[0,\"\\n        \"],[1,[29,\"t\",[\"media.show.units.unit\"],[[\"type\"],[[25,[\"unit\",\"modelType\"]]]]],false],[0,\"\\n        \"],[1,[25,[\"unit\",\"number\"]],false],[0,\"\\n      \"],[10],[0,\"\\n\"],[4,\"if\",[[29,\"not\",[[29,\"eq\",[[25,[\"unit\",\"canonicalTitle\"]],[29,\"concat\",[[29,\"capitalize\",[[25,[\"unit\",\"modelType\"]]],null],\" \",[25,[\"unit\",\"number\"]]],null]],null]],null]],null,{\"statements\":[[0,\"        \"],[7,\"span\"],[11,\"class\",\"info-emphasis thumb-caption\"],[9],[1,[25,[\"unit\",\"canonicalTitle\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/media/units/unit-thumbnail.hbs"
    }
  });

  _exports.default = _default;
});