define("client/templates/components/users/library/library-empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Bbhh0/eg",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"library-empty-block text-xs-center m-t-2 m-b-2 text-muted\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"isSearching\"]]],null,{\"statements\":[[0,\"    \"],[7,\"h5\"],[9],[1,[29,\"t\",[\"users.library.list.empty.search\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[29,\"is-self\",[[25,[\"user\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"h5\"],[9],[1,[29,\"t\",[\"users.library.list.empty.header\"],[[\"type\"],[[25,[\"media\"]]]]],false],[10],[0,\"\\n    \"],[7,\"a\"],[12,\"href\",[29,\"href-to\",[\"anime.index\"],null]],[11,\"class\",\"button button--primary m-t-1\"],[9],[0,\"\\n      \"],[1,[29,\"t\",[\"users.library.list.empty.button\"],[[\"type\"],[[29,\"capitalize\",[[25,[\"media\"]]],null]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"h5\"],[9],[1,[29,\"t\",[\"users.library.list.empty.guest-header\"],[[\"user\"],[[25,[\"user\",\"name\"]]]]],false],[10],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/users/library/library-empty.hbs"
    }
  });

  _exports.default = _default;
});