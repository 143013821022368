define("client/templates/components/users/edit-profile/body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2xb5eYHp",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"tab-content\"],[9],[0,\"\\n  \"],[1,[29,\"users/edit-profile/body/about-me\",null,[[\"id\",\"class\",\"user\",\"addRecord\",\"removeRecord\"],[\"about-me\",[29,\"if\",[[29,\"eq\",[[25,[\"tab\"]],\"about-me\"],null],\"active\"],null],[25,[\"user\"]],[25,[\"addRecord\"]],[25,[\"removeRecord\"]]]]],false],[0,\"\\n\\n  \"],[1,[29,\"users/edit-profile/body/profile-links\",null,[[\"id\",\"class\",\"user\",\"addRecord\",\"removeRecord\"],[\"profile-links\",[29,\"if\",[[29,\"eq\",[[25,[\"tab\"]],\"profile-links\"],null],\"active\"],null],[25,[\"user\"]],[25,[\"addRecord\"]],[25,[\"removeRecord\"]]]]],false],[0,\"\\n\\n  \"],[1,[29,\"users/edit-profile/body/favorites\",null,[[\"id\",\"class\",\"user\",\"externalData\",\"addRecord\",\"removeRecord\"],[\"favorites\",[29,\"if\",[[29,\"eq\",[[25,[\"tab\"]],\"favorites\"],null],\"active\"],null],[25,[\"user\"]],[25,[\"externalData\"]],[25,[\"addRecord\"]],[25,[\"removeRecord\"]]]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/users/edit-profile/body.hbs"
    }
  });

  _exports.default = _default;
});