define("client/templates/components/groups/activity/group-members", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ECFbNGbV",
    "block": "{\"symbols\":[\"member\"],\"statements\":[[7,\"h6\"],[11,\"class\",\"panel-heading\"],[9],[1,[29,\"t\",[\"groups.activity.members\"],null],false],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"getMembersTask\",\"isRunning\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"w-100 text-xs-center m-t-1 m-b-1\"],[9],[0,\"\\n    \"],[1,[29,\"loading-spinner\",null,[[\"size\"],[\"large\"]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"getMembersTask\",\"last\",\"error\"]]],null,{\"statements\":[[0,\"  \"],[7,\"p\"],[9],[1,[29,\"t\",[\"errors.load\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"getMembersTask\",\"last\",\"value\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"group-member-avatars\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"getMembersTask\",\"last\",\"value\"]]],null,{\"statements\":[[4,\"if\",[[24,1,[\"user\"]]],null,{\"statements\":[[0,\"        \"],[7,\"a\"],[12,\"href\",[29,\"href-to\",[\"users.index\",[24,1,[\"user\"]]],null]],[11,\"class\",\"hint--top hint--bounce hint--rounded\"],[12,\"aria-label\",[24,1,[\"user\",\"name\"]]],[9],[0,\"\\n          \"],[1,[29,\"lazy-image\",null,[[\"src\"],[[29,\"image\",[[24,1,[\"user\",\"avatar\"]],\"small\"],null]]]],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[4,\"if\",[[29,\"gt\",[[25,[\"getMembersTask\",\"last\",\"value\",\"meta\",\"count\"]],14],null]],null,{\"statements\":[[0,\"    \"],[7,\"a\"],[12,\"href\",[29,\"href-to\",[\"groups.group.group-page.members\"],null]],[9],[1,[29,\"t\",[\"button.view-all\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/groups/activity/group-members.hbs"
    }
  });

  _exports.default = _default;
});