define("client/routes/groups/index", ["exports", "kitsu-shared/mixins/pagination"], function (_exports, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_pagination.default, {
    model: function model(params) {
      return {
        taskInstance: this.queryPaginated('group', this._getRequestOptions(params)),
        paginatedRecords: []
      };
    },
    headTags: function headTags() {
      var description = "Looking for a place to discuss a topic or activity?\n      Check out Groups on Kitsu.";
      return [{
        type: 'meta',
        tagId: 'meta-description',
        attrs: {
          property: 'description',
          content: description
        }
      }, {
        type: 'meta',
        tagId: 'meta-og-description',
        attrs: {
          property: 'og:description',
          content: description
        }
      }];
    },
    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      }
    },
    _getRequestOptions: function _getRequestOptions(_ref) {
      var category = _ref.category,
          sort = _ref.sort,
          query = _ref.query;
      return {
        filter: {
          category: category !== 'all' ? category : undefined,
          query: Ember.isPresent(query) ? query : undefined,
          featured: sort === 'featured' ? true : undefined
        },
        fields: {
          groups: ['slug', 'name', 'avatar', 'tagline', 'membersCount', 'category'].join(',')
        },
        sort: Ember.isPresent(query) ? undefined : this._getRealSort(sort),
        include: 'category',
        page: {
          limit: 20
        }
      };
    },
    _getRealSort: function _getRealSort(sort) {
      switch (sort) {
        case 'newest':
          return '-created_at';

        case 'oldest':
          return 'created_at';

        default:
          return '-last_activity_at';
      }
    }
  });

  _exports.default = _default;
});