define("client/components/users/library/library-search", ["exports", "ember-invoke-action"], function (_exports, _emberInvokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      Ember.set(this, 'dupQuery', Ember.get(this, 'query'));
    },
    actions: {
      updateQuery: function updateQuery(content) {
        Ember.set(this, 'dupQuery', content);
        (0, _emberInvokeAction.invokeAction)(this, 'update', content);
      }
    }
  });

  _exports.default = _default;
});