define("client/routes/users/following", ["exports", "kitsu-shared/mixins/pagination"], function (_exports, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_pagination.default, {
    intl: Ember.inject.service(),
    model: function model() {
      var user = this.modelFor('users');
      return {
        taskInstance: this.queryPaginated('follow', {
          filter: {
            follower: Ember.get(user, 'id')
          },
          fields: {
            users: ['avatar', 'coverImage', 'name', 'slug'].join(',')
          },
          include: 'followed',
          sort: '-created_at',
          page: {
            limit: 20
          }
        }),
        paginatedRecords: []
      };
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      Ember.set(controller, 'user', this.modelFor('users'));
    },
    titleToken: function titleToken() {
      var model = this.modelFor('users');
      var name = Ember.get(model, 'name');
      return Ember.get(this, 'intl').t('titles.users.following', {
        user: name
      });
    }
  });

  _exports.default = _default;
});