define("client/components/canny-feedback-script", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._createCannyElement();
    },
    _createCannyElement: function _createCannyElement() {
      var element = document.createElement('script');
      var content = document.createTextNode("\n      Canny('render', ".concat(JSON.stringify(this._cannyObject()), ");\n    "));
      element.appendChild(content);
      Ember.get(this, 'element').appendChild(element);
    },
    _cannyObject: function _cannyObject() {
      var baseObject = {
        basePath: Ember.get(this, 'path'),
        boardToken: Ember.get(this, 'boardToken')
      };

      if (Ember.get(this, 'session.hasUser')) {
        baseObject.ssoToken = Ember.get(this, 'token');
      }

      return baseObject;
    }
  });

  _exports.default = _default;
});