define("client/components/facebook-button", ["exports", "ember-invoke-action"], function (_exports, _emberInvokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'button',
    classNames: ['button', 'button--facebook'],
    attributeBindings: ['text:title', 'text:aria-label', 'disabled'],
    click: function click() {
      (0, _emberInvokeAction.strictInvokeAction)(this, 'onclick');
    }
  });

  _exports.default = _default;
});