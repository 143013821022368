define("client/routes/groups/group", ["exports", "client/mixins/routes/data-error", "client/mixins/routes/canonical-redirect"], function (_exports, _dataError, _canonicalRedirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_dataError.default, _canonicalRedirect.default, {
    queryCache: Ember.inject.service(),
    model: function model(_ref) {
      var _this = this;

      var slug = _ref.slug;

      if (slug.match(/\D+/)) {
        return Ember.get(this, 'queryCache').query('group', {
          filter: {
            slug: slug
          }
        }).then(function (records) {
          var record = Ember.get(records, 'firstObject');
          return _this._getMembership(record).then(function (membership) {
            Ember.set(record, 'userMembership', membership);
            return record;
          });
        });
      }

      return Ember.get(this, 'store').findRecord('group', slug, {
        reload: true
      });
    },
    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);

      Ember.set(this, 'breadcrumb', Ember.get(model, 'name'));
      var tags = this.setHeadTags(model);
      Ember.set(this, 'headTags', tags);
    },
    serialize: function serialize(model) {
      return {
        slug: Ember.get(model, 'slug')
      };
    },
    setHeadTags: function setHeadTags(model) {
      return [{
        type: 'meta',
        tagId: 'meta-description',
        attrs: {
          property: 'description',
          content: Ember.get(model, 'tagline')
        }
      }, {
        type: 'meta',
        tagId: 'meta-og-title',
        attrs: {
          property: 'og:title',
          content: Ember.get(model, 'name')
        }
      }, {
        type: 'meta',
        tagId: 'meta-og-description',
        attrs: {
          property: 'og:description',
          content: Ember.get(model, 'tagline')
        }
      }, {
        type: 'meta',
        tagId: 'meta-og-image',
        attrs: {
          property: 'og:image',
          content: Ember.get(model, 'avatar.large') || Ember.get(model, 'avatar')
        }
      }, {
        type: 'meta',
        tagId: 'meta-twitter-label1',
        attrs: {
          property: 'twitter:label1',
          content: 'Members'
        }
      }, {
        type: 'meta',
        tagId: 'meta-twitter-data1',
        attrs: {
          property: 'twitter:data1',
          content: Ember.get(model, 'membersCount')
        }
      }];
    },
    _getMembership: function _getMembership(group) {
      if (!group || !Ember.get(this, 'session.hasUser')) {
        return Ember.RSVP.resolve();
      }

      return Ember.get(this, 'queryCache').query('group-member', {
        include: 'user,permissions',
        filter: {
          group: Ember.get(group, 'id'),
          user: Ember.get(this, 'session.account.id')
        }
      }).then(function (records) {
        return Ember.get(records, 'firstObject');
      });
    }
  });

  _exports.default = _default;
});