define("client/routes/explore/more", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),

    /**
     * Since the page is built using data-driven components, we don't know if this section exists
     * or not. So we don't render a page that is half-broken we redirect if we don't have a
     * translations setup.
     */
    beforeModel: function beforeModel() {
      var title = this.titleToken();

      if (title && title.toString().includes('Missing translation')) {
        this.transitionTo('explore.index');
      }
    },
    model: function model() {
      return this.modelFor('explore');
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      var _this$paramsFor = this.paramsFor('explore.more'),
          type = _this$paramsFor.type;

      var _this$paramsFor2 = this.paramsFor('explore'),
          mediaType = _this$paramsFor2.media_type;

      Ember.setProperties(controller, {
        type: type,
        mediaType: mediaType
      });
    },
    titleToken: function titleToken() {
      var _this$paramsFor3 = this.paramsFor('explore.more'),
          type = _this$paramsFor3.type;

      var _this$paramsFor4 = this.paramsFor('explore'),
          mediaType = _this$paramsFor4.media_type;

      var title = Ember.get(this, 'intl').t("titles.explore.more.".concat(type), {
        type: Ember.String.capitalize(mediaType)
      });
      Ember.set(this, 'breadcrumb', title);
      return title;
    }
  });

  _exports.default = _default;
});