define("client/abilities/library-entry", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend({
    /**
     * Determines if the sessioned user is the owner of the library entry.
     *    (can "edit library-entry" <library-entry|user>)
     *
     * @returns {Boolean}
     */
    canEdit: Ember.computed('session.hasUser', 'model', 'user', function () {
      if (!Ember.get(this, 'session.hasUser')) {
        return false;
      }

      var user = Ember.get(this, 'user') || Ember.get(this, 'model.user') || Ember.get(this, 'model');
      return Ember.get(this, 'session').isCurrentUser(user);
    }).readOnly()
  });

  _exports.default = _default;
});