define("client/components/search-results/row", ["exports", "client/utils/get-title-field"], function (_exports, _getTitleField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    kind: Ember.computed.alias('item.kind'),
    imageUrl: Ember.computed.or('item.avatar', 'item.posterImage'),
    title: Ember.computed.or('item.name', 'computedTitle'),
    slug: Ember.computed.or('item.slug', 'item.id'),
    computedTitle: Ember.computed('session.account.titleLanguagePreference', 'item.titles', function () {
      return (0, _getTitleField.getComputedTitle)(Ember.get(this, 'session'), Ember.get(this, 'item'));
    }).readOnly(),
    imageClass: Ember.computed('item.kind', function () {
      var kind = Ember.get(this, 'item.kind');

      switch (kind) {
        case 'user':
        case 'group':
          return "is-avatar-".concat(kind, "s");

        default:
          return '';
      }
    }).readOnly(),
    route: Ember.computed('item.kind', function () {
      var kind = Ember.get(this, 'item.kind');
      if (!kind) return 'not-found';

      switch (kind) {
        case 'user':
          return 'users.index';

        case 'group':
          return 'groups.group.group-page.index';

        default:
          return "".concat(kind, ".show");
      }
    }).readOnly(),
    tags: Ember.computed('item.kind', 'item.subtype', 'item.nsfw', function () {
      var kind = Ember.get(this, 'item.kind');
      var subtype = Ember.get(this, 'item.subtype');
      var nsfw = Ember.get(this, 'item.nsfw');
      var out = [];
      if (subtype) out.push("media-shared.types.".concat(kind, ".").concat(subtype.toLowerCase()));
      if (nsfw) out.push('groups.nsfw');
      return out;
    }).readOnly()
  });

  _exports.default = _default;
});