define("client/templates/components/users/library/library-list/progress-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s8NjwYPD",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"showProgressEditor\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"ui/selectable-input\",[[25,[\"progress\"]]],[[\"min\",\"max\",\"update\",\"class\"],[0,[25,[\"max\"]],[29,\"pipe-action\",[[29,\"action\",[[24,0,[]],\"sanitizeNumber\"],null],[29,\"action\",[[24,0,[]],[25,[\"update\"]]],null]],null],\"form-control library-progress-input\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[29,\"can\",[\"edit library-entry\",[25,[\"libraryEntry\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"a\"],[11,\"href\",\"#\"],[11,\"class\",\"link\"],[9],[1,[23,\"progress\"],false],[3,\"action\",[[24,0,[]],\"showInput\"]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[23,\"progress\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"  /\\n\"],[10],[0,\"\\n\"],[7,\"span\"],[9],[1,[29,\"or\",[[25,[\"max\"]],[29,\"html-safe\",[\"&mdash;\"],null]],null],false],[10],[0,\"\\n\\n\"],[4,\"if\",[[29,\"can\",[\"edit library-entry\",[25,[\"libraryEntry\"]]],null]],null,{\"statements\":[[4,\"unless\",[[29,\"eq\",[[25,[\"libraryEntry\",\"status\"]],\"completed\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"a\"],[11,\"href\",\"#\"],[11,\"class\",\"increment-unit\"],[9],[0,\"\\n      \"],[7,\"svg\"],[11,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[11,\"width\",\"26\"],[11,\"height\",\"26\"],[11,\"viewBox\",\"0 0 26 26\"],[9],[0,\"\\n        \"],[7,\"path\"],[11,\"d\",\"M13.5,3.188C7.805,3.188,3.188,7.805,3.188,13.5S7.805,23.813,13.5,23.813S23.813,19.195,23.813,13.5 S19.195,3.188,13.5,3.188z M19,15h-4v4h-3v-4H8v-3h4V8h3v4h4V15z\"],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[3,\"action\",[[24,0,[]],[25,[\"increment\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/users/library/library-list/progress-cell.hbs"
    }
  });

  _exports.default = _default;
});