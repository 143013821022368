define("client/components/library-entry/readonly-rating", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['readonly-rating'],
    width: '20',
    height: '20',
    regularRating: Ember.computed('rating', function () {
      return Math.floor(Ember.get(this, 'rating') / 2 * 2) / 2;
    }).readOnly(),
    simpleTag: Ember.computed('rating', function () {
      var rating = Ember.get(this, 'rating');
      return this._getRatingGroup(rating);
    }).readOnly(),
    _getRatingGroup: function _getRatingGroup(rating) {
      if (rating > 0 && rating < 4) {
        return 'awful';
      }

      if (rating >= 4 && rating < 7) {
        return 'meh';
      }

      if (rating >= 7 && rating < 10) {
        return 'good';
      }

      return 'great';
    }
  });

  _exports.default = _default;
});