define("client/components/groups/tickets/ticket-conversation-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isLeader: Ember.computed('messageClass', function () {
      var messageClass = Ember.get(this, 'messageClass');
      return messageClass === 'leader-message' || messageClass === 'mod-note';
    }).readOnly(),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var kind = Ember.get(this, 'message.kind');

      if (kind === 'message') {
        var ticketAuthorId = Ember.get(this, 'ticket.user.id');

        if (ticketAuthorId !== Ember.get(this, 'message.user.id')) {
          Ember.set(this, 'messageClass', 'leader-message');
        } else {
          Ember.set(this, 'messageClass', 'other-message');
        }
      } else {
        Ember.set(this, 'messageClass', 'mod-note');
      }
    }
  });

  _exports.default = _default;
});