define("client/templates/components/lazy-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Sn0dS9kD",
    "block": "{\"symbols\":[],\"statements\":[[7,\"img\"],[12,\"class\",[30,[\"lazyload \",[23,\"imgClass\"]]]],[12,\"data-src\",[23,\"src\"]],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/lazy-image.hbs"
    }
  });

  _exports.default = _default;
});