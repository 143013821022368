define("client/models/video", ["exports", "client/models/-base", "ember-data/attr", "ember-data/relationships"], function (_exports, _base, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // TODO: move to a global location so we can reuse these
  var LANGUAGES = {
    en: 'English',
    ja: 'Japanese',
    es: 'Spanish'
  };

  var _default = _base.default.extend({
    url: (0, _attr.default)('string'),
    canonicalTitle: (0, _attr.default)('string'),
    availableRegions: (0, _attr.default)('array'),
    dubLang: (0, _attr.default)('string'),
    subLang: (0, _attr.default)('string'),
    embedData: (0, _attr.default)('object'),
    episode: (0, _relationships.belongsTo)('episode'),
    streamer: (0, _relationships.belongsTo)('streamer'),
    languageTitle: Ember.computed('dubLang', 'subLang', function () {
      var _Ember$getProperties = Ember.getProperties(this, 'subLang', 'dubLang'),
          subLang = _Ember$getProperties.subLang,
          dubLang = _Ember$getProperties.dubLang;

      if (dubLang !== 'ja') return "".concat(LANGUAGES[dubLang], " Dub");
      return "".concat(LANGUAGES[subLang], " Sub");
    })
  });

  _exports.default = _default;
});