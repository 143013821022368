define("client/mixins/routes/cover-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DISTANCE = 210;
  var HOVER_DELAY = 500;

  var _default = Ember.Mixin.create({
    isHovered: false,
    activate: function activate() {
      var _this = this;

      this._super.apply(this, arguments);

      this._handleScroll = function () {
        _this.handleScroll();
      };

      document.body.classList.add('cover-page');
      document.addEventListener('scroll', this._handleScroll);
      Ember.run.scheduleOnce('afterRender', function () {
        _this.handleScroll();

        var element = document.getElementById('kitsu-navbar');

        _this._handleMouseEnter = function () {
          Ember.run.cancel(_this.fadeTimer);
          element.classList.remove('transparent');
          Ember.set(_this, 'isHovered', true);
        };

        element.addEventListener('mouseenter', _this._handleMouseEnter);

        _this._handleMouseLeave = function () {
          window.requestAnimationFrame(function () {
            Ember.run.join(function () {
              var searchElement = document.getElementById('search');
              var scrollPoint = document.scrollingElement && document.scrollingElement.scrollTop;

              if (scrollPoint < DISTANCE && Ember.isEmpty(searchElement.value)) {
                _this.fadeTimer = Ember.run.later(function () {
                  element.classList.add('transparent');
                }, HOVER_DELAY);
              }

              Ember.set(_this, 'isHovered', false);
            });
          });
        };

        element.addEventListener('mouseleave', _this._handleMouseLeave);
      });
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);

      document.body.classList.remove('cover-page');
      document.removeEventListener('scroll', this._handleScroll);
      var element = document.getElementById('kitsu-navbar');

      if (element) {
        element.removeEventListener('mouseenter', this._handleMouseEnter);
        element.removeEventListener('mouseleave', this._handleMouseLeave);
      }

      Ember.set(this, 'isHovered', false);
    },
    handleScroll: function handleScroll() {
      var _this2 = this;

      window.requestAnimationFrame(function () {
        Ember.run.join(function () {
          var element = document.getElementById('kitsu-navbar');
          var searchElement = document.getElementById('search');

          if (document.scrollingElement && document.scrollingElement.scrollTop >= DISTANCE) {
            element.classList.remove('transparent');
          } else if (!Ember.get(_this2, 'isHovered') && Ember.isEmpty(searchElement.value)) {
            element.classList.add('transparent');
          }
        });
      });
    }
  });

  _exports.default = _default;
});