define("client/templates/feedback/feature-requests", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BTrI28zL",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"w-100\"],[11,\"data-canny\",\"\"],[9],[10],[0,\"\\n\"],[1,[29,\"canny-feedback-script\",null,[[\"token\",\"boardToken\",\"path\"],[[25,[\"model\",\"token\"]],\"78c1c61a-55ee-bc40-d5ca-158b5c97e9c3\",\"/feedback/feature-requests\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/feedback/feature-requests.hbs"
    }
  });

  _exports.default = _default;
});