define("client/models/media-relationship", ["exports", "client/models/-base", "ember-data/attr", "ember-data/relationships"], function (_exports, _base, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    role: (0, _attr.default)('string'),
    source: (0, _relationships.belongsTo)('media', {
      inverse: 'mediaRelationships'
    }),
    destination: (0, _relationships.belongsTo)('media')
  });

  _exports.default = _default;
});