define("client/templates/components/users/library/library-list/loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9mBv6OY6",
    "block": "{\"symbols\":[\"_\"],\"statements\":[[4,\"each\",[[29,\"repeat\",[4],null]],null,{\"statements\":[[4,\"skeleton-container\",null,[[\"class\"],[\"library-list-skeleton\"]],{\"statements\":[[0,\"    \"],[1,[29,\"skeleton-loader\",null,[[\"class\"],[\"library-list-top\"]]],false],[0,\"\\n    \"],[1,[29,\"skeleton-loader\",null,[[\"class\"],[\"library-list-bottom\"]]],false],[0,\"\\n    \"],[1,[29,\"skeleton-loader\",null,[[\"class\"],[\"library-list-start\"]]],false],[0,\"\\n    \"],[1,[29,\"skeleton-loader\",null,[[\"class\"],[\"library-list-title\"]]],false],[0,\"\\n    \"],[1,[29,\"skeleton-loader\",null,[[\"class\"],[\"library-list-end\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/users/library/library-list/loader.hbs"
    }
  });

  _exports.default = _default;
});