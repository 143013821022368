define("client/templates/groups/group/dashboard/leaders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x53/DpIV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row leader-wrapper\"],[9],[0,\"\\n    \"],[1,[29,\"groups/dashboard/leaders/leader-chat\",null,[[\"group\",\"membership\",\"class\"],[[25,[\"group\"]],[25,[\"membership\"]],\"leader-chat\"]]],false],[0,\"\\n\\n    \"],[1,[29,\"groups/leaders/leader-list\",null,[[\"group\",\"class\"],[[25,[\"group\"]],\"leader-table-card card\"]]],false],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"row audit-row\"],[9],[0,\"\\n    \"],[1,[29,\"groups/dashboard/audit-log\",null,[[\"group\"],[[25,[\"group\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/groups/group/dashboard/leaders.hbs"
    }
  });

  _exports.default = _default;
});