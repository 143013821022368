define("client/templates/components/explore/category-sidebar/related-categories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cfZzeSBK",
    "block": "{\"symbols\":[\"_\"],\"statements\":[[7,\"div\"],[11,\"class\",\"card is-sticky related-categories\"],[9],[0,\"\\n  \"],[7,\"h5\"],[11,\"class\",\"text-uppercase\"],[9],[1,[29,\"t\",[\"explore.widget.related\"],null],false],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"getRelatedCategoriesTask\",\"isRunning\"]]],null,{\"statements\":[[4,\"skeleton-container\",null,[[\"class\"],[\"category-skeleton\"]],{\"statements\":[[4,\"each\",[[29,\"repeat\",[6],null]],null,{\"statements\":[[0,\"        \"],[1,[29,\"skeleton-loader\",null,[[\"class\"],[\"category-skeleton-horizontal\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[1,[29,\"skeleton-loader\",null,[[\"class\"],[\"category-skeleton-vertical\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[29,\"explore/category-sidebar/category-list\",null,[[\"categories\"],[[25,[\"categories\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/explore/category-sidebar/related-categories.hbs"
    }
  });

  _exports.default = _default;
});