define("client/models/genre", ["exports", "ember-data/attr", "client/models/-base"], function (_exports, _attr, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    name: (0, _attr.default)('string'),
    slug: (0, _attr.default)('string'),
    description: (0, _attr.default)('string')
  });

  _exports.default = _default;
});