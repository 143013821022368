define("client/templates/posts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NZ8WejGe",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"full-page-post centered\"],[9],[0,\"\\n  \"],[1,[29,\"stream-feed/items/post\",null,[[\"post\",\"isExpanded\",\"readOnly\",\"commentSort\",\"isPermalinkPage\",\"isModalView\"],[[25,[\"model\"]],true,[29,\"not\",[[25,[\"session\",\"hasUser\"]]],null],\"oldest\",true,true]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/posts.hbs"
    }
  });

  _exports.default = _default;
});