define("client/components/ui/selectable-input", ["exports", "ember-one-way-controls/components/one-way-number"], function (_exports, _oneWayNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _oneWayNumber.default.extend({
    click: function click() {
      Ember.get(this, 'element').select();
    }
  });

  _exports.default = _default;
});