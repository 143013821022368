define("client/components/users/profile-links", ["exports", "client/mixins/clipboard"], function (_exports, _clipboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_clipboard.default, {
    notify: Ember.inject.service(),
    actions: {
      copyNotification: function copyNotification(content) {
        Ember.get(this, 'notify').success("".concat(content, " was copied to your clipboard!"));
      }
    }
  });

  _exports.default = _default;
});