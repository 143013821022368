define("client/templates/components/ad-unit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dZexX7o3",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[29,\"and\",[[25,[\"session\",\"hasUser\"]],[25,[\"session\",\"account\",\"isPro\"]]],null]],null,{\"statements\":[],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"isEnabled\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"id\",[23,\"adUnitId\"]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"empty-container\"],[9],[0,\"\\n    \"],[7,\"p\"],[9],[1,[29,\"t\",[\"components.ad-unit\"],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/ad-unit.hbs"
    }
  });

  _exports.default = _default;
});