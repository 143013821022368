define("client/initializers/inflections", ["exports", "client/config/environment", "ember-inflector"], function (_exports, _environment, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    _emberInflector.default.inflector.uncountable('anime');

    _emberInflector.default.inflector.uncountable('manga');
  }

  var result = {
    name: 'inflections',
    initialize: initialize
  }; // run before mirage in testing, we can't do this by default as mirage doesn't include its files
  // in other environments.

  if (_environment.default.environment === 'test') {// Object.assign(result, { before: 'ember-cli-mirage' });
  }

  var _default = result;
  _exports.default = _default;
});