define("client/components/episodes/video-picker/language-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['language-selector'],
    // Videos to choose from
    videos: [],
    selectedVideo: null,
    // Events
    onChange: function onChange() {}
  });

  _exports.default = _default;
});