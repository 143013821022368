define("client/routes/groups/group/dashboard", ["exports", "ember-can", "ember-simple-auth/mixins/authenticated-route-mixin", "jquery"], function (_exports, _emberCan, _authenticatedRouteMixin, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _emberCan.CanMixin, {
    authenticationRoute: 'dashboard',
    ajax: Ember.inject.service(),
    model: function model() {
      var group = this.modelFor('groups.group');
      return Ember.RSVP.hash({
        group: group,
        membership: Ember.get(group, 'userMembership'),
        stats: Ember.get(this, 'ajax').request("/groups/".concat(Ember.get(group, 'id'), "/_stats"))
      });
    },
    afterModel: function afterModel(model) {
      var membership = Ember.get(model, 'membership');

      if (!this.can('view dashboard for group', {
        membership: membership
      })) {
        this.transitionTo('groups.group.group-page.index');
      }
    },
    activate: function activate() {
      this._super.apply(this, arguments);

      (0, _jquery.default)('body').addClass('settings-page');
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);

      (0, _jquery.default)('body').removeClass('settings-page');
    }
  });

  _exports.default = _default;
});