define("client/components/stream-feed/items/post", ["exports", "ember-invoke-action", "ember-can", "client/utils/getter", "client/mixins/clipboard", "client/utils/error-messages"], function (_exports, _emberInvokeAction, _emberCan, _getter, _clipboard, _errorMessages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_clipboard.default, _emberCan.CanMixin, {
    classNameBindings: ['post.isNew:new-post', 'isPinnedPost:pinned-post', 'post.id:stream-item'],
    classNames: ['row'],
    isHidden: false,
    showNsfw: false,
    showSpoilers: false,
    isFollowingPost: false,
    isPermalink: false,
    notify: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    queryCache: Ember.inject.service(),
    metrics: Ember.inject.service(),
    host: (0, _getter.default)(function () {
      return "".concat(window.location.protocol, "//").concat(window.location.host);
    }),
    activity: (0, _getter.default)(function () {
      return Ember.get(this, 'group.activities.firstObject');
    }),
    postUnitText: (0, _getter.default)(function () {
      var unit = Ember.get(this, 'post.spoiledUnit');
      var title = Ember.get(unit, 'canonicalTitle');
      var placeHolderTitle = "".concat(Ember.String.capitalize(Ember.get(unit, 'modelType')), " ").concat(Ember.get(unit, 'number'));
      return Ember.isEmpty(title) || title === placeHolderTitle ? '' : "- ".concat(title);
    }),
    postEdited: Ember.computed('post.createdAt', 'post.editedAt', function () {
      if (!Ember.get(this, 'post.editedAt')) {
        return false;
      }

      return !Ember.get(this, 'post.createdAt').isSame(Ember.get(this, 'post.editedAt'));
    }).readOnly(),
    canEditPost: Ember.computed(function () {
      var group = Ember.get(this, 'post').belongsTo('targetGroup').value();

      if (group) {
        return this.can('edit post in group', group, {
          membership: Ember.get(this, 'groupMembership'),
          post: Ember.get(this, 'post')
        });
      }

      return this.can('edit post', Ember.get(this, 'post'));
    }).readOnly(),
    _streamAnalytics: function _streamAnalytics(label, foreignId) {
      var data = {
        label: label,
        content: {
          foreign_id: foreignId
        },
        position: Ember.get(this, 'positionInFeed') || 0
      };

      if (Ember.get(this, 'feedId') !== undefined) {
        data.feed_id = Ember.get(this, 'feedId');
      }

      Ember.get(this, 'metrics').invoke('trackEngagement', 'Stream', data);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.set(this, 'isPermalink', Ember.get(this, 'router.currentRouteName') === 'posts');

      if (Ember.get(this, 'group') !== undefined) {
        if (Ember.get(this, 'activity.foreignId').split(':')[0] === 'Comment') {
          Ember.set(this, 'post', Ember.get(this, 'activity.target.content') || Ember.get(this, 'activity.target'));
        } else {
          Ember.set(this, 'post', Ember.get(this, 'activity.subject.content') || Ember.get(this, 'activity.subject'));
        }
      }

      if (Ember.get(this, 'feedId') !== undefined) {
        Ember.set(this, 'userId', Ember.get(this, 'feedId').split(':')[1]);
      }

      var post = Ember.get(this, 'post');
      var hideNsfw = Ember.get(post, 'nsfw') && !Ember.get(this, 'showNsfw');
      var hideSpoilers = Ember.get(post, 'spoiler') && !(Ember.get(this, 'showSpoilers') && !Ember.get(post, 'spoiledUnit'));

      if (Ember.get(post, 'user') && Ember.get(this, 'session').isCurrentUser(Ember.get(post, 'user'))) {
        Ember.set(this, 'isHidden', hideNsfw);
      } else {
        Ember.set(this, 'isHidden', hideNsfw || hideSpoilers);
      } // groups


      if (Ember.get(post, 'id') && Ember.get(this, 'session.hasUser')) {
        var group = post.belongsTo('targetGroup').value();

        if (group) {
          if (Ember.get(this, 'kitsuGroupMembership')) {
            Ember.set(this, 'groupMembership', Ember.get(this, 'kitsuGroupMembership'));
          } else {
            Ember.get(this, 'queryCache').query('group-member', {
              filter: {
                group: Ember.get(group, 'id'),
                user: Ember.get(this, 'session.account.id')
              },
              include: 'permissions'
            }).then(function (records) {
              var record = Ember.get(records, 'firstObject');
              Ember.set(_this, 'groupMembership', record);
            }).catch(function () {});
          }
        }
      } // follows


      if (Ember.get(post, 'id')) {
        if (Ember.get(this, 'session.hasUser') && !Ember.get(this, 'session').isCurrentUser(Ember.get(this, 'post.user'))) {
          this._updateFollow();
        }
      }
    },
    _updateHidden: Ember.observer('post.nsfw', 'post.spoiler', function () {
      var post = Ember.get(this, 'post');
      Ember.set(this, 'isHidden', Ember.get(post, 'nsfw') || Ember.get(post, 'spoiler'));
    }),
    _updateFollow: function _updateFollow() {
      var _this2 = this;

      Ember.get(this, 'queryCache').query('post-follow', {
        filter: {
          user_id: Ember.get(this, 'session.account.id'),
          post_id: Ember.get(this, 'post.id')
        }
      }).then(function (records) {
        if (Ember.get(_this2, 'isDestroyed')) {
          return;
        }

        var record = Ember.get(records, 'firstObject');
        Ember.set(_this2, 'isFollowingPost', !!record);
        Ember.set(_this2, 'followRelationship', record);
      });
    },
    actions: {
      trackEngagement: function trackEngagement(label, id) {
        var foreignId = Ember.typeOf(id) === 'string' ? id : undefined;

        this._streamAnalytics(label, foreignId || "Post:".concat(Ember.get(this, 'post.id')));
      },
      followPost: function followPost() {
        var _this3 = this;

        if (Ember.get(this, 'isFollowingPost')) {
          Ember.get(this, 'followRelationship').destroyRecord().then(function () {
            Ember.set(_this3, 'isFollowingPost', false);
            Ember.set(_this3, 'followRelationship', null);
          });
        } else {
          var follow = Ember.get(this, 'store').createRecord('post-follow', {
            user: Ember.get(this, 'session.account'),
            post: Ember.get(this, 'post')
          });
          follow.save().then(function () {
            Ember.set(_this3, 'followRelationship', follow);
            Ember.set(_this3, 'isFollowingPost', true);
          });
        }
      },
      updateFollow: function updateFollow() {
        this._updateFollow();
      },
      hideGroup: function hideGroup(group) {
        var _this4 = this;

        Ember.get(this, 'store').query('group-member', {
          filter: {
            group: Ember.get(group, 'id'),
            user: Ember.get(this, 'session.account.id')
          }
        }).then(function (records) {
          var membership = Ember.get(records, 'firstObject');
          Ember.set(membership, 'hidden', true);
          membership.save().then(function () {
            (0, _emberInvokeAction.invokeAction)(_this4, 'removeGroup', Ember.get(_this4, 'group'));
          }).catch(function (err) {
            Ember.get(_this4, 'notify').error((0, _errorMessages.default)(err));
            membership.rollbackAttributes();
          });
        });
      },
      hideUser: function hideUser(user) {
        var _this5 = this;

        var currentUser = Ember.get(this, 'session.account.id');
        Ember.get(this, 'store').query('follow', {
          filter: {
            follower: currentUser,
            followed: Ember.get(user, 'id')
          }
        }).then(function (records) {
          var follow = Ember.get(records, 'firstObject');
          Ember.set(follow, 'hidden', true);
          follow.save().then(function () {
            (0, _emberInvokeAction.invokeAction)(_this5, 'removeGroup', Ember.get(_this5, 'group'));
          }).catch(function (err) {
            Ember.get(_this5, 'notify').error((0, _errorMessages.default)(err));
            follow.rollbackAttributes();
          });
        });
      },
      ignoreMedia: function ignoreMedia(media) {
        var _this6 = this;

        var user = Ember.get(this, 'session.account');
        Ember.get(this, 'store').createRecord('media-ignore', {
          user: user,
          media: media
        }).save().then(function () {
          (0, _emberInvokeAction.invokeAction)(_this6, 'removeGroup', Ember.get(_this6, 'group'));
        }).catch(function (err) {
          Ember.get(_this6, 'notify').error((0, _errorMessages.default)(err));
        });
      },
      toggleHidden: function toggleHidden() {
        this.toggleProperty('isHidden');
      },
      likeCreated: function likeCreated() {
        Ember.get(this, 'session.account').incrementProperty('likesGivenCount');
        (0, _emberInvokeAction.invoke)(this, 'trackEngagement', 'like');
      },
      deletePost: function deletePost() {
        var _this7 = this;

        if (Ember.get(this, 'post.isDeleted')) {
          return;
        }

        Ember.get(this, 'post').destroyRecord().then(function () {
          // this post is being deleted from its permalink page
          if (Ember.get(_this7, 'group') === undefined) {
            Ember.get(_this7, 'router').transitionTo('dashboard');
          } else {
            // try to find the activity-group that references this post
            var record = Ember.get(_this7, 'store').peekRecord('activity-group', Ember.get(_this7, 'group.id')); // might be a new activity-group that doesn't have a set id

            if (record === null || record === undefined) {
              record = Ember.get(_this7, 'store').peekAll('activity-group').find(function (group) {
                return Ember.get(group, 'activities').findBy('foreignId', "Post:".concat(Ember.get(_this7, 'post.id')));
              });
            }

            (0, _emberInvokeAction.invokeAction)(_this7, 'removeGroup', record);
          }

          Ember.get(_this7, 'notify').success('Success! Your post has been deleted.');
        }).catch(function (err) {
          Ember.get(_this7, 'post').rollbackAttributes();
          Ember.get(_this7, 'notify').error((0, _errorMessages.default)(err));
        });
      },
      pinOrUnpinPost: function pinOrUnpinPost() {
        var _this8 = this;

        var post = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
        var user = Ember.get(this, 'session.account');
        Ember.set(user, 'pinnedPost', post);
        user.save().then(function () {
          var pastAction = post === null ? 'Your post has been unpinned.' : 'Your post has been pinned.';
          Ember.get(_this8, 'notify').success("Success! ".concat(pastAction));
        });
      }
    }
  });

  _exports.default = _default;
});