define("client/mixins/flickity-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Enables the `ember-href-to` `data-href-to-ignore` property if Flickity is in
   * a dragging state.
   */
  var _default = Ember.Mixin.create({
    isFlickityDragging: false,
    _onDragStart: function _onDragStart() {
      Ember.set(this, 'isFlickityDragging', true);
      this.$('a[href^="/"]').attr('data-href-to-ignore', true);
    },
    _onDragEnd: function _onDragEnd() {
      var _this = this;

      // 250ms later so that we don't instantly remove the attribute and then have href-to proc.
      Ember.run.later(function () {
        Ember.set(_this, 'isFlickityDragging', false);

        var elements = _this.$('a[href^="/"]');

        if (elements) {
          elements.removeAttr('data-href-to-ignore');
        }
      }, 250);
    },
    actions: {
      flickityOnDragStart: function flickityOnDragStart() {
        this._onDragStart();
      },
      flickityOnDragEnd: function flickityOnDragEnd() {
        this._onDragEnd();
      }
    }
  });

  _exports.default = _default;
});