define("client/routes/media/show/units/index", ["exports", "kitsu-shared/mixins/pagination"], function (_exports, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Route.extend(_pagination.default, {
    templateName: 'media/show/units/index',
    intl: Ember.inject.service(),
    model: function model() {
      var media = this._getParentModel();

      var mediaType = Ember.String.capitalize(Ember.get(media, 'modelType'));
      var unitType;
      var filter;

      if (mediaType === 'Anime') {
        unitType = 'episode';
        filter = {
          mediaType: mediaType,
          media_id: Ember.get(media, 'id')
        };
      } else {
        unitType = 'chapter';
        filter = {
          manga_id: Ember.get(media, 'id')
        };
      }

      return {
        taskInstance: this.queryPaginated(unitType, {
          filter: filter,
          sort: 'number'
        }),
        paginatedRecords: []
      };
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      var parentRoute = Ember.get(this, 'routeName').split('.').slice(0, 2).join('.');
      var parentController = this.controllerFor(parentRoute);
      Ember.set(controller, 'parent', parentController);
    },
    titleToken: function titleToken() {
      var media = this._getParentModel();

      return Ember.get(this, 'intl').t('titles.media.show.units', {
        type: Ember.get(media, 'modelType')
      });
    },
    _getParentModel: function _getParentModel() {
      var _Ember$get$split = Ember.get(this, 'routeName').split('.'),
          _Ember$get$split2 = _slicedToArray(_Ember$get$split, 1),
          mediaType = _Ember$get$split2[0];

      return this.modelFor("".concat(mediaType, ".show"));
    }
  });

  _exports.default = _default;
});