define("client/templates/feedback/mobile-bugs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LKOBjS/k",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"w-100\"],[11,\"data-canny\",\"\"],[9],[10],[0,\"\\n\"],[1,[29,\"canny-feedback-script\",null,[[\"token\",\"boardToken\",\"path\"],[[25,[\"model\",\"token\"]],\"b3f2fed5-ca07-bf13-dbbb-6e9fea351064\",\"/feedback/mobile-bugs\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/feedback/mobile-bugs.hbs"
    }
  });

  _exports.default = _default;
});