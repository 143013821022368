define("client/components/media-popover", ["exports", "client/mixins/hover-intent"], function (_exports, _hoverIntent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_hoverIntent.default, {
    hoverTimeout: 0,

    /** Determines what class to use based on the percentage value. */
    percentageClass: Ember.computed('media.averageRating', function () {
      if (!Ember.get(this, 'media.averageRating')) {
        return '';
      }

      var rating = Ember.get(this, 'media.averageRating');

      if (rating <= 25) {
        return 'percent-quarter-1';
      }

      if (rating <= 50) {
        return 'percent-quarter-2';
      }

      if (rating <= 75) {
        return 'percent-quarter-3';
      }

      if (rating <= 100) {
        return 'percent-quarter-4';
      }
    }).readOnly()
  });

  _exports.default = _default;
});