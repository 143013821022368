define("client/templates/components/media/media-rankings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "erBF4Krt",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"media\",\"popularityRank\"]]],null,{\"statements\":[[0,\"  \"],[7,\"a\"],[12,\"href\",[29,\"href-to\",[\"explore.more\",[25,[\"media\",\"modelType\"]],\"most-popular\"],null]],[11,\"class\",\"ranking ranking-popularity\"],[9],[0,\"\\n    \"],[1,[29,\"svg-jar\",[\"heart-filled\"],null],false],[0,\"\\n    \"],[1,[29,\"t\",[[29,\"concat\",[\"media.show.summary.ranking.\",[25,[\"length\"]],\".popularity\"],null]],[[\"type\",\"rank\"],[[25,[\"media\",\"modelType\"]],[25,[\"media\",\"popularityRank\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[25,[\"media\",\"ratingRank\"]]],null,{\"statements\":[[0,\"  \"],[7,\"a\"],[12,\"href\",[29,\"href-to\",[\"explore.more\",[25,[\"media\",\"modelType\"]],\"highest-rated\"],null]],[11,\"class\",\"ranking ranking-rating\"],[9],[0,\"\\n    \"],[1,[29,\"svg-jar\",[\"star\"],null],false],[0,\"\\n    \"],[1,[29,\"t\",[[29,\"concat\",[\"media.show.summary.ranking.\",[25,[\"length\"]],\".rating\"],null]],[[\"type\",\"rank\"],[[25,[\"media\",\"modelType\"]],[25,[\"media\",\"ratingRank\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/media/media-rankings.hbs"
    }
  });

  _exports.default = _default;
});