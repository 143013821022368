define("client/utils/error-messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = errorMessages;
  // http://tools.ietf.org/html/rfc6749#section-5.2
  var lookupTable = {
    invalid_grant: 'The provided credentials are invalid.'
  };

  function errorMessages(obj) {
    var defaultMessage = 'There was an issue with your request, please try again.';
    var reason = defaultMessage;

    if (obj === undefined) {
      return reason;
    }

    var errors = Ember.get(obj, 'payload') || Ember.get(obj, 'errors') || Ember.get(obj, 'error');
    errors = errors === undefined ? Ember.get(obj, 'jqXHR.responseJSON.errors') : errors;

    if (Ember.isPresent(errors)) {
      reason = Ember.isArray(errors) ? Ember.String.capitalize(Ember.get(errors[0], 'detail') || Ember.get(errors[0], 'title')) : Ember.get(lookupTable, errors);
    }

    if (!reason || reason === 'Internal Server Error' || reason.charAt(0) === '<') {
      reason = defaultMessage;
    }

    return reason;
  }
});