define("client/templates/components/episodes/video-player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OoiZU/HV",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"loading\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"loading-spinner\",null,[[\"size\"],[\"large\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[1,[29,\"episodes/video-player/hulu-player\",null,[[\"onProgress\",\"video\",\"onLoad\",\"isPlaying\"],[[29,\"action\",[[24,0,[]],[25,[\"onProgress\"]]],null],[25,[\"video\"]],[29,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"loading\"]]],null],false],null],[25,[\"isPlaying\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/episodes/video-player.hbs"
    }
  });

  _exports.default = _default;
});