define("client/templates/components/media/filter-widgets/category-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kYFtGwdW",
    "block": "{\"symbols\":[\"category\",\"_\"],\"statements\":[[7,\"div\"],[11,\"class\",\"filter-header\"],[9],[0,\"\\n  \"],[7,\"label\"],[11,\"class\",\"filter-title\"],[9],[0,\"\\n    \"],[1,[29,\"t\",[\"media.index.filters.categories\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"filter-values\"],[9],[0,\"\\n    \"],[7,\"span\"],[9],[0,\"All\"],[3,\"action\",[[24,0,[]],\"resetCategories\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"getCategoriesTask\",\"isRunning\"]]],null,{\"statements\":[[4,\"each\",[[29,\"repeat\",[6],null]],null,{\"statements\":[[4,\"skeleton-container\",null,[[\"class\"],[\"category-filter-skeleton\"]],{\"statements\":[[0,\"      \"],[1,[29,\"skeleton-loader\",null,[[\"class\"],[\"category-filter-skeleton-item\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[2]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"ul\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"sortedCategories\"]]],null,{\"statements\":[[0,\"      \"],[1,[29,\"media/filter-widgets/category-item\",null,[[\"category\",\"selection\",\"updateCategories\",\"class\",\"tagName\"],[[24,1,[]],[25,[\"selection\"]],[29,\"action\",[[24,0,[]],\"updateCategories\"],null],\"media-category-filter\",\"li\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/media/filter-widgets/category-list.hbs"
    }
  });

  _exports.default = _default;
});