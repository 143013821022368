define("client/controllers/application", ["exports", "ember-parachute", "client/config/environment"], function (_exports, _emberParachute, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var queryParams = new _emberParachute.default({
    notification: {
      defaultValue: null
    }
  });

  var _default = Ember.Controller.extend(queryParams.Mixin, {
    isStaging: _environment.default.kitsu.isStaging,
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    queryParamsDidChange: function queryParamsDidChange(_ref) {
      var _this = this;

      var notification = _ref.changed.notification;

      if (notification && Ember.get(this, 'session.hasUser')) {
        this._markNotificationRead(notification).finally(function () {
          Ember.set(_this, 'notification', null);
        });
      }
    },
    _markNotificationRead: function _markNotificationRead(notification) {
      var _this2 = this;

      // send off read event
      var feedUrl = "/feeds/notifications/".concat(Ember.get(this, 'session.account.id'), "/_read");
      return Ember.get(this, 'ajax').request(feedUrl, {
        method: 'POST',
        data: JSON.stringify([notification]),
        contentType: 'application/json'
      }).then(function () {
        // reset default value
        var trackedItem = Ember.get(_this2, 'store').peekAll('notification').findBy('streamId', notification);

        if (trackedItem) {
          Ember.set(trackedItem, 'isRead', true);
        }
      });
    }
  });

  _exports.default = _default;
});