define("client/components/groups/group-actions", ["exports", "client/utils/error-messages"], function (_exports, _errorMessages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['group-actions'],
    notify: Ember.inject.service(),
    actions: {
      editHidden: function editHidden(hidden) {
        var _this = this;

        var membership = Ember.get(this, 'membership');
        Ember.set(membership, 'hidden', hidden);
        membership.save().catch(function (err) {
          Ember.get(_this, 'notify').error((0, _errorMessages.default)(err));
          membership.rollbackAttributes();
        });
      }
    }
  });

  _exports.default = _default;
});