define("client/templates/components/ui/toggle-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iPntmbJd",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"one-way-checkbox\",[[25,[\"value\"]]],[[\"update\",\"id\"],[[25,[\"update\"]],[29,\"concat\",[[25,[\"elementId\"]],\"-toggle\"],null]]]],false],[0,\"\\n\"],[7,\"label\"],[12,\"for\",[30,[[23,\"elementId\"],\"-toggle\"]]],[9],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/ui/toggle-input.hbs"
    }
  });

  _exports.default = _default;
});