define("client/components/file-select", ["exports", "ember-one-way-controls/components/one-way-file", "ember-invoke-action"], function (_exports, _oneWayFile, _emberInvokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _oneWayFile.default.extend({
    // form is hidden by default
    classNameBindings: ['isHidden:hidden-xs-up'],
    isHidden: true,
    change: function change(event) {
      (0, _emberInvokeAction.invokeAction)(this, 'update', event.target);
    }
  });

  _exports.default = _default;
});