define("client/models/linked-account", ["exports", "client/models/-base", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _base, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    externalUserId: [(0, _emberCpValidations.validator)('presence', true)],
    token: [(0, _emberCpValidations.validator)('presence', true)]
  });

  var _default = _base.default.extend(Validations, {
    disabledReason: (0, _attr.default)('string'),
    externalUserId: (0, _attr.default)('string'),
    kind: (0, _attr.default)('string'),
    shareFrom: (0, _attr.default)('boolean'),
    shareTo: (0, _attr.default)('boolean'),
    syncTo: (0, _attr.default)('boolean'),
    token: (0, _attr.default)('string'),
    user: (0, _relationships.belongsTo)('user'),
    libraryEntryLogs: (0, _relationships.hasMany)('library-entry-log', {
      async: false
    })
  });

  _exports.default = _default;
});