define("client/templates/feedback/database-requests", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/LY3kXki",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"w-100\"],[11,\"data-canny\",\"\"],[9],[10],[0,\"\\n\"],[1,[29,\"canny-feedback-script\",null,[[\"token\",\"boardToken\",\"path\"],[[25,[\"model\",\"token\"]],\"91febb25-fc04-e80a-c5c4-0dcd7028a4ac\",\"/feedback/database-requests\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/feedback/database-requests.hbs"
    }
  });

  _exports.default = _default;
});