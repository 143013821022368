define("client/templates/components/stream-feed/items/library-entry/activities", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o93y5Fdn",
    "block": "{\"symbols\":[\"group\"],\"statements\":[[4,\"each\",[[25,[\"groupedActivities\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"stream-feed/items/library-entry/activity\",null,[[\"tagName\",\"class\",\"group\",\"media\",\"activity\",\"deleteActivity\",\"removeGroup\",\"onDelete\"],[\"li\",\"agg-item\",[24,1,[]],[25,[\"media\"]],[24,1,[\"activity\"]],[25,[\"deleteActivity\"]],[25,[\"removeGroup\"]],[29,\"action\",[[24,0,[]],\"onDelete\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/stream-feed/items/library-entry/activities.hbs"
    }
  });

  _exports.default = _default;
});