define("client/templates/components/users/user-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U86wHIh6",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\"],[12,\"href\",[29,\"href-to\",[\"users.index\",[25,[\"user\"]]],null]],[9],[0,\"\\n  \"],[7,\"img\"],[11,\"class\",\"card-img-top\"],[12,\"src\",[29,\"image\",[[25,[\"user\",\"coverImage\"]],[29,\"if\",[[25,[\"short\"]],\"original\",\"tiny\"],null]],null]],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"card-block\"],[9],[0,\"\\n  \"],[7,\"a\"],[12,\"href\",[29,\"href-to\",[\"users.index\",[25,[\"user\"]]],null]],[9],[0,\"\\n    \"],[7,\"img\"],[11,\"class\",\"card-img-avatar\"],[12,\"src\",[29,\"image\",[[25,[\"user\",\"avatar\"]],[29,\"if\",[[25,[\"short\"]],\"large\",\"small\"],null]],null]],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"a\"],[12,\"href\",[29,\"href-to\",[\"users.index\",[25,[\"user\"]]],null]],[9],[0,\"\\n    \"],[7,\"h4\"],[11,\"class\",\"card-title\"],[9],[1,[25,[\"user\",\"name\"]],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[29,\"not\",[[29,\"is-self\",[[25,[\"user\"]]],null]],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"card-actions\"],[9],[0,\"\\n      \"],[1,[29,\"follow-button\",null,[[\"user\",\"relationship\"],[[25,[\"user\"]],[25,[\"follow\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[25,[\"follow\"]]],null,{\"statements\":[[0,\"        \"],[1,[29,\"users/user-actions\",null,[[\"user\",\"follow\"],[[25,[\"user\"]],[25,[\"follow\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"card-actions\"],[9],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"button button--primary\"],[11,\"disabled\",\"disabled\"],[9],[0,\"\\n        \"],[1,[29,\"t\",[\"components.users.you\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/users/user-card.hbs"
    }
  });

  _exports.default = _default;
});