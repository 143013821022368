define("client/templates/components/users/profile-links", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nilkCeYz",
    "block": "{\"symbols\":[\"profile\"],\"statements\":[[4,\"each\",[[29,\"reject-by\",[\"isNew\",true,[25,[\"user\",\"profileLinks\"]]],null]],null,{\"statements\":[[4,\"if\",[[24,1,[\"isURL\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\"],[12,\"href\",[24,1,[\"url\"]]],[11,\"target\",\"_blank\"],[11,\"rel\",\"noopener noreferrer\"],[11,\"class\",\"profile-icon hint--top hint--bounce hint--rounded\"],[12,\"aria-label\",[24,1,[\"site\",\"name\"]]],[9],[0,\"\\n      \"],[1,[29,\"svg-jar\",[[29,\"profile-link-to-svg\",[[24,1,[\"site\",\"name\"]]],null]],null],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"a\"],[11,\"href\",\"javascript:void();\"],[11,\"class\",\"profile-icon hint--top hint--bounce hint--rounded\"],[12,\"aria-label\",[29,\"concat\",[[24,1,[\"site\",\"name\"]],\": \",[24,1,[\"url\"]]],null]],[12,\"data-clipboard-text\",[24,1,[\"url\"]]],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"copyNotification\",[24,1,[\"url\"]]],null]],[9],[0,\"\\n      \"],[1,[29,\"svg-jar\",[[29,\"profile-link-to-svg\",[[24,1,[\"site\",\"name\"]]],null]],null],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/users/profile-links.hbs"
    }
  });

  _exports.default = _default;
});