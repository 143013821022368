define("client/models/category", ["exports", "ember-data/attr", "ember-data/relationships", "client/models/-base"], function (_exports, _attr, _relationships, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    childCount: (0, _attr.default)('number'),
    description: (0, _attr.default)('string'),
    image: (0, _attr.default)('object'),
    slug: (0, _attr.default)('string'),
    title: (0, _attr.default)('string'),
    parent: (0, _relationships.belongsTo)('category', {
      inverse: null
    }),
    anime: (0, _relationships.hasMany)('anime'),
    manga: (0, _relationships.hasMany)('manga')
  });

  _exports.default = _default;
});