define("client/components/likeable-resource/modal", ["exports", "ember-invoke-action", "client/utils/computed-macros", "kitsu-shared/mixins/pagination"], function (_exports, _emberInvokeAction, _computedMacros, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_pagination.default, {
    limit: 20,
    router: Ember.inject.service(),
    model: (0, _computedMacros.concat)('likes', 'paginatedRecords'),
    isDisabled: Ember.computed('isLoading', function () {
      return Ember.get(this, 'isLoading') || !Ember.get(this, 'hasNextPage');
    }).readOnly(),
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      var likesCount = Ember.get(this, 'likes.length');

      if (likesCount === 0) {
        (0, _emberInvokeAction.strictInvokeAction)(this, 'getLikes').then(function (records) {
          _this.updatePageState(records);
        });
      } else if (likesCount <= 20) {
        this.updatePageState(Ember.get(this, 'likes'));
        Ember.set(this, 'isLoading', true);
        var limit = Ember.get(this, 'limit');
        var options = {
          page: {
            limit: limit,
            offset: likesCount
          }
        };

        this._doPaginationRequest(null, options).finally(function () {
          Ember.set(_this, 'isLoading', false);
        });
      }
    },
    onPagination: function onPagination() {
      this._super.apply(this, arguments);

      Ember.set(this, 'isLoading', false);
      (0, _emberInvokeAction.strictInvokeAction)(this, 'updateLikes', Ember.get(this, 'model'));
      Ember.set(this, 'paginatedRecords', []);
    },
    actions: {
      onPagination: function onPagination() {
        Ember.set(this, 'isLoading', true);

        this._super.apply(this, arguments);
      },
      transitionToUser: function transitionToUser(user) {
        var _this2 = this;

        this.$('.modal').on('hidden.bs.modal', function () {
          Ember.get(_this2, 'router').transitionTo('users.index', user);
        }).modal('hide');
      }
    }
  });

  _exports.default = _default;
});