define("client/controllers/users/groups", ["exports", "ember-parachute", "client/utils/computed-macros"], function (_exports, _emberParachute, _computedMacros) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var queryParams = new _emberParachute.default({
    category: {
      defaultValue: 'all',
      refresh: true
    },
    sort: {
      defaultValue: 'newest',
      refresh: true
    },
    query: {
      defaultValue: '',
      refresh: true
    },
    preserveScrollPosition: {
      defaultValue: true
    }
  });

  var _default = Ember.Controller.extend(queryParams.Mixin, {
    sortOptions: [{
      key: 'newest',
      value: 'users.groups.sort.newest'
    }, {
      key: 'oldest',
      value: 'users.groups.sort.oldest'
    }],
    groups: (0, _computedMacros.concat)('model.taskInstance.value', 'model.paginatedRecords'),
    queryParamsDidChange: function queryParamsDidChange(_ref) {
      var shouldRefresh = _ref.shouldRefresh;

      if (shouldRefresh) {
        this.send('refreshModel');
      }
    },
    actions: {
      updateQueryParam: function updateQueryParam(property, value) {
        Ember.set(this, property, value);
      }
    }
  });

  _exports.default = _default;
});